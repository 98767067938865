import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";

interface Actions {
	changeSelectedClients: (value: string | string[]) => {
		error: string | null;
	};
	changeSelectedEmployee: (value: string) => {
		error: string | null;
	};
	changeSelectedJobItemType: (value: string) => {
		error: string | null;
	};
}

export type StatisticsOptionsSlice = State & Actions;

export const createStatisticsOptionsForClientStore: StateCreator<
	StatisticsOptionsSlice
> = (set, get) => ({
	...initialState,
	changeSelectedClients: (value: string | string[]) => {
		const allClients = get().allClients;
		if (value.length < 1)
			return {
				error: "Es muss mindestens ein Klient ausgewählt sein",
			};
		if (value.length > 5)
			return {
				error: "Es können maximal 5 Klienten ausgewählt werden",
			};

		set({
			selectedClientsForClientChart:
				typeof value === "string" ? value.split(",") : value,
		});
		const distinctClients = new Set(
			// Value is either a string or an array of strings in MUI select, but string[] is expected
			value
				// @ts-expect-error string array type
				?.map((v: string) => {
					const client = allClients.find((c) => c.id === v);
					if (client) {
						return `${client.first_name} ${client.last_name}`;
					}
				})
				.filter((name: string) => name !== undefined)
		);
		if (distinctClients.size < value.length) {
			return {
				error: "Mehrere Kunden haben den gleichen Namen, nur einer der Kunden wird angezeigt",
			};
		}
		return {
			error: null,
		};
	},
	changeSelectedEmployee: (value: string) => {
		set({ selectedEmployeeForClientChart: value });
		return {
			error: null,
		};
	},
	changeSelectedJobItemType: (value: string) => {
		set({ selectedJobItemTypeForClientChart: value });
		return {
			error: null,
		};
	},
});
