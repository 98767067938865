import { FormGroupProps, FormGroupPropsValueChange } from "../../form-types";
import { FloatInput } from "../single/float.input";

const TARIFFS_TIERS: {
  field: "tp_pp1" | "tp_pp2" | "tp_pp3" | "tp_sv";
  name: string;
  help: string;
  inputType: "float";
}[] = [
  {
    field: "tp_pp1",
    name: "PP1",
    help: "Kleinster Taxpunktwert",
    inputType: "float",
  },
  {
    field: "tp_pp2",
    name: "PP2",
    help: "Mittlerer Taxpunktwert",
    inputType: "float",
  },
  {
    field: "tp_pp3",
    name: "PP3",
    help: "Grösster Taxpunktwert",
    inputType: "float",
  },
  {
    field: "tp_sv",
    name: "SV",
    help: "Taxpunktwert für Sozialversicherung",
    inputType: "float",
  },
];

// make onValueChange required
interface TariffValuesGroupProps extends FormGroupProps {
  onValueChange: (valueChange: FormGroupPropsValueChange) => void;
}

export const TariffValuesGroup: React.FC<TariffValuesGroupProps> = ({
  formData,
  onValueChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      {TARIFFS_TIERS.map((tariff) => {
        return (
          <FloatInput
            key={tariff.field}
            label={tariff.name}
            value={formData ? formData[tariff.field] : null}
            onChange={(value: any) => {
              onValueChange({
                name: tariff.field,
                value: value,
              });
            }}
          />
        );
      })}
    </div>
  );
};
