/* eslint-disable */

import { QRCode } from "@progress/kendo-react-barcodes";
import {
	PaymentSlipDataInput,
	formatPaymentSlipData,
} from "./qr-payment-helpers";
import "./qr-payment-slip-styles.css";
import { Ccy } from "./qr-payment-types";
import { PdfTemplateConfiguration } from "@/dentlab/src/context/PdfTemplateConfigurationContext";

const fontMultiplier = 0.3527777778;

// https://www.six-group.com/dam/download/banking-services/standardization/qr-bill/style-guide-qr-bill-de.pdf
export const QrPaymentSlip: React.FC<{
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	paymentSlipData: PaymentSlipDataInput;
	textAbove?: string;
}> = ({ pdfTemplateConfiguration, paymentSlipData, textAbove }) => {
	const {
		success: formatSuccess,
		data: formattedPaymentSlipData,
		qrReceipt,
		error: formatError,
	} = formatPaymentSlipData(paymentSlipData);

	if (!formattedPaymentSlipData || !qrReceipt) {
		return (
			<div>
				Zahlschein konnte nicht erstellt werden, da Felder fehlen oder
				falsch formatiert wurden.
			</div>
		);
	}

	const all_w = 210;
	const all_h = 105;

	const section1_w = 62;
	const section1_pr = 5;
	const section1_pl = 5;
	const section1_pt = 5;
	const section1_pb = 5;
	const section1_title_h = 7;
	const section1_details_h = 56;
	const section1_amount_h = 14;
	const section1_other_h = 18;

	const section2_w = 148;
	const section2_pr = 5;
	const section2_pl = 5;
	const section2_pt = 5;
	const section2_pb = 5;

	const section2_top_h = 85;

	const section2_top_1_w = 51;
	const section2_top_1_title_h = 7;
	const section2_top_1_title_pb = 5;
	const section2_top_1_qr = 46;
	const section2_top_1_qr_pr = 5;
	const section2_top_1_qr_pb = 5;
	const section2_top_1_amount_h = 22;

	const section2_top_2_w = 87;
	const section2_top_2_h = 85;

	const section2_bottom_h = 10;

	// font sizes in pt * 0.3527777778 (to mm)

	const line_space_a = 9 * fontMultiplier + "mm";
	const line_space_b = 12 * fontMultiplier + "mm";

	return (
		<>
			{/* In the PDFExport component, we have set the <p> tag to always move to the next page. 
      In order to make sure the payment slip is moved to the next page, we put a <p> tag of corresponding height in front of it, 
      to simulate the space the payment slip needs and to trigger the page break if needed. */}
			<p
				style={{
					height: all_h + "mm",
				}}
			/>
			<p
				style={{
					position: "absolute",
					top: 297 - all_h - 10 + "mm",
					left: pdfTemplateConfiguration?.general?.marginLeft + "mm",
					fontSize: "12pt",
				}}
			>
				{textAbove}
			</p>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					width: all_w + "mm",
					height: all_h + "mm",
					position: "absolute",
					top: 297 - all_h + "mm",
					left: 0,
					fontFamily: "LiberationSans",
					borderTop: "0.5px solid black",
				}}
			>
				<div
					style={{
						width: section1_w + "mm",
						borderRight: "0.5px solid black",
						paddingRight: section1_pr + "mm",
						paddingLeft: section1_pl + "mm",
						paddingTop: section1_pt + "mm",
						paddingBottom: section1_pb + "mm",
						// backgroundColor: "red",
					}}
				>
					<div
						style={{
							height: section1_title_h + "mm",
							// backgroundColor: "green",
						}}
					>
						<Title title="Empfangsschein" />
					</div>
					<div
						style={{
							height: section1_details_h + "mm",
							// backgroundColor: "yellow",
							paddingTop: line_space_a,
						}}
					>
						<KontoZahlbarAn
							variant="Empfangsschein"
							KONTO={formattedPaymentSlipData.KONTO}
							ZE_NAME={formattedPaymentSlipData.ZE_NAME}
							ZE_STRASSE_ODER_ADRESSZEILE_1={
								formattedPaymentSlipData.ZE_STRASSE_ODER_ADRESSZEILE_1
							}
							ZE_HAUSNUMMER_ODER_ADRESSZEILE_2={
								formattedPaymentSlipData.ZE_HAUSNUMMER_ODER_ADRESSZEILE_2
							}
							ZE_POSTLEITZAHL={
								formattedPaymentSlipData.ZE_POSTLEITZAHL
							}
							ZE_ORT={formattedPaymentSlipData.ZE_ORT}
						/>
						<Referenz
							variant="Empfangsschein"
							REFERENZ={formattedPaymentSlipData.REFERENZ}
						/>
						<ZahlbarDurch
							variant="Empfangsschein"
							EZP_NAME={formattedPaymentSlipData.EZP_NAME}
							EZP_STRASSE_ODER_ADRESSZEILE_1={
								formattedPaymentSlipData.EZP_STRASSE_ODER_ADRESSZEILE_1
							}
							EZP_HAUSNUMMER_ODER_ADRESSZEILE_2={
								formattedPaymentSlipData.EZP_HAUSNUMMER_ODER_ADRESSZEILE_2
							}
							EZP_POSTLEITZAHL={
								formattedPaymentSlipData.EZP_POSTLEITZAHL
							}
							EZP_ORT={formattedPaymentSlipData.EZP_ORT}
						/>
					</div>
					<div
						style={{
							height: section1_amount_h + "mm",
							// backgroundColor: "blue",
						}}
					>
						<WaehrungBetrag
							variant="Empfangsschein"
							WAEHRUNG={formattedPaymentSlipData.WAEHRUNG}
							BETRAG={formattedPaymentSlipData.BETRAG}
						/>
					</div>
					<div
						style={{
							height: section1_other_h + "mm",
							// backgroundColor: "purple",
						}}
					>
						<AnnahmeStelle />
					</div>
				</div>

				<div
					style={{
						width: section2_w + "mm",
						paddingRight: section2_pr + "mm",
						paddingLeft: section2_pl + "mm",
						paddingTop: section2_pt + "mm",
						paddingBottom: section2_pb + "mm",
						// backgroundColor: "orange",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div
						style={{
							height: section2_top_h + "mm",
							// backgroundColor: "pink",
							display: "flex",
							flexDirection: "row",
						}}
					>
						{/* Zweiter Teil Gesamt (Titel, QR, Betrag) */}
						<div
							style={{
								width: section2_top_1_w + "mm",
								//backgroundColor: "cyan",
							}}
						>
							{/* TITEL */}
							<div
								style={{
									height: section2_top_1_title_h + "mm",
									marginBottom:
										section2_top_1_title_pb + "mm",
									//backgroundColor: "magenta",
								}}
							>
								<Title title="Zahlteil" />
							</div>
							{/* QR */}
							<div
								style={{
									height: section2_top_1_qr + "mm",
									marginRight: section2_top_1_qr_pr + "mm",
									marginBottom: section2_top_1_qr_pb + "mm",
									//backgroundColor: "green",
								}}
							>
								<QRCode
									value={qrReceipt}
									errorCorrection="M"
									encoding="UTF_8" // Seite 24, Version 2.2 – 22.02.2021
									overlay={{
										type: "swiss",
									}}
									style={{
										width: "46mm",
										height: "46mm",
									}}
								/>
							</div>
							<div
								style={{
									height: section2_top_1_amount_h + "mm",
									// backgroundColor: "gray",
								}}
							>
								<WaehrungBetrag
									variant="Zahlteil"
									WAEHRUNG={formattedPaymentSlipData.WAEHRUNG}
									BETRAG={formattedPaymentSlipData.BETRAG}
								/>
							</div>
						</div>
						<div
							style={{
								width: section2_top_2_w + "mm",
								// backgroundColor: "lime",
							}}
						>
							<div
								style={{
									height: section2_top_2_h + "mm",
									// backgroundColor: "teal",
								}}
							>
								<KontoZahlbarAn
									variant="Zahlteil"
									KONTO={formattedPaymentSlipData.KONTO}
									ZE_NAME={formattedPaymentSlipData.ZE_NAME}
									ZE_STRASSE_ODER_ADRESSZEILE_1={
										formattedPaymentSlipData.ZE_STRASSE_ODER_ADRESSZEILE_1
									}
									ZE_HAUSNUMMER_ODER_ADRESSZEILE_2={
										formattedPaymentSlipData.ZE_HAUSNUMMER_ODER_ADRESSZEILE_2
									}
									ZE_POSTLEITZAHL={
										formattedPaymentSlipData.ZE_POSTLEITZAHL
									}
									ZE_ORT={formattedPaymentSlipData.ZE_ORT}
								/>
								<ZusaetzlicheInformationen
									UNSTRUKTURIERTE_MITTEILUNG={
										formattedPaymentSlipData.UNSTRUKTURIERTE_MITTEILUNG
									}
								/>
								<Referenz
									variant="Zahlteil"
									REFERENZ={formattedPaymentSlipData.REFERENZ}
								/>
								<ZahlbarDurch
									variant="Zahlteil"
									EZP_NAME={formattedPaymentSlipData.EZP_NAME}
									EZP_STRASSE_ODER_ADRESSZEILE_1={
										formattedPaymentSlipData.EZP_STRASSE_ODER_ADRESSZEILE_1
									}
									EZP_HAUSNUMMER_ODER_ADRESSZEILE_2={
										formattedPaymentSlipData.EZP_HAUSNUMMER_ODER_ADRESSZEILE_2
									}
									EZP_POSTLEITZAHL={
										formattedPaymentSlipData.EZP_POSTLEITZAHL
									}
									EZP_ORT={formattedPaymentSlipData.EZP_ORT}
								/>
							</div>
						</div>
					</div>
					<div
						style={{
							height: section2_bottom_h + "mm",
							// backgroundColor: "yellow",
						}}
					>
						{/* TODO: if we use this, refer back to source at beginning of file and implement */}
					</div>
				</div>
			</div>
		</>
	);
};

const TextField: React.FC<{
	variant: "Empfangsschein" | "Zahlteil";
	title: string;
	children: React.ReactNode;
}> = ({ variant, title, children }) => {
	return (
		<div
			style={{
				marginBottom:
					(variant === "Empfangsschein" ? 9 : 11) * fontMultiplier +
					"mm",
			}}
		>
			<div
				style={{
					fontSize:
						(variant === "Empfangsschein" ? 6 : 8) *
							fontMultiplier +
						"mm",
					fontWeight: "bold",
				}}
			>
				{title}
			</div>
			<div
				style={{
					fontSize:
						(variant === "Empfangsschein" ? 8 : 10) *
							fontMultiplier +
						"mm",
				}}
			>
				{children}
			</div>
		</div>
	);
};

const KontoZahlbarAn: React.FC<{
	variant: "Empfangsschein" | "Zahlteil";
	KONTO: string;
	ZE_NAME: string;
	ZE_STRASSE_ODER_ADRESSZEILE_1: string;
	ZE_HAUSNUMMER_ODER_ADRESSZEILE_2: string;
	ZE_POSTLEITZAHL: string;
	ZE_ORT: string;
}> = ({
	variant,
	KONTO,
	ZE_NAME,
	ZE_STRASSE_ODER_ADRESSZEILE_1,
	ZE_HAUSNUMMER_ODER_ADRESSZEILE_2,
	ZE_POSTLEITZAHL,
	ZE_ORT,
}) => (
	<TextField title="Konto / Zahlbar an" variant={variant}>
		<p>{KONTO}</p>
		<p>{ZE_NAME}</p>
		<p>
			{ZE_STRASSE_ODER_ADRESSZEILE_1} {ZE_HAUSNUMMER_ODER_ADRESSZEILE_2}
		</p>
		<p>
			{ZE_POSTLEITZAHL} {ZE_ORT}
		</p>
	</TextField>
);

const Referenz: React.FC<{
	variant: "Empfangsschein" | "Zahlteil";
	REFERENZ: string;
}> = ({ variant, REFERENZ }) => (
	<TextField title="Referenz" variant={variant}>
		<p>{REFERENZ}</p>
	</TextField>
);

const ZahlbarDurch: React.FC<{
	variant: "Empfangsschein" | "Zahlteil";
	EZP_NAME: string;
	EZP_STRASSE_ODER_ADRESSZEILE_1: string | undefined;
	EZP_HAUSNUMMER_ODER_ADRESSZEILE_2: string | undefined;
	EZP_POSTLEITZAHL: string;
	EZP_ORT: string;
}> = ({
	variant,
	EZP_NAME,
	EZP_STRASSE_ODER_ADRESSZEILE_1,
	EZP_HAUSNUMMER_ODER_ADRESSZEILE_2,
	EZP_POSTLEITZAHL,
	EZP_ORT,
}) => (
	<TextField title="Zahlbar durch" variant={variant}>
		<p>{EZP_NAME}</p>
		<p>
			{EZP_STRASSE_ODER_ADRESSZEILE_1} {EZP_HAUSNUMMER_ODER_ADRESSZEILE_2}
		</p>
		<p>
			{EZP_POSTLEITZAHL} {EZP_ORT}
		</p>
	</TextField>
);

const ZusaetzlicheInformationen: React.FC<{
	UNSTRUKTURIERTE_MITTEILUNG: string | undefined;
}> = ({ UNSTRUKTURIERTE_MITTEILUNG }) => (
	<TextField title="Zusätzliche Informationen" variant="Zahlteil">
		<p>{UNSTRUKTURIERTE_MITTEILUNG}</p>
	</TextField>
);

const WaehrungBetrag: React.FC<{
	variant: "Empfangsschein" | "Zahlteil";
	WAEHRUNG: Ccy;
	BETRAG: string;
}> = ({ variant, WAEHRUNG, BETRAG }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "row",
			gap: variant === "Empfangsschein" ? "8mm" : "6mm",
		}}
	>
		<WaehrungBetragElement
			variant={variant}
			title="Währung"
			value={WAEHRUNG}
		/>
		<WaehrungBetragElement
			variant={variant}
			title="Betrag"
			value={BETRAG}
		/>
	</div>
);

const WaehrungBetragElement: React.FC<{
	title: string;
	value: string;
	variant: "Empfangsschein" | "Zahlteil";
}> = ({ title, value, variant }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
		}}
	>
		<div
			style={{
				fontSize:
					(variant === "Empfangsschein" ? 6 : 8) * fontMultiplier +
					"mm",
				fontWeight: "bold",
			}}
		>
			{title}
		</div>
		<div
			style={{
				fontSize:
					(variant === "Empfangsschein" ? 8 : 10) * fontMultiplier +
					"mm",
			}}
		>
			{value}
		</div>
	</div>
);

const AnnahmeStelle = () => (
	<p
		style={{
			textAlign: "right",
			fontSize: 6 * fontMultiplier + "mm",
			fontWeight: "bold",
		}}
	>
		Annahmestelle
	</p>
);

const Title: React.FC<{
	title: string;
}> = ({ title }) => (
	<div style={{ fontSize: 11 * fontMultiplier + "mm", fontWeight: "bold" }}>
		{title}
	</div>
);
