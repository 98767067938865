import React, { createContext, useContext, useState } from "react";

type TableRow = {
  id: string | number;
  [key: string]: any;
};

type ClipboardDataType = "all" | "job_items";

type ClipboardData = {
  data: TableRow[];
  type: string[];
};

const ClipboardContext = createContext<{
  clipboardData: ClipboardData;
  setClipboardData: React.Dispatch<React.SetStateAction<ClipboardData>>;
}>({
  clipboardData: {
    data: [],
    type: ["all"],
  },
  setClipboardData: () => {},
});

export const ClipboardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [clipboardData, setClipboardData] = useState<ClipboardData>({
    data: [],
    type: ["all"],
  });
  return (
    <ClipboardContext.Provider value={{ clipboardData, setClipboardData }}>
      {children}
    </ClipboardContext.Provider>
  );
};

/**
 * useClipboard - A custom hook that lets components subscribe to the table clipboard
 * @returns {TableRow[]} The current clipboard data
 * @example const { clipboardData, setClipboardData } = useClipboard();
 */
export const useClipboard = () => {
  const context = useContext(ClipboardContext);
  if (!context) {
    throw new Error("useClipboard must be used within a ClipboardProvider");
  }
  return context;
};
