/**
 * NOT_STARTED = 0
 * IN_PROGRESS = 1 // "Pendent"
 * COMPLETED = 2 // "Geliefert"
 * ARCHIVED = 3 // "Archiviert"
 * BOOKED_SINGLE = 4 // "Verbucht als Einzelrechnung"
 * BOOKED_MONTHLY = 5 // "Verbucht als Sammelrechnung"
 */
export enum JobStatusEnum {
	NOT_STARTED = 0,
	IN_PROGRESS = 1,
	COMPLETED = 2,
	ARCHIVED = 3,
	BOOKED_SINGLE = 4,
	BOOKED_MONTHLY = 5,
}

export enum JobStatusLabelsEnum {
	NOT_STARTED = "Voranmeldung",
	IN_PROGRESS = "Pendent",
	COMPLETED = "Geliefert",
	ARCHIVED = "Archiviert",
	BOOKED_SINGLE = "Verbucht als Einzelrechnung",
	BOOKED_MONTHLY = "Verbucht in Sammelrechnung",
}
