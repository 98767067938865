import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";
import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import { DesktopSystemEntityTypeWithStatus } from "@/dentlab/src/store/Desktops/desktops.store";

const PrinterSelectBare: React.FC<
	SelectProps & {
		printers: DesktopSystemEntityTypeWithStatus[];
	}
> = (props) => {
	const options = [
		...props.printers.map((p) => ({
			value: p.id,
			label: `${p.name} (${p.desktopName})`,
		})),
	];

	if (props.printers.length === 0) {
		options.push({
			// use string so options cannot be selected
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value: "" as any,
			label: "Keine Drucker verfügbar",
		});
	}

	return <CustomSelect options={options} inputLabel="Drucker" {...props} />;
};

export const PrinterSelect: React.FC<SelectProps> = (props) => {
	const printers = useDesktopsStore().getPrintersWithStatus();
	return <PrinterSelectBare printers={printers} {...props} />;
};
