import { useEffect, useState } from "react";
import { Logger } from "../../../../../lib/logger/Logger";
import {
	FileWithShare,
	JobDocumentEntityType,
	JobItemEntityType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import {
	Button,
	Chip,
	IconButton,
	LinearProgress,
	Tooltip,
} from "@mui/material";
import { JobItemsTable } from "./job-document-components/job-items-table/job-items-table.component";
import { JobDocumentTitle } from "./job-document-components/job-document-title.component";
import {
	JobDocumentTypeEnum,
	MapJobDocumentTypeToColor,
} from "./job-document.types";
import { useToast } from "../../../context/ToastContext";
import { useDatabaseFunctionsWithPromise } from "../../../hooks/useDatabase";
import { JobStatusEnum } from "@/lib/types/job";
import { ArrowIconButton } from "../../../components/arrow-icon-button/arrow-icon-button.component";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { CustomDatePicker } from "@/dentlab/src/components/custom-date-picker/custom-date-picker.component";
import CalculateIcon from "@mui/icons-material/Calculate";
import { TotalSidebar } from "./job-document-components/total-sidebar.component";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "@/dentlab/src/pdf-templates/document-template.types";
import { useJobDocumentActions } from "@/dentlab/src/hooks/actions/useJobDocumentActions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { JobDocumentOptionsMenu } from "./job-document-components/job-document-options-menu.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import PrintIcon from "@mui/icons-material/Print";
import { CustomButtonWithAnchoredMenu } from "@/dentlab/src/components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "./job-document-components";
import { useDesktop } from "@/dentlab/src/hooks/useDesktop/useDesktop";
import { supabase } from "@/lib/supabase";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { FileDropDown } from "@/dentlab/src/components/file-display-components/file-dropdown.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { calculateJobTotalsLegacy } from "@/dentlab/src/lib/utils/calculate";

const getBorderColorTop = (
	isVisible: boolean,
	jobDocumentType: JobDocumentTypeEnum
) => {
	const color = MapJobDocumentTypeToColor[jobDocumentType] ?? "#000000";
	return isVisible
		? color
		: `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
				color.slice(3, 5),
				16
			)}, ${parseInt(color.slice(5, 7), 16)}, 0.3)`;
};

const getBorderColorOthers = (isVisible: boolean) => {
	return isVisible ? "#E0E0E0" : "rgba(224, 224, 224, 0.3)";
};

export interface JobDocumentExportOption {
	label: string;
	type: PdfTemplateType;
}

export type JobDocumentWithFiles = JobDocumentEntityType & {
	files: FileWithShare[];
};

/**
 * JobDocument - Component displaying information for a single job document
 * @param document - job document object to display, fetched from the database by the parent component
 * @param clientId - id of the client the document belongs to
 *
 * State this component has that is relevant for exporting the document:
 * - jobItems: JobItemEntityType[] - all job items that are associated with the document
 */
export const JobDocument: React.FC<{
	jobDocument: JobDocumentWithFiles;
	onExport: (
		templateType: PdfTemplateType | XmlTemplateType,
		jobItems: JobItemEntityType[]
	) => void;
	disabled?: boolean;
	loading?: boolean;
}> = ({ jobDocument, onExport, disabled = false, loading = false }) => {
	const { debugMode } = useCentralStore((state) => ({
		debugMode: state.debugMode,
	}));
	const { showToast } = useToast();
	const { updateDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { jobItemsForDocuments, fetchJobItems, job, fetchJobDocument } =
		useJobStore((state) => ({
			jobItemsForDocuments: state.jobItemsForDocuments,
			fetchJobItems: state.fetchJobItems,
			job: state.job,
			fetchJobDocument: state.fetchJobDocument,
		}));

	const [jobTotalSidebarOpen, setJobTotalSidebarOpen] = useState(false);
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));
	const [isVisible, setIsVisible] = useState(true);
	const jobDocumentType = jobDocument.type as JobDocumentTypeEnum;
	const jobItems = jobItemsForDocuments?.[jobDocument.id] ?? [];

	useEffect(() => {
		fetchJobItems(jobDocument.id);
	}, []);

	const [date, setDate] = useState<Date | null>(
		new Date(jobDocument.date ?? new Date())
	);

	const handleDateChange = async (event: DatePickerChangeEvent) => {
		if (jobDocument.status !== JobStatusEnum.IN_PROGRESS) {
			showToast(
				"Das Datum kann nur bearbeitet werden, wenn der Auftrag noch nicht abgeschlossen ist",
				"error"
			);
			return;
		}

		// We don't want files in the jobDocument object so we ignore the error
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { files: unnecessaryFiles, ...jobDocumentWithoutFiles } =
			jobDocument;
		const { success, error } = await updateDataWithPromise(
			SupabaseTableEnum.JOB_DOCUMENTS,
			[{ ...jobDocumentWithoutFiles, date: event.value }]
		);
		if (success) {
			setDate(event.value);
			fetchJobDocument(jobDocument.id);
		} else {
			Logger.log("Error updating date", error);
			showToast("Fehler beim Speichern des Datums", "error");
		}
	};

	const handleCloseJobTotalSidebar = () => {
		setJobTotalSidebarOpen(false);
	};
	const [files, setFiles] = useState<FileWithShare[]>([]);

	useEffect(() => {
		setFiles(jobDocument?.files);
	}, [jobDocument?.files]);

	const updateFile = (file: FileWithShare) => {
		setFiles((prev) =>
			prev.map((p) => {
				if (p.id == file.id) return file;
				else return p;
			})
		);
	};
	if (!organization) {
		return null;
	}

	const { total } = calculateJobTotalsLegacy({
		job,
		jobDocument,
		jobItems,
		organization,
	});

	return (
		<>
			{jobTotalSidebarOpen && job && (
				<TotalSidebar
					jobItems={jobItems as JobItemEntityType[]}
					job={job}
					onClose={handleCloseJobTotalSidebar}
					jobDocument={jobDocument}
				/>
			)}
			<div
				style={{
					borderTop: `6px solid ${getBorderColorTop(
						isVisible,
						jobDocumentType
					)}`,
					borderBottom: `4px solid ${getBorderColorOthers(
						isVisible
					)}`,
					borderRight: `4px solid ${getBorderColorOthers(isVisible)}`,
					borderLeft: `4px solid ${getBorderColorOthers(isVisible)}`,
					margin: "6px",
					borderRadius: "6px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "4px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<ArrowIconButton
							open={isVisible}
							onClick={() => setIsVisible(!isVisible)}
						/>
						<JobDocumentTitle
							title={jobDocument.title}
							id={jobDocument.id}
							status={jobDocument.status}
						/>
						{jobDocument.type !== JobDocumentTypeEnum.MATERIALS && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									paddingLeft: "10px",
								}}
							>
								<CustomDatePicker
									value={date}
									onChange={handleDateChange}
								/>
							</div>
						)}
						{jobDocument.type !== JobDocumentTypeEnum.MATERIALS && (
							<JobDocumentStatusChip
								status={jobDocument.status}
							/>
						)}
					</div>
					{jobDocument.type !== JobDocumentTypeEnum.MATERIALS && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								paddingRight: "10px",
								alignItems: "center",
							}}
						>
							<CompleteAndExportButton
								jobDocument={jobDocument}
								jobItems={jobItems ?? []}
								onExport={onExport}
							/>
							<PrintButton files={jobDocument.files} />
							<Button
								onClick={() => setJobTotalSidebarOpen(true)}
								startIcon={<CalculateIcon />}
							>
								{total.value === 0 ? "0.00" : total.text}
							</Button>
							<JobDocumentOptionsMenu
								jobDocument={jobDocument}
								jobItems={jobItems ?? []}
								onExport={(templateType) =>
									onExport(templateType, jobItems ?? [])
								}
							/>
						</div>
					)}
				</div>
				{loading && <LinearProgress />}
				{files?.length > 0 && (
					<div
						data-testid="exported-pdfs"
						style={{
							display: "flex",
							borderTop: `1.5px solid ${getBorderColorOthers(
								isVisible
							)}`,
							padding: "3px 6px",
							overflowX: "auto",
						}}
					>
						{files?.map((file) => (
							<FileDropDown
								key={file.id}
								file={file}
								updateFile={updateFile}
								job={job ?? undefined}
							/>
						))}
					</div>
				)}
				{isVisible && (
					<JobItemsTable
						jobDocumentId={jobDocument.id}
						status={jobDocument.status}
						disabled={disabled}
					/>
				)}
				{debugMode && (
					<div>
						ID: {jobDocument.id} Type: {jobDocument.type}
					</div>
				)}
			</div>
		</>
	);
};

export const JobDocumentTemplate: React.FC<{
	jobDocument: JobDocumentEntityType;
}> = ({ jobDocument }) => {
	const { debugMode } = useCentralStore((state) => ({
		debugMode: state.debugMode,
	}));
	const { fetchJobItems } = useJobStore((state) => ({
		jobItemsForDocuments: state.jobItemsForDocuments,
		fetchJobItems: state.fetchJobItems,
		job: state.job,
	}));

	const [isVisible, setIsVisible] = useState(true);
	const jobDocumentType = jobDocument.type as JobDocumentTypeEnum;

	useEffect(() => {
		fetchJobItems(jobDocument.id);
	}, []);

	return (
		<>
			<div
				style={{
					borderTop: `6px solid ${getBorderColorTop(
						isVisible,
						jobDocumentType
					)}`,
					borderBottom: `4px solid ${getBorderColorOthers(
						isVisible
					)}`,
					borderRight: `4px solid ${getBorderColorOthers(isVisible)}`,
					borderLeft: `4px solid ${getBorderColorOthers(isVisible)}`,
					margin: "6px",
					borderRadius: "6px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "4px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<ArrowIconButton
							open={isVisible}
							onClick={() => setIsVisible(!isVisible)}
						/>
						<JobDocumentTitle
							title={jobDocument.title}
							id={jobDocument.id}
							status={jobDocument.status}
						/>
					</div>
				</div>
				{isVisible && (
					<JobItemsTable
						jobDocumentId={jobDocument.id}
						status={JobStatusEnum.IN_PROGRESS}
						disabled={false}
					/>
				)}
				{debugMode && (
					<div>
						ID: {jobDocument.id} Type: {jobDocument.type}
					</div>
				)}
			</div>
		</>
	);
};

const JobDocumentStatusChip: React.FC<{
	status: JobStatusEnum;
}> = ({ status }) => {
	// switch label based on status
	let label = "";
	switch (status) {
		case JobStatusEnum.IN_PROGRESS:
			label = "Pendent";
			break;
		case JobStatusEnum.COMPLETED:
			label = "Abgeschlossen";
			break;
		case JobStatusEnum.ARCHIVED:
			label = "Archiviert";
			break;
		case JobStatusEnum.BOOKED_SINGLE:
			label = "Verbucht (Einzelrechnung)";
			break;
		case JobStatusEnum.BOOKED_MONTHLY:
			label = "Verbucht (Sammelrechnung)";
			break;
		default:
			label = "Unbekannt";
	}

	return (
		<Chip
			label={label}
			size="small"
			sx={{
				color: "var(--color-white)",
				textTransform: "uppercase",
				fontSize: "12px",
				borderRadius: "5px",
				padding: "2px 4px",
				marginLeft: "10px",
			}}
		/>
	);
};

const CompleteAndExportButton: React.FC<{
	jobDocument: JobDocumentWithFiles;
	jobItems: JobItemEntityType[];
	onExport: (type: PdfTemplateType, jobItems: JobItemEntityType[]) => void;
}> = ({ jobDocument, jobItems, onExport }) => {
	const { isExportJobDocumentAllowed } = useJobDocumentActions();
	const fetchJob = useJobStore((state) => state.fetchJob);
	const { isAllowed, explanation } = isExportJobDocumentAllowed(
		jobDocument,
		jobItems
	);

	const handleCompletionClick = () => {
		if (jobDocument.type === JobDocumentTypeEnum.Quotation) {
			onExport(PdfTemplateType.KOSTENVORANSCHLAG, jobItems);
		} else if (jobDocument.type === JobDocumentTypeEnum.DeliveryNote) {
			onExport(PdfTemplateType.LIEFERSCHEIN, jobItems);
		} else if (jobDocument.type === JobDocumentTypeEnum.CreditNote) {
			onExport(PdfTemplateType.GUTSCHRIFT, jobItems);
		}
	};

	let tooltipText = "Abschliessen und exportieren";
	let icon = <CheckCircleOutlineIcon color="primary" />;
	if (jobDocument.status === JobStatusEnum.COMPLETED) {
		icon = <CheckCircleIcon color="success" />;
		tooltipText = "Abgeschlossen";
	} else if (!isAllowed) {
		icon = <CheckCircleOutlineIcon color="disabled" />;
		tooltipText = explanation;
	}

	return (
		<Tooltip title={tooltipText} placement="bottom">
			{/* We wrap IconButton in a span tag to allow tooltip when disabled */}
			<span>
				<IconButton
					onClick={async () => {
						handleCompletionClick();
						await fetchJob(jobDocument.job_id);
					}}
					disabled={!isAllowed}
					data-testid="complete-job-document-button"
					aria-label="Button to export job document and setting its status to completed"
				>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
};

interface FileMetaData {
	pdf_template_type?: string;
}

const PrintButton: React.FC<{
	files: FileWithShare[];
}> = ({ files }) => {
	const { printDocument } = useDesktop();

	const noDocuments = !files || files.length === 0;

	const handlePrintDocument = async (file: FileWithShare) => {
		if (!file.path_name || !file.bucket_name) {
			return;
		}

		let filePdfTemplateType = (file.meta_data as FileMetaData)
			?.pdf_template_type;
		if (!filePdfTemplateType) {
			showNotification({
				message:
					"Dem Dokument fehlen notwendige Metadaten zum Drucken. Drucker für Kostenvoranschlag ausgewählt.",
				type: "warning",
			});
			filePdfTemplateType = PdfTemplateType.KOSTENVORANSCHLAG;
		}

		const { data, error } = await supabase.storage
			.from(file.bucket_name)
			.download(file.path_name);

		if (!data) {
			Logger.log("Error downloading file for printing", error);
			showNotification({
				message: "Datei konnte nicht geladen werden",
				type: "error",
			});
			return;
		}

		await printDocument({
			pdfTemplateType: filePdfTemplateType as PdfTemplateType,
			fileType: "pdf",
			documentData: data as Blob,
		});
	};

	const icon = <PrintIcon color={noDocuments ? "disabled" : "primary"} />;

	return (
		<>
			{files?.length <= 1 ? (
				<Tooltip
					title={
						// TODO: Allow printing
						// noDocuments ? "Keine Datei zum Drucken vorhanden" : ""
						"Das Drucken über den dentDESK Service wird auf Testaccounts nicht unterstützt. Exportieren Sie das Dokument und drucken Sie es aus."
					}
					placement="bottom"
				>
					<span>
						{/* We wrap IconButton in a span tag to allow tooltip when disabled */}
						<IconButton
							onClick={() => handlePrintDocument(files[0])}
							// TODO: Allow printing
							// disabled={files?.length < 1}
							disabled={true}
							data-testid="print-job-document-button"
						>
							{icon}
						</IconButton>
					</span>
				</Tooltip>
			) : (
				<Tooltip
					title="Das Drucken über den dentDESK Service wird auf Testaccounts nicht unterstützt."
					placement="bottom"
				>
					<span>
						<CustomButtonWithAnchoredMenu
							icon={icon}
							// TODO: Allow printing
							// disabled={noDocuments}
							disabled={true}
						>
							{files?.map((file) => {
								return (
									<div key={file.file_name}>
										<CustomMenuItem
											title=""
											menuFunction={() =>
												handlePrintDocument(file)
											}
											text={file.file_name ?? ""}
											placement="left"
										/>
									</div>
								);
							})}
						</CustomButtonWithAnchoredMenu>
					</span>
				</Tooltip>
			)}
		</>
	);
};
