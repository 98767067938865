import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";

const SHOW_ALL = "ALL";

export const AccountingRecipientSelector: React.FC<{
	onRecipientChange: (recipientId: string) => void;
	selectedRecipientId: string;
	recipientList: Record<
		string,
		{
			type: "client" | "guarantor";
			name: string;
		}
	>;
}> = ({ selectedRecipientId, onRecipientChange, recipientList }) => {
	const handleSelect = (e: SelectChangeEvent<string>) => {
		onRecipientChange(e.target.value);
	};
	if (Object.keys(recipientList).length === 0) {
		return null;
	}
	return (
		<div className="h-full gap-2 pt-1 flex items-end">
			<FormControl variant="filled" size="small">
				<InputLabel>Empfänger</InputLabel>
				<Select
					sx={{
						width: "250px",
					}}
					value={selectedRecipientId || SHOW_ALL}
					onChange={(e) => handleSelect(e)}
					label="Empfänger"
				>
					<MenuItem value={SHOW_ALL}>Alle</MenuItem>)
					{Object.entries(recipientList).map(([id, { name }]) => {
						return (
							<MenuItem key={id} value={id}>
								{name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>

			<div className="h-full py-1 flex">
				<Button
					size="large"
					onClick={() => {
						onRecipientChange("");
					}}
				>
					Adressaten entfernen
				</Button>
			</div>
		</div>
	);
};
