export enum ProductionCountriesEnum {
	CH = "ch",
	CH_OTHER = "ch/other",
	LI = "li",
	LI_OTHER = "li/other",
	OTHER = "other",
}

export const productionCountriesOptions = [
	{ value: "ch", label: "Schweiz" },
	{ value: "ch/other", label: "Schweiz/Ausland" },
	{ value: "li", label: "Liechtenstein" },
	{ value: "li/other", label: "Liechtenstein/Ausland" },
	{ value: "other", label: "Ausland" },
];

export const productionCountriesLookup = {
	ch: "Schweiz",
	"ch/other": "Schweiz/Ausland",
	li: "Liechtenstein",
	"li/other": "Liechtenstein/Ausland",
	other: "Ausland",
};
