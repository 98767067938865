import { useParams } from "react-router-dom";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormDialog } from "../../../hooks/useFormDialog";
import {
	PractitionerForm,
	PractitionerFormProps,
} from "../../../forms/form-components/practitioner/insert-update/practitioner.form";
import { PractsWithCtViewType } from "../../../../../lib/supabase/supabaseTypes";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { Typography } from "@/components/src/typography/typography.component";
import { supabase } from "@/lib/supabase";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import { useState } from "react";
import { Logger } from "@/lib/logger/Logger";

interface ConnectGetOrCreateUserResponse {
	data: {
		data: {
			profileId: string | null;
			organizationId: string | null;
		};
		message: string;
	};
	error: string | null;
}

export const PractitionerList: React.FC = () => {
	const { clientId } = useParams();
	const {
		practitioners: { practitionersByClientIdLookup },
		updatePractitioners,
	} = usePractitionersContext();
	const practitioners = practitionersByClientIdLookup[clientId ?? ""] ?? [];
	const { client, organization } = useCentralStore((state) => ({
		client: state.client,
		organization: state.organization,
	}));
	const [connectPractLoading, setConnectPractLoading] = useState(false);
	const [warningDialogOpen, setWarningDialogOpen] = useState(false);

	const { openDialog, dialog } = useFormDialog<PractitionerFormProps>({
		onSubmit: (practitioner: PractsWithCtViewType) => {
			if (!clientId) {
				showNotification({
					message: "Auftraggeber nicht gefunden.",
					type: "error",
				});
				return;
			}

			if (!updatePractitioners) {
				showNotification({
					message: "Fehler beim Hinzufügen des Behandlers.",
					type: "error",
				});
				return;
			}

			// case if there are no practitioners yet
			if (practitioners.length === 0) {
				updatePractitioners([practitioner]);
				return;
			}

			const existingPractitioner = practitioners.find(
				(pract) => pract.id === practitioner.id
			);

			const newPractitioners = existingPractitioner
				? practitioners.map((pract) =>
						pract.id === practitioner.id ? practitioner : pract
					)
				: [...practitioners, practitioner];

			updatePractitioners(newPractitioners);
		},
		FormComponent: PractitionerForm,
		dialogTitle: "Behandler hinzufügen",
	});

	// If the edit button is clicked, the practitioner id is stored in state and the dialog is opened
	const handleOpenExistingPractitionerForm = (practitionerId: number) => {
		openDialog({ practitionerId: practitionerId });
	};

	// If the add button is clicked, the dialog is opened with clientId as props instead of practitionerId
	const handleOpenNewPractitionerForm = () => {
		if (!clientId) {
			showNotification({
				message: "Auftraggeber nicht gefunden.",
				type: "error",
			});
			return;
		}
		openDialog({ clientId: clientId });
	};

	const removeConnectRelationship = async () => {
		if (!clientId) {
			showNotification({
				message: "Auftraggeber nicht gefunden.",
				type: "error",
			});
			return;
		}

		const { error } = await supabase.rpc("remove_connect_relationship", {
			client_id: clientId,
		});

		if (error) {
			showNotification({
				message: "Fehler beim Aufheben des Zugangs.",
				type: "error",
			});
		} else {
			window.location.reload();
		}
	};

	const connectPractitioner = async (
		practitionerId: number | null,
		practEmail: string | null
	) => {
		setConnectPractLoading(true);

		if (!clientId || !practitionerId || !practEmail) {
			showNotification({
				message: "Fehler beim Hinzufügen des Behandlers.",
				type: "error",
			});
			return;
		}

		let practProfileId: string | null = null;
		let medOrgId: string | null = null;

		// The backend checks if a user/profile with the given email exists
		// If not, it signs up a new user (with a new organization) and returns profile_id and organization_id
		// If yes, it retrieves profile_id and organization_id
		try {
			const {
				data,
				error: connectError,
			}: ConnectGetOrCreateUserResponse = await axios({
				url: `${
					import.meta.env.VITE_PDF_MICROSERVICE_URL
				}/connect/get-or-create-user`,
				method: "POST",
				data: {
					email: practEmail,
				},
			});

			Logger.info("connectPractitioner", {
				data,
				connectError,
			});

			if (connectError) {
				showNotification({
					message: "Fehler beim Verbinden des Behandlers (A2).",
					type: "error",
				});
				setConnectPractLoading(false);
				return;
			}

			practProfileId = data.data.profileId;
			medOrgId = data.data.organizationId;
		} catch (error) {
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A1).",
				type: "error",
			});
			setConnectPractLoading(false);
			return;
		}

		if (!practProfileId || !medOrgId) {
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A3).",
				type: "error",
			});
			setConnectPractLoading(false);
			return;
		}

		if (!organization || !organization.id) {
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A4).",
				type: "error",
			});
			setConnectPractLoading(false);
			return;
		}

		Logger.info("connectPractitioner", {
			lab_org_id: organization.id,
			med_org_id: medOrgId,
			client_id: clientId,
			pract_id: practitionerId,
			pract_profile_id: practProfileId,
		});

		// The rpc
		// - creates a connect relationship between the lab and the med organization
		// - adds the connect_relationship_id to the client
		// - adds the profile_id to the practitioner
		const { error } = await supabase.rpc("connect_practitioner", {
			client_id: clientId,
			lab_org_id: organization.id,
			med_org_id: medOrgId,
			pract_id: practitionerId,
			pract_profile_id: practProfileId,
		});

		if (error) {
			Logger.error("connectPractitioner", {
				error,
			});
			showNotification({
				message: "Fehler beim Verbinden des Behandlers (A5).",
				type: "error",
			});
		} else {
			window.location.reload();
		}
		setConnectPractLoading(false);
	};

	return (
		<Box>
			{client?.connect_relationship_id && (
				<div className="my-4 max-w-96 p-3 bg-blue-100 rounded-md flex flex-col gap-2">
					<Typography variant="title-sm">
						dentCONNECT aktiviert
					</Typography>
					<Typography variant="body-sm">
						dentCONNECT wurde erfolgreich aktiviert. Sie können nun
						Aufträge, Dokumente, Patienten, Nachrichten
						Auftrags-Dateien, Patienten-Dateien teilen.
					</Typography>
					<Typography variant="body-sm">
						Folgende Behandler haben Zugriff:{" "}
						{practitioners
							.filter(
								(p) =>
									p.connect_relationship_id ===
									client?.connect_relationship_id
							)
							.map(
								(practitioner) =>
									practitioner.first_name +
									" " +
									practitioner.last_name
							)
							.join(", ")}
					</Typography>
					<Button
						variant="text"
						className="self-end"
						onClick={() => setWarningDialogOpen(true)}
					>
						Zugang aufheben
					</Button>
				</div>
			)}
			<div className="flex flex-col gap-2 mt-4">
				{practitioners.map((practitioner: PractsWithCtViewType) => (
					<Box key={practitioner.id} className="flex items-center">
						{practitioner.first_name} {practitioner.last_name}{" "}
						{practitioner.email}
						{practitioner.connect_relationship_id &&
							" (dentCONNECT aktiviert)"}
						<IconButton
							aria-label="delete"
							size="medium"
							onClick={() => {
								handleOpenExistingPractitionerForm(
									practitioner.id ?? 0
								);
							}}
						>
							<EditIcon />
						</IconButton>
						{!client?.connect_relationship_id && (
							<IconButton
								aria-label="delete"
								size="medium"
								onClick={() => {
									connectPractitioner(
										practitioner.id,
										practitioner.email
									);
								}}
								disabled={connectPractLoading}
							>
								<ShareIcon />
							</IconButton>
						)}
						{connectPractLoading && <CircularProgress size={24} />}
					</Box>
				))}
			</div>
			<Button onClick={handleOpenNewPractitionerForm}>
				Behandler hinzufügen
			</Button>
			{dialog}
			<Dialog
				open={warningDialogOpen}
				onClose={() => setWarningDialogOpen(false)}
			>
				<DialogTitle>
					Wollen Sie den Zugang wirklich aufheben?
				</DialogTitle>
				<DialogContent>
					Alle geteilten Daten bleiben erhalten, aber werden nicht
					mehr mit Ihrem Behandler geteilt - auch wenn Sie den Zugang
					später wieder herstellen.
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setWarningDialogOpen(false)}>
						Abbrechen
					</Button>
					<Button onClick={removeConnectRelationship}>
						Zugang aufheben
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
