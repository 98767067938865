import React from "react";
import { Typography } from "@mui/material";

export const SignInLayout: React.FC<{
  children: React.ReactNode;
  title: string;
  subtitle: string;
}> = ({ children, title, subtitle }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--primary-main)",
      }}
    >
      <div
        style={{
          width: "400px",
          backgroundColor: "white",
          padding: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingBottom: "40px",
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h5">{subtitle}</Typography>
        </div>
        {children}
      </div>
    </div>
  );
};
