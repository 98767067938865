import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { useEffect, useState } from "react";
import { usePatientStore } from "../../store/Patient";

export const ItemPatientDetail: React.FC<{
	itemId: string;
}> = ({ itemId }) => {
	const patientsLookup = usePatientStore((state) => state.patientsLookup);
	const [patients, setPatients] = useState<
		{
			count: number | null;
			job_item_code: string | null;
			patient_id: string | null;
		}[]
	>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchPatients() {
			setLoading(true);
			if (!itemId) return;
			const { data, error } = await supabase
				.from(SupabaseTableEnum.JOB_ITEMS_WITH_PATIENTS)
				.select("*")
				.eq("job_item_code", itemId);
			if (error || !data) {
				Logger.error(error);
				return;
			}
			setPatients(data);
			setLoading(false);
		}
		fetchPatients();
	}, []);

	return (
		<div className="py-10 px-14 min-h-96 w-128 flex flex-col gap-4">
			<h1 className="text-xl">Rückverfolgung für Position {itemId}</h1>
			{loading && <div>Lade Rückverfolgung...</div>}
			{!loading && patients.length === 0 ? (
				<div>
					<h3>
						Es gibt für diese Position keine Aufträge mit
						verknüpften Patienten.
					</h3>
				</div>
			) : (
				<div className="flex flex-col gap-2">
					{patients.map((entry, index) => {
						const patientId = entry.patient_id;
						if (!patientId) return null;
						const patient = patientsLookup[patientId];
						if (!patient) return null;
						return (
							<div
								key={index}
								className="flex flex-col gap-1 p-2 bg-gray-100 rounded-md"
							>
								<h3>
									Name: {patient.first_name}{" "}
									{patient.last_name}
								</h3>
								<h3>Code: {patient.code}</h3>
								<h3>Anzahl: {entry.count}</h3>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
