// Source: https://www.six-group.com/dam/download/banking-services/standardization/qr-bill/ig-qr-bill-v2.2-de.pdf

import {
	BankAccountEntityType,
	ClientEntityType,
	GuarantorEntityType,
} from "@/lib/supabase/supabaseTypes";
import { AdrTp, Ccy } from "./qr-payment-types";
import {
	isAddInfValid,
	isAmtValid,
	isBldgNbOrAdrLine2Valid,
	isCtryValid,
	isIBANValid,
	isNameValid,
	isPstCdValid,
	checkQrIban,
	isRefValid,
	isStrdBkgInfValid,
	isStrtNmOrAdrLine1Valid,
	isTwnNmValid,
	isUstrdValid,
} from "./qr-payment-validation-functions";
import { generateQrReferenceNumber } from "@/dentlab/src/lib/utils/qr-reference-generator";
import { Currency } from "@/dentlab/src/types/enums";

export interface PaymentSlipDataInput {
	bankAccount: BankAccountEntityType;
	recipient: ClientEntityType | GuarantorEntityType;
	amount: string;
	currency: Ccy;
	unstructuredMessage?: string;
	rechnungsInfo?: string;
	av1Param?: string;
	av2Param?: string;
	invoiceNumber: string;
}

/**
 * Zahlungsempfänger Informationen (Obligatorisch):
 * @param {string} KONTO - (IBAN) IBAN bzw. QR-IBAN des Begünstigten
 * ---
 * Zahlungsempfänger:
 * @param {AdrTp} ZE_ADRESS_TYP - (AdrTp) Adresstyp des Zahlungsempfängers
 * @param {string} ZE_NAME - (Name) Name bzw. Firma des Zahlungsempfängers gemäss Kontobezeichnung
 * @param {string} ZE_STRASSE_ODER_ADRESSZEILE_1 (StrtNmOrAdrLine1) - Strasse oder Adresszeile 1 des Zahlungsempfängers
 * @param {string} ZE_HAUSNUMMER_ODER_ADRESSZEILE_2 (BldgNbOrAdrLine2) - Hausnummer oder Adresszeile 2 des Zahlungsempfängers
 * @param {string} ZE_POSTLEITZAHL (PstCd) - Postleitzahl des Zahlungsempfängers
 * @param {string} ZE_ORT (TwnNm) - Ort des Zahlungsempfängers
 * @param {string} ZE_LAND (Ctry) - Land des Zahlungsempfängers
 * ---
 * Zahlungsempfänger Ersatzadresse: Informationen zum endgültigen Zahlungsempfänger, darf aktuell noch nicht befüllt werden.
 * ...
 * ---
 * Zahlbetragsinformationen (Obligatorisch):
 * @param {string} BETRAG (Amt) - Zahlbetrag
 * @param {Ccy} WAEHRUNG (Ccy) - Währung
 * ---
 * Endgültiger Zahlungspflichtiger (Optional):
 * @param {boolean} IS_EZP - (boolean) true, wenn ein endgültiger Zahlungspflichtiger angegeben werden soll
 * @param {AdrTp} EZP_ADRESS_TYP (AdrTp) - Adresstyp des endgültigen Zahlungspflichtigen
 * @param {string} EZP_NAME (Name) - Name bzw. Firma des endgültigen Zahlungspflichtigen
 * @param {string} EZP_STRASSE_ODER_ADRESSZEILE_1 (StrtNmOrAdrLine1) - Strasse oder Adresszeile 1 des endgültigen Zahlungspflichtigen
 * @param {string} EZP_HAUSNUMMER_ODER_ADRESSZEILE_2 (BldgNbOrAdrLine2) - Hausnummer oder Adresszeile 2 des endgültigen Zahlungspflichtigen
 * @param {string} EZP_POSTLEITZAHL (PstCd) - Postleitzahl des endgültigen Zahlungspflichtigen
 * @param {string} EZP_ORT (TwnNm) - Ort des endgültigen Zahlungspflichtigen
 * @param {string} EZP_LAND (Ctry) - Land des endgültigen Zahlungspflichtigen
 * ---
 * Zahlungsreferenz (Obigatorisch):
 * Referenztyp (QRR - QR-Referenz)
 * @param {string} REFERENZ (Ref) - Referenz
 * ---
 * Zusätzliche Informationen (AddInf) (Optional, except Trailer):
 * @param {string} UNSTRUKTURIERTE_MITTEILUNG (Ustrd) - Unstrukturierte Mitteilung
 * Trailer (EPD - End Payment Data)
 * @param {string} RECHNUNGSINFORMATIONEN (StrdBkgInf) - Rechnungsinformationen - nicht Teil der Standardisierung
 * ---
 * Alternative Verfahren (Additional):
 * @param {string} AV1_PARAMETER (AltPmt) - Alternatives Verfahren Parameter
 * @param {string} AV2_PARAMETER (AltPmt) -Alternatives Verfahren Parameter
 */
export interface PaymentSlipDataOutput {
	KONTO: string;
	ZE_ADRESS_TYP: AdrTp;
	ZE_NAME: string;
	ZE_STRASSE_ODER_ADRESSZEILE_1: string;
	ZE_HAUSNUMMER_ODER_ADRESSZEILE_2: string;
	ZE_POSTLEITZAHL: string;
	ZE_ORT: string;
	ZE_LAND: string;
	BETRAG: string;
	WAEHRUNG: Ccy;
	REFERENZ: string;
	EZP_ADRESS_TYP: AdrTp;
	EZP_NAME: string;
	EZP_STRASSE_ODER_ADRESSZEILE_1?: string;
	EZP_HAUSNUMMER_ODER_ADRESSZEILE_2?: string;
	EZP_POSTLEITZAHL: string;
	EZP_ORT: string;
	EZP_LAND: string;
	UNSTRUKTURIERTE_MITTEILUNG?: string;
	RECHNUNGSINFORMATIONEN?: string; // https://www.swiss-qr-invoice.org/downloads/qr-bill-s1-syntax-de.pdf
	AV1_PARAMETER?: string;
	AV2_PARAMETER?: string;
}

interface FormatPaymentSlipDataResult {
	success: boolean;
	data: PaymentSlipDataOutput | null;
	qrReceipt: string | null;
	error: string;
}

/**
 * Formats data necessary for payment slips into exact variables according to the Swiss QR Bill standard/syntax
 */
export const formatPaymentSlipData = ({
	bankAccount,
	recipient,
	amount,
	currency,
	unstructuredMessage,
	rechnungsInfo,
	av1Param,
	av2Param,
	invoiceNumber,
}: PaymentSlipDataInput): FormatPaymentSlipDataResult => {
	if (!invoiceNumber) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Die Rechnungsnummer fehlt.",
		};
	}
	if (!bankAccount.reference_number) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine Referenznummer zugeordnet. Wenn Sie keine Referenznummer haben, setzen Sie sie auf 0000000000000.",
		};
	}
	if (!bankAccount.qr_iban) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine IBAN zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_name) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist kein Name zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_adrtp) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist kein Adresstyp zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_street_line_1) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine Strasse zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_house_number_line_2) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine Hausnummer zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_area_code) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine Postleitzahl zugeordnet.",
		};
	}
	if (!bankAccount.cdtr_city) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Dem gewählten Bankkonto ist keine Stadt zugeordnet.",
		};
	}
	if (invoiceNumber.length !== 6) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: "Die Rechnungsnummer muss 6-stellig sein.",
		};
	}

	const KONTO = bankAccount.qr_iban;
	if (!isIBANValid(KONTO)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `IBAN des Zahlungsempfängers (KONTO) ist unzulässig (${KONTO})`,
		};
	}
	// Decided to always use S for now, instead of bankAccount.cdtr_adrtp
	const ZE_ADRESS_TYP = "S";
	if (ZE_ADRESS_TYP !== "S" && ZE_ADRESS_TYP !== "K") {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Adresstyp des Zahlungsempfängers (ZE_ADRESS_TYP) ist unzulässig (${ZE_ADRESS_TYP})`,
		};
	}
	const ZE_NAME = bankAccount.cdtr_name;
	if (!isNameValid(ZE_NAME)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Name des Zahlungsempfängers (ZE_NAME) ist unzulässig (${ZE_NAME})`,
		};
	}
	const ZE_STRASSE_ODER_ADRESSZEILE_1 = bankAccount.cdtr_street_line_1;
	if (!isStrtNmOrAdrLine1Valid(ZE_STRASSE_ODER_ADRESSZEILE_1)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Strasse oder Adresszeile 1 des Zahlungsempfängers (ZE_STRASSE_ODER_ADRESSZEILE_1) ist unzulässig (${ZE_STRASSE_ODER_ADRESSZEILE_1})`,
		};
	}
	const ZE_HAUSNUMMER_ODER_ADRESSZEILE_2 =
		bankAccount.cdtr_house_number_line_2;
	if (
		!isBldgNbOrAdrLine2Valid(
			ZE_HAUSNUMMER_ODER_ADRESSZEILE_2,
			ZE_ADRESS_TYP
		)
	) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Hausnummer oder Adresszeile 2 des Zahlungsempfängers (ZE_HAUSNUMMER_ODER_ADRESSZEILE_2) ist unzulässig (${ZE_ADRESS_TYP}, ${ZE_HAUSNUMMER_ODER_ADRESSZEILE_2})`,
		};
	}

	// As long as we use only "S", we simplify the following: ZE_ADRESS_TYP === "K" ? "" : bankAccount.cdtr_area_code || "";
	const ZE_POSTLEITZAHL = bankAccount.cdtr_area_code;
	if (!isPstCdValid(ZE_POSTLEITZAHL, ZE_ADRESS_TYP)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Die Postleitzahl des Zahlungsempfängers ist unzulässig (ZE_POSTLEITZAHL lautet "${ZE_POSTLEITZAHL}", ZE_ADRESS_TYP lautet "${ZE_ADRESS_TYP}"). Sie können diese in den Einstellungen des Bankkontos ändern.`,
		};
	}
	// As long as we use only "S", we simplify the following: ZE_ADRESS_TYP === "K" ? "" : bankAccount.cdtr_city || "";
	const ZE_ORT = bankAccount.cdtr_city;
	if (!isTwnNmValid(ZE_ORT, ZE_ADRESS_TYP)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Ort des Zahlungsempfängers (ZE_ORT) ist unzulässig (${ZE_ADRESS_TYP}, ${ZE_ORT})`,
		};
	}
	const ZE_LAND = bankAccount.cdtr_country?.toUpperCase() || "CH";
	if (!isCtryValid(ZE_LAND)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Land des Zahlungsempfängers (ZE_LAND) ist unzulässig (${ZE_LAND})`,
		};
	}
	const BETRAG = amount;
	if (!isAmtValid(BETRAG)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `BETRAG ist unzulässig (${BETRAG})`,
		};
	}
	const WAEHRUNG = currency || Currency.CHF;
	const REFERENZ = generateQrReferenceNumber(
		bankAccount.reference_number,
		invoiceNumber
	);

	// For now, we simplify by using "S" instead of allowing "S" or "K"
	const EZP_ADRESS_TYP = "S";
	if (!EZP_ADRESS_TYP) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `EZP_ADRESS_TYP ist unzulässig (${EZP_ADRESS_TYP})`,
		};
	}
	const EZP_NAME = recipient.first_name + " " + recipient.last_name;
	if (!isNameValid(EZP_NAME)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `EZP_NAME ist unzulässig (${EZP_NAME})`,
		};
	}
	const EZP_STRASSE_ODER_ADRESSZEILE_1 = recipient.street || undefined;
	if (
		EZP_STRASSE_ODER_ADRESSZEILE_1 &&
		!isStrtNmOrAdrLine1Valid(EZP_STRASSE_ODER_ADRESSZEILE_1)
	) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Strasse oder Adresszeile 1 des endgültigen Zahlungspflichtigen (EZP_STRASSE_ODER_ADRESSZEILE_1) ist unzulässig (${EZP_STRASSE_ODER_ADRESSZEILE_1})`,
		};
	}
	const EZP_HAUSNUMMER_ODER_ADRESSZEILE_2 = undefined;
	if (
		EZP_HAUSNUMMER_ODER_ADRESSZEILE_2 &&
		!isBldgNbOrAdrLine2Valid(
			EZP_HAUSNUMMER_ODER_ADRESSZEILE_2,
			EZP_ADRESS_TYP
		)
	) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Hausnummer oder Adresszeile 2 des endgültigen Zahlungspflichtigen (EZP_HAUSNUMMER_ODER_ADRESSZEILE_2) ist unzulässig (${EZP_ADRESS_TYP}, ${EZP_HAUSNUMMER_ODER_ADRESSZEILE_2})`,
		};
	}
	const EZP_POSTLEITZAHL = recipient.postal_code || "";
	if (!isPstCdValid(EZP_POSTLEITZAHL, EZP_ADRESS_TYP)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Postleitzahl des endgültigen Zahlungspflichtigen (EZP_POSTLEITZAHL) ist unzulässig (${EZP_ADRESS_TYP}, ${EZP_POSTLEITZAHL})`,
		};
	}
	const EZP_ORT = recipient.city || "";
	if (!isTwnNmValid(EZP_ORT, EZP_ADRESS_TYP)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Ort des endgültigen Zahlungspflichtigen (EZP_ORT) ist unzulässig (${EZP_ADRESS_TYP}, ${EZP_ORT})`,
		};
	}
	const EZP_LAND = recipient.country?.toUpperCase() || "CH";
	if (!isCtryValid(EZP_LAND)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `Land des endgültigen Zahlungspflichtigen (EZP_LAND) ist unzulässig (${EZP_LAND})`,
		};
	}
	if (!isRefValid(REFERENZ)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `REFERENZ ist unzulässig (${REFERENZ})`,
		};
	}
	const UNSTRUKTURIERTE_MITTEILUNG = unstructuredMessage || undefined;
	if (
		UNSTRUKTURIERTE_MITTEILUNG &&
		!isUstrdValid(UNSTRUKTURIERTE_MITTEILUNG)
	) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `UNSTRUKTURIERTE_MITTEILUNG ist unzulässig (${UNSTRUKTURIERTE_MITTEILUNG})`,
		};
	}
	const RECHNUNGSINFORMATIONEN = rechnungsInfo || undefined;
	if (RECHNUNGSINFORMATIONEN && !isStrdBkgInfValid(RECHNUNGSINFORMATIONEN)) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `RECHNUNGSINFORMATIONEN ist unzulässig (${RECHNUNGSINFORMATIONEN})`,
		};
	}
	if (
		UNSTRUKTURIERTE_MITTEILUNG &&
		RECHNUNGSINFORMATIONEN &&
		!isAddInfValid(UNSTRUKTURIERTE_MITTEILUNG, RECHNUNGSINFORMATIONEN)
	) {
		return {
			success: false,
			data: null,
			qrReceipt: null,
			error: `UNSTRUKTURIERTE_MITTEILUNG and RECHNUNGSINFORMATIONEN are not valid (${UNSTRUKTURIERTE_MITTEILUNG}, ${RECHNUNGSINFORMATIONEN})`,
		};
	}
	const AV1_PARAMETER = av1Param || undefined;
	const AV2_PARAMETER = av2Param || undefined;

	const result: PaymentSlipDataOutput = {
		KONTO,
		ZE_ADRESS_TYP,
		ZE_NAME,
		ZE_STRASSE_ODER_ADRESSZEILE_1,
		ZE_HAUSNUMMER_ODER_ADRESSZEILE_2,
		ZE_POSTLEITZAHL,
		ZE_ORT,
		ZE_LAND,
		BETRAG,
		WAEHRUNG,
		REFERENZ,
		EZP_ADRESS_TYP,
		EZP_NAME,
		EZP_STRASSE_ODER_ADRESSZEILE_1,
		EZP_HAUSNUMMER_ODER_ADRESSZEILE_2,
		EZP_POSTLEITZAHL,
		EZP_ORT,
		EZP_LAND,
		UNSTRUKTURIERTE_MITTEILUNG,
		RECHNUNGSINFORMATIONEN,
		AV1_PARAMETER,
		AV2_PARAMETER,
	};
	return {
		success: true,
		data: result,
		qrReceipt: getQrCodeReceipt(result),
		error: "",
	};
};

/**
 * getQrData - returns the QR-Code data for the given parameters
 * ---
 * Header (Obligatorisch):
 * QRType
 * Version
 * Coding Type
 * ---
 */
const getQrCodeReceipt = ({
	KONTO,
	ZE_ADRESS_TYP,
	ZE_NAME,
	ZE_STRASSE_ODER_ADRESSZEILE_1,
	ZE_HAUSNUMMER_ODER_ADRESSZEILE_2,
	ZE_POSTLEITZAHL,
	ZE_ORT,
	ZE_LAND,
	BETRAG,
	WAEHRUNG,
	REFERENZ,
	EZP_ADRESS_TYP,
	EZP_NAME,
	EZP_STRASSE_ODER_ADRESSZEILE_1 = "",
	EZP_HAUSNUMMER_ODER_ADRESSZEILE_2 = "",
	EZP_POSTLEITZAHL,
	EZP_ORT,
	EZP_LAND,
	UNSTRUKTURIERTE_MITTEILUNG = "",
	RECHNUNGSINFORMATIONEN = "",
	AV1_PARAMETER = "",
	AV2_PARAMETER = "",
}: PaymentSlipDataOutput): string => {
	const isQrIban = checkQrIban(KONTO);

	const QRTYPE = "SPC"; // Swiss Payment Code
	const VERSION = "0200"; // Version 2.0
	const CODING_TYPE = "1"; // UTF-8

	// EZE (Endgültiger Zahlungsempfänger) darf aktuell noch nicht befüllt werden.
	const EZE_ADRESS_TYP = "";
	const EZE_NAME = "";
	const EZE_STRASSE_ODER_ADRESSZEILE_1 = "";
	const EZE_HAUSNUMMER_ODER_ADRESSZEILE_2 = "";
	const EZE_POSTLEITZAHL = "";
	const EZE_ORT = "";
	const EZE_LAND = "";

	// Referenztyp (QR, SCOR, NON)
	const REFERENZTYP = isQrIban ? "QRR" : "NON";
	const _REFERENZ = isQrIban ? REFERENZ.replaceAll(" ", "") : "";
	const TRAILER = "EPD";

	// It is essential that there are no tabs at the beginning of the line.
	return `${QRTYPE}
${VERSION}
${CODING_TYPE}
${KONTO}
${ZE_ADRESS_TYP}
${ZE_NAME}
${ZE_STRASSE_ODER_ADRESSZEILE_1}
${ZE_HAUSNUMMER_ODER_ADRESSZEILE_2}
${ZE_POSTLEITZAHL}
${ZE_ORT}
${ZE_LAND}
${EZE_ADRESS_TYP}
${EZE_NAME}
${EZE_STRASSE_ODER_ADRESSZEILE_1}
${EZE_HAUSNUMMER_ODER_ADRESSZEILE_2}
${EZE_POSTLEITZAHL}
${EZE_ORT}
${EZE_LAND}
${BETRAG}
${WAEHRUNG}
${EZP_ADRESS_TYP}
${EZP_NAME}
${EZP_STRASSE_ODER_ADRESSZEILE_1}
${EZP_HAUSNUMMER_ODER_ADRESSZEILE_2}
${EZP_POSTLEITZAHL}
${EZP_ORT}
${EZP_LAND}
${REFERENZTYP}
${_REFERENZ}
${UNSTRUKTURIERTE_MITTEILUNG}
${TRAILER}
${RECHNUNGSINFORMATIONEN}
${AV1_PARAMETER}
${AV2_PARAMETER}`;
};
