import { Switch } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ShieldIcon from "@mui/icons-material/Shield";
import { useCentralStore } from "@/dentlab/src/store/Central";

export function PrivacySwitch() {
	const { profile, updatePrivacy } = useCentralStore((state) => ({
		profile: state.profile,
		updatePrivacy: state.updatePrivacy,
	}));
	const isPrivacyOn = !!profile?.data_privacy_mode;

	return (
		<Switch
			checked={isPrivacyOn}
			onChange={(e) => updatePrivacy(e.target.checked)}
			sx={{
				"& .MuiSwitch-switchBase.Mui-checked": {
					color: "green",
				},
				"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
					backgroundColor: "green",
				},
			}}
			checkedIcon={
				<VerifiedUserIcon
					sx={{
						fontSize: "1.3rem",
						color: "green",
					}}
				/>
			}
			icon={
				<ShieldIcon
					sx={{
						fontSize: "1.3rem",
						color: "gray",
					}}
				/>
			}
		/>
	);
}
