import { StateCreator } from "zustand";
import { supabase } from "../../supabase/supabaseClient";
import { SupabaseTableEnum } from "../../supabase/supabaseTypes";
import { RealtimeChannel } from "@supabase/supabase-js";
import { Logger } from "../../logger/Logger";
import { useRealtimeStore } from ".";

export interface RealtimeSubscriptionSlice {
	channels: RealtimeChannel[];

	subscribe: (connectRelationshipId: string) => void;
	unsubscribe: () => Promise<void>;
}

export const createRealtimeSubscriptionStore: StateCreator<
	RealtimeSubscriptionSlice
> = (set, get) => ({
	channels: [],
	subscribe: (connectRelationshipId: string) => {
		const activityId = useRealtimeStore.getState().activityId;
		Logger.info("Subscribing..", connectRelationshipId, activityId);

		const channel = supabase
			.channel("custom-changes-db")
			.on(
				"postgres_changes",
				{
					event: "INSERT",
					schema: "public",
					table: SupabaseTableEnum.REPLIES,
					filter: `shared_id=eq.${activityId}`,
				},
				(payload) => {
					useRealtimeStore.getState().updateReplies(payload);
				}
			)
			.subscribe();

		const channel2 = supabase
			.channel("customsss")
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: SupabaseTableEnum.SHARED,
					filter: `connect_relationship_id=eq.${connectRelationshipId}`,
				},
				(payload) => {
					useRealtimeStore.getState().updateActivities(payload);
				}
			)
			.subscribe();

		set({
			channels: [channel, channel2],
		});
	},

	unsubscribe: async () => {
		Logger.info("Unsubscribing..");
		const channels = get().channels;

		await Promise.all(
			channels.map((c) => {
				c.unsubscribe();
			})
		);
	},
});
