import { Typography } from "@/components/src/typography/typography.component";
import { Button, Card, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import {
	Printer,
	ResponseToGetPrinters,
	useDesktop,
} from "@/dentlab/src/hooks/useDesktop/useDesktop";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { DesktopSystemEntityTypeInsert } from "@/lib/supabase/supabaseTypes";
import { DesktopSystemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { Logger } from "@/lib/logger/Logger";

export const PrinterList: React.FC<{
	desktopId: string;
	onPrinterAdded: () => void;
}> = ({ desktopId, onPrinterAdded }) => {
	const { postRequestToGetPrinters, getResponse } = useDesktop();
	const { addDesktopSystem } = useDesktopsStore((state) => ({
		addDesktopSystem: state.addDesktopSystem,
	}));
	const [loading, setLoading] = useState<boolean>(false);
	const [printersResponse, setPrintersResponse] =
		useState<ResponseToGetPrinters | null>(null);

	useEffect(() => {
		if (!printersResponse) {
			sendRequestForPrinters();
		}
	}, [desktopId]);

	const sendRequestForPrinters = async () => {
		if (!desktopId) {
			Logger.log("No desktop id");
			return;
		}

		setLoading(true);

		const requestId = await postRequestToGetPrinters(desktopId);
		if (!requestId) {
			showNotification({
				message: "Fehler beim Senden der Anfrage",
				type: "error",
			});
			return;
		}

		const {
			success: successPoll,
			data: responseDataPoll,
			error: errorPoll,
		} = await getResponse({
			desktopJobRequestId: requestId,
			pollingInterval: 1000,
			pollingTimeout: 10000,
		});

		if (successPoll && responseDataPoll && responseDataPoll.length > 0) {
			setPrintersResponse(
				JSON.parse(
					responseDataPoll[0].data as string
				) as ResponseToGetPrinters
			);
		} else if (errorPoll) {
			// We are not showing an error because after the timeout the user might already have selected a different desktop
			Logger.log("Error getting printers 4", errorPoll);
		} else {
			Logger.log("Error getting printers 5", responseDataPoll);
		}

		setLoading(false);
	};

	const addPrinter = async (printer: Printer) => {
		if (!desktopId) {
			showNotification({
				message: "Kein Desktop ausgewählt",
				type: "error",
			});
			return;
		}
		const system: DesktopSystemEntityTypeInsert = {
			config: {
				...printer,
			},
			desktop_id: desktopId,
			type: DesktopSystemTypeEnum.PRINTER,
			name: printer.PrinterName,
			mac_address: "", // TODO: Use mac address as identifier?
		};
		const desktopSystemId = await addDesktopSystem(system);
		if (!desktopSystemId) {
			showNotification({
				message: "Fehler beim Hinzufügen des Druckers",
				type: "error",
			});
			return;
		} else {
			showNotification({
				message: "Drucker erfolgreich hinzugefügt",
				type: "success",
			});
			onPrinterAdded();
		}
	};

	return (
		<div
			style={{
				minHeight: "200px",
			}}
		>
			<div
				className="px-4 py-2"
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					minHeight: "200px",
				}}
			>
				{printersResponse &&
					printersResponse.Printers.map((printer, index) => (
						<Card
							key={index}
							variant="outlined"
							className="p-2 flex justify-between items-center"
						>
							{printer.PrinterName}
							<Button onClick={() => addPrinter(printer)}>
								Hinzufügen
							</Button>
						</Card>
					))}
				{!loading &&
					(!printersResponse ||
						printersResponse.Printers.length === 0) && (
						<div className="flex justify-center items-center">
							<Typography variant="body-lg">
								Keine Drucker gefunden
							</Typography>
						</div>
					)}
			</div>

			{loading && <LinearProgress />}
		</div>
	);
};
