import {
	Grid,
	GridColumn as Column,
	GridSelectionChangeEvent,
	GridHeaderSelectionChangeEvent,
	GridColumnResizeEvent,
	GridToolbar,
	GridColumnProps,
	GridRowProps,
	GridItemChangeEvent,
	GridCustomHeaderCellProps,
	GridCustomCellProps,
	GridExpandChangeEvent,
	GridColumnReorderEvent,
} from "@progress/kendo-react-grid";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import {
	idGetter,
	processWithSelected,
	processEdit,
	processWithCollapsed,
	getIsGrouped,
	getSelectedRows,
	getClipboardData,
	setTableConfig,
} from "./table-helpers";
import {
	getSelectedState,
	setGroupIds,
} from "@progress/kendo-react-data-tools";
import { GridSelectableSettings } from "@progress/kendo-react-grid";
import { TableConfig } from "../../types/types";
import { DATA_ITEM_KEY, SELECTED_FIELD } from "./table-helpers";
import { getTrueKeys } from "../../lib/utils/utils-functions";
import { useClipboard } from "../../context/ClipboardContext";
import { useShortCutListener } from "../../hooks/useShortcutListener";
import { Logger } from "../../../../lib/logger/Logger";
import { process } from "@progress/kendo-data-query";
import { Button as MuiButton, Skeleton } from "@mui/material";
import { HeaderCustomCell } from "./custom-cells/header-custom-cell.component";
import { GroupHeaderCustomCell } from "./custom-cells/group-header-custom-cell.component";
import { Translate } from "../translate/translate.component";
import { showNotification } from "../../store/Central/selectors";

const selectable: GridSelectableSettings = {
	enabled: false, // Determines if selection is allowed (only applies to selection via row, not via checkbox column).
	drag: false, // Determines if drag selection is allowed.
	cell: false, // Determines if cell selection is allowed.
	mode: "multiple", // Single or multiple.
};

/**
 * CustomTable - Base table component
 * - Listens to copy and cut events, listens to paste events and checks if the pasted content is valid
 * @param onConfigChange - Sends up new TableConfig object, when columns are reordered or resized
 * @param onRowChange - Sends up edited row object
 * @param onSelectedChange - Sends up array of currently selected rows
 * @param onPasteRows - Sends up array of pasted rows (does not add them to the table by itself)
 * @param dataCompatibility - Array of strings that indicate what rows can be pasted via clipboard
 *
 * To add a "select" column for selecting rows, add a column to the tableConfig with the field "selected".
 */
export const CustomTable: React.FC<{
	rows: any[];
	userConfig?: TableConfig | null;
	defaultConfig: TableConfig;
	toolbarChildren?: React.ReactNode;
	onConfigChange: (newConfig: TableConfig) => void;
	onRowChange: (newRow: any) => void;
	onSelectedChange: (selected: any[]) => void;
	onPasteRows: (pastedRows: any[]) => void;
	onCutRows: (cutRows: any[]) => void;
	onDeleteRows: (deletedRows: any[]) => void;
	dataCompatibility?: string[];
	noToolbar?: boolean;
	noGroups?: boolean;
	disabled?: boolean;
	externalLoading?: boolean;
}> = ({
	rows: initialRows,
	userConfig,
	defaultConfig,
	toolbarChildren,
	onConfigChange,
	onRowChange,
	onSelectedChange,
	onPasteRows,
	onCutRows,
	onDeleteRows,
	dataCompatibility = [],
	noToolbar = false,
	noGroups = false,
	disabled = false,
	externalLoading = false,
}) => {
	// Memoizing rows and tableConfig to prevent unnecessary re-rendering
	const rows = useMemo(() => initialRows, [initialRows]);
	const [lastUpdated, setLastUpdated] = useState<{
		time: Date;
		items: { code_e: string; quantity: number }[];
		last: string[];
	}>({ time: new Date(), items: [], last: [] });
	const tableConfig = useMemo(
		() => setTableConfig(defaultConfig, userConfig),
		[defaultConfig, userConfig]
	);

	const [data, setData] = useState<any[]>([]);
	const [collapsedState, setCollapsedState] = useState<string[]>([]);
	const [internalLoading, setInternalLoading] = useState<boolean | null>(
		null
	);
	const [selectedState, setSelectedState] = useState<{
		[id: string]: boolean | number[];
	}>({});
	const [isGrouped, setIsGrouped] = useState<boolean>(!noGroups);
	const [isHovering, setIsHovering] = useState<boolean>(false);

	const { clipboardData, setClipboardData } = useClipboard();

	const handleCopy = () => {
		const selectedRows = getSelectedRows(rows, selectedState);
		const newClipboardData = getClipboardData(
			selectedRows,
			dataCompatibility
		);
		if (selectedRows.length === 0) {
			return;
		}
		Logger.log("Copy rows", selectedRows);
		setClipboardData(newClipboardData);
	};

	const handleCut = () => {
		const selectedRows = getSelectedRows(rows, selectedState);
		const newClipboardData = getClipboardData(
			selectedRows,
			dataCompatibility
		);
		if (selectedRows.length === 0) {
			return;
		}
		Logger.log("Cut rows", selectedRows);
		setClipboardData(newClipboardData);
		onCutRows(selectedRows);
		setSelectedState({});
	};

	const handleDelete = () => {
		const selectedRows = getSelectedRows(rows, selectedState);
		if (selectedRows.length === 0) {
			return;
		}
		Logger.log("Delete rows", selectedRows);
		onDeleteRows(selectedRows);
		setSelectedState({});
	};

	const handlePaste = () => {
		if (!isHovering) {
			return;
		}
		if (clipboardData.data.length === 0) {
			return;
		}
		Logger.log("Paste rows", clipboardData);
		onPasteRows(clipboardData.data);
	};

	// TODO: also listen to escape events outside of focusing the cell input
	useShortCutListener((key) => {
		switch (key) {
			case "c":
				handleCopy();
				break;
			case "x":
				handleCut();
				break;
			case "v":
				handlePaste();
				break;
			case "Backspace":
				handleDelete();
				break;
			default:
				break;
		}
	});

	useEffect(() => {
		if ((!data || !data.length) && rows.length) setInternalLoading(true);
		else setInternalLoading(false);
	}, [data]);

	useEffect(() => {
		Logger.log("Starting processing");
		const dataState = tableConfig.dataState ?? {};
		const newData = process(rows, dataState);
		let processedWithCollapsed = [];
		const isGrouped = getIsGrouped(dataState); // do not use isGrouped from state, because it might not be set yet
		setIsGrouped(isGrouped);

		if (isGrouped) {
			// setGroupIds adds a unique id to each group
			setGroupIds({
				data: newData.data,
				group: dataState.group,
			});
			processedWithCollapsed = processWithCollapsed(
				newData.data,
				collapsedState
			);
		} else {
			processedWithCollapsed = newData.data;
		}

		const dataWithSelected = processWithSelected(
			processedWithCollapsed,
			selectedState,
			isGrouped
		);

		Logger.log("Finished processing", dataWithSelected);
		setData(dataWithSelected);
	}, [rows, tableConfig.dataState, collapsedState, selectedState]);

	// Handles the following changes of "dataState": sorting, grouping
	const handleDataStateChange = (event: any) => {
		Logger.log("handleDataStateChange", event);
		const isGrouped = getIsGrouped(event.dataState);
		Logger.log("isGrouped", isGrouped, event.dataState);
		setIsGrouped(isGrouped);
		Logger.log("Set isGrouped", isGrouped);
		onConfigChange({
			columns: tableConfig.columns,
			dataState: event.dataState,
		});
	};

	const handleExpandChange = useCallback(
		(event: GridExpandChangeEvent) => {
			const item = event.dataItem;
			if (item.groupId) {
				const collapsedIds = !event.value
					? [...collapsedState, item.groupId]
					: collapsedState.filter(
							(groupId) => groupId !== item.groupId
						);
				setCollapsedState(collapsedIds);
				setData((prevData) =>
					processWithCollapsed(prevData, collapsedIds)
				);
			}
		},
		[collapsedState]
	);

	const handleColumnResize = (event: GridColumnResizeEvent) => {
		Logger.log("handleColumnResize", event);

		if (event.end) {
			const newColumns: GridColumnProps[] = event.columns;
			const newConfig = setTableConfig(defaultConfig, {
				columns: newColumns,
				dataState: tableConfig.dataState,
			});
			onConfigChange(newConfig);
		}
	};

	const handleColumnReorder = (event: GridColumnReorderEvent) => {
		Logger.log("handleColumnReorder", event);
		const newColumns: GridColumnProps[] = event.columns;
		const newConfig = setTableConfig(defaultConfig, {
			columns: newColumns,
			dataState: tableConfig.dataState,
		});
		onConfigChange(newConfig);
	};

	// TODO check out getSelectedState
	const completeSelectionChange = useCallback(
		(newSelectedState: any, isGrouped: boolean) => {
			setSelectedState(newSelectedState);
			onSelectedChange(getTrueKeys(newSelectedState));
			Logger.log("completeSelectionState", newSelectedState, isGrouped);
			setData((prevData) =>
				processWithSelected(prevData, newSelectedState, isGrouped)
			);
		},
		[onSelectedChange]
	);

	const handleSelectionChange = useCallback(
		(event: GridSelectionChangeEvent) => {
			const newSelectedState: any = getSelectedState({
				event,
				selectedState: selectedState,
				dataItemKey: DATA_ITEM_KEY,
			});
			completeSelectionChange(newSelectedState, isGrouped);
			Logger.log("handleSelectionChange", event);
		},
		[selectedState, isGrouped, completeSelectionChange]
	);

	const handleHeaderSelectionChange = useCallback(
		(event: GridHeaderSelectionChangeEvent) => {
			const checkboxElement: any = event.syntheticEvent.target;
			const checked = checkboxElement.checked;
			const newSelectedState: any = {};

			event.dataItems.forEach((item) => {
				newSelectedState[idGetter(item)] = checked;
			});
			completeSelectionChange(newSelectedState, isGrouped);
			Logger.log("handleHeaderSelectionChange", event);
		},
		[isGrouped, completeSelectionChange]
	);

	const handleItemChange = (event: GridItemChangeEvent) => {
		if (disabled) {
			showNotification({
				message: "Tabelle ist deaktiviert",
				type: "error",
			});
			return;
		}

		Logger.log("handleItemChange", event);

		const editedItemID = event.dataItem[DATA_ITEM_KEY];
		if (!event.field) {
			Logger.error("No field in event", event);
			return;
		}
		const editedField = event.field as string;
		const editedValue = event.value;
		setData((prevData) =>
			processEdit(
				prevData,
				editedItemID,
				editedField,
				editedValue,
				isGrouped
			)
		);

		onRowChange({ ...event.dataItem, [editedField]: editedValue });
	};

	const handleMouseEnter = () => {
		setIsHovering(true);
	};

	const handleMouseLeave = () => {
		setIsHovering(false);
	};

	const updateLastUpdatedState = () => {
		const codesToBeUpdated: string[] = [];

		// if new row is added, set the last updated item to the new row by checking which element in rows is not in lastUpdated.items
		if (rows.length > lastUpdated.items.length) {
			const foundRowCode = rows.find(
				(row) =>
					!lastUpdated.items.find(
						(item) => item.code_e === row.code_e
					)
			)?.code_e;
			if (foundRowCode) {
				codesToBeUpdated.push(foundRowCode);
			}
		} else {
			// if no new row is added, check which item has a different quantity than before
			rows.forEach((row) => {
				lastUpdated.items.forEach((item) => {
					if (
						item.code_e === row.code_e &&
						item.quantity !== Number(row.quantity)
					) {
						codesToBeUpdated.push(row.code_e);
					}
				});
			});
		}

		setLastUpdated((prev) => ({
			time: new Date(),
			items: rows.map((row) => {
				return {
					code_e: row.code_e,
					quantity: Number(row.quantity),
				};
			}),
			last: codesToBeUpdated || prev.last,
		}));

		setTimeout(() => {
			setLastUpdated((prev) => {
				return {
					...prev,
					last: [],
				};
			});
		}, 2000);
	};

	useEffect(() => {
		updateLastUpdatedState();
	}, [rows]);

	const rowRender = useCallback(
		(
			trElement: React.ReactElement<HTMLTableRowElement>,
			props: GridRowProps
		) => {
			const lastUpdatedRow = lastUpdated.last.includes(
				props.dataItem.code_e
			);
			const blue = { backgroundColor: "var(--tertiary-200)" };
			const trProps: any = { style: lastUpdatedRow ? blue : {} };
			return cloneElement(
				trElement,
				{ ...trProps },
				// @ts-expect-error this is how kendo react does it in their guides
				trElement.props.children
			);
		},
		[lastUpdated]
	);

	if (internalLoading || externalLoading)
		return (
			<Translate>
				<div>
					<Grid
						data={rows}
						cells={{
							group: {
								groupHeader:
									GroupHeaderCustomCell as React.ComponentType<GridCustomCellProps>,
							},
							headerCell:
								// @ts-expect-error This is the type defined by Kendo but doesn't overlap with the type of the custom cell
								HeaderCustomCell as React.ComponentType<GridCustomHeaderCellProps>,
						}}
					>
						{!noToolbar && (
							<GridToolbar>
								{Object.values(selectedState).filter((e) => e)
									.length > 0 ? (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<MuiButton
											disabled
											sx={{
												color: "var(--grey-400)",
												backgroundColor:
													"var(--grey-100)",
											}}
										>
											Kopieren
										</MuiButton>
										<MuiButton disabled>
											Ausschneiden
										</MuiButton>
										<MuiButton disabled>Löschen</MuiButton>
									</div>
								) : (
									<>{toolbarChildren && toolbarChildren}</>
								)}
							</GridToolbar>
						)}
						{tableConfig.columns.map((column) => {
							return (
								<Column
									field={column.field}
									key={column.field}
									title={column.title}
									width={column?.width || "100px"}
									cell={() => {
										return (
											<td
												style={{
													padding: "0 4px",
													margin: 0,
												}}
											>
												<Skeleton
													variant="text"
													width={column?.width || 100}
													style={{
														padding: 0,
														margin: 0,
														height: 26,
													}}
												/>
											</td>
										);
									}}
								/>
							);
						})}
					</Grid>
				</div>
			</Translate>
		);
	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{
				pointerEvents: disabled ? "none" : "auto",
				opacity: disabled ? 0.5 : 1,
			}}
		>
			<Translate>
				<Grid
					size="small"
					data={data}
					dataItemKey={DATA_ITEM_KEY}
					reorderable={true}
					sortable={true}
					groupable={!noGroups}
					resizable={true}
					onDataStateChange={handleDataStateChange}
					onExpandChange={handleExpandChange}
					expandField="expanded"
					selectable={selectable}
					selectedField={SELECTED_FIELD}
					onSelectionChange={handleSelectionChange}
					onHeaderSelectionChange={handleHeaderSelectionChange}
					onColumnResize={handleColumnResize}
					onColumnReorder={handleColumnReorder}
					onItemChange={handleItemChange}
					onContextMenu={(event) =>
						Logger.log("onContextMenu", event)
					}
					{...tableConfig.dataState}
					cells={{
						group: {
							groupHeader: GroupHeaderCustomCell as any,
						},
						headerCell: HeaderCustomCell as any,
					}}
					rowRender={rowRender}
				>
					{!noToolbar && (
						<GridToolbar>
							{Object.values(selectedState).filter((e) => e)
								.length > 0 ? (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "10px",
									}}
								>
									<MuiButton
										onClick={() => handleCopy()}
										variant="outlined"
									>
										Kopieren
									</MuiButton>
									<MuiButton
										onClick={() => handleCut()}
										variant="outlined"
									>
										Ausschneiden
									</MuiButton>
									<MuiButton
										onClick={() => handleDelete()}
										variant="outlined"
									>
										Löschen
									</MuiButton>
								</div>
							) : (
								<>{toolbarChildren && toolbarChildren}</>
							)}
						</GridToolbar>
					)}
					{tableConfig.columns.map((column: GridColumnProps) => {
						if (column.field === SELECTED_FIELD) {
							return (
								<Column
									key={column.id}
									field={SELECTED_FIELD}
									width={
										tableConfig.columns.find(
											(col) =>
												col.field === SELECTED_FIELD
										)?.width || "50px"
									}
									// using the default data is sufficient, because it is only needed to have a list of all items and their ids
									// whether all items are selected is determined by selectedState
									headerSelectionValue={
										rows.findIndex(
											(item: any) =>
												!selectedState[idGetter(item)]
										) === -1
									}
									cell={(props) => {
										if (props.rowType != "groupHeader") {
											return (
												<td
													style={{
														padding: 0,
														margin: 0,
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
															height: "100%",
														}}
													>
														<input
															type="checkbox"
															checked={
																selectedState[
																	idGetter(
																		props.dataItem
																	)
																] as boolean
															}
															onChange={(e) => {
																const newSelectedState =
																	{
																		...selectedState,
																		[idGetter(
																			props.dataItem
																		)]:
																			e
																				.target
																				.checked,
																	};
																completeSelectionChange(
																	newSelectedState,
																	isGrouped
																);
															}}
															style={{
																margin: 0,
																padding: 0,
															}}
														/>
													</div>
												</td>
											);
										} else {
											return <td></td>;
										}
									}}
								/>
							);
						}
						// GridColumnProps has no "show" property, but the kendo react documentation suggests to use it like this
						if ((column as any).show) {
							return (
								<Column
									key={column.field}
									field={column.field}
									title={column.title}
									width={column.width}
									cells={column?.cells || undefined}
									{...column}
								/>
							);
						}
					})}
				</Grid>
			</Translate>
		</div>
	);
};
