import { SelectProps } from "../../form-types";
import { useTeamProfilesContext } from "../../../context/single-entity-contexts/TeamProfilesContext";
import { ProfileEntityType } from "../../../../../lib/supabase/supabaseTypes";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

const generateLabel = (profile: ProfileEntityType) => {
	if (profile.first_name === null || profile.last_name === null) {
		return profile.email || profile.code || "Unbekannt";
	} else {
		return `${profile.first_name} ${profile.last_name}`;
	}
};

export const TeamProfileSelect: React.FC<SelectProps> = (props) => {
	const {
		teamProfiles: { teamProfiles, teamProfilesLookup },
	} = useTeamProfilesContext();

	const handleChange = (event: SelectChangeEvent) => {
		props.onChange(event.target.value);
	};

	return (
		<FormControl
			variant="standard"
			sx={{ minWidth: 70, margin: 0, width: "100%" }}
		>
			<Select
				labelId="demo-simple-select-standard-label"
				id="demo-simple-select-standard"
				value={props.value || ""}
				onChange={handleChange}
				label="Age"
				sx={{
					height: "20px",
					"& .MuiSelect-select": {
						padding: "0px",
						height: "22px",
						fontSize: "12px",
					},
				}}
				fullWidth
			>
				{teamProfiles.length > 0 &&
					teamProfiles.map((profile) => (
						<MenuItem key={profile.id} value={profile.id}>
							{generateLabel(profile)}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};
