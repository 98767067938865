import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CustomButtonProps } from "./button-types";

// TODO: if there's a label, this should not use the IconButton component, instead all should be clickable

/**
 *
 * @param onClick: () => void;
 * @param size?: "small" | "medium" | "large";
 * @returns
 */
export const GoBackButton: React.FC<
  CustomButtonProps & {
    label?: string;
  }
> = (props) => {
  let labelSize = "1rem";
  if (props.size === "small") {
    labelSize = "0.75rem";
  } else if (props.size === "large") {
    labelSize = "1.25rem";
  }

  let iconSize = "1.5rem";
  if (props.size === "small") {
    iconSize = "1rem";
  } else if (props.size === "large") {
    iconSize = "2rem";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <IconButton
        {...props}
        sx={{
          margin: "0",
        }}
      >
        <ArrowBackIcon
          sx={{
            fontSize: iconSize,
          }}
        />
      </IconButton>
      {props.label && (
        <span
          style={{
            fontSize: labelSize,
            color: "gray",
          }}
        >
          {props.label ? props.label : "Zurück"}
        </span>
      )}
    </div>
  );
};
