import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { TableConfig } from "../../types/types";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "../../../../lib/supabase";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../lib/logger/Logger";

export interface ConfigSlice extends State {
	gridLoading: boolean;
	gridConfig: TableConfig | null;
	updateGridConfig: (newConfig: TableConfig) => Promise<void>;
	fetchGridConfig: () => Promise<void>;
}

export const createConfigStore: StateCreator<ConfigSlice> = (set, get) => ({
	...initialState,
	gridLoading: false,
	gridConfig: null,
	fetchGridConfig: async () => {
		set({ gridLoading: true });
		const userId = get().userId;
		if (!userId) return;

		const { success, data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.PROFILES)
				.select("*")
				.eq("id", userId)
		);

		if (success && data) {
			const profile = data[0];
			const gridConfig = profile?.grid_config_job_items;
			if (gridConfig) {
				set({ gridConfig: JSON.parse(gridConfig) });
			}
		}
	},
	updateGridConfig: async (newConfig: TableConfig) => {
		const oldConfig = get().gridConfig;
		set({ gridConfig: newConfig });

		const userId = get().userId;
		if (!userId) return;

		const { success, data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.PROFILES)
				.update({ grid_config_job_items: JSON.stringify(newConfig) })
				.eq("id", userId)
		);

		if (error) {
			set({ gridConfig: oldConfig });
			Logger.error("Error updating grid config", error);
			return;
		}
	},
});
