import {
	ActivityEntityTypeInsert,
	ActivityTypeEnum,
	SupabaseTableEnum,
} from "../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../store/Central";
import { supabase } from "@/lib/supabase";

const userIdError = {
	data: null,
	error: "userId is null",
};

const organizationIdError = {
	data: null,
	error: "organizationId is null",
};

export const useShared = () => {
	const { userId, organizationId } = useCentralStore((state) => {
		return {
			userId: state.userId,
			organizationId: state.organization?.id,
		};
	});

	/**
	 * Please note that right now "creator_type" is set to "lab"
	 * @param connectRelationshipId
	 * @param jobId
	 * @returns {AddActivityReturnType} success, data, error
	 */
	const addSharedJobActivity = async (
		connectRelationshipId: string,
		jobId: number
	) => {
		if (!userId) {
			return userIdError;
		}
		if (!organizationId) {
			return organizationIdError;
		}
		return supabase
			.from(SupabaseTableEnum.SHARED)
			.insert({
				connect_relationship_id: connectRelationshipId,
				created_by: userId,
				job_id: jobId,
				activity_type: ActivityTypeEnum.SHARED_JOB,
				created_by_org: organizationId,
			})
			.select()
			.single();
	};

	/**
	 * Please note that right now "creator_type" is set to "lab"
	 * @param connectRelationshipId
	 * @param patientId
	 * @returns {AddActivityReturnType} success, data, error
	 */
	const addSharedPatientActivity = async (
		connectRelationshipId: string,
		patientId: string
	) => {
		if (!userId) {
			return userIdError;
		}
		if (!organizationId) {
			return organizationIdError;
		}
		return supabase
			.from(SupabaseTableEnum.SHARED)
			.insert({
				connect_relationship_id: connectRelationshipId,
				created_by: userId,
				patient_id: patientId,
				activity_type: ActivityTypeEnum.SHARED_PATIENT,
				created_by_org: organizationId,
			})
			.select()
			.single();
	};

	const addSharedFileActivity = async (
		connectRelationshipId: string,
		fileId: string,
		activityType:
			| ActivityTypeEnum.SHARED_DOCUMENT
			| ActivityTypeEnum.SHARED_FILE
	) => {
		if (!userId) {
			return userIdError;
		}
		if (!organizationId) {
			return organizationIdError;
		}

		const activity: ActivityEntityTypeInsert = {
			connect_relationship_id: connectRelationshipId,
			created_by: userId,
			file_id: fileId,
			activity_type: activityType,
			created_by_org: organizationId,
		};

		return supabase
			.from(SupabaseTableEnum.SHARED)
			.insert(activity)
			.select()
			.single();
	};

	const addMessageActivity = async (
		connectRelationshipId: string,
		message: string
	) => {
		if (!userId) {
			return userIdError;
		}
		if (!organizationId) {
			return organizationIdError;
		}
		return supabase
			.from(SupabaseTableEnum.SHARED)
			.insert({
				connect_relationship_id: connectRelationshipId,
				created_by: userId,
				text: message,
				activity_type: ActivityTypeEnum.MESSAGE_GENERAL,
				job_id: null,
				patient_id: null,
				file_id: null,
				created_by_org: organizationId,
			})
			.select()
			.single();
	};

	return {
		addSharedJobActivity,
		addSharedPatientActivity,
		addSharedFileActivity,
		addMessageActivity,
	};
};
