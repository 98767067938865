// All field validation functions should be defined here

import {
	isBldgNbOrAdrLine2Valid,
	isCtryValid,
	isIBANValid,
	isNameValid,
	isPstCdValid,
	isStrtNmOrAdrLine1Valid,
	isTwnNmValid,
} from "../../pdf-templates/pdf-template-components/qr-payment-slip/qr-payment-validation-functions";
import { uniqueCantons } from "../../lib/constants";

export const _validateField = (name: string, value: any) => {
	if (value === "") return null; // if the user empties the field, remove the error message
	switch (name) {
		case "tp_variation":
		case "default_tp_variation":
			return !isNaN(value) ? null : "Bitte geben Sie eine Zahl ein.";
		case "price":
		case "purchase_price":
		case "margin":
		case "standard_quantity":
			return parseFloat(value) >= 0
				? null
				: "Bitte geben Sie eine positive Zahl ein.";
		case "email":
			return validateEmail(value)
				? null
				: "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
		case "phone_personal":
		case "phone_business":
		case "phone_mobile":
		case "c_phone_personal":
		case "c_phone_business":
		case "c_phone_mobile":
			return validatePhone(value)
				? null
				: "Bitte geben Sie eine gültige Telefonnummer ein.";
		case "kv_print_qty":
		case "del_note_print_qty":
		case "inv_print_qty":
			return validateNumeric(value)
				? null
				: "Bitte geben Sie eine ganze Zahl ein.";
		case "own_tp":
		case "discount_work":
		case "discount_material":
			return validateFloat(value)
				? null
				: "Bitte geben Sie eine Zahl ein.";
		case "phone_and_fax":
			return validatePhone(value)
				? null
				: "Bitte geben Sie eine gültige Telefon- oder Faxnummer ein.";
		case "gln":
			return validateGLN(value)
				? null
				: "Bitte geben Sie eine gültige GLN ein.";
		case "qr_iban":
			return isIBANValid(value)
				? null
				: "Bitte geben Sie eine gültige IBAN ein.";
		case "cdtr_street_line_1":
			return isStrtNmOrAdrLine1Valid(value)
				? null
				: "Bitte geben Sie eine gültige Strasse ein.";
		case "cdtr_house_number_line_2":
			return isBldgNbOrAdrLine2Valid(value, "K") ||
				isBldgNbOrAdrLine2Valid(value, "S")
				? null
				: "Bitte geben Sie eine gültige Hausnummer ein."; // TODO: not making sure it is correct based on address type
		case "cdtr_city":
			return isTwnNmValid(value, "K") || isTwnNmValid(value, "S")
				? null
				: "Bitte geben Sie eine gültige Stadt ein."; // TODO: not making sure it is correct based on address type
		case "cdtr_name":
			return isNameValid(value)
				? null
				: "Bitte geben Sie einen gültigen Namen ein.";
		case "cdtr_area_code":
			return isPstCdValid(value, "K") || isPstCdValid(value, "S")
				? null
				: "Bitte geben Sie eine gültige Postleitzahl ein."; // TODO: not making sure it is correct based on address type
		case "cdtr_country":
			return isCtryValid(value)
				? null
				: "Bitte geben Sie ein gültiges Land ein.";

		case "ahv_number":
			return validateNumericWithNumberOfDigits(value, 13)
				? null
				: "Bitte geben Sie eine gültige AHV-Nummer mit 13 Stellen ein.";
		default:
			return null;
	}
};

export const validateEmail = (value: string) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(value);
};

// validate phone number
// value.replace(/\s/g, "") - remove all spaces (i.e. allow any spaces in the middle of the number)
// (?:\+|00)\d{2}) - country code, either +xx or 00xx or 0
// \d{9,10} - 9 or 10 digits
export const validatePhone = (value: string) => {
	const valueWithoutSpaces = value.replace(/\s/g, "");
	const phoneRegex = /^(?:0|(?:\+|00)\d{2})\d{9,10}$/;
	return phoneRegex.test(valueWithoutSpaces);
};

export const validateCantonAbbreviation = (cantonAbbreviation: string) => {
	return uniqueCantons.includes(cantonAbbreviation);
};

const validateNumeric = (value: string) => {
	const numericRegex = /^\d+$/;
	return numericRegex.test(value);
};

const validateFloat = (value: string) => {
	const floatRegex = /^\d+(\.\d+)?$/;
	return floatRegex.test(value);
};

// validate Global Location Number (GLN)
const validateGLN = (value: string) => {
	const glnRegex = /^\d{13}$/;
	return glnRegex.test(value);
};

const validateNumericWithNumberOfDigits = (
	value: string,
	numberOfDigits: number
) => {
	const numericRegex = new RegExp(`^\\d{${numberOfDigits}}$`);
	return numericRegex.test(value);
};
