import {
	IntlProvider,
	LocalizationProvider,
	load,
	loadMessages,
} from "@progress/kendo-react-intl";
import deMessages from "./de-DE.json";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/de/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/de/timeZoneNames.json";

loadMessages(deMessages, "de-DE");

load(
	likelySubtags,
	currencyData,
	weekData,
	caGregorian,
	dateFields,
	timeZoneNames
);
/**
 * Can be used to wrap kendo react components and translate their default messages to German.
 */
export const Translate: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	return (
		<LocalizationProvider language="de-DE">
			<IntlProvider locale="de-DE">{children}</IntlProvider>
		</LocalizationProvider>
	);
};
