import { EditableTitle } from "../../../../../../components/src/editable-title/editable-title.component";
import { useState, useEffect } from "react";
import { useJobActions } from "@/dentlab/src/hooks/actions/useJobActions";
import { JobWithShare } from "@/lib/supabase/supabaseTypes";

export const JobCodeForm: React.FC<{
	job: JobWithShare | null;
}> = ({ job }) => {
	const { isUpdateJobCodeAllowed, updateJobCode } = useJobActions();

	const [code, setCode] = useState<string>(job?.code || "");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	useEffect(() => {
		setCode(job?.code || "");
	}, [job]);

	const handleSubmit = async () => {
		if (!job) return;
		await updateJobCode(job, code);
	};

	return (
		<EditableTitle
			name="code"
			value={code}
			onChange={handleInputChange}
			onSubmit={handleSubmit}
			onReset={() => {}}
			type="code"
			loading={!job}
			disabled={job ? !isUpdateJobCodeAllowed(job).isAllowed : true}
		/>
	);
};
