import { Checkbox, FormControlLabel } from "@mui/material";
import { CustomTextField } from "../custom-input-components/custom-text-field.component";
import { FormGroupProps } from "../../form-types";

// make onCheckboxChange and onChange required
interface OwnTpGroupProps extends FormGroupProps {
  onCheckboxChange: (event: any) => void;
  onChange: (event: any) => void;
}

export const OwnTpGroup: React.FC<OwnTpGroupProps> = ({
  formData,
  formErrors,
  onChange = () => {},
  onCheckboxChange = () => {},
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            name="is_own_tp"
            checked={formData.is_own_tp ? true : false}
            onChange={onCheckboxChange}
            color="primary"
          />
        }
        label="Eigener TP-Wert"
        sx={{
          mt: "6.5px",
          width: "170px",
        }}
      />
      <CustomTextField
        label="Taxpunktwert"
        name="own_tp"
        value={formData.own_tp || ""}
        onChange={onChange}
        validationError={formErrors.own_tp}
        disabled={!formData.is_own_tp}
        sx={{
          flex: 1,
        }}
      />
    </div>
  );
};
