import { FileEntityTypeWithUrl, FileWithShareWithUrl } from "./useStorage";

/**
 * useFileOptions - provides functions to download or email a file
 * @example const { downloadFile, printFile } = useFileOptions();
 */
export const useFileOptions = () => {
	/**
	 * downloadFile - downloads a file to the user's device
	 * @param file - file object to download
	 */
	const downloadFile = async (
		file: FileEntityTypeWithUrl | FileWithShareWithUrl
	) => {
		const a = document.createElement("a");

		// download file from url
		const response = await fetch(file.url);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const blob = await response.blob();

		a.href = URL.createObjectURL(blob);
		a.download = file.file_name || "";
		a.click();
	};

	return { downloadFile };
};
