import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { RolesEnum } from "../types/enums";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getRole(roleNumber: number): string {
	switch (roleNumber) {
		case RolesEnum.OWNER:
			return "Besitzer";
		case RolesEnum.ADMIN:
			return "Administrator";
		case RolesEnum.MEMBER:
			return "Mitglied";
		default:
			return "Mitglied";
	}
}
