import { FormControl, InputLabel, Select } from "@mui/material";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";

const options = [
	{
		value: PdfTemplateType.KOSTENVORANSCHLAG,
		label: "Kostenvoranschlag",
		type: "document",
	},
	{
		value: PdfTemplateType.LIEFERSCHEIN,
		label: "Lieferschein",
		type: "document",
	},
	{
		value: PdfTemplateType.GUTSCHRIFT,
		label: "Gutschrift",
		type: "document",
	},
	{ value: PdfTemplateType.RECHNUNG, label: "Rechnung", type: "document" },
	{
		value: PdfTemplateType.SAMMELRECHNUNG,
		label: "Sammelrechnung",
		type: "document",
	},
	{
		value: PdfTemplateType.KARTENZAHLUNGSQUITTUNG,
		label: "Kartenzahlungsquittung",
		type: "document",
	},
	{
		value: PdfTemplateType.BARZAHLUNGSQUITTUNG,
		label: "Barzahlungsquittung",
		type: "document",
	},
	{
		value: PdfTemplateType.AKONTORECHNUNG,
		label: "Akontorechnung",
		type: "document",
	},
	{
		value: PdfTemplateType.ZAHLUNGSERRINERUNG,
		label: "Zahlungserinnerung",
		type: "document",
	},
	{
		value: PdfTemplateType.ERSTE_MAHNUNG,
		label: "Erste Mahnung",
		type: "document",
	},
	{
		value: PdfTemplateType.FOLGEMAHNUNG,
		label: "Folgemahnung",
		type: "document",
	},
	{
		value: PdfTemplateType.AUFTRAGSBLATT,
		label: "Auftragsblatt",
		type: "export",
	},
];

// TODO: divide dropdown into documents (kv, ls,...) and elements of documents (footer,...)
export const PdfTemplateSelect: React.FC<{
	value: PdfTemplateType;
	onChange: (value: PdfTemplateType) => void;
}> = ({ value, onChange }) => {
	return (
		<FormControl sx={{ minWidth: 120, maxWidth: 300 }}>
			<InputLabel shrink htmlFor="select-multiple-native">
				Dokumente
			</InputLabel>
			<Select
				native
				value={value}
				onChange={(e) => onChange(e.target.value as PdfTemplateType)}
				label="Dokumente"
				className="h-8"
			>
				<optgroup label="Dokumente">
					{options.map(
						(option) =>
							option.type === "document" && (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							)
					)}
				</optgroup>
				<optgroup label="Auftragsexporte">
					{options.map(
						(option) =>
							option.type === "export" && (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							)
					)}
				</optgroup>
			</Select>
		</FormControl>
	);
};
