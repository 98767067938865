import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";
import { GuarantorEntityType } from "../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "@/dentlab/src/store/Central";

const GuarantorSelectBare: React.FC<
	SelectProps & {
		guarantors: GuarantorEntityType[];
	}
> = (props) => {
	const options = [
		{
			value: "",
			label: "Kein Garant",
		},
		...props.guarantors.map((g) => ({
			value: g.id,
			label: g.first_name + " " + g.last_name,
		})),
	];

	return <CustomSelect options={options} inputLabel="Garant" {...props} />;
};

export const GuarantorSelect: React.FC<SelectProps> = (props) => {
	const guarantors = useCentralStore((state) =>
		Object.values(state.guarantorLookup ?? {})
	);
	return <GuarantorSelectBare guarantors={guarantors} {...props} />;
};
