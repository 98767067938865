import { ClientEntityType, JobEntityType } from "@/lib/supabase/supabaseTypes";
import { useEffect, useRef, useState } from "react";
import { CollapsibleList } from "./components/collapsible-list.component";
import { formatDate, getAllDaysInRange } from "./components/utils";
import { JobTimeline } from "./components/job-timeline.component";
import { Button, OutlinedInput } from "@mui/material";
import { SearchIcon } from "lucide-react";
import clsx from "clsx";
import { PageTitle } from "@/components/src/page-title/page-title.component";

interface GanttChartProps {
	clients: (ClientEntityType & { jobs: JobEntityType[] })[];
	fullScreen?: boolean;
}
export const GanttChart: React.FC<GanttChartProps> = ({
	clients,
	fullScreen,
}) => {
	const [expanded, setExpanded] = useState<string[]>([]);
	const [visibleClients, setVisibleClients] = useState<
		(ClientEntityType & { jobs: JobEntityType[] })[]
	>([]);
	useEffect(() => {
		setVisibleClients(clients);
	}, [clients]);

	const today = new Date();

	const startDate = new Date();
	startDate.setDate(today.getDate() - 7);

	const endDate = new Date();
	endDate.setDate(today.getDate() + 30);

	const daysInRange = getAllDaysInRange(startDate, endDate);

	const timelineRef = useRef<HTMLDivElement>(null);

	const searchClient = (term: string) => {
		const searchValue = term;
		if (searchValue === "") {
			setVisibleClients(clients.filter((c) => c.jobs.length));
			return;
		}
		setVisibleClients((prev) =>
			prev.filter((client) => {
				return (
					client.first_name
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					client.last_name
						.toLowerCase()
						.includes(searchValue.toLowerCase())
				);
			})
		);
	};

	return (
		<div
			className={clsx("w-full h-full flex flex-col justify-end", {
				"px-12 pt-6": !fullScreen,
			})}
		>
			<div
				className={clsx("w-full flex gap-4 items-center mb-4 ", {
					hidden: fullScreen,
				})}
			>
				<PageTitle title={[{ name: "Agenda" }]} />
				<Button
					onClick={() => {
						if (!timelineRef.current) return;
						timelineRef.current.scrollLeft =
							(today.getDate() - startDate.getDate()) * 112;
					}}
				>
					Heute
				</Button>
			</div>
			<div
				className={clsx(
					"rounded-lg rounded-b-none relative flex-col border overflow-hidden border-neutral-700 flex w-full max-w-screen h-full max-h-screen",
					{
						"rounded-none": fullScreen,
					}
				)}
			>
				<div className="w-full flex overflow-y-auto relative overflow-x-hidden h-full">
					<div className="w-1/4 h-max min-h-full border-r-2 border-neutral-700">
						<div className="h-10 border-b w-full py-2 flex justify-center border-neutral-700 bg-neutral-200">
							<OutlinedInput
								className="w-4/5 text-sm hover:bg-neutral-200 transition-all duration-150 bg-transparent"
								placeholder="Kunde suchen..."
								startAdornment={
									<div className="mr-2">
										<SearchIcon size={14} />
									</div>
								}
								onChange={(e) => searchClient(e.target.value)}
							/>
						</div>
						<div>
							{visibleClients.map((client, i) => {
								return (
									<CollapsibleList
										key={client.id}
										client={client}
										expanded={expanded.includes(client.id)}
										setExpanded={setExpanded}
										even={i % 2 === 0}
									/>
								);
							})}
						</div>
					</div>
					<div className="w-3/4">
						<div
							className="w-full  min-h-full h-max  overflow-x-auto"
							ref={timelineRef}
						>
							<div className="h-10 flex w-max items-center border-b border-neutral-700 bg-neutral-200">
								{/*7 days before today and 30 days after today*/}
								{daysInRange.map((date) => {
									return (
										<div
											key={date.toString()}
											className="w-28 items-center flex justify-center"
										>
											{formatDate(date)}
											{startDate.getFullYear() !==
												endDate.getFullYear() && (
												<sup className="text-xs">
													{date.getFullYear()}
												</sup>
											)}
										</div>
									);
								})}
							</div>
							<div className="min-w-full w-max">
								{visibleClients.map((client, i) => (
									<JobTimeline
										key={client.id}
										client={client}
										expanded={expanded.includes(client.id)}
										daysInRange={daysInRange}
										even={i % 2 === 0}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
