import React from "react";
import { IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export const ArrowIconButton: React.FC<{
  open: boolean;
  onClick: () => void;
}> = ({ open, onClick }) => {
  return (
    <IconButton aria-label="open" size="small" onClick={onClick}>
      {open ? (
        <ArrowDropDownIcon fontSize="small" />
      ) : (
        <ArrowRightIcon fontSize="small" />
      )}
    </IconButton>
  );
};
