import { GuarantorEntityType } from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { QrPaymentSlip } from "../pdf-template-components/qr-payment-slip/qr-payment-slip.component";
import { CommonPDFTemplateProps } from "./types";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";

export interface FolgemahnungTemplateProps extends CommonPDFTemplateProps {
	guarantor?: GuarantorEntityType;
	dateMahnung: string;
	paymentSlipData: PaymentSlipDataInput;
}

export const FolgemahnungTemplate: React.FC<FolgemahnungTemplateProps> = ({
	client,
	job,
	jobDocument,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	guarantor,
	dateMahnung,
	paymentSlipData,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobDocument ||
		!jobItems ||
		!client
	) {
		return null;
	}
	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.FOLGEMAHNUNG}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.FOLGEMAHNUNG}
			/>
			<PdfTitleAndDate title="Mahnung" date={dateMahnung} />
			<div
				style={{
					paddingTop: "10mm",
					fontWeight: 700,
					fontSize: "12pt",
				}}
			>
				Auftraggeber: {client?.title} {client.first_name}{" "}
				{client.last_name}, {client.street}, {client.city}
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					paddingTop: "10mm",
					paddingBottom: "30mm",
					fontWeight: 500,
					gap: "4mm",
					fontSize: "12pt",
				}}
			>
				<p>
					Guten Tag {client?.title} {client?.last_name},
				</p>
				<p>
					Da meine vorherige Mahnung unbeantwortet geblieben ist,
					bitte ich Sie den offenen Betrag von
					{paymentSlipData.amount} innerhalb von{" "}
					{organization.days_payment_mahnung_2} Tagen zu begleichen
					oder mich umgehend anzurufen.
				</p>
				<p>Wir bitten Sie, den Betrag jetzt zu überweisen.</p>
				<p>
					Sollten Sie bereits überwiesen haben, so entschuldigen Sie
					bitte die Überschneidung.
				</p>
				<p>Mit freundlichen Grüssen</p>
			</div>
			<PdfFooter
				templateType={PdfTemplateType.FOLGEMAHNUNG}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
			<QrPaymentSlip
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				paymentSlipData={paymentSlipData}
				textAbove={
					pdfTemplateConfiguration[PdfTemplateType.RECHNUNG]
						.textAbovePaymentSlip
				}
			/>
		</PdfLayout>
	);
};
