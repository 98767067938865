import { getConfig } from "../../context/util";
import parse from "html-react-parser";
import vzlsLogo from "../../assets/vzls.ico";
import swissLabelLogo from "../../assets/swisslabel.png";
import szvLogo from "../../assets/szv.jpg";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

export const OrganizationsImg: React.FC<{
	config: ReturnType<typeof getConfig>;
	generalConfig: PdfTemplateConfiguration["general"];
}> = ({ config, generalConfig }) => {
	if (config.hideMembers) {
		return null;
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				gap: "2mm",
				alignItems: "center",
				width: "100%",
				justifyContent: config.organizationsImgAlignment as
					| "left"
					| "right",

				left:
					config.organizationsImgPosition === "bottom" &&
					config.organizationsImgAlignment == "left"
						? `${generalConfig.marginLeft}mm`
						: undefined,
				right:
					config.organizationsImgPosition === "bottom"
						? config.organizationsImgAlignment == "right"
							? `${generalConfig.marginRight}mm`
							: ""
						: undefined,
				bottom:
					config.organizationsImgPosition === "bottom"
						? 0
						: undefined,
			}}
		>
			{config.organizationsImgAlignment === "right" && (
				<p
					style={{
						fontStyle: "italic",
					}}
				>
					{parse(config.orgImgText ?? "")}
				</p>
			)}
			{config.showVzlsMembership && (
				<>
					<img src={vzlsLogo} height={20} alt="VZLS Logo" />
				</>
			)}

			{config.showSwissLabel && (
				<img src={swissLabelLogo} width={30} alt="Swiss Label Logo" />
			)}
			{config.showSZVLabel && (
				<img src={szvLogo} width={30} alt="SZV Label Logo" />
			)}

			{config.customImgUrl && (
				<img src={config.customImgUrl} width={30} alt="Custom Image" />
			)}
			{config.organizationsImgAlignment === "left" && (
				<p
					style={{
						fontStyle: "italic",
					}}
				>
					{parse(config.orgImgText ?? "")}
				</p>
			)}
		</div>
	);
};
