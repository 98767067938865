import { Box, Typography, Button } from "@mui/material";

interface HeadingWithButtonProps {
  heading: string;
  buttonLabel: string;
  onClick: () => void;
  size?: "small" | "medium";
  buttonComponent?: React.ReactNode;
}

export const HeadingWithButton: React.FC<HeadingWithButtonProps> = ({
  heading,
  buttonLabel,
  onClick,
  size = "medium",
  buttonComponent = null,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant={size === "medium" ? "h4" : "h5"}
        gutterBottom
        sx={{ my: "15px" }}
      >
        {heading}
      </Typography>
      {buttonComponent ? (
        buttonComponent
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ ml: "15px" }}
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};
