import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";

export const jobItemExamples: JobItemEntityType[] = [
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 3.8,
		description: "Beispiel für einen Abzug",
		job_document_id: 603,
		modified_at: "2023-03-16 02:13:30.055116+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "111.0",
		quantity: 3,
		created_at: "2023-03-16 02:13:30.055116+00",
		type: 4,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "664",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 10,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 24.1,
		description: "R\u00fcckenplatte/Kaufl\u00e4che auf vorh. Wurzelkappe",
		job_document_id: 608,
		modified_at: "2023-03-16 02:13:34.953106+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "131.0",
		quantity: 10,
		created_at: "2023-03-16 02:13:34.953106+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "667",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 27.6,
		description: "Gussf\u00fcllung, indirekt, mehrfl\u00e4chig",
		job_document_id: 611,
		modified_at: "2023-03-16 02:13:40.543094+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "152.0",
		quantity: 1,
		created_at: "2023-03-16 02:13:40.543094+00",
		type: 1,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "669",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 14.8,
		description: "Beispiel für Porto",
		job_document_id: 619,
		modified_at: "2023-03-16 02:13:36.558765+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "135.0",
		quantity: 1,
		created_at: "2023-03-16 02:13:36.558765+00",
		type: 2,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "687",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: true,
		additional_text: null,
		price: 53.8,
		description: "Beispiel für Fremdarbeit",
		job_document_id: 609,
		modified_at: "2023-03-16 02:14:25.6336+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "513.0",
		quantity: 1,
		created_at: "2023-03-16 02:14:25.6336+00",
		type: 3,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "728",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: true,
		additional_text: null,
		price: 19.6,
		description: "Noch ein Beispiel für einen Abzug",
		job_document_id: 609,
		modified_at: "2023-03-16 02:14:27.024938+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "521.0",
		quantity: 1,
		created_at: "2023-03-16 02:14:27.024938+00",
		type: 4,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "729",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: true,
		additional_text: null,
		price: 13.5,
		description: "Beispiel für eine Arbeitspauschale",
		job_document_id: 609,
		modified_at: "2023-03-16 02:15:17.72753+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "905.0",
		quantity: 5,
		created_at: "2023-03-16 02:15:17.72753+00",
		type: 5,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "730",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: true,
		additional_text: null,
		price: 28.6,
		description: "Kloss a hohl, 2-teilig gestopft, aus hartem Kunststoff",
		job_document_id: 609,
		modified_at: "2023-03-16 02:15:19.062921+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "909.0",
		quantity: 1,
		created_at: "2023-03-16 02:15:19.062921+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "731",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: true,
		additional_text: null,
		price: 34.0,
		description:
			"Modellgussarbeit \u00fcber Metallteile, Stege und Pr\u00e4zisionsge.",
		job_document_id: 609,
		modified_at: "2023-03-16 02:14:10.632687+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "314.0",
		quantity: 1,
		created_at: "2023-03-16 02:14:10.632687+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "732",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 27.5,
		description: "Modell-Analyse z.B. Parallelit\u00e4tskontrolle",
		job_document_id: 634,
		modified_at: "2023-03-16 02:15:30.654735+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "45.1",
		quantity: 1,
		created_at: "2023-03-16 02:15:30.654735+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "739",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 4.1,
		description: "Modell mit Gegenbiss, zweiteilig (Quetschbiss)",
		job_document_id: 642,
		modified_at: "2023-03-16 02:13:08.841968+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "14.0",
		quantity: 1,
		created_at: "2023-03-16 02:13:08.841968+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "747",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 25.9,
		description: "Modell aus Hartgips",
		job_document_id: 645,
		modified_at: "2023-03-16 02:15:21.232944+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "12.1",
		quantity: 1,
		created_at: "2023-03-16 02:15:21.232944+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "752",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: "222",
		profile3_id: "d4a00b5e-ab80-44cc-9035-bccdcc86de69",
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 11.7,
		description: "Stiftaufbau",
		job_document_id: 630,
		modified_at: "2023-12-05 17:34:25.894561+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "133.0",
		quantity: 1,
		created_at: "2023-12-05 17:34:25.894561+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "770",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 24.2,
		description: "Vollgusskrone",
		job_document_id: 630,
		modified_at: "2023-03-16 02:13:31.097373+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "114.0",
		quantity: 1,
		created_at: "2023-03-16 02:13:31.097373+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "771",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 27.5,
		description: "Zuschlag f\u00fcr Feiertagsarbeiten, pro 15 Min.",
		job_document_id: 649,
		modified_at: "2023-03-16 02:15:20.925563+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "1.3",
		quantity: 1,
		created_at: "2023-03-16 02:15:20.925563+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "775",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 4.1,
		description: "Modell mit Gegenbiss, zweiteilig (Quetschbiss)",
		job_document_id: 647,
		modified_at: "2023-03-16 02:13:08.841968+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "14.0",
		quantity: 1,
		created_at: "2023-03-16 02:13:08.841968+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "774",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: "574499e2-a761-47f2-8b2e-2f8c8b83cb0f",
		profile3_id: null,
		discount: 0,
		profile_id: "d4a00b5e-ab80-44cc-9035-bccdcc86de69",
		is_warranty: true,
		is_req_dr_tax: false,
		additional_text: null,
		price: 21.3,
		description: "Material A",
		job_document_id: 621,
		modified_at: "2023-03-16 02:13:36.865397+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "136.0",
		quantity: 3,
		created_at: "2023-03-16 02:13:36.865397+00",
		type: 1,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "693",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
	{
		tax_rate: 1,
		profile2_id: null,
		profile3_id: null,
		discount: 0,
		profile_id: null,
		is_warranty: null,
		is_req_dr_tax: false,
		additional_text: null,
		price: 54.3,
		description: "Spezialmodell",
		job_document_id: 649,
		modified_at: "2023-03-16 02:15:22.596263+00",
		lot: null,
		is_print_additional_text: null,
		note: null,
		code: "17.1",
		quantity: 1,
		created_at: "2023-03-16 02:15:22.596263+00",
		type: 0,
		techn_perc_2: null,
		tp_value: 1,
		techn_perc_3: null,
		id: "776",
		article_id: 222,
		ce_number: "CE 0123",
		composition: "Kronenring nach Ringmass",
		manufacturer: "Dentaurum",
		tariff_id: 222,
		cluster: "1200",
		fpo_id: "1",
		code_e: "131",
	},
];
