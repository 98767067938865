function stringToColor(string: string) {
  const mutedColors = [
    "#950952",
    "#5E0035",
    "#023618",
    "#005C69",
    "#BF8B85",
    "#AD8A64",
    "#721121",
    "#A5402D",
    "#FFC07F",
    "#A49E8D",
    "#504136",
    "#689689",
    "#272838",
    "#5D536B",
    "#7D6B91",
    "#347FC4",
    "#B98B82",
    "#37515F",
    "#9899A6",
    "#AA78A6",
    "#824670",
    "#A2708A",
    "#629460",
  ];

  const hash = string.split("").reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);
  const index = hash % mutedColors.length;
  return mutedColors[index];
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.charAt(0)}${name.split(" ")[1]?.charAt(0)}`,
  };
}
