import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

const options = [
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Französisch" },
  { value: "it", label: "Italienisch" },
  { value: "en", label: "Englisch" },
];

export const LanguageSelect: React.FC<SelectProps> = (props) => {
  return <CustomSelect options={options} inputLabel="Sprache" {...props} />;
};
