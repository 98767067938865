import { IconButton, Menu } from "@mui/material";
import { useState } from "react";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

// TODO: replace children with list of menu items to iterate through
// TODO: see if you can replace ContextMenu used in JobList component with this

/**
 * CustomButtonWithAnchoredMenu - Renders a MoreHoriz icon or custom icon with an anchored menu, which is passed as children
 * @param children - menu items to be rendered in the anchored menu
 * @param CustomButton - custom icon to be displayed instead of MoreHoriz
 * @example
 * const IconButton: React.FC<{
 * onClick: (event: React.MouseEvent<HTMLElement>) => void;
 * }> = ({ onClick }) => {
 * return (
 * <IconButton color="primary" onClick={onClick} sx={{ pr: 2 }}>
 * <MoreVert />
 * </IconButton>
 * );
 * <CustomButtonWithAnchoredMenu
 *  CustomButton={IconButton}
 * onButtonClick={() => Logger.log("button clicked")}
 * >
 */
export const CustomButtonWithAnchoredMenu: React.FC<{
	children: React.ReactNode;
	icon?: React.ReactNode;
	disabled?: boolean;
}> = ({ children, icon, disabled = false }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
		handleOpenMenu(event);
	};

	return (
		<>
			<IconButton
				color="primary"
				onClick={handleButtonClick}
				disabled={disabled}
			>
				{icon || <MoreHoriz />}
			</IconButton>
			<Menu
				sx={{ mt: "35px" }}
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}
			>
				{children}
			</Menu>
		</>
	);
};
