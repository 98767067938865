import { InputAdornment } from "@mui/material";
import {
  CustomTextField,
  CustomTextFieldProps,
} from "./custom-text-field.component";

// TODO: DW-312
export const CustomTextFieldWithPercentage: React.FC<CustomTextFieldProps> = (
  props,
) => {
  return (
    <CustomTextField
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
    />
  );
};
