export const patientFormTexts = {
	options: {
		inv_covered_by: [
			{
				value: "private",
				label: "Privat",
			},
			{
				value: "kvg",
				label: "KVG-Tarif",
			},
			{
				value: "uvg",
				label: "UVG-Tarif",
			},
			{
				value: "social_welfare_office",
				label: "Sozialtarife",
			},
			{
				value: "other",
				label: "Andere",
			},
		],
		gender: [
			{
				value: "male",
				label: "Männlich",
			},
			{
				value: "female",
				label: "Weiblich",
			},
			{
				value: "diverse",
				label: "Divers",
			},
			{
				value: "no_answer",
				label: "Keine Angabe",
			},
		],
	},
	labels: {
		inv_covered_by: "Kostenübernahme",
	},
};
