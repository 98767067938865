import { ClientForm } from "../../forms/form-components/client/insert/client.form";
import { CustomDialog } from "../../components/dialog-components/custom-dialog/custom-dialog.component";

export const ClientManagementDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	return (
		<CustomDialog
			maxWidth="sm"
			open={open}
			onClose={onClose}
			dataTestId="client-management-dialog"
		>
			<div className="pt-10" />
			<ClientForm onSave={onClose} />
		</CustomDialog>
	);
};
