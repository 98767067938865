import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { TeamProfileSelect } from "../../../forms/form-input-components/selects/team-profile.select";
import { Logger } from "../../../../../lib/logger/Logger";
import "./custom-cells.css";

export const TechnicianSelectCustomCell = (props: GridCustomCellProps) => {
	const [profileId, setProfileId] = useState<string | null>(null);

	useEffect(() => {
		if (props.field) {
			setProfileId(props.dataItem[props.field]);
		}
	}, [props.dataItem, props.field]);

	const handleProfileChange = (value: string) => {
		if (props.onChange) {
			Logger.log("TechniciansCustomCell", "handleProfileChange", value);
			props.onChange({
				dataItem: props.dataItem,
				field: props.field,
				value: value,
				syntheticEvent: value as unknown as React.ChangeEvent<Element>,
				dataIndex: props.dataIndex,
			});
		}
	};

	return (
		<td className="custom-td">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					padding: 0,
					width: "100%",
				}}
			>
				<TeamProfileSelect
					onChange={(value) => handleProfileChange(value)}
					value={profileId}
					size="small"
				/>
			</div>
		</td>
	);
};
