import { Button, Chip, OutlinedInput, Portal } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Logger } from "../../../../../../../../lib/logger/Logger";
import { RightSidebar } from "../../../../job-page-components/right-sidebar.component";
import { useRefContext } from "../../../../../../context/RefContext";
import {
	ArticleEntityType,
	JobItemEntityType,
	TariffEntityType,
} from "../../../../../../../../lib/supabase/supabaseTypes";
import "./styles.css";
import { ArticleTypeEnum } from "@/lib/supabase/supabaseEnums";
import { ItemListing } from "./item-listing.component";
import { articleTypeOptions } from "@/dentlab/src/lib/constants/options";
import { useJobStore } from "@/dentlab/src/store/Jobs";

/**
 * JobItemsArticlesSelection
 */
export const JobItemsArticlesSelection: React.FC<{
	rows: ArticleEntityType[];
	label: string;
	jobItems: JobItemEntityType[];
	onSelect: (jobItemId: string, options?: { force: boolean }) => void;
	jobDocumentId: number;
}> = ({ rows, label, onSelect, jobItems, jobDocumentId }) => {
	const { container } = useRefContext();
	const [selectedCategory, setSelectedCategory] = useState<ArticleTypeEnum>(
		ArticleTypeEnum.MATERIAL
	);
	const [visibleRows, setVisibleRows] = useState<ArticleEntityType[]>([]);

	useEffect(() => {
		setVisibleRows(rows.filter((row) => selectedCategory === row.type));
	}, [rows, selectedCategory]);

	const { openedSidebar, openSidebar, closeSidebar } = useJobStore(
		(state) => ({
			openedSidebar: state.openedSidebar,
			openSidebar: state.openSideBar,
			closeSidebar: state.closeSidebar,
		})
	);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) {
			setVisibleRows(rows.filter((row) => selectedCategory === row.type));
			return;
		}

		setVisibleRows(
			rows.filter(
				(row) =>
					// This makes the search case-insensitive
					row.description_de?.match(new RegExp(e.target.value, "i"))
			)
		);
	};

	const open =
		openedSidebar?.type === "article" &&
		openedSidebar?.documentId === jobDocumentId;

	const handleClose = () => {
		closeSidebar();
	};

	const handleOpen = () => {
		openSidebar(jobDocumentId, "article");
	};

	const handleSelect = (
		position: { code_e: string },
		options?: { force: boolean }
	) => {
		Logger.log(
			"job-items-selection.component.tsx",
			"handleSelect",
			position
		);
		const jobItemId = position.code_e;
		onSelect(jobItemId, options);
	};

	const handleClick = (
		e: React.MouseEvent<HTMLDivElement>,
		row: TariffEntityType | ArticleEntityType
	) => {
		if (e.ctrlKey || e.metaKey) {
			handleSelect({ code_e: row.code_e }, { force: true });
			return;
		}
		handleSelect({ code_e: row.code_e });
	};

	const handleSelectCategory = (categoryValue: ArticleTypeEnum) => {
		setSelectedCategory(categoryValue);
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpen}
				startIcon={<SearchIcon />}
				size="small"
			>
				{label}
			</Button>
			{open && (
				<Portal container={container.current}>
					<RightSidebar title={label} onClose={handleClose}>
						<div
							style={{
								paddingTop: "10px",
								paddingBottom: "10px",
							}}
						>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: "5px",
									margin: "0px 10px 10px 10px",
								}}
							>
								{articleTypeOptions.map((category) => (
									<Chip
										label={category.label}
										key={category.value}
										color="primary"
										size="small"
										variant={
											selectedCategory === category.value
												? "filled"
												: "outlined"
										}
										onClick={() =>
											handleSelectCategory(category.value)
										}
										style={{
											cursor: "pointer",
										}}
									/>
								))}

								<div className="w-full flex items-start">
									<OutlinedInput
										className="mx-4 h-6"
										type="text"
										style={{
											fontSize: "14px",
										}}
										onChange={handleSearch}
										placeholder="Artikelname"
									/>
								</div>
							</div>
							<ItemListing
								jobItems={jobItems}
								rows={visibleRows}
								handleClick={handleClick}
							/>
						</div>
					</RightSidebar>
				</Portal>
			)}
		</>
	);
};
