import { createContext, useContext, useEffect, useState } from "react";
import { FileIdReferencesType } from "../types/types";
import { FileEntityTypeWithUrl, useStorage } from "../hooks/useStorage";

const FilesContext = createContext<
  | {
      files: FileEntityTypeWithUrl[];
      setFiles: React.Dispatch<React.SetStateAction<FileEntityTypeWithUrl[]>>;
      fetchFiles: () => Promise<void>;
    }
  | undefined
>(undefined);

/**
 * FilesContextProvider - Context provider for FilesContext
 * @example
 * <FilesContextProvider>
 *  <Component />
 * </FilesContextProvider>
 */
export const FilesContextProvider: React.FC<{
  children: React.ReactNode;
  fileIdReferences: FileIdReferencesType;
}> = ({ children, fileIdReferences }) => {
  const [files, setFiles] = useState<FileEntityTypeWithUrl[]>([]);

  const { fetchFilesByProps } = useStorage();

  useEffect(() => {
    fetchFiles();
  }, [fileIdReferences]);

  const fetchFiles = async () => {
    const { success, data, error } = await fetchFilesByProps({
      ...fileIdReferences,
    });

    if (success && data) {
      // if success, data should not be null
      setFiles(data as FileEntityTypeWithUrl[]);
    }
  };

  return (
    <FilesContext.Provider
      value={{
        files,
        setFiles,
        fetchFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export const useFilesContext = () => {
  const context = useContext(FilesContext);
  if (!context) {
    throw new Error("useFilesContext must be used within FilesContextProvider");
  }
  return context;
};
