/**
 * invoices.type
 */
export enum InvoiceType {
	RECHNUNG = 0,
	BARRECHNUNG = 1,
	KARTENZAHLUNGSRECHNUNG = 11, // New type for card payments
	AKONTORECHNUNG = 2,
	//TODO Akontoverrechnung = 6,
	//TODO Rueckverguetung = 7,
	//TODO: Verlustbuchung = 8,
	MONATSRG = 20,
}
