import { CommonProps } from "./PdfTemplateConfigurationContext";

export const getConfig = (
	pdfConfig: Partial<CommonProps>, // Specific config for pdfType
	generalConfig: CommonProps // General config
): CommonProps => ({
	addressAlignment:
		pdfConfig.addressAlignment ?? generalConfig.addressAlignment,
	oneLiner: pdfConfig.oneLiner ?? generalConfig.oneLiner,
	showBorder: pdfConfig.showBorder ?? generalConfig.showBorder,
	recipientAddressAlignment:
		pdfConfig.recipientAddressAlignment ??
		generalConfig.recipientAddressAlignment,
	idOneLiner: pdfConfig.idOneLiner ?? generalConfig.idOneLiner,
	idAlignment: pdfConfig.idAlignment ?? generalConfig.idAlignment,
	showSwissLabel: pdfConfig.showSwissLabel ?? generalConfig.showSwissLabel,
	showSZVLabel: pdfConfig.showSZVLabel ?? generalConfig.showSZVLabel,
	organizationsImgAlignment:
		pdfConfig.organizationsImgAlignment ??
		generalConfig.organizationsImgAlignment,
	organizationsImgPosition:
		pdfConfig.organizationsImgPosition ??
		generalConfig.organizationsImgPosition,
	orgImgText: pdfConfig.orgImgText ?? generalConfig.orgImgText,
	customIconPath: pdfConfig.customIconPath ?? generalConfig.customIconPath,
	customImgUrl: pdfConfig.customImgUrl ?? generalConfig.customImgUrl,
	showVzlsMembership:
		pdfConfig.showVzlsMembership ?? generalConfig.showVzlsMembership,
	addressRtfEnabled:
		pdfConfig.addressRtfEnabled ?? generalConfig.addressRtfEnabled,
	addressRtfText: pdfConfig.addressRtfText ?? generalConfig.addressRtfText,
	idRtfEnabled: pdfConfig.idRtfEnabled ?? generalConfig.idRtfEnabled,
	idRtfText: pdfConfig.idRtfText ?? generalConfig.idRtfText,
	absoluteRecipientAddress:
		pdfConfig.absoluteRecipientAddress ??
		generalConfig.absoluteRecipientAddress,
	hideMembers: pdfConfig.hideMembers ?? generalConfig.hideMembers,
	hideLabAddress: pdfConfig.hideLabAddress ?? generalConfig.hideLabAddress,
	hideIds: pdfConfig.hideIds ?? generalConfig.hideIds,
	headerBodyGap: pdfConfig.headerBodyGap ?? generalConfig.headerBodyGap,
});
