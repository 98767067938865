import { useEffect, useState } from "react";
import { ClientEntityType } from "@/lib/supabase/supabaseTypes";
import {
	Box,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { Loading } from "@/components/src/animations/loading";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { useToast } from "../../context/ToastContext";
import { useStatisticsStore } from "../../store/Statistics";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { useCentralStore } from "../../store/Central";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { EmptyDataPlaceholder } from "@/components/src/empty-with-label/empty-data-placeholder.component";

const SHOW_ALL = "All";

export const StatisticsPage = () => {
	const {
		initialize,
		dateValue,
		changeDate,
		formatEmployeeData,
		formattedEmployeeData,
		allClients,
		allEmployees,
		selectedClientsForClientChart,
		changeSelectedClients,
		formattedClientData,
		formatClientData,
		JobItemTypes,
		selectedJobItemTypeForClientChart,
		selectedEmployeeForClientChart,
		changeSelectedEmployee,
		changeSelectedJobItemType,
	} = useStatisticsStore();

	const organizationId = useCentralStore((state) => state.organization?.id);
	const [clientDataLoaidng, setClientDataLoading] = useState(true);
	const [employeeDataLoading, setEmployeeDataLoading] = useState(true);

	useEffect(() => {
		if (organizationId) initialize();
	}, [organizationId, initialize]);
	const { showToast } = useToast();

	const handleClientChange = (
		event: SelectChangeEvent<typeof selectedClientsForClientChart>
	) => {
		const changedClients = changeSelectedClients(event.target.value);
		if (changedClients.error) {
			const { error } = changedClients;
			showToast(error, "error");
			return;
		}
	};

	const handleJobItemTypeChange = (event: SelectChangeEvent) => {
		changeSelectedJobItemType(event.target.value as string);
	};

	const handleSelectedEmployeeChange = (event: SelectChangeEvent) => {
		changeSelectedEmployee(event.target.value as string);
	};

	useEffect(() => {
		if (organizationId)
			formatEmployeeData().finally(() => {
				setEmployeeDataLoading(false);
			});
	}, [formatEmployeeData, organizationId, dateValue]);

	useEffect(() => {
		if (organizationId)
			formatClientData().finally(() => {
				setClientDataLoading(false);
			});
	}, [
		formatClientData,
		organizationId,
		dateValue,
		selectedClientsForClientChart,
		selectedJobItemTypeForClientChart,
		selectedEmployeeForClientChart,
	]);

	return (
		<div className="flex flex-col px-12 py-6">
			<PageTitle title={[{ name: "Statistik" }]} />
			<div className="flex flex-col gap-6 py-4">
				<CustomDateRangePicker
					handleDateChange={changeDate}
					dateVal={dateValue}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<h3>Leistungsübersicht nach Auftraggeber</h3>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "20px",
						}}
					>
						<FormControl size="medium">
							<InputLabel>Auftraggeber</InputLabel>
							<Select
								multiple
								sx={{
									width: "400px",
								}}
								value={selectedClientsForClientChart}
								onChange={handleClientChange}
								input={<OutlinedInput label="Chip" />}
								label="Client"
								renderValue={(selected) => (
									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											gap: "5px",
										}}
									>
										{selected.map((value: string) => (
											<Chip
												key={value}
												label={
													allClients.find(
														(client) =>
															client.id === value
													)?.first_name
												}
												size="small"
											/>
										))}
									</Box>
								)}
							>
								{allClients.map((client: ClientEntityType) => {
									return (
										<MenuItem
											key={client.id}
											value={client.id}
										>
											{client.first_name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl
							sx={{
								width: "200px",
							}}
							size="medium"
						>
							<InputLabel>Leistungsart</InputLabel>
							<Select
								value={selectedJobItemTypeForClientChart}
								label="Document Types"
								onChange={handleJobItemTypeChange}
							>
								<MenuItem value={SHOW_ALL}>Alle</MenuItem>
								{JobItemTypes.map((itemType) => {
									return (
										<MenuItem
											key={itemType.value}
											value={itemType.value.toString()}
										>
											{itemType.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl
							sx={{
								width: "200px",
							}}
							size="medium"
						>
							<InputLabel id="demo-simple-select-label">
								Mitarbeiter
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={selectedEmployeeForClientChart}
								label="Document Types"
								onChange={handleSelectedEmployeeChange}
							>
								<MenuItem value={SHOW_ALL}>Alle</MenuItem>
								{allEmployees?.map((employee) => {
									return (
										<MenuItem
											key={employee.id}
											value={employee.id}
										>
											{`${employee.first_name} ${employee.last_name}`}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div>
					{formattedClientData.length > 0 ? (
						<BarChart
							// Typing in zustand store doesn't comply with the library
							//@ts-expect-error formatting
							dataset={formattedClientData}
							xAxis={[
								{
									scaleType: "band",
									dataKey: "name",
									id: "client_id",
								},
							]}
							yAxis={[
								{
									scaleType: "linear",
									label: "Umsatz in CHF",
									dataKey: "amount",
								},
							]}
							series={[
								...JobItemTypes.filter(
									(type) =>
										selectedJobItemTypeForClientChart ===
											SHOW_ALL ||
										type.value.toString() ===
											selectedJobItemTypeForClientChart
								).map(
									(type: {
										label: string;
										value: JobItemTypeEnum;
									}) => {
										return {
											dataKey: type.value.toString(),
											label:
												type.label
													.charAt(0)
													.toUpperCase() +
												type.label.slice(1),
										};
									}
								),
							]}
							width={1200}
							height={500}
						/>
					) : clientDataLoaidng ? (
						<div
							style={{
								height: 500,
							}}
						>
							<Loading />
						</div>
					) : (
						<EmptyDataPlaceholder label="Noch keine Daten zur Analyse vorhanden." />
					)}
				</div>
				<div>
					<h3>Leistungsübersicht nach Mitarbeiter</h3>
					{formattedEmployeeData.length > 0 ? (
						<BarChart
							// Typing in zustand store doesn't comply with the MUI library
							//@ts-expect-error formatting
							dataset={formattedEmployeeData}
							xAxis={[
								{
									scaleType: "band",
									data: formattedEmployeeData.map(
										(employee) => employee.name
									),
								},
							]}
							yAxis={[
								{
									scaleType: "linear",
									label: "Umsatz in CHF",
									dataKey: "amount",
								},
							]}
							series={JobItemTypes.map(
								(type: {
									label: string;
									value: JobItemTypeEnum;
								}) => {
									return {
										dataKey: type.value.toString(),
										label:
											type.label.charAt(0).toUpperCase() +
											type.label.slice(1),
									};
								}
							)}
							width={1200}
							height={500}
						/>
					) : employeeDataLoading ? (
						<div
							style={{
								height: 500,
							}}
						>
							<Loading />
						</div>
					) : (
						<EmptyDataPlaceholder label="Noch keine Daten zur Analyse vorhanden." />
					)}
				</div>
			</div>
		</div>
	);
};
