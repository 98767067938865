import { StateCreator } from "zustand";

import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { initialState } from "./utils";
import {
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { Logger } from "@/lib/logger/Logger";
import { FormattedEmployeeType, State } from "./types";
import { useCentralStore } from "../Central";

export interface Actions {
	formatEmployeeData: () => Promise<void>;
	fetchJobItemsForEmployees: () => Promise<JobItemEntityType[]>;
}

export type EmployeeChartSlice = State & Actions;

export const createEmployeeChartStore: StateCreator<EmployeeChartSlice> = (
	set,
	get
) => ({
	...initialState,
	fetchJobItemsForEmployees: async () => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return;

		const dateValue = get().dateValue;
		// TODO: Fetch only job items that have at least one of the profile_ids
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.select(
					`*, job_documents!inner( *, jobs!inner(client_id, organization_id ))`
				)
				.eq("job_documents.jobs.organization_id", organizationId)
				.in("job_documents.status", [
					JobStatusEnum.BOOKED_SINGLE,
					JobStatusEnum.BOOKED_MONTHLY,
				])
				.gte(
					"job_documents.created_at",
					`${dateValue.start?.toDateString()}`
				)
				.lte(
					"job_documents.created_at",
					`${dateValue.end?.toDateString()}`
				)
		);

		if (error) {
			Logger.error("Error fetching job items", error);
			set({ error });
			return;
		}

		return data;
	},

	// This function is used to format the data for the employee chart
	formatEmployeeData: async () => {
		const employees = get().allEmployees;
		if (!employees) return;

		const formattedEmployeeData: FormattedEmployeeType[] = employees.map(
			(employee) => {
				return {
					employee_id: employee.id,
					name: `${employee.first_name} ${employee.last_name}`,
					items: [],
				};
			}
		);

		const data = await get().fetchJobItemsForEmployees();

		if (!data || data.length === 0) {
			set({ formattedEmployeeData });
			return;
		}
		employees.forEach((employee) => {
			data?.forEach((d: JobItemEntityType) => {
				if (d.profile_id === employee.id) {
					const amount = d.price * d.quantity;
					const calculatedAmount =
						(amount *
							(100 -
								(d.techn_perc_2 ?? 0) -
								(d.techn_perc_3 ?? 0))) /
						100;
					const employeeIndex = formattedEmployeeData.findIndex(
						(fd) => fd.employee_id === employee.id
					);
					if (employeeIndex < 0) return;
					formattedEmployeeData[employeeIndex].items.push({
						type: d.type,
						amount: calculatedAmount,
					});
					formattedEmployeeData[employeeIndex][d.type ?? 0] =
						Number(
							formattedEmployeeData[employeeIndex][d.type ?? 0] ??
								0
						) + Number(calculatedAmount);
				} else if (d.profile2_id === employee.id) {
					const amount = d.price * d.quantity;
					const calculatedAmount =
						(amount * (d.techn_perc_2 ?? 0)) / 100;
					const employeeIndex = formattedEmployeeData.findIndex(
						(fd) => fd.employee_id === employee.id
					);

					if (employeeIndex < 0) return;
					formattedEmployeeData[employeeIndex].items.push({
						type: d.type,
						amount: calculatedAmount,
					});
					formattedEmployeeData[employeeIndex][d.type ?? 0] =
						Number(
							formattedEmployeeData[employeeIndex][d.type ?? 0] ??
								0
						) + calculatedAmount;
				} else if (d.profile3_id === employee.id) {
					const amount = d.price * d.quantity;
					const calculatedAmount =
						(amount * (d.techn_perc_3 ?? 0)) / 100;
					const employeeIndex = formattedEmployeeData.findIndex(
						(fd) => fd.employee_id === employee.id
					);

					if (employeeIndex < 0) return;
					formattedEmployeeData[employeeIndex].items.push({
						type: d.type,
						amount: calculatedAmount,
					});
					formattedEmployeeData[employeeIndex][d.type ?? 0] =
						Number(
							formattedEmployeeData[employeeIndex][d.type ?? 0] ??
								0
						) + calculatedAmount;
				}
			});
		});
		formattedEmployeeData.forEach((fd) => {
			get().JobItemTypes.forEach((type) => {
				const total = fd[type.value] ?? 0;
				fd[type.value] = total;
			});
		});

		set({ formattedEmployeeData });
	},
});
