import {
	DateRangePicker,
	DateRangePickerChangeEvent,
	SelectionRange,
	MultiViewCalendar,
	MultiViewCalendarProps,
} from "@progress/kendo-react-dateinputs";
import { Translate } from "../translate/translate.component";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

const DATE_RANGES = {
	1: "Dieser Monat", // This month
	2: "Letzter Monat", // Last month
	3: "Dieses Quartal", // This quarter
	4: "Letztes Quartal", // Last quarter
	5: "Dieses Jahr", // This year
	6: "Letztes Jahr", // Last year
};
function datesEqual(date1: Date, date2: Date): boolean {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

function getDateRangeFromValue(startDate: Date, endDate: Date): number | null {
	const currentDate = new Date();
	const thisMonthStart = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		1
	);
	const thisMonthEnd = currentDate;
	const lastMonthStart = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth() - 1,
		1
	);
	const lastMonthEnd = new Date(
		currentDate.getFullYear(),
		currentDate.getMonth(),
		0
	);
	const thisQuarterStart = new Date(
		currentDate.getFullYear(),
		Math.floor(currentDate.getMonth() / 3) * 3,
		1
	);
	const thisQuarterEnd = currentDate;
	const lastQuarterStart = new Date(
		currentDate.getMonth() - 3 < 0
			? currentDate.getFullYear() - 1
			: currentDate.getFullYear(),
		currentDate.getMonth() - 3 < 0 ? 9 : currentDate.getMonth() - 3,
		1
	);
	const lastQuarterEnd = new Date(
		currentDate.getMonth() - 3 < 0
			? currentDate.getFullYear() - 1
			: currentDate.getFullYear(),
		(currentDate.getMonth() - 3 < 0 ? 9 : currentDate.getMonth() - 3) + 3,
		0
	);
	const thisYearStart = new Date(currentDate.getFullYear(), 0, 1);
	const thisYearEnd = currentDate;
	const lastYearStart = new Date(currentDate.getFullYear() - 1, 0, 1);
	const lastYearEnd = new Date(currentDate.getFullYear() - 1, 11, 31);

	if (
		datesEqual(startDate, thisMonthStart) &&
		datesEqual(endDate, thisMonthEnd) &&
		(endDate.getHours() === 21 || endDate.getHours() === 0)
	) {
		return 1;
	} else if (
		datesEqual(startDate, lastMonthStart) &&
		datesEqual(endDate, lastMonthEnd)
	) {
		return 2;
	} else if (
		datesEqual(startDate, thisQuarterStart) &&
		datesEqual(endDate, thisQuarterEnd) &&
		(endDate.getHours() === 22 || endDate.getHours() === 0)
	) {
		return 3;
	} else if (
		datesEqual(startDate, lastQuarterStart) &&
		datesEqual(endDate, lastQuarterEnd)
	) {
		return 4;
	} else if (
		datesEqual(startDate, thisYearStart) &&
		datesEqual(endDate, thisYearEnd) &&
		(endDate.getHours() === 23 || endDate.getHours() === 0)
	) {
		return 5;
	} else if (
		datesEqual(startDate, lastYearStart) &&
		datesEqual(endDate, lastYearEnd)
	) {
		return 6;
	} else {
		return null; // Invalid date range
	}
}
function getDateRange(option: number) {
	const currentDate = new Date();
	let startDate: Date;
	let endDate: Date;

	const lastQuarterStartMonth =
		currentDate.getMonth() - 3 < 0 ? 9 : currentDate.getMonth() - 3;
	switch (option) {
		case 0: // This month
		case 1:
			startDate = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				1
			);
			endDate = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate(),
				21
			);
			break;
		case 2: // Last month
			startDate = new Date(
				currentDate.getMonth() - 1 < 0
					? currentDate.getFullYear() - 1
					: currentDate.getFullYear(),
				currentDate.getMonth() - 1,
				1
			);
			endDate = new Date(
				currentDate.getMonth() - 1 < 0
					? currentDate.getFullYear() - 1
					: currentDate.getFullYear(),
				currentDate.getMonth(),
				0
			);
			break;
		case 3: // This quarter
			startDate = new Date(
				currentDate.getFullYear(),
				Math.floor(currentDate.getMonth() / 3) * 3,
				1
			);
			endDate = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate(),
				22
			);
			break;
		case 4: // Last quarter
			startDate = new Date(
				currentDate.getMonth() - 3 < 0
					? currentDate.getFullYear() - 1
					: currentDate.getFullYear(),
				lastQuarterStartMonth,
				1
			);
			endDate = new Date(
				currentDate.getMonth() - 3 < 0
					? currentDate.getFullYear() - 1
					: currentDate.getFullYear(),
				lastQuarterStartMonth + 3,
				0
			);
			break;
		case 5: // This year
			startDate = new Date(currentDate.getFullYear(), 0, 1);
			endDate = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate(),
				23
			);
			break;
		case 6: // Last year
			startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
			endDate = new Date(currentDate.getFullYear() - 1, 11, 31);
			break;
		default:
			return null; // Invalid option
	}
	return { startDate, endDate };
}

const CustomCalendar = (props: MultiViewCalendarProps) => {
	const { onChange, value } = props;
	const [selectedDateRange, setSelectedDateRange] = useState<number>(1);

	const handleDateRangeChange = (value: number) => {
		setSelectedDateRange(value);
		const dateRange = getDateRange(value);
		if (!dateRange) return;
		if (onChange)
			onChange({
				value: {
					start: dateRange.startDate,
					end: dateRange.endDate,
				},
				// @ts-expect-error - This is a synthetic event and it's not being used
				syntheticEvent: null,
			});
	};
	useEffect(() => {
		// @ts-expect-error Value is of type SelectionRange
		if (!value?.start || !value?.end) return;
		// @ts-expect-error Value is of type SelectionRange
		const dateRange = getDateRangeFromValue(value?.start, value?.end);
		if (dateRange) {
			setSelectedDateRange(dateRange);
		} else {
			setSelectedDateRange(0);
		}
	}, [value]);
	return (
		<div
			style={{
				display: "flex",
				maxWidth: 900,
				justifyContent: "space-between",
				height: "100%",
			}}
		>
			<div
				style={{
					padding: 10,
					paddingLeft: 16,
					paddingRight: 16,
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				{Object.entries(DATE_RANGES).map((range) => {
					return (
						<Button
							key={range[0]}
							style={{
								width: "100%",
								textAlign: "center",
								cursor: "pointer",
								backgroundColor:
									selectedDateRange.toString() === range[0]
										? "#2288da"
										: "white",
								color:
									selectedDateRange.toString() === range[0]
										? "white"
										: "black",
							}}
							onClick={() => {
								handleDateRangeChange(Number(range[0]));
							}}
						>
							{range[1]}
						</Button>
					);
				})}
			</div>
			<MultiViewCalendar {...props} />
		</div>
	);
};
const defaultStartDate = () => {
	const currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 7);
	return currentDate;
};

export const CustomDateRangePicker: React.FC<{
	dateVal: SelectionRange;
	handleDateChange: (value: SelectionRange) => void;
}> = ({ dateVal, handleDateChange }) => {
	const [show, setShow] = useState<boolean>(true);
	const today = new Date();

	today.setHours(23);
	today.setMinutes(59);
	today.setSeconds(59);

	return (
		<Translate>
			{/* This will allow the daterange picker to close whenever a date range is selected*/}
			{!show ? (
				<DateRangePicker
					format={"dd.MM.yyyy"}
					calendar={CustomCalendar}
					defaultShow={false}
					style={{
						width: 360,
					}}
					onFocus={() => setShow(false)}
					max={today}
					value={dateVal}
					onChange={(event: DateRangePickerChangeEvent) => {
						handleDateChange(event.value);
						// Must make sure that the date range is valid before closing the picker
						if (!event.value.start || !event.value.end) return;
						setTimeout(() => {
							setShow(true);
						}, 500);
					}}
					defaultValue={{
						start: defaultStartDate(),
						end: new Date(),
					}}
				/>
			) : (
				<DateRangePicker
					format={"dd.MM.yyyy"}
					calendar={CustomCalendar}
					defaultShow={false}
					show={false}
					style={{
						width: 360,
					}}
					onFocus={() => setShow(false)}
					max={today}
					value={dateVal}
					onChange={(event: DateRangePickerChangeEvent) => {
						handleDateChange(event.value);
					}}
					defaultValue={{
						start: defaultStartDate(),
						end: new Date(),
					}}
				/>
			)}
		</Translate>
	);
};
