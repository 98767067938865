import { FormGroupProps } from "../../form-types";
import { CountrySelect } from "../selects/country-select/country.select";
import { CustomTextField } from "../custom-input-components/custom-text-field.component";
import { PostalCodeAndCityInput } from "@/dentlab/src/components/postal-code-and-city-input/postal-code-and-city-input.component";

type AddressGroupFieldTypes =
	| "street"
	| "city"
	| "postal_code"
	| "country"
	| "email"
	| "fax"
	| "phone_personal"
	| "phone_business"
	| "phone_mobile"
	| "additional_information";

interface AddressGroupProps extends FormGroupProps {
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	exclude?: AddressGroupFieldTypes[];
	requiredFields?: AddressGroupFieldTypes[];
}

export const AddressGroup: React.FC<AddressGroupProps> = ({
	formData,
	formErrors,
	onChange,
	onValueChange = () => {},
	customNaming = null,
	exclude = [],
	requiredFields = [],
}) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CustomTextField
				label="Strasse"
				name={customNaming?.street || "street"}
				value={
					customNaming?.street
						? formData[customNaming.street]
						: formData.street ?? ""
				}
				onChange={onChange}
				size="small"
				fullWidth
				required={requiredFields.includes("street")}
				validationError={formErrors?.street}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "8px",
				}}
			>
				{!exclude.includes("country") && (
					<CountrySelect
						onChange={(value) =>
							onValueChange({
								name: customNaming?.country || "country",
								value: value,
							})
						}
						value={
							customNaming?.country
								? formData[customNaming.country]
								: formData.country ?? ""
						}
						required={requiredFields.includes("country")}
					/>
				)}
				<PostalCodeAndCityInput
					country={
						customNaming?.country
							? formData[customNaming.country]
							: formData.country ?? "ch"
					}
					postalCodeValue={
						customNaming?.postal_code
							? formData[customNaming.postal_code]
							: formData.postal_code ?? ""
					}
					cityValue={
						customNaming?.city
							? formData[customNaming.city]
							: formData.city ?? ""
					}
					required={
						requiredFields.includes("city") ||
						requiredFields.includes("postal_code")
					}
					onPostalCodeChange={(value) =>
						onValueChange({
							name: customNaming?.postal_code || "postal_code",
							value: value,
						})
					}
					onCityChange={(value) =>
						onValueChange({
							name: customNaming?.city || "city",
							value: value,
						})
					}
				/>
			</div>

			<CustomTextField
				label="Zusatz"
				name={
					customNaming?.additional_information ||
					"additional_information"
				}
				value={
					customNaming?.additional_information
						? formData[customNaming.additional_information]
						: formData.additional_information ?? ""
				}
				onChange={onChange}
				size="small"
				fullWidth
				required={requiredFields.includes("additional_information")}
				validationError={formErrors?.additional_information}
			/>

			{!exclude.includes("phone_personal") && (
				<CustomTextField
					id="phone_personal"
					label="Telefon (privat)"
					name={customNaming?.phone_personal || "phone_personal"}
					value={
						customNaming?.phone_personal
							? formData[customNaming.phone_personal]
							: formData.phone_personal ?? ""
					}
					onChange={onChange}
					sx={{ mr: 2 }}
					fullWidth
					required={requiredFields.includes("phone_personal")}
					validationError={formErrors?.phone_personal}
				/>
			)}
			<CustomTextField
				id="phone_business"
				label="Telefon (geschäftlich)"
				name={customNaming?.phone_business || "phone_business"}
				value={
					customNaming?.phone_business
						? formData[customNaming.phone_business]
						: formData.phone_business ?? ""
				}
				onChange={onChange}
				sx={{ mr: 2 }}
				fullWidth
				required={requiredFields.includes("phone_business")}
				validationError={formErrors?.phone_business}
			/>
			<CustomTextField
				id="phone_mobile"
				label="Telefon (mobil)"
				name={customNaming?.phone_mobile || "phone_mobile"}
				value={
					customNaming?.phone_mobile
						? formData[customNaming.phone_mobile]
						: formData.phone_mobile ?? ""
				}
				onChange={onChange}
				fullWidth
				required={requiredFields.includes("phone_mobile")}
				validationError={formErrors?.phone_mobile}
			/>
			<CustomTextField
				id="email"
				label="E-Mail"
				name={customNaming?.email || "email"}
				value={
					customNaming?.email
						? formData[customNaming.email]
						: formData.email ?? ""
				}
				onChange={onChange}
				required={requiredFields.includes("email")}
				validationError={formErrors?.email}
			/>
			<CustomTextField
				label="Fax"
				name={customNaming?.fax || "fax"}
				value={
					customNaming?.fax
						? formData[customNaming.fax]
						: formData.fax ?? ""
				}
				onChange={onChange}
				size="small"
				fullWidth
				required={requiredFields.includes("fax")}
				validationError={formErrors?.fax}
			/>
		</div>
	);
};
