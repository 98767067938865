/**
 * SessionContext provides the current user's session data at the top level of the app.
 * The app needs to provide a sign in component that is displayed when there is no active session.
 */
/* eslint-disable indent,no-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase";
import { Logger } from "../logger/Logger";
import { Loading } from "../../components/src/animations/loading";

type SessionContextType = {
	userId: string;
	userEmail: any;
	isConnectUser: boolean;
};

const SessionContext = createContext<{
	session: SessionContextType | null;
	setSession: (session: any) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
}>({
	session: null,
	setSession: () => {},
	loading: true,
	setLoading: () => {},
});

export const SessionContextProvider: React.FC<{
	children: React.ReactNode;
	SignInComponent: React.ReactNode;
}> = ({ children, SignInComponent }: any) => {
	const [session, setSession] = useState<SessionContextType | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		fetchSession();
	}, []);

	const fetchSession = async () => {
		setLoading(true);

		try {
			const { data }: any = await supabase.auth.getSession();

			if (data.session) {
				Logger.log("SessionContext", "Session found", data.session);
				setSession({
					userId: data.session.user.id,
					userEmail: data.session.user.email,
					isConnectUser:
						data.session.user.user_metadata?.is_connect_user ||
						false,
				});
			} else {
				setSession(null);
				throw new Error("No session");
			}
		} catch (error) {
			Logger.error("Error retrieving session:", error);
		}

		setLoading(false);
	};

	if (loading) {
		return <Loading height="100vh" width="100vw" />;
	}
	return (
		<SessionContext.Provider
			value={{ session, setSession, loading, setLoading }}
		>
			<>{session ? children : SignInComponent}</>
		</SessionContext.Provider>
	);
};

/**
 * @example const { session: { userId, userEmail, isConnectUser } } = useSessionContext();
 */
export const useSessionContext = () => {
	const context = useContext(SessionContext);
	if (!context) {
		throw new Error(
			"useSessionContext must be used within a SessionContextProvider"
		);
	}
	return context;
};
