import { Typography } from "@mui/material";
import { CloseButtonWithAbsolutePosition } from "../../../buttons/close-button-with-absolute-position.component";

/**
 * DialogPageBase - provides a base for dialog pages with a close button on the top left and an optional title
 * @param children
 * @param title - optional, if provided, padding will be applied
 * @param onClose
 */
export const DialogPageBase: React.FC<{
	children: React.ReactNode;
	title?: string;
	onClose: () => void;
}> = ({ children, title, onClose }) => {
	const padding = "1.5rem";
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				paddingTop: "1rem",
				position: "relative",
			}}
		>
			<CloseButtonWithAbsolutePosition onClick={onClose} />
			{title && (
				<Typography
					variant="h5"
					sx={{
						paddingLeft: padding,
						paddingRight: padding,
					}}
				>
					{title}
				</Typography>
			)}
			<div
				style={{
					...(title && { paddingLeft: padding }),
				}}
			>
				{children}
			</div>
		</div>
	);
};
