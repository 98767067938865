/**
 * DocumentTemplateType - Codes for pdf template types
 */
export enum PdfTemplateType {
	KOSTENVORANSCHLAG = "kv", // quotation
	LIEFERSCHEIN = "ls", // delivery note
	RECHNUNG = "rg", // invoice
	RECHNUNGSDOPPEL = "rg_doppel", // duplicate invoice
	ZAHLUNGSERRINERUNG = "zahlungserrinerung", // reminder
	ERSTE_MAHNUNG = "mahnung_1", // first notice
	FOLGEMAHNUNG = "mahnung_2", // second notice
	// ARBEITSVORBEREITUNG = "avor", // job preparation
	// PATIENTENPASS = "pp", // patient pass
	BARZAHLUNGSQUITTUNG = "bar", // cash payment receipt
	KARTENZAHLUNGSQUITTUNG = "karte", // card payment receipt
	AKONTORECHNUNG = "akonto", // advance payment invoice
	GUTSCHRIFT = "gs", // credit note
	AUFTRAGSBLATT = "auftragsblatt", // order sheet
	// ETIKETTE = "etikette", // label
	// FAKTURAJOURNAL = "fakturajournal", // invoice journal
	SAMMELRECHNUNG = "srg", // monthly invoice
}

export enum XmlTemplateType {
	KOSTENVORANSCHLAG_XML = "kv_xml",
	LIEFERSCHEIN_XML = "ls_xml",
}
