import { useState } from "react";
import { DialogPageWithAddEntityForm } from "../../../../components/dialog-components/dialog-pages/dialog-page-with-add-entity-form/dialog-page-with-add-entity-form.component";
import { GuarantorsList } from "../../../../components/list-components/guarantors-list.component";
import { GuarantorForm } from "../../../../forms/form-components/guarantor/insert-update/guarantor.form";

export const ManageGuarantorsTab: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [guarantorId, setGuarantorId] = useState<string | undefined>(
		undefined
	);
	return (
		<DialogPageWithAddEntityForm
			FormComponent={GuarantorForm}
			onClose={onClose}
			tools={<></>}
			isFormOpen={isFormOpen}
			formAddButtonLabel="Neuer Garant"
			onIsFormOpenChange={(isFormOpen) => {
				setIsFormOpen(isFormOpen);
				setGuarantorId(undefined);
			}}
			formProps={{
				guarantorId: guarantorId,
			}}
			formAddTitle="Garant hinzufügen"
			formUpdateTitle="Garant bearbeiten"
		>
			<GuarantorsList
				onEdit={(id) => {
					setIsFormOpen(true);
					setGuarantorId(id);
				}}
			/>
		</DialogPageWithAddEntityForm>
	);
};
