import {
  DateInput,
  DateInputChangeEvent,
} from "@progress/kendo-react-dateinputs";

/**
 * CustomDateInput - works with standard Supabase date format
 * @param label
 * @param onValueChange
 * @param value
 */
export const CustomDateInput: React.FC<{
  label: string;
  onValueChange: (value: Date) => void;
  value: string;
}> = ({ label, onValueChange, value }) => {
  const changeDate = (event: DateInputChangeEvent) => {
    if (event.value) {
      onValueChange(event.value);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "-0.5rem",
        marginBottom: "0.3rem",
        width: "100%",
        flex: 1,
      }}
    >
      <DateInput
        value={value ? new Date(value) : null}
        onChange={changeDate}
        format={"dd.MM.yyyy"}
        placeholder=""
        label={label}
        fillMode="outline"
        formatPlaceholder="formatPattern"
        width={"100%"}
      />
    </div>
  );
};
