import { Checkbox } from "@mui/material";
import { SELECTED_FIELD } from "@/dentlab/src/components/custom-table/table-helpers";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const ManageTablesTab: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const { gridConfig, updateGridConfig } = useCentralStore((state) => ({
		gridConfig: state.gridConfig,
		updateGridConfig: state.updateGridConfig,
	}));

	if (!gridConfig) return null;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		const newColumns = gridConfig.columns.map((column) => {
			if (column.field === name) {
				return { ...column, show: checked };
			}
			return column;
		});
		updateGridConfig({ ...gridConfig, columns: newColumns });
	};

	return (
		<div
			style={{
				padding: "10px 20px 40px 20px",
			}}
		>
			<h3>Spalten in Positionstabelle zeigen</h3>
			<div>
				{gridConfig.columns
					.filter((column) => column.field != SELECTED_FIELD)
					.map((column, index) => (
						<div
							key={index}
							className="col-xs-12 col-md-2 example-col"
						>
							<Checkbox
								id={column.field}
								name={column.field}
								// GridColumnProps has no "show" property, but the kendo react documentation suggests to use it like this
								checked={(column as any).show}
								onChange={handleChange}
							/>
							<label>{column.title}</label>
						</div>
					))}
			</div>
		</div>
	);
};
