import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { Logger } from "../../../../../lib/logger/Logger";
import "./custom-cells.css";

export const CheckboxCustomCell = (props: GridCustomCellProps) => {
	const booleanValue = props.field ? props.dataItem[props.field] : null;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		Logger.log("CheckboxCustomCell", "handleChange", event);
		if (props.onChange) {
			props.onChange({
				dataItem: props.dataItem,
				field: props.field,
				value: event.target.checked,
				syntheticEvent: event,
				dataIndex: props.dataIndex,
			});
		}
	};

	return (
		<td className="custom-td">
			<input
				type="checkbox"
				id="scales"
				name="scales"
				checked={booleanValue}
				onChange={handleChange}
			/>
		</td>
	);
};
