import {
	Box,
	Button,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { Database } from "../../../../../../lib/supabase";
import { FormProps } from "../../../form-types";
import { JobEntityType } from "../../../../../../lib/supabase/supabaseTypes";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { JobStatusSelect } from "../../../form-input-components/selects/job-status.select";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { CustomDatePicker } from "@/dentlab/src/components/custom-date-picker/custom-date-picker.component";
import { ChangeEvent } from "react";
import { TpTier, TpValue } from "@/dentlab/src/types/enums";
import { ProdCountryChoiceSelect } from "../../../form-input-components/selects/prod-country-choice.select";
import { BankAccountSelect } from "../../bank-account/input-components/bank-account.select";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { CustomTextFieldWithPercentage } from "../../../form-input-components/custom-input-components/custom-text-field-with-percentage.component";

export const JobGeneralForm: React.FC<FormProps> = ({
	onSave = (job: JobEntityType) => {},
	submitRef = null,
}) => {
	const supabaseTable = SupabaseTableEnum.JOBS;

	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));

	const { job, changeJobStatus, fetchJob } = useJobStore((state) => ({
		job: state.job,
		changeJobStatus: state.changeJobStatus,
		fetchJob: state.fetchJob,
	}));

	const {
		handleInputChange,
		handleUpdate,
		formData,
		isPendingChanges,
		handleValueChange,
		formErrors,
	} = useForm<Database["public"]["Tables"]["jobs"]["Update"]>(
		{
			organization_id: organization?.id,
		},
		supabaseTable,
		true,
		job?.id,
		"Auftrag"
	);

	const assertSubmit = () => {
		return true;
	};

	const onSubmit = async (event: React.FormEvent) => {
		const jobId = job?.id;
		if (!jobId) {
			showNotification({
				type: "error",
				message: "Auftrag konnte nicht gefunden werden",
			});
			return;
		}
		if (assertSubmit()) {
			const { data } = await handleUpdate(event, jobId);
			if (data) {
				onSave(data[0]);
			}
		}
		if (job) await fetchJob(job.id as number);
	};

	return (
		<form onSubmit={onSubmit}>
			<Stack direction="column" spacing={2}>
				<div className="my-6 w-full flex flex-row gap-2 items-center">
					<div>Eingangsdatum</div>
					<CustomDatePicker
						value={new Date(formData.incoming_order_date ?? "")}
						onChange={(e) =>
							handleInputChange({
								target: {
									name: "incoming_order_date",
									value: e.value as any,
								},
							} as ChangeEvent<HTMLInputElement>)
						}
					/>
				</div>
				<div className="my-6 w-full flex flex-row gap-2 items-center">
					<div>Ausgangsdatum</div>
					<CustomDatePicker
						value={new Date(formData.outgoing_order_date ?? "")}
						onChange={(e) =>
							handleInputChange({
								target: {
									name: "outgoing_order_date",
									value: e.value as any,
								},
							} as ChangeEvent<HTMLInputElement>)
						}
					/>
				</div>
				<div className="my-6 w-full flex flex-row gap-2 items-center">
					<div>Status</div>
					<JobStatusSelect
						value={formData.status?.toString()}
						onChange={(value) => {
							handleValueChange("status", parseInt(value));
							if (job) changeJobStatus(job, parseInt(value));
						}}
					/>
				</div>
				<div className="border-b border-gray-300 my-6"></div>
				<div>
					<div>TP-Stufe</div>
					<ToggleButtonGroup
						color="primary"
						value={formData.tp_tier}
						exclusive
						onChange={(event, value) => {
							handleValueChange("tp_tier", value);
						}}
					>
						<ToggleButton value={TpTier.SV}>SV</ToggleButton>
						<ToggleButton value={TpTier.PP1}>PP1</ToggleButton>
						<ToggleButton value={TpTier.PP2}>PP2</ToggleButton>
						<ToggleButton value={TpTier.PP3}>PP3</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<CustomTextFieldWithPercentage
					label="TP-Variation (+/-)"
					name="tp_variation"
					value={formData.tp_variation ?? ""}
					onChange={handleInputChange}
					size="small"
					sx={{
						width: "50%",
					}}
					validationError={
						formData.tp_variation
							? formErrors.tp_variation
							: "Bitte geben Sie eine Zahl ein."
					}
				/>
				<div>
					<div>TP-Wert</div>
					<ToggleButtonGroup
						color="primary"
						value={formData.tp_value}
						exclusive
						onChange={(event, value) => {
							handleValueChange("tp_value", value);
						}}
					>
						<ToggleButton value={TpValue.OLD}>5.55</ToggleButton>
						<ToggleButton value={TpValue.NEW}>1.0</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div className="border-b border-gray-300 my-6"></div>
				<div>
					<TextField
						label="Zahnfarbe"
						name="tooth_color"
						value={formData.tooth_color || ""}
						onChange={handleInputChange}
						variant="outlined"
						size="small"
						fullWidth
					/>
					<TextField
						label="Beschreibung"
						name="description"
						value={formData.description || ""}
						onChange={handleInputChange}
						variant="outlined"
						size="small"
						fullWidth
						rows={2}
						multiline
					/>
				</div>
				<div className="border-b border-gray-300 my-6"></div>
				<div className="my-6 w-full flex flex-col gap-0">
					<ProdCountryChoiceSelect
						value={formData.prod_country_choice || ""}
						onChange={(value) =>
							handleValueChange("prod_country_choice", value)
						}
					/>
					{formData.prod_country_choice === "other" && (
						<TextField
							label="Fertigungsland (Ausland)"
							name="prod_country_other"
							value={formData.prod_country_other || ""}
							onChange={handleInputChange}
							variant="outlined"
							size="small"
						/>
					)}
					<TextField
						label="MwSt."
						name="tax_rate"
						value={formData.tax_rate || ""}
						onChange={handleInputChange}
						size="small"
					/>
					<BankAccountSelect
						onChange={(value) =>
							handleValueChange("bank_account_id", value)
						}
						value={
							formData.bank_account_id
								? formData.bank_account_id.toString()
								: ""
						}
						helperText="Wenn kein Konto gewählt ist, wird das Standardkonto des Auftragsgebers benutzt."
					/>
				</div>
				<Box>
					<Button
						type="submit"
						variant="contained"
						disabled={!isPendingChanges}
					>
						Speichern
					</Button>
				</Box>
			</Stack>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
		</form>
	);
};
