import { useEffect, useState } from "react";
import { FileCard } from "./file-card.component";
import { FileWithShareWithUrl, useStorage } from "../../hooks/useStorage";
import {
	FileEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFetch } from "../../hooks/useDatabase";
import { Loading } from "@/components/src/animations/loading";

// TODO: this is not ready to be used in messages, but something like this could be used
export const FileCardWrapper: React.FC<{
	fileId: string;
	onDelete: () => void;
}> = ({ fileId, onDelete }) => {
	const [file, setFile] = useState<FileWithShareWithUrl | null>(null);
	const { fetchFile } = useStorage();
	const { data, loading } = useDatabaseFetch(SupabaseTableEnum.FILES, {
		column: "id",
		value: fileId,
	});
	const dbFile: FileEntityType = data?.[0];

	useEffect(() => {
		fetch();
	}, [dbFile]);

	const fetch = async () => {
		const { data: file } = await fetchFile(dbFile);
		setFile(file as FileWithShareWithUrl);
	};

	if (loading) {
		return <Loading />;
	}
	if (!file) {
		return null;
	}
	return (
		<FileCard
			key={fileId}
			file={file}
			onDelete={onDelete}
			setFile={setFile}
		/>
	);
};
