import { Alert } from "@mui/material";
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";

const NetworkContext = createContext<{
  isOnline: boolean;
}>({
  isOnline: false,
});

export const NetworkContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const isNavigatorDefined = typeof navigator !== "undefined";

  const [isOnline, setIsOnline] = useState(
    isNavigatorDefined ? navigator.onLine : false,
  );

  const handleNetworkChange = useCallback(() => {
    setIsOnline(navigator.onLine);
  }, []);

  // Register and unregister the event handlers
  useEffect(() => {
    if (isNavigatorDefined) {
      window.addEventListener("online", handleNetworkChange);
      window.addEventListener("offline", handleNetworkChange);
    }

    return () => {
      if (isNavigatorDefined) {
        window.removeEventListener("online", handleNetworkChange);
        window.removeEventListener("offline", handleNetworkChange);
      }
    };
  }, [isNavigatorDefined, handleNetworkChange]);

  return (
    <NetworkContext.Provider value={{ isOnline }}>
      <div>
        {!isOnline && (
          <Alert severity="warning">
            Sie befinden sich offline. Bitte verbinden Sie sich mit dem
            Internet, um alle Funktionen zu nutzen.
          </Alert>
        )}
      </div>
      {children}
    </NetworkContext.Provider>
  );
};

/**
 * useNetwork - A custom hook that lets components subscribe to the network status
 * @returns {boolean} The current network status
 * @example const isOnline = useNetwork();
 */
export const useNetwork = () => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw new Error("useNetwork must be used within a NetworkContextProvider");
  }
  return context;
};
