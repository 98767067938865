import { IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export const EditRowButton: React.FC<{
	id: string;
	onEdit: (id: string) => void;
}> = ({ id, onEdit }) => (
	<IconButton onClick={() => onEdit(id)} data-testid="edit-row-button">
		<ModeEditIcon />
	</IconButton>
);
