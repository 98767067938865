import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link, Navigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Logger } from "../../../../lib/logger/Logger";
import { SignInLayout } from "../../../../components/src/signin/signin-components/sign-in-layout.component";
import "../../../../components/tokens.css";
import axios from "axios";
import { environment } from "../../lib/utils/environment";
import { showNotification } from "../../store/Central/selectors";

export const SignUp: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [isRedirect, setIsRedirect] = useState(false);
	const [formFields, setFormFields] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		activationCode: "",
		organizationName: "",
	});

	const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormFields({ ...formFields, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (formFields.password.length < 6) {
			showNotification({
				message: "Das Passwort muss mindestens 6 Zeichen lang sein.",
				type: "error",
			});
			return;
		}

		try {
			Logger.log("submit");
			setLoading(true);

			const res = await axios({
				method: "POST",
				url: `${environment.VITE_PDF_MICROSERVICE_URL}/register/lab-admin`,
				data: {
					first_name: formFields.firstName,
					last_name: formFields.lastName,
					organization_name: formFields.organizationName,
					activation_code: formFields.activationCode,
					email: formFields.email,
					password: formFields.password,
				},
			});

			Logger.log("res", res);

			// Only redirect on a 200 status
			if (res.status == 200) {
				setIsRedirect(true);
			} else {
				Logger.error("Error in signup", res);
				showNotification({
					message:
						"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
					type: "error",
				});
			}
		} catch (error: any) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			Logger.error("Error in signup", error);
		} finally {
			setLoading(false);
		}
	};

	// because the sign up is outside of <App />, we need to set the theme colors here
	const themeColorsTextField = {
		sx: {
			"& .MuiOutlinedInput-root": {
				"&:hover fieldset": {
					borderColor: "var(--primary-dark)",
				},
				"&.Mui-focused fieldset": {
					borderColor: "var(--primary-dark)",
				},
			},
		},
		InputLabelProps: {
			style: {
				color: "var(--primary-dark)",
			},
		},
	};

	const themeColorsButton = {
		backgroundColor: "var(--primary-main)",
		"&:hover": {
			backgroundColor: "var(--primary-dark)",
		},
	};

	return (
		<>
			{isRedirect && <Navigate to="/" replace={true} />}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
				onClick={() => {
					Logger.log("click");
				}}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<SignInLayout title="Erstellen Sie Ihren Account" subtitle="">
				<Box component="form" noValidate onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								autoComplete="given-name"
								name="firstName"
								required
								fullWidth
								id="firstName"
								label="Vorname"
								autoFocus
								value={formFields.firstName}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								fullWidth
								id="lastName"
								label="Nachname"
								name="lastName"
								autoComplete="family-name"
								value={formFields.lastName}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="email"
								label="E-Mail"
								name="email"
								autoComplete="email"
								value={formFields.email}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name="password"
								label="Passwort"
								type="password"
								id="password"
								autoComplete="new-password"
								value={formFields.password}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name="organizationName"
								label="Name der Organisation"
								id="organizationName"
								autoComplete="Neuer Organisationsname"
								value={formFields.organizationName}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name="activationCode"
								label="Aktivierungscode"
								type="text"
								id="activationCode"
								autoComplete="activationCode"
								value={formFields.activationCode}
								onChange={handleFieldChange}
								{...themeColorsTextField}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							...themeColorsButton,
						}}
					>
						Registrieren
					</Button>
				</Box>
				<Link
					style={{
						position: "absolute",
						top: "0",
						right: "0",
						margin: "20px",
						fontSize: "16px",
						color: "var(--neutral-light)",
						textDecoration: "none",
					}}
					to={"/"}
				>
					Ich habe bereits einen Account.
				</Link>
			</SignInLayout>
		</>
	);
};
