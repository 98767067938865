import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { FormGroupProps } from "../../../form-types";

export const InvoiceSettingsAGroup: React.FC<FormGroupProps> = ({
	formData,
	onCheckboxChange = () => {},
}) => {
	return (
		<FormGroup>
			<FormControlLabel
				control={
					<Checkbox
						name="is_inv_w_pat_data"
						checked={formData.is_inv_w_pat_data ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"Sammelrechnung mit Patientendaten"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_inv_w_job_title"
						checked={formData.is_inv_w_job_title ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"Sammelrechnung mit Auftragstiteln"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_incl_dr_tax"
						checked={formData.is_incl_dr_tax ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"Aerztesteuer inkl. MwSt. ausweisen"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_print_del_note_w_pat_pass"
						checked={
							formData.is_print_del_note_w_pat_pass ? true : false
						}
						onChange={onCheckboxChange}
					/>
				}
				label={"Lieferschein mit Patientenpass drucken"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_print_del_note_w_col_card"
						checked={
							formData.is_print_del_note_w_col_card ? true : false
						}
						onChange={onCheckboxChange}
					/>
				}
				label={"Lieferschein mit Farbkarte drucken"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_print_pat_code_only"
						checked={formData.is_print_pat_code_only ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"Nur Patientencode drucken"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_print_skonto"
						checked={formData.is_print_skonto ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"Skonto statt Rabatt drucken"}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="is_print_kv_w_col_card"
						checked={formData.is_print_kv_w_col_card ? true : false}
						onChange={onCheckboxChange}
					/>
				}
				label={"KV mit Farbkarte drucken"}
			/>
		</FormGroup>
	);
};
