import React from "react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FileOptions } from "./file-options.component";
import { Logger } from "../../../../lib/logger/Logger";
import { PdfPreview } from "./file-display.components";
import { FileWithShareWithUrl } from "../../hooks/useStorage";
import { useFileOverlay } from "../../hooks/useFileOverlay";

/**
 * FileCard - Component previewing a file, with options and possibility to open in dialog
 * @param fileType - type of file to display
 * @param fileName - name of file to display
 * @param children - preview of file to display
 * @returns
 */
export const FileCard: React.FC<{
	file: FileWithShareWithUrl;
	setFile: (file: FileWithShareWithUrl) => void;
	onDelete: () => void;
}> = ({ file, setFile, onDelete }) => {
	const { showFileOverlayWithUrl, fileOverlay } = useFileOverlay();
	const handleOpen = () => {
		showFileOverlayWithUrl(file);
	};
	return (
		<Box>
			{fileOverlay}
			<Card
				style={{
					height: "100%",
				}}
			>
				<CardHeader
					sx={{ pb: "0px" }}
					avatar={<InsertDriveFileIcon />}
					title={
						<Typography
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "80px",
								fontSize: "0.875rem",
							}}
						>
							{file.file_name}
						</Typography>
					}
					action={
						<FileOptions
							file={file}
							clientId={file.client_id?.toString() ?? ""} // TODO: not good
							onDeleteStart={() => {
								Logger.log("");
							}} // TODO: provide user feedback/progress indicator
							onDeleteSuccess={onDelete}
							variant="menu"
							setFile={setFile}
						/>
					}
					// onClick={() => setIsOptionsOpen(true)}
				/>
				<CardContent
					sx={{
						padding: "0px",
					}}
				>
					<Box
						onClick={handleOpen}
						sx={{ cursor: "pointer", maxHeight: "100px" }}
					>
						<PdfPreview file={file} />
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};
