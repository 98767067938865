import notFound from "./assets/404.svg";
export const NotFoundPage = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				width: "100vw",
				height: "100vh",
				flexDirection: "column",
				gap: "6rem",
				fontSize: "2rem",
			}}
		>
			<img src={notFound} alt="404" style={{ width: "30%" }} />
			<div>Seite konnte nicht gefunden werden.</div>
		</div>
	);
};
