import { Typography } from "@mui/material";
import { CustomMarginType } from "../../types/types";
import { convertCustomMarginTypeToRem } from "../../lib/utils/utils-functions";

interface FormSectionWithTitleProps {
  title: string;
  children: React.ReactNode;
  margin?: CustomMarginType;
  maxWidth?: "300px" | "500px" | "100%";
}

/**
 * FormSectionWithTitle - A section of a form with a title
 * @param title - the title of the section
 * @param children - the content of the section
 * @param styles - optional, styles to apply to the section; e.g. set maxWidth
 */

export const FormSectionWithTitle: React.FC<FormSectionWithTitleProps> = ({
  title,
  children,
  margin = "normal",
  maxWidth = "500px",
}) => {
  const customMargin = convertCustomMarginTypeToRem(margin);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: maxWidth,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginTop: customMargin,
          marginBottom: title ? "0.3rem" : "0rem",
        }}
      >
        {title}
      </Typography>
      {children}
    </div>
  );
};
