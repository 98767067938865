import { ClientInformationForm } from "../../forms/form-components/client/update/client-information.form";
import { ClientInvoiceSettingsForm } from "../../forms/form-components/client/update/client-invoice-settings.form";
import { PractitionerList } from "../../pages/client-page/client-components/practitioner-list.component";
import { LargeDialogFrameWithTabs } from "../../components/dialog-components/dialog-frames/large-dialog-with-tabs/large-dialog-frame-with-tabs.component";
import { DialogPageBase } from "../../components/dialog-components/dialog-pages/dialog-page-base/dialog-page-base.component";

const tabs = (onClose: () => void) => [
	{
		label: "Allgemeine Informationen",
		index: 0,
		component: (
			<DialogPageBase title="Allgemeine Informationen" onClose={onClose}>
				<ClientInformationForm />
			</DialogPageBase>
		),
	},
	{
		label: "Rechnungseinstellungen",
		index: 1,
		component: (
			<DialogPageBase title="Rechnungseinstellungen" onClose={onClose}>
				<ClientInvoiceSettingsForm />
			</DialogPageBase>
		),
	},
	{
		label: "Behandlerliste und Zugriff",
		index: 2,
		component: (
			<DialogPageBase
				title="Behandlerliste und Zugriff"
				onClose={onClose}
			>
				<PractitionerList />
			</DialogPageBase>
		),
	},
];

export const ClientSettingsDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	return (
		<LargeDialogFrameWithTabs
			id="client-settings"
			open={open}
			onClose={onClose}
			tabs={tabs(onClose)}
			height="80%"
			maxWidth="lg"
		/>
	);
};
