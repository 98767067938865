import {
	ClientEntityType,
	JobEntityType,
	JobItemEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateType } from "../document-template.types";
import { countryLookup } from "../../lib/constants/countries";
import { CountryEnum } from "@/lib/supabase/supabaseEnums";

export const PdfJobInformation: React.FC<{
	pdfTemplateType: PdfTemplateType;
	client: ClientEntityType;
	job: JobEntityType;
	jobItems: JobItemEntityType[];
	patient?: PatientEntityType;
	invoiceNumber?: string;
}> = ({ pdfTemplateType, client, job, jobItems, patient, invoiceNumber }) => {
	const lineHeight = "12px";

	const jobInformationRows: { label: string; value: string }[] = [];

	if (pdfTemplateType === PdfTemplateType.RECHNUNG) {
		jobInformationRows.push({
			label: "Rg.-Nummer:",
			value: invoiceNumber || "",
		});
	}

	let jobInfoLabel = "";
	let jobInfoValue = "";
	if (pdfTemplateType === PdfTemplateType.KOSTENVORANSCHLAG) {
		jobInfoLabel = "KV:";
		jobInfoValue = job.code as string;
	} else {
		jobInfoLabel = "Auftrag:";
		jobInfoValue = job.code as string;
	}
	jobInformationRows.push({ label: jobInfoLabel, value: job.code as string });

	if (job.title) {
		jobInformationRows.push({ label: "", value: job.title });
	}
	// If the job has a guarantor, we need to add the client the job belongs to
	if (job.guarantor_id) {
		jobInformationRows.push({
			label: "Auftraggeber:",
			value:
				client?.title +
				" " +
				client?.last_name +
				" " +
				client?.first_name,
		});
	}
	if (job.prod_country_choice || job.prod_country_other) {
		// If prod_country_choice is set, use it, otherwise use prod_country_other
		jobInformationRows.push({
			label: "Fertigung:",
			value: job.prod_country_choice
				? countryLookup[job.prod_country_choice as CountryEnum]
				: job.prod_country_other || "",
		});
	}
	if (job.patient_id) {
		const patientValue = client.is_print_pat_code_only
			? patient?.code ?? ""
			: (patient?.title ?? "") +
				" " +
				(patient?.last_name ?? "") +
				" " +
				(patient?.first_name ?? "");
		jobInformationRows.push({ label: "Patient:", value: patientValue });
	}
	if (job.tooth_color) {
		jobInformationRows.push({
			label: "Zahnfarbe:",
			value: job.tooth_color,
		});
	}
	if (job.description) {
		jobInformationRows.push({
			label: "Beschreibung:",
			value: job.description,
		});
	}

	const firstTpValue = jobItems[0].tp_value;
	const allItemsHaveSameTp = jobItems.every(
		(item) => item.tp_value === firstTpValue
	);
	if (allItemsHaveSameTp === true) {
		jobInformationRows.push({
			label: "Taxpt.-Wert:",
			value: firstTpValue?.toFixed(2) || "", // TODO: jobItems[0].own_tp should not be nullable
		});
	}

	return (
		<table>
			<tbody>
				{jobInformationRows.map((row, index) => (
					<tr key={index}>
						<td
							style={{
								paddingRight: "5mm",
							}}
						>
							<p
								style={{
									lineHeight,
								}}
							>
								{row.label}
							</p>
						</td>
						<td>
							<p
								style={{
									lineHeight,
								}}
							>
								{row.value}
							</p>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
