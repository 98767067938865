import { CustomSelect } from "../../../components/custom-select.component";
import { JobStatusEnum } from "@/lib/types/job";
import { SelectProps } from "../../form-types";

const options = [
	{ value: JobStatusEnum.NOT_STARTED, label: "Voranmeldung" },
	{ value: JobStatusEnum.ARCHIVED, label: "Archiviert" },
	{ value: JobStatusEnum.COMPLETED, label: "Geliefert" },
	{ value: JobStatusEnum.IN_PROGRESS, label: "In Arbeit" },
];

export const JobStatusSelect: React.FC<SelectProps> = (props) => {
	return <CustomSelect options={options} inputLabel="" {...props} />;
};
