import { create } from "zustand";
import {
	EmployeeChartSlice,
	createEmployeeChartStore,
} from "./employee-chart.store";
import {
	CentralStatisticsSlice,
	createCentralStatisticsStore,
} from "./central-statistics.store";
import {
	StatisticsOptionsSlice,
	createStatisticsOptionsForClientStore,
} from "./statistics-options.store";
import { ClientChartSlice, createClientChartStore } from "./client-chart.store";

type StatisticsSlice = EmployeeChartSlice &
	CentralStatisticsSlice &
	StatisticsOptionsSlice &
	ClientChartSlice;

export const useStatisticsStore = create<StatisticsSlice>((...a) => ({
	...createEmployeeChartStore(...a),
	...createCentralStatisticsStore(...a),
	...createStatisticsOptionsForClientStore(...a),
	...createClientChartStore(...a),
}));
