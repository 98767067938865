import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface SmallDialogProps {
  open: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  children: React.ReactNode;
  dialogActions?: ("cancel" | "save")[];
  dialogTitle: string;
  dialogText: string;
  dialogActionsText?: { cancel: string; save: string };
}

export const SmallDialog: React.FC<SmallDialogProps> = ({
  open,
  onCancel,
  onSave,
  children,
  dialogActions = ["cancel", "save"],
  dialogTitle,
  dialogText,
  dialogActionsText = { cancel: "Cancel", save: "Save" },
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {dialogActions.includes("cancel") && (
          <Button onClick={onCancel}>{dialogActionsText.cancel}</Button>
        )}
        {dialogActions.includes("save") && (
          <Button onClick={onSave}>{dialogActionsText.save}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
