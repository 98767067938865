import { createContext, useContext } from "react";
import {
	ArticleEntityType,
	ArticleEntityTypeInsert,
	SupabaseTableEnum,
	TariffEntityType,
	TariffEntityTypeInsert,
} from "../../../lib/supabase/supabaseTypes";
import {
	useDatabaseFetch,
	useDatabaseFunctionsWithPromise,
} from "../hooks/useDatabase";
import { useCentralStore } from "../store/Central";
import {
	ExtendedTariffEntityType,
	ExtendedArticleEntityType,
} from "../store/Jobs/types";
import { Logger } from "@/lib/logger/Logger";

const JobItemsContext = createContext<{
	handleAddTariff: (tariff: TariffEntityTypeInsert) => void;
	handleUpdateTariff: (tariff: ExtendedTariffEntityType) => void;
	handleAddArticle: (article: ArticleEntityTypeInsert) => void;
	handleUpdateArticle: (article: ExtendedArticleEntityType) => void;
	recognizeNewTariff: (tariff: ExtendedTariffEntityType) => void;
	recognizeNewArticle: (article: ExtendedArticleEntityType) => void;
}>({
	handleAddTariff: () => {},
	handleUpdateTariff: () => {},
	handleAddArticle: () => {},
	handleUpdateArticle: () => {},
	recognizeNewTariff: () => {},
	recognizeNewArticle: () => {},
});

export const JobItemsContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { organizationId, getArticles, getTariffs } = useCentralStore(
		(state) => ({
			organizationId: state.organization?.id,
			getArticles: state.getArticles,
			getTariffs: state.getTariffs,
		})
	);
	const { insertDataWithPromise, updateDataWithPromise } =
		useDatabaseFunctionsWithPromise();

	const { data: tariffsUser, setData: setTariffsUser } = useDatabaseFetch(
		SupabaseTableEnum.TARIFFS,
		{
			column: "organization_id",
			value: organizationId,
		}
	);
	const { data: articlesUser, setData: setArticlesUser } = useDatabaseFetch(
		SupabaseTableEnum.ARTICLES,
		{
			column: "organization_id",
			value: organizationId,
		}
	);

	const handleAddTariff = async (tariff: TariffEntityTypeInsert) => {
		const { success, data } = await insertDataWithPromise(
			SupabaseTableEnum.TARIFFS,
			[tariff]
		);
		if (success && data) {
			recognizeNewTariff(data[0]);
		}
	};

	const handleUpdateTariff = async (tariff: ExtendedTariffEntityType) => {
		// remove tariff.custom as it is not a column in the db
		const { custom, is_new, ...filteredTariff } = tariff;
		const newTariff: TariffEntityType = {
			...filteredTariff,
			organization_id: organizationId as string,
		};

		// if custom is false, we need to create a new user tariff (without id)
		if (custom === false) {
			const newTariffWithoutId: TariffEntityTypeInsert = {
				...newTariff,
				id: undefined,
			};
			handleAddTariff(newTariffWithoutId);
		} else {
			const { success, data, error } = await updateDataWithPromise(
				SupabaseTableEnum.TARIFFS,
				[newTariff]
			);

			if (success && data) {
				// set tariffsUser to new array with updated tariff, this will trigger useEffect to rerun the merge to tariffs
				setTariffsUser(
					tariffsUser?.map((t) =>
						t.id === tariff.id ? data[0] : t
					) ?? []
				);
				getTariffs();
			}
		}
	};

	const handleAddArticle = async (article: ArticleEntityTypeInsert) => {
		const { success, data, error } = await insertDataWithPromise(
			SupabaseTableEnum.ARTICLES,
			[article]
		);
		if (success && data) {
			recognizeNewArticle(data[0]);
		}
	};

	const handleUpdateArticle = async (article: ExtendedArticleEntityType) => {
		// remove article.custom as it is not a column in the db
		const { custom, is_new, ...filteredArticle } = article;
		const newArticle: ArticleEntityType = {
			...filteredArticle,
			organization_id: organizationId as string,
		};

		// if custom is false, we need to create a new user article (without id)
		if (custom === false) {
			const newArticleWithoutId: ArticleEntityTypeInsert = {
				...newArticle,
				id: undefined,
			};
			handleAddArticle(newArticleWithoutId);
		} else {
			// otherwise, we update the existing article (with id)
			const { success, data, error } = await updateDataWithPromise(
				SupabaseTableEnum.ARTICLES,
				[newArticle]
			);

			if (error) {
				Logger.error(error);
				return;
			}

			if (success && data) {
				// set articlesUser to new array with updated article, this will trigger useEffect to rerun the merge to articles
				setArticlesUser(
					articlesUser?.map((a) =>
						a.id === article.id ? data[0] : a
					) ?? []
				);
				getArticles();
			}
		}
	};

	const recognizeNewTariff = (tariff: ExtendedTariffEntityType) => {
		setTariffsUser([...(tariffsUser ?? []), tariff]);
		getTariffs();
	};

	const recognizeNewArticle = (article: ExtendedArticleEntityType) => {
		setArticlesUser([...(articlesUser ?? []), article]);
		getArticles();
	};

	return (
		<JobItemsContext.Provider
			value={{
				handleAddTariff,
				handleUpdateTariff,
				handleAddArticle,
				handleUpdateArticle,
				recognizeNewTariff,
				recognizeNewArticle,
			}}
		>
			{children}
		</JobItemsContext.Provider>
	);
};

/**
 * - Provides all items that can be added to a job document, i.e. tariffs and articles.
 * - Handles merging the default and custom tables from supabase.
 * @example const { tariffs, articles } = useJobItemsContext();
 */
export const useJobItemsContext = () => {
	const context = useContext(JobItemsContext);
	if (!context) {
		throw new Error(
			"useJobItemsContext must be used within a JobItemsContextProvider"
		);
	}

	return context;
};
