import { useToast } from "../context/ToastContext";
import {
	ReplyEntityType,
	SupabaseTableEnum,
} from "../../../lib/supabase/supabaseTypes";
import { useSessionContext } from "../../../lib/contexts/SessionContext";
import { supabase } from "@/lib/supabase";

/**
 *
 * @returns
 * @example const { sendReply } = useSendReply();
 */
export function useSendReply() {
	const { showToast } = useToast();
	const {
		session: { userId },
	}: any = useSessionContext();

	// TODO: email template for being notified about new replies

	const sendReply = async (activityId: number, text: string) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.REPLIES)
			.insert({
				shared_id: activityId,
				created_by: userId,
				text: text,
			} as ReplyEntityType)
			.select()
			.single();

		if (error) {
			showToast("Fehler beim Senden der Antwort", "error");
			return {
				success: false,
				response: null,
				error: error,
			};
		} else {
			return {
				success: true,
				response: data,
				error: null,
			};
		}
	};

	return { sendReply };
}
