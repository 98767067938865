import { Typography } from "@/components/src/typography/typography.component";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { LazyPatient } from "@/dentlab/src/store/Patient/patients.store";
import { PatientWithShare } from "@/lib/supabase/supabaseTypes";
import {
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandDialog,
} from "@lab/src/components/ui/command";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SetStateAction } from "react";

export const PatientDialog: React.FC<{
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	openNewPatientDialog: () => void;
	onPatientSelect: (patientId: string) => void;
}> = ({ open, setOpen, openNewPatientDialog, onPatientSelect }) => {
	const { patients, patientName } = usePatientStore((state) => ({
		patients: Object.values(state.patientsLookup),
		patientName: state.patientName,
	}));

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput placeholder="Patient suchen…" />{" "}
			<CommandList className="h-96 max-h-96">
				<CommandGroup heading="Aktionen">
					<CommandItem
						onSelect={openNewPatientDialog}
						className="flex h-12 font-medium pl-5 gap-6 w-full items-center justify-start"
					>
						<AddCircleIcon />
						<Typography variant="title-sm">
							Neuer Patient
						</Typography>
					</CommandItem>
				</CommandGroup>{" "}
				<CommandEmpty>Keine Patienten</CommandEmpty>{" "}
				<CommandGroup heading="Patient öffnen" className="w-[500px]">
					{patients.map((patient) => {
						const patientId =
							(patient as PatientWithShare)?.id ??
							(patient as LazyPatient).patient_id;
						const name = patientName(patient);
						return (
							<CommandItem
								key={patientId}
								className="flex cursor-pointer w-full h-full flex-row gap-2 items-center"
								onSelect={() => {
									onPatientSelect(patientId);
								}}
							>
								<div className="text-lg">{name}</div>
								<div className="text-gray-500">
									{patient.code}
								</div>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};
