import { JobStatusEnum } from "@/lib/types/job";

export interface PermissionStatus {
	isAllowed: boolean;
	needsConfirmation?: boolean;
	explanation: string;
}

export enum KvStatusEnum {
	IN_PROGRESS = JobStatusEnum.IN_PROGRESS,
	COMPLETED = JobStatusEnum.COMPLETED,
	ARCHIVED = JobStatusEnum.ARCHIVED,
}

export enum LsStatusEnum {
	IN_PROGRESS = JobStatusEnum.IN_PROGRESS,
	COMPLETED = JobStatusEnum.COMPLETED,
	ARCHIVED = JobStatusEnum.ARCHIVED,
	BOOKED_SINGLE = JobStatusEnum.BOOKED_SINGLE,
	BOOKED_MONTHLY = JobStatusEnum.BOOKED_MONTHLY,
}

export enum GsStatusEnum {
	IN_PROGRESS = JobStatusEnum.IN_PROGRESS,
	COMPLETED = JobStatusEnum.COMPLETED,
	ARCHIVED = JobStatusEnum.ARCHIVED,
	BOOKED_SINGLE = JobStatusEnum.BOOKED_SINGLE,
	BOOKED_MONTHLY = JobStatusEnum.BOOKED_MONTHLY,
}
