// https://www.six-group.com/dam/download/banking-services/standardization/qr-bill/ig-qr-bill-v2.2-de.pdf
// https://dentdesk.atlassian.net/browse/DW-219?focusedCommentId=10248&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-10248

const PRUEFZIFFER = [0, 9, 8, 7, 6, 5, 4, 3, 2, 1];

/**
 * generateCheckSum - Checksum algorith according to Schweizer Implementation Guidelines QR-Rechnung, p. 53
 * Example:
 * digit:   3   5   9    7   6
 * sum:     3  11  18   10   6
 * mod:     3   1   8    0   6
 * weight:  6   9   3    0  (7) -> 7 is the checksum
 * @param input - string of digits
 * @returns checksum digit
 */
export const generateCheckSum = (input: string) => {
	const weights = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];

	let weight = 0;
	let sum = 0;
	let sumMod = 0;
	let digit = input[0];

	for (let i = 0; i < input.length; i++) {
		digit = input[i];
		sum = weight + parseInt(digit);
		sumMod = sum % 10;
		weight = weights[sumMod];
	}
	return weight;
};

export const generatePruefziffer = (input: string) => {
	return PRUEFZIFFER[generateCheckSum(input)];
};

function formatReferenceNumber(referenceNumber: string) {
	return referenceNumber
		.split("-")
		.map((s) => s.trim())
		.join("")
		.padEnd(13, "0");
}

/**
 * QR Reference Generator
 * - Muss zusammen mit einer QR-IBAN verwendet werden
 * - Immer 27 Zeichen
 * - Numerisch
 * - Prüfzifferberechnung nach Modulo 10 rekursiv (27. Stelle der Referenz)
 * @param bankAccountReference - reference number taken from bank_account entity
 * @param invoiceNumber - number of the invoice, e.g. 000000 (always 6 digits)
 * labId - id of the lab, e.g. 00 (always 2 digits) - this was used in Dental lab XP if multiple labs were registered, as this is not the case anymore, this is always 00
 * @returns QR reference number, 27 digits in 2-5-5-5-5 format
 */
export const generateQrReferenceNumber = (
	bankAccountReference: string,
	invoiceNumber: string
) => {
	const LAB_ID = "00";
	const SIGNATURE = "17171";

	const formattedBankAccountReference =
		formatReferenceNumber(bankAccountReference);

	const referenceNumberNoSpaces =
		formattedBankAccountReference + // 13 digits
		SIGNATURE + // 5 digits
		LAB_ID + // 2  digits
		invoiceNumber + // 6 digits
		generatePruefziffer(
			formattedBankAccountReference + SIGNATURE + LAB_ID + invoiceNumber
		); // 1 digit
	let referenceNumber = referenceNumberNoSpaces.slice(0, 2);

	// format as 2-5-5-5-5
	for (let i = 2; i < referenceNumberNoSpaces.length; i += 5) {
		referenceNumber += " " + referenceNumberNoSpaces.slice(i, i + 5);
	}

	return referenceNumber;
};
