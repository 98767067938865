// TODO: Remove this logic from react context
import { showNotification } from "../store/Central/selectors";

type Severity = "success" | "error" | "warning" | "info";
type ToastAction = {
	actionLabel: string;
	actionHandler: () => void;
};

export const useToast = () => {
	// TODO Any part of the code that uses this must be refactored to use the new toast component
	const showToast = (
		message: string,
		severity: Severity,
		// This is so that it could be backwards compatible with the previous function
		autoHide: number = 6000,
		action?: ToastAction
	) => {
		showNotification({
			message,
			type: severity,
			duration: autoHide,
			...(action && {
				action: {
					label: action.actionLabel,
					onClick: action.actionHandler,
				},
			}),
		});
	};

	return { showToast };
};
