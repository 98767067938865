import { CloseButtonWithAbsolutePosition } from "../../../buttons/close-button-with-absolute-position.component";
import { useCustomDialogContext } from "../../custom-dialog/custom-dialog.component";

/**
 * DialogPageWithToolbar
 * @param closeButtonVariant "inside" | "none" -- defaults to "inside"
 * @returns
 */
export const DialogPageWithToolbar: React.FC<{
	toolbar: React.ReactNode;
	children: React.ReactNode;
	marginTop?: string;
	onClose: () => void;
	closeButtonVariant?: "inside" | "none";
}> = ({
	toolbar,
	children,
	marginTop = "1rem",
	onClose,
	closeButtonVariant = "inside",
}) => {
	const { isPendingChanges, setIsCloseAttempt } = useCustomDialogContext();

	const handleClose = () => {
		if (isPendingChanges) {
			setIsCloseAttempt(true);
		} else {
			onClose();
		}
	};

	return (
		// This will allow the children of this div tag to own the entire height
		<div className="h-full">
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<div
					style={{
						padding: "0.25rem",
						minHeight: "2.8rem",
						position: "sticky",
						top: 0,
						borderBottom: "1px solid lightgray",
						backgroundColor: "white",
						zIndex: 100,
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					{toolbar}
					{closeButtonVariant === "inside" && (
						<CloseButtonWithAbsolutePosition
							onClick={handleClose}
						/>
					)}
				</div>
				<div
					style={{
						// This is necessary to give the toolbar enough space
						height: "90%",
						marginTop: marginTop,
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};
