import {
	Checkbox,
	FormControlLabel,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { useForm } from "../../hooks/useForm/useForm";
import { Database } from "../../../../lib/supabase";
import { FormProps } from "../form-types";
import { FormSectionWithSaveButton } from "../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../lib/logger/Logger";
import { useCustomDialogContext } from "../../components/dialog-components/custom-dialog/custom-dialog.component";
import { CustomTextFieldWithPercentage } from "../form-input-components/custom-input-components/custom-text-field-with-percentage.component";
import { useCentralStore } from "../../store/Central";
import MwstInput from "./bank-account/input-components/mwst-input.component";
import { useState } from "react";
import { showNotification } from "../../store/Central/selectors";
import { TpTier, TpValue } from "../../types/enums";
import { Typography } from "@/components/src/typography/typography.component";
import { BankAccountSelect } from "./bank-account/input-components/bank-account.select";

export const CompanyAccountingInformation: React.FC<FormProps> = ({
	onSave = (org: any) => {},
	submitRef = null,
}) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { setIsPendingChanges } = useCustomDialogContext();
	const [validMwst, setValidMwst] = useState<boolean>(true);

	const {
		handleInputChange,
		handleUpdate,
		formData,
		formErrors,
		resetFormData,
		handleCheckboxChange,
		handleValueChange,
	} = useForm<
		Database["public"]["Tables"][SupabaseTableEnum.ORGANIZATIONS]["Update"]
	>(
		{},
		SupabaseTableEnum.ORGANIZATIONS,
		true,
		organizationId,
		"Rechnungswesen",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!organizationId) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			Logger.error(
				"No organizationId found in CompanyAccountingInformation"
			);
			return;
		}
		const { success, error, data } = await handleUpdate(
			event,
			organizationId
		);
		if (data) {
			const org = data[0];
			onSave(org);
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={!validMwst}
			margin="none"
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "500px",
					paddingTop: "1rem",
				}}
			>
				<MwstInput
					value={formData.mwst_number || ""}
					onChange={handleInputChange}
					validationError={formErrors.mwst_number}
					validMwst={validMwst}
					setValidMwst={setValidMwst}
				/>
				<FormControlLabel
					control={
						<Checkbox
							name="is_mwst_eust"
							checked={formData.is_mwst_eust ? true : false}
							onChange={handleCheckboxChange}
						/>
					}
					label="Wir nutzen EUSt. statt MwSt."
				/>
				<CustomTextFieldWithPercentage
					label={formData.is_mwst_eust ? "EUSt.-Satz" : "MwSt.-Satz"}
					name="mwst_percentage"
					value={formData.mwst_percentage || ""}
					onChange={handleInputChange}
					validationError={formErrors.mwst_percentage}
				/>
				<FormControlLabel
					control={
						<Checkbox
							name="is_mwst_included"
							checked={formData.is_mwst_included ? true : false}
							onChange={handleCheckboxChange}
						/>
					}
					label="Sind Artikel und Leistungen bereits inkl. Steuer?"
				/>
				<div className="py-2 gap-1 flex flex-col">
					<Typography variant="subtitle-md">
						Standard Tarifstufe
					</Typography>
					<ToggleButtonGroup
						color="primary"
						value={formData.default_tp_tier}
						exclusive
						onChange={(event, value) => {
							handleValueChange("default_tp_tier", value);
						}}
					>
						<ToggleButton value={TpTier.SV}>SV</ToggleButton>
						<ToggleButton value={TpTier.PP1}>
							Min (PP1)
						</ToggleButton>
						<ToggleButton value={TpTier.PP2}>
							Privat (PP2)
						</ToggleButton>
						<ToggleButton value={TpTier.PP3}>
							Max (PP3)
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div className="py-2 gap-1 flex flex-col">
					<Typography variant="subtitle-md">
						Standard Tarifwert
					</Typography>
					<ToggleButtonGroup
						color="primary"
						value={formData.default_tp_value}
						exclusive
						onChange={(event, value) => {
							handleValueChange("default_tp_value", value);
						}}
					>
						<ToggleButton value={TpValue.OLD}>5.55</ToggleButton>
						<ToggleButton value={TpValue.NEW}>1.0</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<BankAccountSelect
					label="Standard Bankkonto"
					value={formData.default_bank_account_id?.toString() || ""}
					onChange={(value) => {
						handleValueChange("default_bank_account_id", value);
					}}
					helperText="Das Standard Bankkonto wird verwendet, wenn kein anderes Bankkonto für eine Rechnung angegeben wird."
				/>
			</div>
		</FormSectionWithSaveButton>
	);
};
