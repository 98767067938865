import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "../../hooks/useForm/useForm";
import { FormProps } from "../form-types";
import { Database } from "../../../../lib/supabase";
import { OwnTpGroup } from "../form-input-components/groups/own-tp.group";
import { FormSectionWithSaveButton } from "../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { FormSectionWithTitle } from "../../components/form-section-with-title/form-section-with-title.component";
import { TariffValuesGroup } from "../form-input-components/groups/tariff-values.group";
import {
	SupabaseTableEnum,
	TariffEntityType,
} from "../../../../lib/supabase/supabaseTypes";
import { useCustomDialogContext } from "../../components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "../../store/Central";

export const TariffForm: React.FC<FormProps> = ({
	onSave = (tariff: TariffEntityType) => {},
	submitRef = null,
}) => {
	const { organization, getTariffs } = useCentralStore((state) => ({
		organization: state.organization,
		getTariffs: state.getTariffs,
	}));
	const { setIsPendingChanges } = useCustomDialogContext();

	const {
		handleInputChange,
		handleCheckboxChange,
		handleValueChange,
		handleCreate,
		formData,
		formErrors,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["tariffs"]["Insert"]>(
		{
			organization_id: organization?.id,
		},
		SupabaseTableEnum.TARIFFS,
		undefined,
		undefined,
		"Tarif",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const { success, data, error } = await handleCreate(event);
		if (data) {
			const tariff = data[0];
			onSave(tariff);
			await getTariffs();
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<FormSectionWithTitle title="Tarifdaten">
				<TextField
					// Tarif-Position (code) is what is printed on the invoice
					// Tarif-Position (code) doesn't have to be unique
					label="Tarif-Position (Anzeige)"
					value={formData.code || ""}
					name="code"
					onChange={handleInputChange}
					required
					helperText="Dieser Code wird auf Dokumenten und Rechnungen angezeigt."
				/>
				<TextField
					// Tarif-Code (code_e) is what is used for adding a job item
					// Tarif-Code (code_e) must be unique
					label="Tarif-Code (Eingabe)"
					value={formData.code_e || ""}
					name="code_e"
					onChange={handleInputChange}
					required
					helperText="Dieser Code wird zur Eingabe von Positionen genutzt. Er muss eindeutig sein. Falls Sie nicht mehrere Varianten des Tarifs haben, sollte er identisch mit dem Tarif-Code sein."
				/>
				<TextField
					label="Bezeichnung (DE)"
					value={formData.description_de || ""}
					name="description_de"
					onChange={handleInputChange}
				/>
				<TextField
					label="Bezeichnung (FR)"
					value={formData.description_fr || ""}
					name="description_fr"
					onChange={handleInputChange}
				/>
				<TextField
					label="Bezeichnung (IT)"
					value={formData.description_it || ""}
					name="description_it"
					onChange={handleInputChange}
				/>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Taxpunkte">
				<TariffValuesGroup
					formData={formData}
					formErrors={formErrors}
					onValueChange={(args: any) =>
						handleValueChange(args.name, args.value)
					}
				/>
				<OwnTpGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onCheckboxChange={handleCheckboxChange}
				/>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Weiteres">
				<TextField
					label="Vorgabemenge"
					value={formData.standard_quantity || ""}
					name="standard_quantity"
					onChange={handleInputChange}
				/>
				<TextField
					label="Zusatztext"
					value={formData.additional_text || ""}
					name="additional_text"
					onChange={handleInputChange}
					multiline
					rows={4}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.is_req_dr_tax ? true : false}
							onChange={handleCheckboxChange}
							name="is_req_dr_tax"
						/>
					}
					label="Ärztesteuerpflichtig"
				/>
				<TextField
					label="Zusammensetzung"
					value={formData.composition || ""}
					name="composition"
					onChange={handleInputChange}
					multiline
					rows={4}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
