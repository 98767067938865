import { EditableTitle } from "@/components/src/editable-title/editable-title.component";
import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
} from "@mui/material";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { Logger } from "@/lib/logger/Logger";

const DesktopSystemSettingsSchema = z.object({
	name: z.string(),
	config: z
		.object({
			paperSourceKind: z.number().optional(),
		})
		.optional(),
});

type DesktopSystemSettingsType = z.infer<typeof DesktopSystemSettingsSchema>;

export const DesktopSystemSettings: React.FC = () => {
	const { updateDesktopSystem, selectedDesktopSystem } = useDesktopsStore();
	const { showToast } = useToast();

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm<DesktopSystemSettingsType>({
		resolver: zodResolver(DesktopSystemSettingsSchema),
	});

	useEffect(() => {
		if (selectedDesktopSystem)
			reset({
				name: selectedDesktopSystem.name,
				config: JSON.parse(
					JSON.stringify(selectedDesktopSystem.config)
				),
			});
	}, [reset, selectedDesktopSystem]);

	if (Object.keys(errors).length > 0) {
		Logger.error(errors);
		if (Object.values(errors)[0].message)
			showToast(Object.values(errors)[0].message as string, "error");
	}

	if (!selectedDesktopSystem) {
		return <LoadingDesktopSettings />;
	}

	return (
		<div
			style={{
				border: "2px solid #e0e0e0",
				borderRadius: "8px",
				padding: "16px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "start",
				gap: "1rem",
			}}
		>
			<EditableTitle
				{...register("name")}
				name={selectedDesktopSystem.name}
				value={watch("name")}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					setValue("name", e.target.value);
				}}
				onSubmit={handleSubmit((data) => {
					updateDesktopSystem(selectedDesktopSystem.id, {
						name: data.name ?? "",
					});
				})}
				onReset={() => {
					setValue("name", selectedDesktopSystem.name);
				}}
			/>
			<div
				style={{
					height: "200px",
					width: "100%",
				}}
			>
				{selectedDesktopSystem.type === 1 && ( // TODO: Add correct type value via enum
					<>
						<FormControl size="medium">
							<InputLabel>PaperSourceKind</InputLabel>
							<Select
								value={
									watch("config.paperSourceKind") ??
									(
										selectedDesktopSystem.config as {
											paperSourceKind: string;
										}
									)?.paperSourceKind
								}
								label="PaperSourceKind"
								sx={{ width: "200px" }}
								onChange={(e) => {
									setValue(
										"config.paperSourceKind",
										Number(e.target.value)
									);
									handleSubmit((data) => {
										updateDesktopSystem(
											selectedDesktopSystem.id,
											{
												config: {
													paperSourceKind: Number(
														data.config
															?.paperSourceKind
													),
												},
											}
										);
									})();
								}}
							>
								<MenuItem value="1">1</MenuItem>
								<MenuItem value="2">2</MenuItem>
								<MenuItem value="3">3</MenuItem>
							</Select>
						</FormControl>
					</>
				)}
			</div>
		</div>
	);
};

const LoadingDesktopSettings: React.FC = () => {
	return (
		<div
			style={{
				border: "2px solid #e0e0e0",
				borderRadius: "8px",
				padding: "16px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "start",
				gap: "1rem",
			}}
		>
			<Skeleton height={40} width={80} />
			<div
				style={{
					height: "200px",
				}}
			>
				<Skeleton height={80} width={200} />
			</div>
		</div>
	);
};
