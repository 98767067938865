import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { AccountingStatus } from "../types";

export const AccountingStatusSelector: React.FC<{
	onSelect: React.Dispatch<React.SetStateAction<AccountingStatus[]>>;
	selectedStatuses: AccountingStatus[];
}> = ({ onSelect, selectedStatuses }) => {
	const handleSelect = (e: SelectChangeEvent<typeof selectedStatuses>) => {
		const value = e.target.value as unknown as AccountingStatus[];

		// @ts-expect-error this is how it's done in MUI
		onSelect(typeof value === "string" ? value.split(",") : value);
	};

	return (
		<div className="h-full pt-1 flex items-end">
			<FormControl variant="filled" size="small">
				<InputLabel>
					{selectedStatuses.length > 0 ? "Status" : "Alle Status"}
				</InputLabel>
				<Select
					multiple
					sx={{
						width: "250px",
					}}
					value={selectedStatuses}
					onChange={handleSelect}
					label="Status"
				>
					{Object.entries(AccountingStatus).map(([key, value]) => {
						return (
							<MenuItem
								key={value}
								sx={{
									textTransform: "capitalize",
								}}
								value={value}
							>
								{value}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};
