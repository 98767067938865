import { Layout } from "./layout/layout";
import { Outlet } from "react-router-dom";
import { NetworkContextProvider } from "./context/NetworkContext";
import "@progress/kendo-theme-material/dist/all.css";
import { ClipboardProvider } from "./context/ClipboardContext";
import { SessionContextProvider } from "../../lib/contexts/SessionContext";
import { ProfileContextProvider } from "../../lib/contexts/ProfileContext";
import { TeamProfilesContextProvider } from "./context/single-entity-contexts/TeamProfilesContext";
import { ThemeProvider } from "@mui/material/styles";
import { PractitionersContextProvider } from "./context/single-entity-contexts/PractitionersContext";
import * as Sentry from "@sentry/react";
import { ErrorFallbackPage } from "./pages/error-fallback/error-fallback.page";
import { SignIn } from "../../components/src/signin/signin.page";
import "../../components/tokens.css";
import { theme } from "../../components/theme";
import { JobItemsContextProvider } from "./context/JobItemsContext";
import { PdfTemplateConfigurationContextProvider } from "./context/PdfTemplateConfigurationContext";
import { DialogContextProvider } from "./context/DialogContext";
import WarningOnUnload from "./components/job/job-warning.component";
import "./globals.css";

const App: React.FC = () => {
	return (
		<Sentry.ErrorBoundary
			fallback={({ error, componentStack, resetError }) => (
				<ErrorFallbackPage
					error={error}
					componentStack={componentStack}
					resetError={resetError}
				/>
			)}
			showDialog
		>
			<ThemeProvider theme={theme}>
				<NetworkContextProvider>
					<DialogContextProvider>
						<SessionContextProvider
							SignInComponent={<SignIn isConnectLogin={false} />}
						>
							<ProfileContextProvider>
								<JobItemsContextProvider>
									<PdfTemplateConfigurationContextProvider>
										<Layout>
											<TeamProfilesContextProvider>
												<PractitionersContextProvider>
													<ClipboardProvider>
														<WarningOnUnload />
														<Outlet />
													</ClipboardProvider>
												</PractitionersContextProvider>
											</TeamProfilesContextProvider>
										</Layout>
									</PdfTemplateConfigurationContextProvider>
								</JobItemsContextProvider>
							</ProfileContextProvider>
						</SessionContextProvider>
					</DialogContextProvider>
				</NetworkContextProvider>
			</ThemeProvider>
		</Sentry.ErrorBoundary>
	);
};

export default App;
