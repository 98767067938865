import { State, getter } from "@progress/kendo-data-query";
import { setExpandedState } from "@progress/kendo-react-data-tools";
import { TableConfig } from "../../types/types";
import { GridColumnProps } from "@progress/kendo-react-grid";

export const DATA_ITEM_KEY: string = "id";

/** field name of the column for selecting rows  */
export const SELECTED_FIELD: string = "selected";

export const idGetter = getter(DATA_ITEM_KEY);

const mapSelected = (data: any[], selectedState: any) => {
	return data.map((item: any) => {
		return { ...item, SELECTED_FIELD: selectedState[idGetter(item)] };
	});
};

export const processWithSelected = (
	data: any[],
	selectedState: {
		[id: string]: boolean | number[];
	},
	isGrouped: boolean
) => {
	if (isGrouped) {
		return data.map((group) => {
			group.items =
				group.items && group.items.length > 0
					? mapSelected(group.items, selectedState)
					: [];
			return group;
		});
	} else {
		return mapSelected(data, selectedState);
	}
};

const mapEdit = (
	data: any[],
	editedItemID: number,
	editedField: string,
	editedValue: any
) => {
	return data.map((item: any) =>
		item[DATA_ITEM_KEY] === editedItemID
			? { ...item, [editedField]: editedValue }
			: item
	);
};

export const processEdit = (
	data: any[],
	editedItemID: number,
	editedField: string,
	editedValue: any,
	isGrouped: boolean
) => {
	if (isGrouped) {
		return data.map((group) => ({
			...group,
			items:
				group.items && group.items.length > 0
					? mapEdit(
							group.items,
							editedItemID,
							editedField,
							editedValue
						)
					: [],
		}));
	} else {
		return mapEdit(data, editedItemID, editedField, editedValue);
	}
};

const mapRestore = (data: any[], itemCache: any) => {
	return data.map((item: any) =>
		item[DATA_ITEM_KEY] === itemCache[DATA_ITEM_KEY]
			? { ...itemCache }
			: item
	);
};

export const processRestore = (
	data: any[],
	itemCache: any,
	isGrouped: boolean
) => {
	if (isGrouped) {
		return data.map((group) => ({
			...group,
			items:
				group.items && group.items.length > 0
					? mapRestore(group.items, itemCache)
					: [],
		}));
	} else {
		return mapRestore(data, itemCache);
	}
};

export const processWithCollapsed = (data: any[], collapsedState: any[]) => {
	return setExpandedState({
		data: data,
		collapsedIds: collapsedState,
	});
};

export const getIsGrouped = (dataState: State) => {
	return dataState.group && dataState.group.length > 0 ? true : false;
};

export const getSelectedRows = (rows: any[], selectedState: any) => {
	return rows.filter((row: any) => selectedState[row.id]);
};

export const getClipboardData = (rows: any[], dataCompatibility: any) => {
	return { data: rows, type: dataCompatibility };
};

// TODO: beware any performance issues and possibly add more attributes
export const setTableConfig = (
	defaultConfig: TableConfig,
	userConfig: TableConfig | undefined | null
) => {
	if (!userConfig || !userConfig.columns || userConfig.columns.length === 0) {
		return defaultConfig;
	}
	const newColumns = defaultConfig.columns.map((column) => {
		const gridConfigColumn = userConfig.columns.find(
			(gridConfigColumn: GridColumnProps) =>
				gridConfigColumn.field === column.field
		);
		if (gridConfigColumn) {
			return {
				...column,
				width: gridConfigColumn.width,
				orderIndex: gridConfigColumn.orderIndex,
				// GridColumnProps has no "show" property, but the kendo react documentation suggests to use it like this
				show: (gridConfigColumn as any).show,
			};
		} else {
			return {
				...column,
				show: false,
			};
		}
	});
	return {
		...userConfig,
		columns: newColumns,
	};
};
