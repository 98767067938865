import { PatientEntityType } from "@/lib/supabase/supabaseTypes";

export const patientExample: PatientEntityType = {
	id: "123e4567-e89b-12d3-a456-426614174000",
	created_at: "2021-06-29T15:00:00Z",
	modified_at: "2021-06-29T15:00:00Z",
	created_by: "123e4567-e89b-12d3-a456-426614174000",
	modified_by: "123e4567-e89b-12d3-a456-426614174000",
	title: "Herr",
	first_name: "Max",
	last_name: "Mustermann",
	gender: "m",
	birth_date: "1990-01-01",
	insurance: "Helsana",
	ahv_number: "123.4567.8901.23",
	organization_id: "123e4567-e89b-12d3-a456-426614174000",
	code: "123456",
	inv_covered_by: "Helsana",
};
