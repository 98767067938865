import { JobEntityType } from "../../../lib/supabase/supabaseTypes";

export const jobExample: JobEntityType = {
	id: 1001,
	created_at: "2024-01-07T10:00:00Z",
	modified_at: "2024-01-07T12:00:00Z",
	code: "2023-02-0001",
	outgoing_order_date: "2024-01-06",
	incoming_order_date: "2024-01-05",
	title: "Kronenrestauration",
	tooth_color: "A2",
	description: "Vollkeramikkrone für den oberen linken ersten Molar",
	additional_information: "Patient bevorzugt eine natürliche Optik",
	currency: "EUR",
	exchange_ratio: 1.1,
	tax_rate: 19.0,
	prod_country_choice: "Deutschland",
	prod_country_other: "N/A",
	max_inv_value: 500.0,
	status: 2,
	tp_value: 3.5,
	bank_account_id: 200123456789,
	organization_id: "123e4567-e89b-12d3-a456-426614174000",
	note: "Schnelle Lieferung erforderlich",
	note_modified_at: "2024-01-07T12:00:00Z",
	guarantor_id: null,
	invoice_id: 400789123456,
	quote_date: "2024-01-05T09:00:00Z",
	credit_note_date: "2024-01-10T09:00:00Z",
	del_note_data: "2024-01-08T09:00:00Z",
	created_by: "123e4567-e89b-12d3-a456-426614174001",
	modified_by: "123e4567-e89b-12d3-a456-426614174002",
	is_via_email_del_note: true,
	is_via_email_kv: false,
	kv_total_final: 1200.0,
	is_mwst: true,
	is_ignore_monthly_discount: false,
	practitioner_id: 500123789456,
	employee_id: "123e4567-e89b-12d3-a456-426614174003",
	patient_id: "123e4567-e89b-12d3-a456-426614174004",
	client_id: "123e4567-e89b-12d3-a456-426614174005",
	monthly_count: 1,
	job_request_id: 1001,
	shared_ids: [],
	connect_relationship_id: null,
	tp_tier: 1,
	tp_variation: 0,
};
