import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

export const PdfLayout: React.FC<{
	children: React.ReactNode;
	pdfTemplateConfiguration: PdfTemplateConfiguration;
}> = ({ children, pdfTemplateConfiguration }) => {
	const height = 297;
	const width = 210;
	return (
		<div
			style={{
				fontFamily: "LiberationSans",
				fontWeight: 400,
				fontStyle: "normal",
				fontSize: "12pt",
				width: width + "mm",
				height: height + "mm",
				position: "relative", // To position the footer at the bottom of the page
				paddingTop: pdfTemplateConfiguration?.general?.marginTop + "mm", // defaults to "5mm",
				paddingLeft:
					pdfTemplateConfiguration?.general?.marginLeft + "mm", // defaults to "10mm",
				paddingRight:
					pdfTemplateConfiguration?.general?.marginRight + "mm", // defaults to "10mm",
				paddingBottom:
					pdfTemplateConfiguration?.general?.marginBottom + "mm", // defaults to "10mm",
			}}
		>
			{children}
		</div>
	);
};
