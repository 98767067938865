import React, { createContext, ReactNode, useContext, useRef } from "react";

const RefContext = createContext<{
  container: any;
  container2: any;
  container3: any;
}>({
  container: { current: null },
  container2: { current: null },
  container3: { current: null },
});

export const RefContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const container = useRef<any>(null);

  // if a component needs more than one ref
  const container2 = useRef<any>(null);
  const container3 = useRef<any>(null);

  return (
    <RefContext.Provider
      value={{
        container,
        container2,
        container3,
      }}
    >
      {children}
    </RefContext.Provider>
  );
};

export const useRefContext = () => {
  const context = useContext(RefContext);
  if (!context) {
    throw new Error("useRefContext must be used within a RefContextProvider");
  }
  return context;
};
