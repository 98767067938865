import { useCallback } from "react";
import {
	useDatabaseFetch,
	useDatabaseFunctionsWithPromise,
} from "../useDatabase";
import { useCentralStore } from "../../store/Central";

/**
 * useFetchEntriesByOrganizationId
 * @param supabaseTable - table to fetch entries from
 * @example const { rows, loading, error, setRows } = useFetchEntriesByOrganizationId("bank_accounts");
 */
export const useFetchEntriesByOrganizationId = (supabaseTable: string) => {
	const organizationId = useCentralStore((state) => state.organization?.id);

	const {
		data: rows,
		loading,
		error,
	} = useDatabaseFetch(supabaseTable, {
		column: "organization_id",
		value: organizationId,
	});

	return { rows, loading, error };
};

export const useFetchEntriesByOrganizationIdWithPromise = (
	supabaseTable: string
) => {
	const organizationId = useCentralStore((state) => state.organization?.id);

	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	/**
	 * fetchEntriesByOrganizationId - fetches entries by organization id
	 * @example const { success, data: practitioners, error } = await fetchEntriesByOrganizationId();
	 */
	const fetchEntriesByOrganizationId = useCallback(async () => {
		return await fetchDataWithPromise(supabaseTable, {
			column: "organization_id",
			value: organizationId,
		});
	}, [supabaseTable, organizationId]);

	return { fetchEntriesByOrganizationId };
};
