import {
	BankAccountEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { CustomSelect } from "../../../../components/custom-select.component";
import { useFetchEntriesByOrganizationId } from "../../../../hooks/fetch-hooks/useFetchEntriesByOrganizationId";
import { SelectProps } from "../../../form-types";

// TODO: when there are no bank accounts, show a message that there are no bank accounts and a button to create one

interface BankAccountSelectProps extends SelectProps {
	helperText?: string;
}

/**
 * BankAccountSelect - select component for bank accounts
 * @param onChange - function to call when the value changes
 * @param value - the current value 
 * @example 
 * <BankAccountSelect 
 *    onChange={(value: number) => handleValueChange("bank_account_id", value)}
 *    value={formData.bank_account_id}
    />
 */
export const BankAccountSelect: React.FC<BankAccountSelectProps> = (props) => {
	const { rows: bankAccounts } = useFetchEntriesByOrganizationId(
		SupabaseTableEnum.BANK_ACCOUNTS
	);

	const options =
		bankAccounts?.map((account: BankAccountEntityType) => ({
			value: account.id,
			label: account.bank_name ?? "",
		})) ?? [];

	return (
		<CustomSelect
			options={options}
			inputLabel="Konto"
			{...props}
			helperText={props.helperText}
		/>
	);
};
