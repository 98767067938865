import { useSearchParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { archiveColumns } from "./archive.columns";
import { useEffect, useState } from "react";
import { useStorage } from "../../hooks/useStorage";
import { ClientEntityType } from "../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../../store/Central";

/**
 * ArchivePage - The archive page displays all client-related files, it can be filtered by
 * - Client
 * - File Type (PDF, Word, Excel, etc.)
 * - Document Type (Invoice, Offer, etc.)
 */
export const ArchivePage: React.FC = () => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { fetchFilesByProps } = useStorage();
	const [searchParams, setSearchParams] = useSearchParams();
	const [clientId, setClientId] = useState<string | null>("");
	const [clientName, setClientName] = useState<string | null>("");

	const [clientFiles, setClientFiles] = useState<any>([]);

	const clients: any[] = [
		{ id: 114, name: "Hans Muster" },
		{ id: 115, name: "Max Muster" },
		{ id: 116, name: "Tom Muster" },
	]; // TODO: make list of client ids and names part of org context, then also add proper type

	useEffect(() => {
		const fetch = async () => {
			const clientId = searchParams.get("clientId");
			setClientId(clientId);
			setClientName(
				clientId
					? clients.find(
							(client: ClientEntityType) => client.id === clientId
						)?.name
					: ""
			);
			if (!clientId) return;
			if (!organizationId) return;
			const { success, data, error }: any = await fetchFilesByProps({
				organization_id: organizationId,
				client_id: clientId,
			});
			setClientFiles(data);
		};
		fetch();
	}, [searchParams]);

	return (
		<Box>
			<h1>Archiv {clientName}</h1>
			<FormControl fullWidth>
				<InputLabel>Klient</InputLabel>
				<Select
					value={clientId}
					label="Klient"
					onChange={(event) =>
						setSearchParams({ clientId: event.target.value || "" })
					}
				>
					{clients.map((client: ClientEntityType) => (
						<MenuItem key={client.id} value={client.id}>
							{client.first_name} {client.last_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<DataGrid rows={[]} columns={archiveColumns} />
		</Box>
	);
};
