import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "./styles.css";

export const PatientProfileButton: React.FC<{
	name: string;
	onClick: () => void;
}> = ({ name, onClick }) => {
	return (
		<div
			className="patient-profile-button"
			onClick={onClick}
			data-testid="patient-profile-button"
		>
			<AccountBoxIcon
				sx={{
					fontSize: "1.5rem",
				}}
			/>
			<span>{name}</span>
		</div>
	);
};
