import { GridCustomCellProps } from "@progress/kendo-react-grid";
import "./custom-cells.css";
import { useEffect, useState } from "react";
import { FormControl, OutlinedInput } from "@mui/material";

export const TextCustomCell = (props: GridCustomCellProps) => {
	const [input, setInput] = useState<string>("");

	useEffect(() => {
		if (props.field) {
			setInput(props.dataItem[props.field]);
		}
	}, [props.dataItem, props.field]);

	const applyChange = (event: React.SyntheticEvent<EventTarget, Event>) => {
		if (props.onChange) {
			props.onChange({
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: event,
				value: input,
				dataIndex: props.dataIndex,
			});
		}
	};

	const handleBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		applyChange(event);
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (event.key === "Enter") {
			applyChange(event);
			(event.target as HTMLInputElement).blur();
		}
	};

	return (
		<td className="custom-td">
			<FormControl
				variant="outlined"
				sx={{
					padding: 0,
					margin: 0,
				}}
				fullWidth
			>
				<OutlinedInput
					data-testid={`text-custom-cell-outlined-input-${props.field}`}
					value={input}
					onChange={(event) => {
						setInput(event.target.value);
					}}
					onBlur={handleBlur}
					onKeyDown={handleKeyDown}
					size="small"
					sx={{
						height: "22px",
						fontSize: "12px",
						"& .MuiOutlinedInput-input": {
							textOverflow: "ellipsis",
						},
					}}
				/>
			</FormControl>
		</td>
	);
};
