import { SignInLayout } from "@/components/src/signin/signin-components/sign-in-layout.component";
import { supabase } from "@/lib/supabase";
import { Button } from "@mui/material";
import { useState } from "react";
export const PasswordRecoveryPage = () => {
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		const { data, error } = await supabase.auth.resetPasswordForEmail(
			email,
			{
				redirectTo: `${window.location.origin}/set-password`,
			}
		);

		if (error) {
			setErrorMessage("Fehler beim Senden des Links");
		} else {
			setErrorMessage(null);
			setSuccessMessage(
				"Link gesendet. Bitte überprüfen Sie Ihr E-Mail Postfach und folgen Sie dem Link."
			);
		}
	};

	return (
		<SignInLayout title="Passwort zurücksetzen" subtitle="">
			<form onSubmit={handleSubmit}>
				<input
					type="email"
					id="email"
					name="email"
					placeholder="E-Mail"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
					autoComplete="email"
					autoFocus
					style={{
						width: "100%",
						padding: "12px",
						borderRadius: "4px",
						border: "1px solid #ccc",
					}}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{
						my: 2,
					}}
				>
					Passwort zurücksetzen
				</Button>
			</form>
			{errorMessage && <p>{errorMessage}</p>}
			{successMessage && <p>{successMessage}</p>}
		</SignInLayout>
	);
};
