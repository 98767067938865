import {
	ClientEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTitleAndDate } from "./pdf-title-and-date.component";
import { PdfJobInformation } from "./pdf-job-information.component";
import { PdfTemplateType } from "../document-template.types";
import { PaymentSlipDataInput } from "./qr-payment-slip/qr-payment-helpers";
import { PdfJobItemsTable } from "./pdf-job-items-table";
import { CalculateTotalsResult } from "../../lib/utils/calculate";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

export const PdfInvoiceBody: React.FC<{
	title: "Rechnung" | "Quittung";
	client: ClientEntityType;
	job: JobEntityType;
	jobDocument: JobDocumentEntityType;
	jobItems: JobItemEntityType[];
	patient: PatientEntityType | undefined;
	paymentSlipData: PaymentSlipDataInput;
	organization: OrganizationEntityType;
	jobTotals: CalculateTotalsResult;
	pdfTemplateConfiguration: PdfTemplateConfiguration;
}> = ({
	title,
	client,
	job,
	jobDocument,
	jobItems,
	patient,
	paymentSlipData,
	organization,
	jobTotals,
	pdfTemplateConfiguration,
}) => {
	return (
		<div>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate title={title} date={jobDocument?.date} />
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<PdfJobInformation
						pdfTemplateType={PdfTemplateType.RECHNUNG}
						client={client}
						job={job}
						jobItems={jobItems}
						patient={patient}
						invoiceNumber={paymentSlipData.invoiceNumber}
					/>
				</div>
				<div
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					{jobItems && job && (
						<PdfJobItemsTable
							pdfTemplateConfiguration={pdfTemplateConfiguration}
							jobTotals={jobTotals}
							jobDocument={jobDocument}
							jobItems={jobItems}
							organization={organization}
							showDrTax={true}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
