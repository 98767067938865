import {
	IconButton,
	InputAdornment,
	StandardTextFieldProps,
	TextField,
	Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

/**
 * CustomTextFieldProps
 * @param {StandardTextFieldProps} props - props from MUI TextField https://mui.com/material-ui/api/text-field/#props
 * @param {string} validationError - error message to be displayed
 * @param {string} customHelperText - helper text to be displayed
 */
export interface CustomTextFieldProps extends StandardTextFieldProps {
	validationError?: string | null;
	customHelperText?: string;
	name?: string;
}

/**
 * Set onChange to use as a controlled component
 */
export const CustomTextField: React.FC<CustomTextFieldProps> = ({
	validationError,
	customHelperText,
	InputProps,
	value,
	onChange,
	...rest
}) => {
	const [newError, setNewError] = useState<string | null>(null);
	const [internalValue, setInternalValue] = useState<string>(
		(value as string) || ""
	);

	// Effect to update internal value when external value changes
	useEffect(() => {
		// If there exists an external value, update the internal value
		if (value !== undefined) {
			setInternalValue(value as string);
		}
	}, [value]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInternalValue(event.target.value);
		onChange?.(event);
	};

	const handleBlur = () => {
		setNewError(validationError || null);
	};

	const endAdornment = useMemo(
		() =>
			customHelperText ? (
				<InputAdornment position="end">
					<Tooltip title={customHelperText}>
						<IconButton edge="end">
							<HelpOutlineIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</InputAdornment>
			) : null,
		[customHelperText]
	);

	return (
		<TextField
			{...rest}
			data-testid={`custom-text-field-${rest.name}`}
			size="small" // default to small, will be overwritten if size is passed in
			value={internalValue}
			onChange={handleChange}
			error={Boolean(newError)}
			helperText={newError || rest.helperText || ""}
			onBlur={handleBlur}
			InputProps={{
				...InputProps,
				endAdornment: InputProps?.endAdornment || endAdornment,
			}}
		/>
	);
};
