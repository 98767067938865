import { SignInLayout } from "@/components/src/signin/signin-components/sign-in-layout.component";
import { zodResolver } from "@hookform/resolvers/zod";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { environment } from "../../lib/utils/environment";
import { Logger } from "@/lib/logger/Logger";
import { useState } from "react";

const InviteeSignUpSchema = z.object({
	email: z.string().email(),
	password: z.string().min(6),
	firstName: z.string(),
	lastName: z.string(),
});

type InviteeSingUpType = z.infer<typeof InviteeSignUpSchema>;

export const InviteeSignup = () => {
	const [search] = useSearchParams();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const { register, handleSubmit } = useForm<InviteeSingUpType>({
		resolver: zodResolver(InviteeSignUpSchema),
		defaultValues: {
			email: search.get("email") ?? "",
		},
	});

	const onSubmit = async () => {
		await handleSubmit(async (data) => {
			try {
				setLoading(true);
				const res = await axios({
					method: "POST",
					url: `${environment.VITE_PDF_MICROSERVICE_URL}/register/lab-invite`,
					data: {
						first_name: data.firstName,
						last_name: data.lastName,
						email: data.email,
						password: data.password,
					},
				});

				if (res.status == 200) navigate("/");
			} catch (e: unknown) {
				Logger.error(e instanceof AxiosError ? e.message : e);
				return;
			} finally {
				setLoading(false);
			}
		})();
	};

	return (
		<div>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
				onClick={() => {
					Logger.log("click");
				}}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<SignInLayout title="Erstellen Sie Ihren Account" subtitle="">
				<div className="gap-4 flex flex-col">
					<TextField
						required
						fullWidth
						id="email"
						label="E-Mail"
						type="email"
						autoComplete="email"
						{...register("email")}
					/>
					<div className="flex justify-between gap-2 items-center">
						<TextField
							required
							fullWidth
							id="firstName"
							label="Vorname"
							autoComplete="given-name"
							{...register("firstName")}
						/>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Nachname"
							autoComplete="family-name"
							{...register("lastName")}
						/>
					</div>
					<TextField
						required
						fullWidth
						label="Passwort"
						type="password"
						id="password"
						autoComplete="new-password"
						{...register("password")}
					/>
				</div>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					onClick={onSubmit}
					sx={{
						mt: 3,
						mb: 2,
						backgroundColor: "var(--primary-main)",
						"&:hover": {
							backgroundColor: "var(--primary-dark)",
						},
					}}
				>
					Registrieren
				</Button>
			</SignInLayout>
		</div>
	);
};
