import { Button } from "@mui/material";
import {
  useState,
  useRef,
  useCallback,
  MutableRefObject,
  useMemo,
} from "react";
import { FormProps } from "../forms/form-types";
import { Logger } from "../../../lib/logger/Logger";
import { DialogWithTitleAndActions } from "../components/dialog-components/dialog-frames/dialog-with-title-and-actions/dialog-with-title-and-actions.component";

// TODO: if save button is clicked, the form is not submitted with proper form functionality (e.g. field validation)

interface UseFormDialogProps {
  onSubmit: (entity: any) => void;
  FormComponent: React.FC<any>;
  dialogTitle: string;
}

/**
 * useFormDialog - handles opening and closing a form within a dialog.
 * @param onSubmit - function that informs the parent component about the form submission, every form should have this prop
 * @param FormComponent - the form component that is rendered in the dialog
 * @param dialogTitle - the title of the dialog
 * @type T - type for any additional form props, T will extend FormProps (default form type which should be used/extended in a all form components)
 * @example
 * const { openDialog, dialog } = useFormDialog<ClientFormProps>({
 *    onSubmit: (client) => {
 *     handleChange(client);
 *   },
 *  FormComponent: ClientForm,
 *  dialogTitle: "Auftraggeber hinzufügen",
 * });
 * ...later in the return statement of the component:
 * {dialog}
 * ...to open the dialog:
 * openDialog({ optionalEntityIdUsedInForm: optionalEntityIdUsedInForm });
 */
export const useFormDialog = <T extends FormProps>({
  onSubmit,
  FormComponent,
  dialogTitle,
}: UseFormDialogProps) => {
  const [open, setOpen] = useState(false);

  const [formProps, setFormProps] = useState<T | undefined>(undefined);
  const formRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  /**
   * openDialog - opens the dialog with the form component
   * @param formProps - optional props that are passed to the form component, for update components the id needs to be passed
   */
  const openDialog = useCallback((formProps?: T) => {
    Logger.log("useFormDialog", "openDialog", "formProps", formProps);
    setFormProps(formProps);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    formRef.current?.click();
    setOpen(false);
  }, []);

  const dialog = useMemo(() => {
    if (!open) return null;
    Logger.log("useFormDialog", "rendering dialog", "formProps", formProps);
    return (
      <DialogWithTitleAndActions
        dialogTitle={dialogTitle}
        open={open}
        onClose={handleClose}
        dialogFooterActions={
          <Button autoFocus color="inherit" onClick={handleSubmit}>
            Speichern
          </Button>
        }
      >
        <FormComponent
          {...formProps}
          submitRef={formRef}
          onSave={(entity: any) => {
            onSubmit(entity);
            handleClose();
          }}
        />
      </DialogWithTitleAndActions>
    );
  }, [open, FormComponent, dialogTitle, formProps]);

  return {
    openDialog,
    dialog,
  };
};
