import { articleClusterOptions } from "@/dentlab/src/lib/constants/options";
import { SelectProps } from "../../../form-types";
import { CustomSelect } from "@/dentlab/src/components/custom-select.component";

export const ClusterSelect: React.FC<SelectProps> = (props) => {
	return (
		<CustomSelect
			options={articleClusterOptions}
			inputLabel="Cluster"
			{...props}
		/>
	);
};
