import { StateCreator } from "zustand";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	ClientEntityType,
	JobEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";
import { JobStatusEnum } from "@/lib/types/job";

export interface AgendaSlice {
	fetchClientJobs: () => Promise<void>;
	clientsWithJobs: (ClientEntityType & { jobs: JobEntityType[] })[];
}

export const createAgendaStore: StateCreator<AgendaSlice> = (set) => ({
	clientsWithJobs: [],
	fetchClientJobs: async () => {
		const visibleStatusesForJobs = [
			JobStatusEnum.NOT_STARTED,
			JobStatusEnum.IN_PROGRESS,
			JobStatusEnum.COMPLETED,
		];

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.CLIENTS)
				.select(`*, ${SupabaseTableEnum.JOBS} (*) `)
				.in("jobs.status", visibleStatusesForJobs)
		);

		if (error) {
			Logger.error("Error fetching client jobs", error);
			return;
		}

		const clients = data.filter(
			(
				d: ClientEntityType & {
					jobs: JobEntityType[];
				}
			) => d.jobs.length
		);

		set({
			clientsWithJobs: clients,
		});
	},
});
