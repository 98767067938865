import { GuarantorEntityType } from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { QrPaymentSlip } from "../pdf-template-components/qr-payment-slip/qr-payment-slip.component";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { CommonPDFTemplateProps } from "./types";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";

export interface AkontorechnungTemplateProps extends CommonPDFTemplateProps {
	guarantor?: GuarantorEntityType;
	paymentSlipData: PaymentSlipDataInput;
	date: string;
}

export const AkontorechnungTemplate: React.FC<AkontorechnungTemplateProps> = ({
	client,
	job,
	jobItems,
	organization,
	pdfTemplateConfiguration,
	guarantor,
	paymentSlipData,
	date,
}) => {
	if (
		!organization ||
		!pdfTemplateConfiguration ||
		!job ||
		!jobItems ||
		!client
	) {
		return null;
	}

	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.AKONTORECHNUNG}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.AKONTORECHNUNG}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate title="Akontorechnung" date={date} />
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<tr>
						<td
							style={{
								paddingRight: "5mm",
							}}
						>
							<p>Rg.-Nummer:</p>
						</td>
						<td>
							<p>{paymentSlipData.invoiceNumber}</p>
						</td>
					</tr>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						paddingTop: "10mm",
						paddingBottom: "30mm",
						fontWeight: 500,
						gap: "4mm",
						fontSize: "12pt",
					}}
				>
					<p>
						Guten Tag {client?.title} {client?.last_name},
					</p>
					<p>Zahlbar innert {organization.days_payment} Tagen.</p>
					<p>Mit freundlichen Grüssen</p>
				</div>
			</div>
			<PdfFooter
				templateType={PdfTemplateType.AKONTORECHNUNG}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
			/>
			<QrPaymentSlip
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				paymentSlipData={paymentSlipData}
			/>
		</PdfLayout>
	);
};
