import { useEffect } from "react";
import { useAgendaStore } from "../../store/Agenda";
import { GanttChart } from "./components/gantt-chart.component";
import { Button } from "@mui/material";
import {
	FullScreen,
	useFullScreenHandle,
} from "../../hooks/useFullScreenHandle";
import { FullscreenIcon } from "lucide-react";
import clsx from "clsx";

export const Agenda = () => {
	const { fetchClientJobs, clientsWithJobs } = useAgendaStore((state) => ({
		fetchClientJobs: state.fetchClientJobs,
		clientsWithJobs: state.clientsWithJobs,
	}));

	useEffect(() => {
		fetchClientJobs();
	}, []);

	const handle = useFullScreenHandle();

	return (
		<div className=" flex flex-col relative items-center w-full pb-0 h-full">
			<div className="fixed top-16 z-50 right-2">
				<Button onClick={handle.enter}>
					<FullscreenIcon size={24} />
				</Button>
			</div>
			<FullScreen className=" w-full h-full" handle={handle}>
				<div className="w-full h-full bottom-0  relative flex bg-white justify-center">
					<GanttChart
						fullScreen={handle.active}
						clients={clientsWithJobs}
					/>
				</div>
			</FullScreen>
		</div>
	);
};
