import { useRef } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Logger } from "../../../lib/logger/Logger";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { useStorage } from "./useStorage";

export interface OperationResult {
	success: boolean;
	error: string | null;
}

interface ExportPdfProps {
	documentComponent: React.ReactNode;
	filePath: string;
}

export const useExportPdf = () => {
	const pdfRef = useRef(null);
	const { uploadFileWithProvidedPath } = useStorage();

	/**
	 * Takes a React component and exports it as a PDF
	 * Returns the path to the PDF file in Supabase storage
	 */
	const exportPdf = async ({
		documentComponent,
		filePath,
	}: ExportPdfProps): Promise<OperationResult> => {
		const uuid = uuidv4();

		Logger.info(`Start processing PDF export ${uuid}`, {
			documentComponent,
		});

		const pdfNode = (
			<div
				style={{
					// Make sure the element is not visible between rendering and exporting
					position: "absolute",
					left: "-10000px",
					display: undefined,
				}}
			>
				<div ref={pdfRef} className="pdf">
					{documentComponent}
				</div>
			</div>
		);

		// Create a container for the PDF wrapper
		const container = document.createElement("div");
		document.body.appendChild(container);

		const root = createRoot(container);
		root.render(pdfNode);

		// check if the root node has rendered
		if (!root) {
			Logger.error("Root node not rendered", { root });
			return {
				success: false,
				error: "PDF konnte in diesem Browser nicht erstellt werden.",
			};
		}

		// while pdfRef.current is null, the PDF is not rendered
		let counter = 0;
		while (!pdfRef.current) {
			Logger.info("Waiting for PDF to be rendered");
			counter++;
			await new Promise((resolve) => setTimeout(resolve, 1000));

			if (counter > 10) {
				Logger.error("PDF not rendered after 10 seconds");
				return {
					success: false,
					error: "PDF konnte nicht innerhalb von 10 Sekunden erstellt werden.",
				};
			}
		}

		Logger.info(`Start saving PDF export ${uuid}, ${pdfRef.current}`);

		// get the element with className "pdf"
		const pdfElement = document.querySelector(".pdf") as HTMLElement;

		if (!pdfElement) {
			Logger.error("PDF element not found", { pdfElement });
			return {
				success: false,
				error: "PDF Element konnte nicht gefunden werden.",
			};
		}

		const group = await drawDOM(pdfElement, {
			paperSize: ["210mm", "297mm"],
			keepTogether: "p",
			scale: 0.75,
		});

		if (!group) {
			Logger.error("Error drawing PDF", { group });
			return {
				success: false,
				error: "Fehler beim Erstellen des Dokuments (drawDOM)",
			};
		}

		const dataUri = await exportPDF(group, {});
		if (!dataUri) {
			Logger.error("Error exporting PDF", { dataUri });
			return {
				success: false,
				error: "Fehler beim Erstellen des Dokuments (exportPDF)",
			};
		}

		// convert the base64 string to a binary string
		const base64String = dataUri.split(";base64,")[1];
		const binaryString = atob(base64String);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		const pdfBuffer = new Blob([bytes], {
			type: "application/pdf",
		});

		const { success, pathName } = await uploadFileWithProvidedPath({
			filePath,
			fileBody: pdfBuffer,
			fileOptions: {
				contentType: "application/pdf",
			},
		});

		if (!success) {
			return {
				success: false,
				error: `Fehler beim Speichern des Dokuments (${pathName})`,
			};
		}

		Logger.info(`Finished processing PDF export ${uuid}`);
		root.unmount();
		document.body.removeChild(container);

		return { success: true, error: null };
	};

	return {
		exportPdf,
	};
};
