import { BankAccountEntityType } from "../../../lib/supabase/supabaseTypes";

export const bankAccountExample: BankAccountEntityType = {
	id: 20,
	created_at: "2021-06-29T15:00:00Z",
	modified_at: "2021-06-29T15:00:00Z",
	is_pc_account: true,
	pc_account: "PC-Konto-Nummer",
	bank_name: "Schweizer Bank",
	bank_city: "Zürich",
	cdtr_street_line_1: "Bahnhofstrasse 1",
	cdtr_city: "",
	reference_number: "RF18539007547034",
	account_name: "Max Mustermann",
	qr_iban: "CH9300762011623852957",
	letterhead: "Firmenbriefkopf",
	organization_id: "123e4567-e89b-12d3-a456-426614174000",
	cdtr_house_number_line_2: "2",
	cdtr_name: "Max Mustermann",
	cdtr_adrtp: "S",
	cdtr_area_code: "",
	cdtr_country: "CH",
	currency: "CHF",
};
