import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";
import {
	ClientEntityType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import { useDatabaseFetch } from "../../../hooks/useDatabase";
import { useCentralStore } from "../../../store/Central";

export const ClientSelect: React.FC<SelectProps> = (props) => {
	const organizationId = useCentralStore((state) => state.organization?.id);

	const {
		data: clients,
		loading,
		error,
	} = useDatabaseFetch(SupabaseTableEnum.CLIENTS, {
		column: "organization_id",
		value: organizationId,
	});

	return (
		<>
			<CustomSelect
				options={
					clients?.map((p: ClientEntityType) => ({
						value: p.id,
						label: `${p.first_name} ${p.last_name}`,
					})) ?? []
				}
				inputLabel="Auftraggeber"
				{...props}
			/>
		</>
	);
};
