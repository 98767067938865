import { FormattedClientType, State } from "./types";
import { StateCreator } from "zustand";
import { SHOW_ALL, initialState } from "./utils";
import {
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { JobStatusEnum } from "@/lib/types/job";
import { useCentralStore } from "../Central";

interface Actions {
	formatClientData: () => Promise<void>;
	fetchJobItems: () => Promise<JobItemEntityType[]>;
	fetchJobItemsByEmployee: () => Promise<JobItemEntityType[]>;
}

export type ClientChartSlice = State & Actions;

const clientFilter = (selectedClients: string[]) => {
	return `(${selectedClients
		.map((client: string) => {
			return `"${client}"`;
		})
		.join(selectedClients.length <= 1 ? "" : ",")})`;
};

const jobItemTypeFilter = (
	jobItemTypes: { value: JobItemTypeEnum; label: string }[],
	selectedJobItemType: string
) => {
	return `(${jobItemTypes
		.map((jt) => {
			if (
				selectedJobItemType === SHOW_ALL ||
				jt.value.toString() === selectedJobItemType
			) {
				return `"${jt.value}"`;
			}
		})
		.join(selectedJobItemType !== SHOW_ALL ? "" : ",")})`;
};

export const createClientChartStore: StateCreator<ClientChartSlice> = (
	set,
	get
) => ({
	...initialState,
	// For formatting the client data
	formatClientData: async () => {
		let data;

		if (get().selectedEmployeeForClientChart === SHOW_ALL) {
			data = await get().fetchJobItems();
		} else {
			data = await get().fetchJobItemsByEmployee();
		}
		const selectedEmployee = get().selectedEmployeeForClientChart;
		const selectedClients = get().selectedClientsForClientChart;
		const allClients = get().allClients;
		const jobItemTypes = get().JobItemTypes;

		const finalData: FormattedClientType[] = selectedClients.map(
			(clientIndex) => {
				const client = allClients.find((c) => c.id === clientIndex);
				return {
					client_id: client?.id ?? "",
					name: `${client?.first_name} ${client?.last_name}`,
					items: [],
				};
			}
		);

		if (!data || data?.length === 0) {
			set({
				formattedClientData: finalData,
			});
			return;
		}

		data?.forEach((d: JobItemEntityType) => {
			const amount = d.price * d.quantity;
			// data is fetched as a nested object which isn't typed in supabase, so we have to suppress the ts error
			// @ts-expect-error cast
			const clientId = d.job_documents?.jobs?.client_id;
			const clientIndex = finalData.findIndex(
				(fd) => fd.client_id === clientId
			);
			if (!selectedClients.includes(clientId)) return;

			if (clientIndex > -1) {
				finalData[clientIndex].items.push({
					type: d.type,
					amount,
				});

				if (selectedEmployee == SHOW_ALL) {
					const prevAmount = finalData[clientIndex][d.type ?? 0] ?? 0;
					finalData[clientIndex][d.type ?? 0] =
						Number(prevAmount) + amount;
				} else {
					if (d.profile_id === selectedEmployee) {
						const calculatedAmount =
							(amount *
								(100 -
									((d.techn_perc_2 ?? 0) +
										(d.techn_perc_3 ?? 0)))) /
							100;
						const prevAmount =
							finalData[clientIndex][d.type ?? 0] ?? 0;
						finalData[clientIndex][d.type ?? 0] =
							Number(prevAmount) + calculatedAmount;
					} else if (d.profile2_id === selectedEmployee) {
						const calculatedAmount =
							(amount * (d.techn_perc_2 ?? 0)) / 100;
						const prevAmount =
							finalData[clientIndex][d.type ?? 0] ?? 0;
						finalData[clientIndex][d.type ?? 0] =
							Number(prevAmount) + calculatedAmount;
					} else if (d.profile3_id === selectedEmployee) {
						const calculatedAmount =
							(amount * (d.techn_perc_3 ?? 0)) / 100;
						const prevAmount =
							finalData[clientIndex][d.type ?? 0] ?? 0;
						finalData[clientIndex][d.type ?? 0] =
							Number(prevAmount) + calculatedAmount;
					}
				}
			}
		});

		finalData.forEach((fd) => {
			jobItemTypes.forEach((type) => {
				const total = fd[type.value] ?? 0;
				fd[type.value] = total;
			});
		});
		finalData.filter((fd) => {
			return selectedClients.includes(fd.client_id);
		});
		set({
			formattedClientData: finalData.filter((fd) => {
				return selectedClients.includes(fd.client_id);
			}),
		});
	},

	// fetch job items if no employee is selected
	fetchJobItems: async () => {
		const dateValue = get().dateValue;
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return;
		const selectedClients = get().selectedClientsForClientChart;
		const jobItemTypes = get().JobItemTypes;
		const selectedJobItemType = get().selectedJobItemTypeForClientChart;

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.select(
					`*, job_documents!inner( *, jobs!inner(client_id, organization_id ))`
				)
				.eq("job_documents.jobs.organization_id", organizationId)
				.in("job_documents.status", [
					JobStatusEnum.BOOKED_SINGLE,
					JobStatusEnum.BOOKED_MONTHLY,
				])
				.gte(
					"job_documents.created_at",
					`${dateValue.start?.toDateString()}`
				)
				.lte(
					"job_documents.created_at",
					`${dateValue.end?.toDateString()}`
				)
				.filter(
					"job_documents.jobs.client_id",
					"in",
					clientFilter(selectedClients)
				)
				.filter(
					"type",
					"in",
					jobItemTypeFilter(jobItemTypes, selectedJobItemType)
				)
		);

		if (error) {
			Logger.error("Error fetching job items", error);
			set({ error: "Error fetching job items" });
			return;
		}

		return data;
	},
	// fetch job items if an employee is selected
	fetchJobItemsByEmployee: async () => {
		const dateValue = get().dateValue;
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return;
		const selectedClients = get().selectedClientsForClientChart;
		const jobItemTypes = get().JobItemTypes;
		const selectedJobItemType = get().selectedJobItemTypeForClientChart;
		const selectedEmployee = get().selectedEmployeeForClientChart;

		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.select(
					`*, job_documents!inner( *, jobs!inner(client_id, organization_id ))`
				)
				.eq("job_documents.jobs.organization_id", organizationId)
				.in("job_documents.status", [
					JobStatusEnum.BOOKED_SINGLE,
					JobStatusEnum.BOOKED_MONTHLY,
				])
				.gte(
					"job_documents.created_at",
					`${dateValue.start?.toDateString()}`
				)
				.lte(
					"job_documents.created_at",
					`${dateValue.end?.toDateString()}`
				)
				.filter(
					"job_documents.jobs.client_id",
					"in",
					clientFilter(selectedClients)
				)
				.filter(
					"type",
					"in",
					jobItemTypeFilter(jobItemTypes, selectedJobItemType)
				)
				.or(
					`profile_id.eq.${selectedEmployee},profile2_id.eq.${selectedEmployee},profile3_id.eq.${selectedEmployee}`
				)
		);

		if (error) {
			Logger.error("Error fetching job items", error);
			set({ error: "Error fetching job items" });
			return;
		}

		return data;
	},
});
