import {
	articleClusterOptions,
	articleTypeOptions,
} from "@/dentlab/src/lib/constants/options";

export const articlesColumns: any[] = [
	{
		field: "custom",
		type: "boolean",
		headerName: "Eigener/Bearbeitet",
		width: 140,
		editable: false,
	},
	{ field: "code_e", headerName: "Artikelcode", width: 100, editable: true },
	{
		field: "description_de",
		headerName: "Bezeichnung (D)",
		width: 300,
		editable: true,
	},
	{
		field: "description_fr",
		headerName: "Bezeichnung (F)",
		width: 300,
		editable: true,
	},
	{
		field: "description_it",
		headerName: "Bezeichnung (I)",
		width: 300,
		editable: true,
	},
	{
		field: "additional_text",
		headerName: "Zusatztext",
		width: 300,
		editable: true,
	},
	{
		field: "is_req_dr_tax",
		type: "boolean",
		headerName: "Ärztesteuerpflichtig",
		width: 100,
		editable: true,
	},
	{
		field: "composition",
		headerName: "Zusammensetzung",
		width: 300,
		editable: true,
	},
	{
		field: "modified_at",
		headerName: "Letzte Änderung",
		width: 200,
		editable: false,
	},
	{
		field: "created_at",
		headerName: "Erstellt am",
		width: 200,
		editable: false,
	},
	{
		field: "manufacturer",
		headerName: "Hersteller",
		width: 200,
		editable: true,
	},
	{
		field: "cluster",
		headerName: "Cluster",
		width: 200,
		editable: true,
		type: "singleSelect",
		valueOptions: articleClusterOptions,
	},
	{
		field: "lot",
		headerName: "Lot",
		width: 200,
		editable: true,
	},
	{
		field: "ce_number",
		headerName: "CE-Nummer",
		width: 200,
		editable: true,
	},
	{
		field: "unit",
		headerName: "Einheit",
		width: 200,
		editable: true,
	},
	{
		field: "type",
		headerName: "Typ",
		width: 200,
		editable: true,
		type: "singleSelect",
		valueOptions: articleTypeOptions,
	},
	{
		field: "purchase_price",
		headerName: "Einkaufspreis",
		width: 200,
		editable: true,
	},
	{
		field: "price",
		headerName: "Verkaufspreis",
		width: 200,
		editable: true,
	},
	{
		field: "margin",
		headerName: "Marge",
		width: 200,
		editable: true,
	},
	{
		field: "purchase_quantity",
		headerName: "Einkaufsmenge",
		width: 200,
		editable: true,
	},
	{
		field: "standard_quantity",
		headerName: "Standardmenge",
		width: 200,
		editable: true,
	},
	{
		field: "modified_stock_quantity_at",
		headerName: "Letzte Bestandsänderung",
		width: 200,
		editable: false,
	},
	{
		field: "stock_quantity",
		headerName: "Bestand",
		width: 200,
		editable: true,
	},
];
