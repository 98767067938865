import { useMemo } from "react";
import PrintIcon from "@mui/icons-material/Print";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { Skeleton } from "@mui/material";
import { DesktopSystemRequestTypes } from "@/dentlab/src/store/Desktops/types";

export const DesktopSystemRequestCard: React.FC<{
	request: {
		id: number;
		created_at: string;
		type: DesktopSystemRequestTypes;
	};
	response: { id: number; created_at: string };
}> = ({ response, request }) => {
	const icon = useMemo(() => {
		switch (request.type) {
			case DesktopSystemRequestTypes.PrintDocument:
				return <PrintIcon />;
			case DesktopSystemRequestTypes.PrintLabels:
				return <PrintIcon />;
			case DesktopSystemRequestTypes.ScanDocument:
				return <AdfScannerIcon />;
		}
	}, [request.type]);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "start",
				gap: "1rem",
				padding: "16px",
				width: "98%",
				borderRadius: "8px",
				border: "1px solid #e0e0e0",
				boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			}}
		>
			<div
				style={{
					width: "50px",
					display: "flex",
					justifyContent: "center",
					marginRight: "1rem",
				}}
			>
				{icon}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					width: "100%",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				<div>{request.id}</div>
				<div>
					{formatDistance(request.created_at, new Date(), {
						addSuffix: true,
						locale: de,
					})}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					width: "100%",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				<div>{response.id}</div>
				<div>
					{formatDistance(response.created_at, new Date(), {
						addSuffix: true,
						locale: de,
					})}
				</div>
			</div>
		</div>
	);
};

export const LoadingDesktopSystemRequestCard: React.FC = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "start",
				gap: "1rem",
				padding: "16px",
				width: "98%",
				borderRadius: "8px",
				border: "1px solid #e0e0e0",
				boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
			}}
		>
			<div
				style={{
					width: "50px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Skeleton width={"50px"} height="24px" />
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					alignItems: "center",
				}}
			>
				<div>
					<Skeleton height={24} width={100} />
				</div>
				<div>
					<Skeleton height={24} width={150} />
				</div>
			</div>
		</div>
	);
};
