import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { supabase } from "@/lib/supabase";
import {
	JobRequestEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { produce } from "immer";
import { showNotification } from "../Central/selectors";

interface Actions {
	fetchJobsByStatus: (status: JobStatusEnum) => Promise<void>;
	changeSelectedJobStatus: (status: JobStatusEnum | null) => void;
	// This function accepts/rejects a job request and correspondingly creates
	// a new one if it's accepted
	changeRequestToJob: (
		jobRequest: JobRequestEntityType,
		accepted: boolean
	) => Promise<number | null>;
	fetchJobRequest: (
		jobRequestId: number
	) => Promise<JobRequestEntityType | null>;
}

export type JobListSlice = State & Actions;

export const createJobListStore: StateCreator<JobListSlice> = (set, get) => ({
	...initialState,
	fetchJobsByStatus: async (status: JobStatusEnum) => {
		const searchConfig = useCentralStore.getState().searchConfig;

		let year = searchConfig?.statuses?.[status] ?? 0;
		const clientId = useCentralStore.getState().clientId;

		if (!clientId) return;

		if (year === 0) {
			year = 100;
		}

		const targetDate = new Date();
		targetDate.setDate(targetDate.getDate() - 365 * year);

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.select("*, job_documents(*)")
			.eq("status", status)
			.eq("client_id", clientId)
			.order("created_at", { ascending: false })
			.gte("created_at", targetDate.toISOString());
		if (error) {
			set(
				produce((state) => {
					state.jobList[status] = {
						jobCount: 0,
						jobs: [],
					};
				})
			);
			Logger.error("Error fetching jobs by status", error);
			return;
		}

		const connectRelationshipId =
			useCentralStore.getState().connectRelationshipId;
		const { data: jobRequestData, error: jobRequestError } = await supabase
			.from(SupabaseTableEnum.JOB_REQUESTS)
			.select()
			.eq("connect_relationship_id", connectRelationshipId as string)
			.is("accepted", null)
			.is("rejected", null);

		// If there's an error fetching job requests don't return
		// log the error and continue
		if (jobRequestError) {
			Logger.error("Error fetching requests", jobRequestError);
		}

		const requests =
			jobRequestData?.map((j) => ({
				id: j.id,
				request: true,
				...JSON.parse(j.request as string),
			})) ?? [];

		set({
			jobRequests: jobRequestData,
		});

		set(
			produce((state) => {
				state.jobList[status] = {
					jobCount:
						status == JobStatusEnum.NOT_STARTED
							? data?.length + requests?.length
							: data?.length,
					jobs:
						status == JobStatusEnum.NOT_STARTED
							? [...data, ...requests]
							: data,
				};
			})
		);
	},

	changeRequestToJob: async (jobRequest, accepted) => {
		const clientId = useCentralStore.getState().client?.id;
		const currentDate = new Date().toDateString();

		// Helper function to update job request status
		const updateJobRequestStatus = async (
			statusField: "accepted" | "rejected",
			statusDate: string
		) => {
			const { error } = await supabase
				.from(SupabaseTableEnum.JOB_REQUESTS)
				.update({
					...jobRequest,
					[statusField]: statusDate,
				})
				.eq("id", jobRequest.id);

			if (error) {
				showNotification({
					message: `Fehler beim ${
						accepted ? "Annehmen" : "Ablehnen"
					} der Arbeit`,
					type: "error",
				});
				Logger.error(
					`Error ${accepted ? "accepting" : "rejecting"} job`,
					error
				);
				return null;
			}

			return true;
		};

		if (accepted) {
			const { data: job, error } = await supabase
				.from(SupabaseTableEnum.JOBS)
				.insert({
					// @ts-expect-error request is a JSON field
					...JSON.parse(jobRequest.request),
					code: "", // If code = '' it will be set on insert on the db
					client_id: clientId,
					status: JobStatusEnum.IN_PROGRESS,
				})
				.select("*, job_documents(*)")
				.single();

			if (error) {
				showNotification({
					message: "Fehler beim Annehmen der Arbeit",
					type: "error",
				});
				Logger.error("Error accepting job", error);
				return null;
			}

			const statusUpdated = await updateJobRequestStatus(
				"accepted",
				currentDate
			);
			if (!statusUpdated) return null;

			set(
				produce((state) => {
					state.jobList[JobStatusEnum.NOT_STARTED] = {
						jobCount:
							state.jobList[JobStatusEnum.NOT_STARTED].jobCount -
							1,
						jobs: state.jobList[
							JobStatusEnum.NOT_STARTED
						].jobs.filter(
							(j: JobRequestEntityType) => j.id !== jobRequest.id
						),
					};
					state.jobList[JobStatusEnum.IN_PROGRESS] = {
						jobCount:
							state.jobList[JobStatusEnum.IN_PROGRESS].jobCount +
							1,
						jobs: [
							job,
							...state.jobList[JobStatusEnum.IN_PROGRESS].jobs,
						],
					};
				})
			);

			showNotification({
				message: "Arbeit erfolgreich angenommen",
				type: "success",
			});

			return job.id;
		} else {
			const statusUpdated = await updateJobRequestStatus(
				"rejected",
				currentDate
			);
			if (!statusUpdated) return null;

			set(
				produce((state) => {
					state.jobList[JobStatusEnum.NOT_STARTED] = {
						jobCount:
							state.jobList[JobStatusEnum.NOT_STARTED].jobCount -
							1,
						jobs: state.jobList[
							JobStatusEnum.NOT_STARTED
						].jobs.filter(
							(j: JobRequestEntityType) => j.id !== jobRequest.id
						),
					};
				})
			);

			showNotification({
				message: "Arbeit erfolgreich abgelehnt",
				type: "warning",
			});

			return null;
		}
	},

	fetchJobRequest: async (jobRequestId: number) => {
		const jobRequests = get().jobRequests;
		const req = jobRequests?.find((jr) => jr.id == Number(jobRequestId));
		if (req) return req;

		const { data: jobRequest, error: jobRequestError } = await supabase
			.from(SupabaseTableEnum.JOB_REQUESTS)
			.select()
			.eq("id", jobRequestId)
			.single();
		if (jobRequestError) {
			showNotification({
				message: "Die Anfrage, die Sie suchen, existiert nicht",
				type: "error",
			});

			Logger.error("Error fetching request", jobRequestError);

			return null;
		}
		set(
			produce((state) => {
				state.jobList[JobStatusEnum.NOT_STARTED] = {
					jobCount:
						state.jobList[JobStatusEnum.NOT_STARTED].jobCount + 1,
					jobs: [
						...state.jobList[JobStatusEnum.NOT_STARTED].jobs,
						jobRequest,
					],
				};
			})
		);

		return jobRequest;
	},

	changeSelectedJobStatus: (status: JobStatusEnum | null) => {
		set({
			selectedJobStatus: status,
		});

		if (status) get().fetchJobsByStatus(status);
	},
});
