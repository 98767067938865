import { SignInLayout } from "@/components/src/signin/signin-components/sign-in-layout.component";
import { supabase } from "@/lib/supabase";
import { Button } from "@mui/material";
import { useState } from "react";
export const SetPasswordPage = () => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const form = e.target as HTMLFormElement;
		const newPassword = form.password.value;
		const { error } = await supabase.auth.updateUser({
			password: newPassword,
		});

		if (error) {
			setErrorMessage("Fehler beim Setzen des Passworts");
		} else {
			window.location.href = "/";
		}
	};

	return (
		<SignInLayout title="Passwort zurücksetzen" subtitle="">
			<form onSubmit={handleSubmit}>
				<input
					autoFocus
					style={{
						width: "100%",
						padding: "12px",
						borderRadius: "4px",
						border: "1px solid #ccc",
					}}
					type="password"
					id="password"
					name="password"
					placeholder="Passwort"
					required
				/>
				<Button
					type="submit"
					fullWidth
					sx={{ my: 2 }}
					variant="contained"
				>
					OK
				</Button>
			</form>
			{errorMessage && <p>{errorMessage}</p>}
		</SignInLayout>
	);
};
