import { useLocation, useNavigate } from "react-router-dom";
import "../../../../styles.css";
import { AppBarButton } from "../app-bar-button/app-bar-button.component";
import { Stack } from "@mui/material";
import { GanttChart } from "lucide-react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { RolesEnum } from "@/dentlab/src/types/enums";

export const AppBarAppsList: React.FC = () => {
	const role = useCentralStore((state) => state.role ?? 2);
	const navigate = useNavigate();
	const location = useLocation();

	const outerSize = {
		width: "40px",
		height: "40px",
	};

	const innerSize = {
		width: "30px",
		height: "30px",
	};

	const pagePath = location.pathname.split("/")[1];

	return (
		<Stack gap={1.5}>
			{role <= RolesEnum.ADMIN && (
				<AppBarButton
					key="accounting"
					onClick={() => navigate(`/accounting`)}
					isSelected={pagePath === "accounting"}
					style={{
						backgroundColor: "var(--secondary-main)",
						...outerSize,
					}}
					tooltipTitle="Debitorenbuchhaltung"
				>
					<CalculateIcon
						sx={{
							...innerSize,
							color: "var(--common-white)",
						}}
					/>
				</AppBarButton>
			)}
			{role <= RolesEnum.ADMIN && (
				<AppBarButton
					key="statistics"
					onClick={() => navigate(`/statistics`)}
					isSelected={pagePath === "statistics"}
					style={{
						backgroundColor: "var(--secondary-main)",
						...outerSize,
					}}
					tooltipTitle="Statistik"
				>
					<BarChartIcon
						sx={{
							...innerSize,
							color: "var(--common-white)",
						}}
					/>
				</AppBarButton>
			)}
			<AppBarButton
				key="agenda"
				onClick={() => navigate(`/agenda`)}
				isSelected={pagePath === "agenda"}
				style={{
					backgroundColor: "var(--secondary-main)",
					...outerSize,
				}}
				tooltipTitle="Agenda"
			>
				<GanttChart
					style={{
						...innerSize,
						color: "var(--common-white)",
					}}
				/>
			</AppBarButton>
			{role <= RolesEnum.ADMIN && (
				<AppBarButton
					key="monthly-invoices"
					onClick={() => navigate(`/monthly-invoices`)}
					isSelected={pagePath === "monthly-invoices"}
					style={{
						backgroundColor: "var(--secondary-main)",
						...outerSize,
					}}
					tooltipTitle="Sammelrechnungen"
				>
					<ReceiptIcon
						sx={{
							...innerSize,
							color: "var(--common-white)",
						}}
					/>
				</AppBarButton>
			)}
			{role <= RolesEnum.ADMIN && (
				<AppBarButton
					key="job-document-templates"
					onClick={() => navigate(`/job-document-templates`)}
					isSelected={pagePath === "job-document-templates"}
					style={{
						backgroundColor: "var(--secondary-main)",
						...outerSize,
					}}
					tooltipTitle="Jumbos/Vorlagen"
				>
					<WorkspacesIcon
						sx={{
							...innerSize,
							color: "var(--common-white)",
						}}
					/>
				</AppBarButton>
			)}
		</Stack>
	);
};
