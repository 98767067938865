import { Checkbox, FormControlLabel } from "@mui/material";
import { InactiveReasonSelect } from "./inactive-reason.select";
import { FormGroupPropsValueChange } from "../../../form-types";

export const InactiveCheckboxAndSelect: React.FC<{
  formData: any;
  onValueChange: (valueChange: FormGroupPropsValueChange) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ formData, onValueChange, onCheckboxChange }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.is_inactive || false}
            onChange={onCheckboxChange}
            name="is_inactive"
            color="primary"
          />
        }
        label="Inaktiv"
        sx={{ my: 1 }}
      />
      {formData.is_inactive && (
        <InactiveReasonSelect
          value={formData.inactive_reason || ""}
          onChange={(value) =>
            onValueChange({ name: "inactive_reason", value: value })
          }
          width={"200px"}
        />
      )}
    </div>
  );
};
