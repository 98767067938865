import { JobDocumentTypeEnum } from "../../job-document/job-document.types";

export const NEW_DOCUMENT_OPTIONS = [
	{
		value: JobDocumentTypeEnum.Quotation,
		label: "Kostenvoranschlag",
	},
	{
		value: JobDocumentTypeEnum.DeliveryNote,
		label: "Lieferschein",
	},
	{
		value: JobDocumentTypeEnum.CreditNote,
		label: "Gutschrift",
	},
	{
		value: JobDocumentTypeEnum.MATERIALS,
		label: "Materialien",
	},
];
