import { useStorage } from "../hooks/useStorage";
import { useDatabaseFunctionsWithPromise } from "../hooks/useDatabase";
import {
	FileWithShare,
	SupabaseTableEnum,
	SupabaseShareEnitityViewEnum,
} from "@/lib/supabase/supabaseTypes";
import { StorageBucketsEnum } from "../types/enums";
import { v4 } from "uuid";
import { useCentralStore } from "../store/Central";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../store/Central/selectors";
import { useState } from "react";
import { supabase } from "@/lib/supabase";

export const useUploadJobFiles = () => {
	const { uploadFileWithProvidedPath } = useStorage();
	const { insertDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { organization, connectRelationshipId } = useCentralStore(
		(state) => ({
			organization: state.organization,
			connectRelationshipId: state.connectRelationshipId,
		})
	);
	const [jobFilesLookup, setJobFilesLookup] = useState<
		Record<string, FileWithShare>
	>({});
	const uploadFile = async (file: File, jobId: number) => {
		const filePath = `${v4()}/${file.name}`;
		const { success, pathName } = await uploadFileWithProvidedPath({
			fileBody: file,
			filePath: filePath,
		});

		if (!success) {
			showNotification({
				message: "Fehler beim Hochladen der Datei für den Auftrag",
				type: "error",
			});
			Logger.error("Error uploading file for job");
			return;
		}
		const { error, data } = await insertDataWithPromise(
			SupabaseTableEnum.FILES,
			[
				{
					file_name: file.name,
					bucket_name: StorageBucketsEnum.V1,
					path_name: pathName,
					job_id: jobId,
					organization_id: organization?.id,
				},
			]
		);

		if (error) {
			Logger.error("Error inserting file for job", error);
			return;
		}

		updateFile(data?.[0]);
	};

	const fetchFiles = async (jobId: number) => {
		setJobFilesLookup({});
		let filter = `organization_id.eq.${organization?.id}`;
		if (connectRelationshipId) {
			filter += `,connect_relationship_id.eq.${connectRelationshipId}`;
		}
		const { data, error } = await supabase
			.from(SupabaseShareEnitityViewEnum.FILES_WITH_SHARES)
			.select()
			.eq("job_id", jobId)
			.or(filter);

		if (error) {
			showNotification({
				message: "Fehler beim Abrufen der Dateien für den Auftrag",
				type: "error",
			});
			Logger.error("Error fetching files for job", error);
			return;
		}
		if (data?.length) {
			const filesLookup = data?.reduce(
				(acc: Record<string, FileWithShare>, row: FileWithShare) => {
					acc[row.id as string] = row;
					return acc;
				},
				{}
			);

			setJobFilesLookup(filesLookup);
		}
	};

	const updateFile = (file: FileWithShare) => {
		setJobFilesLookup((prev) => ({
			...prev,
			[file.id as string]: file,
		}));
	};

	return {
		uploadFile,
		files: Object.values(jobFilesLookup),
		fetchFiles,
		updateFile,
	};
};
