import { useToast } from "../../context/ToastContext";
import { useEffect, useRef, useState } from "react";
import { _validateField } from "./form-field-validation-functions";
import { Logger } from "../../../../lib/logger/Logger";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../useDatabase";
import { DBOperationResult } from "../../types/types";

// TODO: practitioner.form.tsx is an example of how useForm can be
// used to use the same form component for insert and update - this should
// become standard for any forms where the UI for insert and update is the same

/**
 * useForm - exposes functions to handle and submit form data, provides user feedback using showToast
 * @param customFields
 * @param supabaseTable
 * @param fetchData
 * @param fetchId
 *
 * @param setExternalIsPendingChanges - optional, allows to set isPendingChanges state outside the hook. This should be used when the useForm is used within a custom dialog.
 *
 * # form state
 * @returns formData: an object containing the current values of the form fields
 * @returns setFormData: a function to update the form data
 *
 * # functions to change form state
 * @returns handleInputChange: a function to handle changes to input fields
 * @returns handleCheckboxChange: a function to handle changes to checkbox fields
 * @returns handleValueChange: a function to handle changes to specific form fields
 *
 * # information about form state
 * @returns isPendingChanges: a boolean indicating whether the form data has been modified but not yet saved.
 * @return formErrors: an object containing the current errors of the form fields
 *
 * # functions to submit form data
 * @returns handleCreate: a function to create a new record in the Supabase table
 * @returns handleUpdate: a function to update an existing record in the Supabase table
 *
 * # initial form data state
 * If we set fetchData: false, fetchId null; the initialFormData state won't be set by useForm. To use checking
 * for changes (which requires initialFormData), we need to set the initialFormData state in the component.
 * @returns initialFormData: an object containing the initial values of the form fields
 * @returns setInitialFormData: a function to update the initial form data
 */
export function useForm<T>(
	customFields: any,
	supabaseTable: SupabaseTableEnum,
	fetchData: boolean = false,
	fetchId: any = null,
	entityName: string,
	setExternalIsPendingChanges?: (isPendingChanges: boolean) => void
) {
	const { showToast } = useToast();
	const {
		fetchDataWithPromise,
		insertDataWithPromise,
		updateDataWithPromise,
	} = useDatabaseFunctionsWithPromise();

	const [initialFormData, setInitialFormData] = useState<T>({
		...customFields,
	});
	const [formData, setFormData] = useState<T>({
		...customFields,
	});
	const [formErrors, setFormErrors] = useState<Record<string, string | null>>(
		{}
	);

	const [isPendingChanges, setisPendingChanges] = useState<boolean>(false);

	useEffect(() => {
		if (fetchData && fetchId) {
			_handleRead(fetchId);
		}
	}, [fetchData, fetchId]);

	const prevCustomFieldsRef = useRef(customFields);
	useEffect(() => {
		if (
			JSON.stringify(prevCustomFieldsRef.current) !==
			JSON.stringify(customFields)
		) {
			setFormData((prev) => ({
				...prev,
				...customFields,
			}));
			// Update the ref to the new customFields
			prevCustomFieldsRef.current = customFields;
		}
	}, [customFields]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		_updateFormData(event.target.name, event.target.value);
	};

	/**
	 * handleValueChange - gives control over specific form fields, e.g. for usage with select components
	 * @param name - the column name
	 * @param value - the value to set
	 */
	const handleValueChange = (name: string, value: any) => {
		_updateFormData(name, value);
	};

	const handleCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		_updateFormData(event.target.name, event.target.checked);
	};

	const handleCreate = async (
		event?: React.FormEvent
	): Promise<DBOperationResult> => {
		if (event) {
			event.preventDefault();
		}

		if (!_isFormValid()) {
			return { success: false, data: null, error: null, status: 400 };
		}

		const { success, data, error, status } = await insertDataWithPromise(
			supabaseTable,
			[formData]
		);

		if (success) {
			// reset form data after successful submission
			setFormData({ ...customFields });
			_handleSetIsPendingChanges(false);
			showToast(
				entityName ? `${entityName} hinzugefügt!` : "Hinzugefügt!",
				"success"
			);
		}
		return { success, data, error, status };
	};

	const handleUpdate = async (
		event: React.FormEvent | null,
		id: string | number | undefined
	): Promise<DBOperationResult> => {
		if (event) {
			event.preventDefault();
		}

		if (!_isFormValid()) {
			return { success: false, data: null, error: null, status: 400 };
		}
		if (!_hasFormChanged()) {
			return { success: false, data: null, error: null, status: 300 };
		}

		Logger.log("Submitting form data (UPDATE): ", formData);

		const { success, data, error, status } = await updateDataWithPromise(
			supabaseTable,
			[{ id: id, ...formData }]
		);

		if (success) {
			// do not reset form data here, because we want to keep the data in the form
			_handleSetIsPendingChanges(false);
			showToast(
				entityName ? `${entityName} aktualisiert!` : "Aktualisiert!",
				"success"
			);
		}
		return { success, data, error, status };
	};

	const _validateFieldAndSetError = (name: string, value: any) => {
		const error = _validateField(name, value);
		setFormErrors((prev) => ({
			...prev,
			[name]: error,
		}));
	};

	const _isFormValid = () => {
		const valid = Object.values(formErrors).every((x) => x === null);
		if (!valid) {
			showToast("Bitte korrigieren Sie die Fehler im Formular", "error");
		}
		return valid;
	};

	const _hasFormChanged = () => {
		const hasChanged =
			JSON.stringify(initialFormData) !== JSON.stringify(formData);
		if (!hasChanged) {
			Logger.info("Form has not changed");
		}
		return hasChanged;
	};

	/**
	 * handleRead - only used internally and not returned by useForm
	 * @param selectArgs
	 * @param id
	 * @returns
	 */
	const _handleRead = async (id: any) => {
		const { success, data, error } = await fetchDataWithPromise(
			supabaseTable,
			[
				{
					column: "id",
					value: id,
				},
			]
		);
		if (error) {
			return { success: false };
		}
		Logger.log("Received form data: ", data, error);
		setFormData(data[0]);
		setInitialFormData(data[0]);
		return { success: true, data: data[0], error: error };
	};

	const _updateFormData = (name: string, value: any) => {
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
		_validateFieldAndSetError(name, value);
		_handleSetIsPendingChanges(true);
	};

	const _handleSetIsPendingChanges = (isPendingChanges: boolean) => {
		if (setExternalIsPendingChanges) {
			setExternalIsPendingChanges(isPendingChanges);
		}
		setisPendingChanges(isPendingChanges);
	};

	const resetFormData = () => {
		setFormData(initialFormData);
		_handleSetIsPendingChanges(false);
	};

	return {
		// state
		formData,
		setFormData,

		// initial form data
		initialFormData,
		setInitialFormData,

		// functions to change state
		handleInputChange,
		handleCheckboxChange,
		handleValueChange,

		// functions to submit form data
		handleCreate,
		handleUpdate,

		// information about form state
		isPendingChanges,
		formErrors,

		// reset form data
		resetFormData,
	};
}
