import { ArticleTypeEnum } from "@/lib/supabase/supabaseEnums";

// This is a duplication of the articlte type enum
// must change if original article type enum changes
export const articleTypeOptions = [
	{ value: ArticleTypeEnum.MATERIAL, label: "Material" },
	{ value: ArticleTypeEnum.POSTAGE, label: "Porto" },
	{ value: ArticleTypeEnum.EXTERNAL_WORK, label: "Fremdarbeit" },
	{ value: ArticleTypeEnum.DISCOUNTS, label: "Abzug/Barrabatt" },
	{ value: ArticleTypeEnum.FIXED_RATE, label: "Arbeitspauschale" },
];

export const articleClusterOptions = [
	{ value: "2100", label: "2100 - Abutments/Distanzhülsen" },
	{ value: "2130", label: "2130 - Schrauben" },
	{ value: "2150", label: "2150 - Übrige Elemente" },
	{ value: "2200", label: "2200 - Legierungen/Lote" },
	{ value: "2300", label: "2300 - Konstruktionselemente" },
	{ value: "2400", label: "2400 - CAD/CAM" },
	{ value: "2900", label: "2900 - Diverses Material" },
	{ value: "3100", label: "3100 - AUSL.:Kronen/Brücken" },
	{ value: "3110", label: "3110 - AUSL.:Implantat-Arbeiten" },
	{ value: "3170", label: "3170 - AUSL.:CAD/CAM" },
	{ value: "3500", label: "3500 - AUSL.:Prothetik" },
	{ value: "3700", label: "3700 - AUSL.:Kieferorthopädie" },
];
