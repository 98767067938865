import {
	DesktopEntityTypeWithSystems,
	DesktopJobRequestEntityType,
	DesktopJobResponseEntityType,
	DesktopSystemEntityType,
	DesktopSystemEntityTypeInsert,
} from "@/lib/supabase/supabaseTypes";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import {
	dateOlderThanSeconds,
	handleDatabaseOperation,
} from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { DesktopSystemRequestTypes, State } from "./types";
import { initialState } from "./utils";
import { DesktopSystemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { showNotification } from "../Central/selectors";

const VISIBLE_DESKTOP_JOB_REQUESTS = [
	DesktopSystemRequestTypes.PrintDocument,
	DesktopSystemRequestTypes.PrintLabels,
	DesktopSystemRequestTypes.ScanDocument,
];

export interface DesktopSystemEntityTypeWithStatus
	extends DesktopSystemEntityType {
	active: boolean;
	desktopName: string;
}

interface Actions {
	fetchDesktops: (organizationId: string) => Promise<void>;
	addDesktopSystem: (
		system: DesktopSystemEntityTypeInsert
	) => Promise<null | number>;
	deleteDesktop: (desktopId: string) => Promise<void>;
	deleteDesktopSystem: (desktopSystemId: number) => Promise<void>;
	fetchDesktopSystem: (desktopSystemId: string) => Promise<void>;
	updateDesktopSystem: (
		desktopSystemId: number,
		system: Partial<DesktopSystemEntityType>
	) => Promise<void>;
	fetchRequests: (organizationId: string, cursor: number) => Promise<void>;
	getPrintersWithStatus: () => DesktopSystemEntityTypeWithStatus[];
	getSystemIdToDesktopIdLookup: () => Record<number, string>;
}

export type DesktopsSlice = State & Actions;

export const createDesktopsStore: StateCreator<DesktopsSlice> = (set, get) => ({
	...initialState,

	fetchDesktops: async (organizationId: string) => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOPS)
				.select("*, desktop_systems (*)")
				.eq("organization_id", organizationId)
		);
		if (error) {
			Logger.error(error);
			return;
		}
		set({ desktopsWithSystems: data as DesktopEntityTypeWithSystems[] });
	},

	addDesktopSystem: async (
		system: DesktopSystemEntityTypeInsert
	): Promise<null | number> => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_SYSTEMS)
				.insert([system])
				.select("*")
		);
		if (error) {
			Logger.error(error);
			showNotification({
				message: "Fehler beim Hinzufügen des Systems",
				type: "error",
			});
			return null;
		}
		set((state) => ({
			desktopsWithSystems: state.desktopsWithSystems.map((desktop) => {
				if (desktop.id === data[0]?.desktop_id) {
					return {
						...desktop,
						desktop_systems: [...desktop.desktop_systems, data[0]],
					};
				}
				return desktop;
			}),
		}));
		return data[0]?.id;
	},

	deleteDesktop: async (desktopId: string) => {
		await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOPS)
				.delete()
				.eq("id", desktopId)
		);
		set((state) => ({
			desktopsWithSystems: state.desktopsWithSystems.filter(
				(desktop) => desktop.id !== desktopId
			),
		}));
	},

	deleteDesktopSystem: async (desktopSystemId: number) => {
		await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_SYSTEMS)
				.delete()
				.eq("id", desktopSystemId)
		);
		set((state) => ({
			desktopsWithSystems: state.desktopsWithSystems.map((desktop) => ({
				...desktop,
				desktop_systems: desktop.desktop_systems.filter(
					(system) => system.id !== desktopSystemId
				),
			})),
		}));
	},

	fetchDesktopSystem: async (desktopSystemId: string) => {
		// Desktop systems and desktop requests are being fetched separately
		// because desktop requests need to be limited and ordered
		const { data: desktopSystem, error: desktopSystemError } =
			await handleDatabaseOperation(
				supabase
					.from(SupabaseTableEnum.DESKTOP_SYSTEMS)
					.select()
					.eq("id", desktopSystemId)
			);

		if (desktopSystemError) {
			Logger.error(desktopSystemError);
			return;
		}

		const {
			data: desktopSystemRequests,
			error: desktopSystemRequestsError,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_JOB_REQUESTS)
				.select("*, desktop_job_responses (*)")
				.eq("desktop_system_id", desktopSystemId)
				.in("type", VISIBLE_DESKTOP_JOB_REQUESTS)
				.order("created_at", { ascending: false })
				.limit(20)
		);

		if (desktopSystemRequestsError) {
			Logger.error(desktopSystemRequestsError);
			return;
		}

		set({
			selectedDesktopSystem: {
				...desktopSystem[0],
				desktop_job_requests: desktopSystemRequests.map(
					(
						request: DesktopJobRequestEntityType & {
							desktop_job_responses: DesktopJobResponseEntityType[];
						}
					) => ({
						...request,
						desktop_job_response: request.desktop_job_responses[0],
					})
				),
			},
		});
	},

	updateDesktopSystem: async (
		desktopSystemId: number,
		system: Partial<DesktopSystemEntityType>
	) => {
		set((state) => ({
			desktopsWithSystems: state.desktopsWithSystems.map((desktop) => {
				return {
					...desktop,
					desktop_systems: desktop.desktop_systems.map(
						(desktopSystem) => {
							if (desktopSystem.id === desktopSystemId) {
								return {
									...desktopSystem,
									system,
								};
							}
							return desktopSystem;
						}
					),
				};
			}),
		}));

		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_SYSTEMS)
				.update(system)
				.eq("id", desktopSystemId)
		);

		if (error) {
			Logger.error(error);
			return;
		}
	},

	fetchRequests: async (organizationId: string, cursor: number) => {
		const { data, error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_JOB_REQUESTS)
				.select(
					"*, desktop_systems(*,desktops(*)), desktop_job_responses(*)"
				)
				.eq("desktop_systems.desktops.organization_id", organizationId)
				.in("type", VISIBLE_DESKTOP_JOB_REQUESTS)
				.order("created_at", { ascending: false })
				.range(cursor, cursor + 19)
		);

		if (error) {
			Logger.error(error);
			return;
		}

		set({
			desktopRequests: data,
		});
	},

	getPrintersWithStatus: () => {
		const { desktopsWithSystems } = get();
		if (!desktopsWithSystems) {
			return [];
		}
		const printersWithStatus = desktopsWithSystems.flatMap((desktop) =>
			dateOlderThanSeconds(new Date(desktop.last_active_at), 60)
				? desktop.desktop_systems
						.filter(
							(system) =>
								system.type === DesktopSystemTypeEnum.PRINTER
						)
						.map((system) => ({
							...system,
							active: true,
							desktopName: desktop.name,
						}))
				: desktop.desktop_systems
						.filter(
							(system) =>
								system.type === DesktopSystemTypeEnum.PRINTER
						)
						.map((system) => ({
							...system,
							active: false,
							desktopName: desktop.name,
						}))
		);
		return printersWithStatus;
	},

	getSystemIdToDesktopIdLookup: () => {
		const lookup: Record<number, string> = get().desktopsWithSystems.reduce(
			(acc, desktop) => {
				const newAcc = desktop.desktop_systems.reduce(
					(acc, system) => ({
						...acc,
						[system.id]: desktop.id,
					}),
					{}
				);
				return { ...acc, ...newAcc };
			},
			{}
		);
		Logger.log(
			"SystemId to DesktopId lookup",
			get().desktopsWithSystems,
			lookup
		);
		return lookup;
	},
});
