import { Autocomplete, TextField } from "@mui/material";
import { Logger } from "../../../../../lib/logger/Logger";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";
import { useToast } from "../../../context/ToastContext";
import { useCallback, useEffect, useState } from "react";
import { PractsWithCtViewType } from "../../../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../../../../../dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

export type PractitionerOptionType = {
	label: string;
	name: string;
	value: number;
};

export const PractitionerEmailsAutocomplete: React.FC<{
	selectedPractitioners: PractitionerOptionType[];
	setSelectedPractitioners: (practitioners: PractitionerOptionType[]) => void;
}> = ({ selectedPractitioners, setSelectedPractitioners }) => {
	const { showToast } = useToast();
	const { client } = useCentralStore();
	const [practitionerOptions, setPractitionerOptions] = useState<
		PractitionerOptionType[]
	>([]);

	// get practitioners of this client
	// because as of Nov 2023, only one dentCONNECT user per client is allowed,
	// we don't need to have state of what practitioners were selected.
	const {
		practitioners: { practitionersByClientIdLookup },
	} = usePractitionersContext();

	const getPractitioners = useCallback(async () => {
		const practitioners = client?.id
			? practitionersByClientIdLookup[client?.id]
			: [];

		// if there's not practitioners, something went wrong
		if (!practitioners) {
			Logger.error("No practitioners found for client", client?.id);
			showToast("Keine Behandler gefunden", "error");
		}

		const someoneIsConnected = practitioners.some(
			(row: PractsWithCtViewType) => row.connect_relationship_id
		);

		if (!someoneIsConnected) {
			showNotification({
				message:
					"Es wurde noch kein Behandler mit dentCONNECT verbunden",
				type: "warning",
			});
		}

		// bring practitioners into the right format
		const connectedPracts = practitioners
			.filter((row) => row.connect_relationship_id)
			.map((row) => ({
				label: row.email ?? "",
				name: `${row?.title || ""} ${row.first_name} ${row.last_name}`,
				value: row.id as number,
			}));

		setPractitionerOptions(connectedPracts);

		// TODO: this might be removed if there should no longer be a default email selected
		if (connectedPracts.length > 0 && selectedPractitioners.length === 0) {
			Logger.log(
				"PractitionerEmailsAutocomplete: setting default practitioner"
			);
			setSelectedPractitioners([connectedPracts[0]]);
		}
	}, [practitionersByClientIdLookup, client?.id]);

	useEffect(() => {
		getPractitioners();
	}, [getPractitioners]);

	if (practitionerOptions.length === 0) {
		return null;
	}
	return (
		<Autocomplete
			disablePortal
			multiple
			options={practitionerOptions}
			sx={{
				marginTop: "1rem",
			}}
			renderInput={(params) => (
				<TextField {...params} label="Personen benachrichtigen" />
			)}
			value={selectedPractitioners}
			onChange={(event, newValue) => {
				setSelectedPractitioners(newValue);
			}}
			disabled
		/>
	);
};
