import { UserAvatarComponent } from "../components/user-avatar-component";
import { Box, Button, Paper, Typography } from "@mui/material";
import { ProfileForm_USER } from "../forms/form-components/user-profile.form";
import { useCentralStore } from "../store/Central";
import { Check } from "lucide-react";

export const AccountPage: React.FC = () => {
	const handleSignOut = useCentralStore((state) => state.handleSignOut);
	const { setMFADialog, mfaEnrolled, mfaVerified } = useCentralStore(
		(state) => ({
			setMFADialog: state.setMFADialog,
			mfaEnrolled: state.mfaEnrolled,
			mfaVerified: state.mfaVerified,
		})
	);

	if (!mfaVerified) return null;

	return (
		<Box sx={{ mt: 6 }}>
			<div className="flex items-center justify-between pr-8">
				<Typography sx={{ m: "30px" }} variant="h4" gutterBottom>
					Kontoeinstellungen
				</Typography>

				{!mfaEnrolled ? (
					<Button onClick={() => setMFADialog(true)} className="">
						Mehrfaktor-Authentifizierung einrichten
					</Button>
				) : (
					<div className=" flex gap-2 items-center bg-green-100 text-green-700 rounded p-2 ">
						<span>
							<Check size={22} />
						</span>
						<span>Mehrfaktor-Authentifizierung eingerichtet</span>
					</div>
				)}
			</div>
			<Paper sx={{ p: "30px" }}>
				<Box sx={{ my: "20px" }}>
					<UserAvatarComponent />
				</Box>
				<ProfileForm_USER />
				<Button
					sx={{ my: 5 }}
					size="small"
					variant="outlined"
					color="primary"
					onClick={handleSignOut}
				>
					Ausloggen
				</Button>
			</Paper>
		</Box>
	);
};
