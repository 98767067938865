import React from "react";
import { EditableTitle } from "../../../../../../components/src/editable-title/editable-title.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { useForm } from "react-hook-form";

export const JobTitleForm: React.FC = () => {
	const { job, updateJob } = useJobStore((state) => ({
		job: state.job,
		updateJob: state.updateJob,
	}));

	const { register, watch, handleSubmit, setValue, reset } = useForm({
		defaultValues: {
			title: job?.title,
		},
	});

	const onSubmit = async () => {
		handleSubmit((data) => {
			if (data.title && job) updateJob(job, "title", data.title);
		})();
	};

	return (
		<>
			<EditableTitle
				{...register("title")}
				value={watch("title") ?? job?.title ?? ""}
				onSubmit={onSubmit}
				onReset={reset}
				loading={job?.title == undefined}
				onChange={(e) => setValue("title", e.target.value)}
			/>
		</>
	);
};
