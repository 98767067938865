import {
	ParsedJobItemFromView,
	calculateJobItemAmount,
} from "@/dentlab/src/lib/utils/calculate";
import "./custom-cells.css";
import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";

// TODO: right now we only have "tariff" and "article"
// But: "Arbeit", "Pauschalzuschlag für Arbeit", "Fremdarbeiten", "Zähne/Material", "Rückvergütung/Barrabatt", "Expresszuschlag/Porto". Codes (A, F, L, M, R, Z).
export const AmountCustomCell = (props: { dataItem: JobItemEntityType }) => {
	const { dataItem } = props;
	const amount = calculateJobItemAmount(dataItem as ParsedJobItemFromView); // TODO: this is a hack, we should not need to cast here
	return (
		<td className="custom-td">
			<div className="cell">{amount.toFixed(2)}</div>
		</td>
	);
};
