export const ClientNotFoundComponent: React.FC = () => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
				width: "100%",
				flexDirection: "column",
				gap: "6rem",
			}}
		>
			<div>
				Dieser Auftraggeber existiert nicht. Bitte wählen Sie einen
				gültigen Auftraggeber.
			</div>
		</div>
	);
};
