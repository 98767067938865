import React, { useRef, useState, useEffect, useMemo } from "react";
import { Loading } from "@/components/src/animations/loading";
import { InputBase, ClickAwayListener, Popper, MenuItem } from "@mui/material";
import { Logger } from "../../../lib/logger/Logger";

// TODO: add possibility to add options with an Autocomplete
// Could use AutocompleteTextField component from /components/autocomplete-text-field.component.tsx
/* TODO: DW-166 TextField should match the style of the parent */
// TODO: add handling "Esc" key press and make sure that it blurs

/**
 * EditableTitle
 * @param name name prop for TextField
 * @param value value prop for TextField
 * @param onChange onChange prop for TextField
 * @param onBlur onBlur prop for TextField
 * @param isCode if true, the title will be displayed using # formatting
 */
export const EditableTitle: React.FC<{
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSubmit: () => void;
	onReset: () => void;
	type?: "title" | "code" | "text";
	fontSize?: string;
	loading?: boolean;
	disabled?: boolean;
	options?: string[];
}> = ({
	name,
	value,
	onChange,
	onSubmit,
	onReset,
	type = "title",
	fontSize = "var(--font-size-title-sm)",
	loading = false,
	disabled = false,
	options = [],
	// Additional props that will be passed to the input
	...props
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

	const styles = useMemo(() => {
		let color = "var(--neutral-900)";
		let fontWeight = "var(--font-weight-title)";
		if (type === "code") {
			color = "var(--neutral-600)";
		} else if (type === "text") {
			fontWeight = "500";
		}
		return {
			input: {
				borderRadius: "4px",
				color: color,
				fontSize: fontSize,
				fontWeight: fontWeight,
				paddingLeft: "5px",
				"&:hover": {
					cursor: "pointer",
					backgroundColor: "var(--tertiary-100)",
				},
				"&:focus": {
					cursor: "text",
					border: "var(--border-md)",
					borderColor: "var(--tertiary-500)",
					backgroundColor: "transparent",
				},
			},
		};
	}, [type, fontSize]);

	useEffect(() => {
		updateInputWidth(value);
	}, [value]);

	useEffect(() => {
		setAnchorEl(isFocused ? inputRef.current : null);
	}, [isFocused]);

	const updateInputWidth = (value: string) => {
		if (inputRef.current) {
			const context = document.createElement("canvas").getContext("2d");
			context!.font = getComputedStyle(inputRef.current).font;
			const textWidth = context!.measureText(value).width;
			inputRef.current.style.width = `${textWidth + 10}px`; // Additional 10px for padding
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && inputRef.current) {
			inputRef.current.blur();
			onSubmit();
		} else if (event.key === "Escape" && inputRef.current) {
			Logger.log("handleKeyDown", "Escape");
			inputRef.current.blur();
			onReset();
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleClickAway = () => {
		if (isFocused) {
			onSubmit();
			setIsFocused(false);
		}
	};

	const inputRef = useRef<HTMLInputElement>(null);

	if (loading) return <Loading type="skeleton" />;
	return (
		<div>
			<ClickAwayListener onClickAway={handleClickAway}>
				<InputBase
					name={name}
					value={value || ""}
					onChange={onChange}
					sx={styles}
					onKeyDown={handleKeyDown}
					inputRef={inputRef}
					onFocus={handleFocus}
					disabled={disabled}
					{...props}
				/>
			</ClickAwayListener>
			{options.length > 0 && (
				<Popper id={name} open={isFocused} anchorEl={anchorEl}>
					<div className="bg-white w-full">
						{options.map((option) => (
							<MenuItem
								key={option}
								value={option}
								onClick={() => {
									onChange({
										target: { value: option },
									} as React.ChangeEvent<HTMLInputElement>);
									handleClickAway();
								}}
							>
								{option}
							</MenuItem>
						))}
					</div>
				</Popper>
			)}
		</div>
	);
};
