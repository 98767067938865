import { useNavigate, useParams } from "react-router-dom";
import { JobList } from "../../../../components/job-list/job-list.component";

export const ClientJobList = () => {
	const navigate = useNavigate();
	const { clientId } = useParams<{ clientId: string }>();

	const handleRefresh = () => {
		navigate(`/client/${clientId}`);
	};

	return <JobList onRefresh={handleRefresh} />;
};
