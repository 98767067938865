import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

interface NumberSelectProps extends SelectProps {
  label: string; // require label prop
  number: number;
}

export const NumberSelect: React.FC<NumberSelectProps> = (props) => {
  const options = Array.from(Array(props.number).keys()).map((number) => ({
    value: number + 1,
    label: (number + 1).toString(),
  }));

  return <CustomSelect options={options} {...props} inputLabel={props.label} />;
};
