import React from "react";
import "../../tokens.css";

export const Typography: React.FC<{
	variant:
		| "title-lg"
		| "title-md"
		| "title-sm"
		| "subtitle-lg"
		| "subtitle-md"
		| "subtitle-sm"
		| "body-lg"
		| "body-md"
		| "body-sm"
		| "caption";
	children: React.ReactNode;
}> = ({ variant, children }) => {
	switch (variant) {
		case "title-lg":
			return <h1 className="title-lg">{children}</h1>;
		case "title-md":
			return <h2 className="title-md">{children}</h2>;
		case "title-sm":
			return <h3 className="title-sm">{children}</h3>;
		case "subtitle-lg":
			return <h4 className="subtitle-lg">{children}</h4>;
		case "subtitle-md":
			return <h5 className="subtitle-md">{children}</h5>;
		case "subtitle-sm":
			return <h6 className="subtitle-sm">{children}</h6>;
		case "body-lg":
			return <p className="body-lg">{children}</p>;
		case "body-md":
			return <p className="body-md">{children}</p>;
		case "body-sm":
			return <p className="body-sm">{children}</p>;
		case "caption":
			return <p className="caption">{children}</p>;
		default:
			return <p>{children}</p>;
	}
};
