import { useNavigate, useParams } from "react-router-dom";
import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import { useEffect, useState } from "react";
import {
	DesktopSystemRequestCard,
	LoadingDesktopSystemRequestCard,
} from "./desktop-system-components/desktop-system-request-card.component";
import { Button } from "@mui/material";
import { DesktopSystemSettings } from "./desktop-system-components/desktop-system-settings.component";
import ArrowBack from "@mui/icons-material/ArrowBack";

export const DesktopSystemPage: React.FC = () => {
	// get system id from route
	const { desktopSystemId } = useParams();
	const [showMore, setShowMore] = useState(false);
	const { selectedDesktopSystem, fetchDesktopSystem } = useDesktopsStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (desktopSystemId) {
			fetchDesktopSystem(desktopSystemId);
		}
	}, [desktopSystemId, fetchDesktopSystem]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				margin: "1rem",
			}}
		>
			<Button
				sx={{
					width: 80,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gap: "0.75rem",
				}}
				onClick={() => {
					navigate(-1);
				}}
			>
				<ArrowBack />
				Zurück
			</Button>
			<div>
				<DesktopSystemSettings />
			</div>
			<div>
				<div
					style={{
						fontSize: "1.25rem",
						fontWeight: "semi-bold",
					}}
				>
					Letzte Anfragen
				</div>
				<div
					style={{
						display: "flex",
						gap: "1rem",
						flexDirection: "column",
						marginTop: "1.5rem",
						alignItems: "center",
						width: "100%",
					}}
				>
					{!selectedDesktopSystem
						? Array.from({ length: 6 }).map(() => (
								<LoadingDesktopSystemRequestCard />
							))
						: selectedDesktopSystem.desktop_job_requests
								?.slice(
									0,
									showMore
										? selectedDesktopSystem
												.desktop_job_requests.length
										: 6
								)
								.map((request) => {
									return (
										<DesktopSystemRequestCard
											request={request}
											response={
												request.desktop_job_response
											}
										/>
									);
								})}
					{selectedDesktopSystem?.desktop_job_requests?.length ===
						0 && <div>Keine Anfragen gefunden</div>}{" "}
					<Button
						disabled={
							selectedDesktopSystem?.desktop_job_requests
								?.length == undefined ||
							selectedDesktopSystem?.desktop_job_requests
								.length <= 6
						}
						onClick={() => setShowMore((prev) => !prev)}
						style={{
							width: "100px",
						}}
					>
						{showMore ? "Weniger anzeigen" : "Mehr anzeigen"}
					</Button>
				</div>
			</div>
		</div>
	);
};
