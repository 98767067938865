import { useJobDocumentActions } from "@/dentlab/src/hooks/actions/useJobDocumentActions";
import { JobDocumentWithFiles } from "../job-document.component";
import { JobStatusEnum } from "@/lib/types/job";
import { JobDocumentTypeEnum } from "../job-document.types";
import { CustomButtonWithAnchoredMenu } from "@/dentlab/src/components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "./job-document-customMenuItem.component";
import { PermissionStatus } from "@/dentlab/src/hooks/actions/actions-hooks-types";
import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "@/dentlab/src/pdf-templates/document-template.types";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { useNavigate } from "react-router-dom";

interface DocumentOption {
	text: string;
	action: () => void | Promise<void>;
	permission: PermissionStatus;
}

export const JobDocumentOptionsMenu: React.FC<{
	jobDocument: JobDocumentWithFiles;
	jobItems: JobItemEntityType[];
	onExport: (templateType: PdfTemplateType | XmlTemplateType) => void;
}> = ({ jobDocument, jobItems, onExport }) => {
	const { addJobDocumentTemplateFromJobDocument } = useCentralStore(
		(state) => ({
			addJobDocumentTemplateFromJobDocument:
				state.addJobDocumentTemplateFromJobDocument,
		})
	);
	const navigate = useNavigate();
	const {
		isArchiveJobDocumentAllowed,
		isUnarchiveJobDocumentAllowed,
		archiveJobDocument,
		unarchiveJobDocument,
		isExportAuftragsblattAllowed,
		isExportRechnungAllowed,
		isExportBarzahlungAllowed,
		isExportKartenzahlungAllowed,
		isExportXmlAllowed,
	} = useJobDocumentActions();

	const { duplicateJobDocument, isCreateJobDocumentAllowed } = useJobStore(
		(state) => ({
			duplicateJobDocument: state.duplicateJobDocument,
			isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
		})
	);
	const timers = useJobStore((state) => state.timers);

	const documentOptions: DocumentOption[] = [
		{
			text: "Dokument als Vorlage speichern",
			action: async () => {
				if (Object.entries(timers).length > 0) {
					showNotification({
						message:
							"Bitte warten, bis die Elemente gespeichert wurden",
						type: "error",
					});
					return;
				}
				await addJobDocumentTemplateFromJobDocument(jobDocument.id);
				showNotification({
					message: "Neue Jobgruppe erstellt",
					type: "success",
					action: {
						label: "Weiterleiten",
						onClick: () => navigate("/job-document-templates"),
					},
				});
			},
			permission: {
				isAllowed: true,
				explanation: "",
			},
		},
	];

	if (jobDocument.status === JobStatusEnum.ARCHIVED) {
		documentOptions.push({
			text: "Archivierung aufheben",
			action: () => unarchiveJobDocument(jobDocument),
			permission: isUnarchiveJobDocumentAllowed(jobDocument),
		});
	} else {
		documentOptions.push({
			text: "Dokument archivieren",
			action: () => archiveJobDocument(jobDocument),
			permission: isArchiveJobDocumentAllowed(jobDocument),
		});
	}

	documentOptions.push({
		text: "Dokument duplizieren",
		action: async () =>
			await duplicateJobDocument(jobDocument, jobItems ?? []),
		permission: isCreateJobDocumentAllowed(
			jobDocument.type as JobDocumentTypeEnum
		),
	});

	if (jobDocument.type !== JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Zu Lieferschein duplizieren",
			action: async () =>
				await duplicateJobDocument(
					jobDocument,
					jobItems ?? [],
					JobDocumentTypeEnum.DeliveryNote
				),
			permission: isCreateJobDocumentAllowed(
				JobDocumentTypeEnum.DeliveryNote
			),
		});
	}

	if (
		jobDocument.type === JobDocumentTypeEnum.Quotation ||
		jobDocument.type === JobDocumentTypeEnum.DeliveryNote
	) {
		documentOptions.push({
			text: "Auftragsblatt exportieren",
			action: () => onExport(PdfTemplateType.AUFTRAGSBLATT),
			permission: isExportAuftragsblattAllowed(jobDocument),
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Rechnung erstellen",
			action: () => onExport(PdfTemplateType.RECHNUNG),
			permission: isExportRechnungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Barzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.BARZAHLUNGSQUITTUNG),
			permission: isExportBarzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Kartenzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.KARTENZAHLUNGSQUITTUNG),
			permission: isExportKartenzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "SV LS ELNF erstellen",
			action: () => onExport(XmlTemplateType.LIEFERSCHEIN_XML),
			permission: isExportXmlAllowed(jobItems),
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.Quotation) {
		documentOptions.push({
			text: "SV KV ELNF erstellen",
			action: () => onExport(XmlTemplateType.KOSTENVORANSCHLAG_XML),
			permission: isExportXmlAllowed(jobItems),
		});
	}
	return (
		<CustomButtonWithAnchoredMenu>
			{documentOptions.map((d) => {
				const { isAllowed, explanation } = d.permission;
				return (
					<div key={d.text}>
						<CustomMenuItem
							title={isAllowed ? "" : explanation}
							menuFunction={d.action}
							disabled={isAllowed === false}
							text={d.text}
							placement="left"
						/>
					</div>
				);
			})}
		</CustomButtonWithAnchoredMenu>
	);
};
