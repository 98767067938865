import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database, supabase } from "../../../../../../lib/supabase";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../../../lib/logger/Logger";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

interface PractitionerFormPropsInsert extends FormProps {
	clientId: string;
	practitionerId?: undefined;
}

interface PractitionerFormPropsUpdate extends FormProps {
	practitionerId: number;
	clientId?: undefined;
}

export type PractitionerFormProps =
	| PractitionerFormPropsInsert
	| PractitionerFormPropsUpdate;

export const PractitionerForm: React.FC<PractitionerFormProps> = ({
	onSave = (pract: any) => {},
	submitRef = null,
	clientId = undefined,
	practitionerId = undefined,
}) => {
	const isUpdate = clientId ? false : true;

	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
		client: state.client,
		upsertClient: state.upsertClient,
	}));

	// Set values for useForm based on whether form is used for update or insert
	const useFormCustomFields = isUpdate
		? {}
		: {
				organization_id: organization?.id,
				client_id: clientId,
			};
	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? practitionerId : undefined;

	// useForm hook
	const {
		handleInputChange,
		handleCreate,
		formData,
		formErrors,
		handleValueChange,
		handleUpdate,
	} = useForm<Database["public"]["Tables"]["practitioners"]["Row"]>(
		useFormCustomFields,
		SupabaseTableEnum.PRACTITIONERS,
		useFormFetchData,
		useFormFetchId,
		"Behandler"
	);

	const handleUpdateSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleUpdate(event, useFormFetchId);
	};

	const handleInsertSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!organization?.id || !formData.email)
			return Logger.error(
				"PractitionerForm",
				"onSubmit",
				"No organizationId"
			);
		const { data: practitionerExists } = await supabase
			.from(SupabaseTableEnum.PRACTITIONERS)
			.select("*")
			.eq("email", formData.email)
			.eq("organization_id", organization?.id);

		if (practitionerExists?.length) {
			showNotification({
				message:
					"Ein Behandler mit dieser E-Mail-Adresse existiert bereits",
				type: "error",
			});
			return;
		}

		const { data, error } = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		if (data) {
			onSave(data[0]);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<TitleAndNameGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onValueChange={(args) =>
						handleValueChange(args.name, args.value)
					}
				/>
				<div
					style={{
						paddingTop: "1rem",
						display: "flex",
						flexDirection: "row",
						gap: "20px",
					}}
				>
					<CustomTextField
						label="E-Mail"
						value={formData.email}
						onChange={(event) =>
							handleValueChange("email", event.target.value)
						}
						disabled={isUpdate}
						validationError={formErrors.email}
					/>
				</div>
			</Box>

			{!submitRef && (
				<Button type="submit" variant="contained">
					Speichern
				</Button>
			)}
		</form>
	);
};
