import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { DialogPageWithToolbar } from "../dialog-page-with-toolbar/dialog-page-with-toolbar.component";
import { GoBackButton } from "../../../buttons/go-back-button.component";
import { FormProps } from "../../../../forms/form-types";
import { useCustomDialogContext } from "../../custom-dialog/custom-dialog.component";
import {
	ArticleEntityType,
	ClientEntityType,
	TariffEntityType,
} from "@/lib/supabase/supabaseTypes";

/**
 * DialogPageWithAddEntityForm - provides a dialog with an "add entity" button that opens a form dialog passed as a prop
 * @param tools - tools to be displayed in the toolbar
 * @param children - children to be displayed in the dialog main page, e.g. list of entities
 * @param FormComponent - form component to be displayed in the dialog form page
 * @param formAddButtonLabel - label of the button that opens the form dialog
 * @param onIsFormOpenChange - makes this form dialog controlled 
 * @param isFormOpen - makes this form dialog controlled
 * Note: 
 * - To add "edit" functionality, use controlled isFormOpen and pass the id of the entity via formProps to edit to the form
 * - formProps is also used to determine the title displayed for the form (formAddTitle vs formUpdateTitle and their fallbacks)
 * @example  
 * <DialogPageWithAddEntityForm
      FormComponent={BankAccountForm}
      onClose={onClose}
      tools={<></>}
      isFormOpen={isFormOpen}
      onIsFormOpenChange={(isFormOpen) => {
        setIsFormOpen(isFormOpen);
        setBankAccountId(undefined);
      }}
      formAddButtonLabel="Neues Konto"
      formProps={{
        bankAccountId: bankAccountId,
      }}
    >
      <BankAccountList
        onEdit={(bankAccountId: any) => {
          setIsFormOpen(true);
          setBankAccountId(bankAccountId);
        }}
      />
    </DialogPageWithAddEntityForm>
*/
export const DialogPageWithAddEntityForm: React.FC<{
	onClose: () => void;
	children: React.ReactNode;
	FormComponent: React.FC<FormProps>;
	tools: React.ReactNode;
	onIsFormOpenChange?: (isFormOpen: boolean) => void;
	isFormOpen?: boolean;
	formAddButtonLabel?: string;
	formProps?: Record<string, string | undefined>;
	formAddTitle?: string;
	formUpdateTitle?: string;
	onAddEntity?:
		| ((entity: ClientEntityType) => void)
		| ((entity: ArticleEntityType) => void)
		| ((entity: TariffEntityType) => void);
}> = ({
	onClose,
	children,
	FormComponent,
	tools,
	onIsFormOpenChange,
	isFormOpen,
	formAddButtonLabel,
	formProps,
	formAddTitle,
	formUpdateTitle,
	onAddEntity,
}) => {
	const [isFormPageOpen, setIsFormPageOpen] = useState(isFormOpen || false);
	const { setIsCloseAttempt, isPendingChanges } = useCustomDialogContext();

	useEffect(() => {
		setIsFormPageOpen(isFormOpen || false);
	}, [isFormOpen]);

	const handleOpenAddClient = () => {
		onIsFormOpenChange ? onIsFormOpenChange(true) : setIsFormPageOpen(true);
	};

	const handleCloseAddEntityForm = () => {
		onIsFormOpenChange
			? onIsFormOpenChange(false)
			: setIsFormPageOpen(false);
	};

	const handleSave = (entity: any) => {
		if (onAddEntity) {
			onAddEntity(entity);
		}
		handleCloseAddEntityForm();
	};

	const handleGoBack = () => {
		if (isPendingChanges) {
			setIsCloseAttempt(true);
		} else {
			handleCloseAddEntityForm();
		}
	};

	if (isFormPageOpen) {
		return (
			<DialogPageWithToolbar
				onClose={onClose}
				toolbar={
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							height: "100%",
							paddingLeft: "1.5rem",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<div>
							<GoBackButton
								onClick={handleGoBack}
								size="small"
								label=""
							/>
						</div>
						<div className="font-medium text-base text-gray-800">
							{formProps?.id
								? formUpdateTitle || "Bearbeiten"
								: formAddTitle || "Neuer Eintrag"}
						</div>
					</div>
				}
			>
				<FormComponent onSave={handleSave} {...formProps} />
			</DialogPageWithToolbar>
		);
	} else {
		return (
			<DialogPageWithToolbar
				onClose={onClose}
				toolbar={
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							// quick fix because for some reason height: 100% did not work
							// (2.8rem is taken from and to match DialogPageWithToolbar)
							minHeight: "2.8rem",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								marginLeft: "15px",
							}}
						>
							{tools}
						</div>
						<Button
							variant="contained"
							color="primary"
							size="small"
							sx={{ mr: "15px" }}
							onClick={handleOpenAddClient}
							data-testid="dialog-page-add-entity-button"
						>
							{formAddButtonLabel || "Neuer Eintrag"}
						</Button>
					</div>
				}
			>
				{children}
			</DialogPageWithToolbar>
		);
	}
};
