export const compressImage = async (
	file: File,
	quality: number
): Promise<File> => {
	const dataUrl = await fileToDataUrl(file);
	const img = await loadImage(dataUrl);
	const canvas = drawImageToCanvas(img);
	const blob = await canvasToBlob(canvas, quality);
	return createCompressedFile(blob, file);
};

const fileToDataUrl = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
};

const loadImage = (dataUrl: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = dataUrl;
		img.onload = () => resolve(img);
		img.onerror = (error) => reject(error);
	});
};

const drawImageToCanvas = (img: HTMLImageElement): HTMLCanvasElement => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d")!;
	canvas.width = 300; // Set the width to whatever size you want
	canvas.height = (canvas.width * img.height) / img.width;
	ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
	return canvas;
};

const canvasToBlob = (
	canvas: HTMLCanvasElement,
	quality: number
): Promise<Blob> => {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => resolve(blob as Blob), "image/jpeg", quality);
	});
};

const createCompressedFile = (blob: Blob, file: File): File => {
	return new File([blob], file.name, {
		type: "image/jpeg",
		lastModified: Date.now(),
	});
};
