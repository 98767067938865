import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { EditRowButton } from "../components/edit-row-button.component";

export const createMuiDataGridColumn = (
	field: string,
	headerName: string,
	width = 150,
	type: string | undefined = undefined
): GridColDef<GridValidRowModel> => ({
	field,
	headerName,
	width,
	...(type && { type }),
});

export const getMuiDataGridColumnsWithEditRowButton = (
	onEdit: (id: string) => void,
	columns: GridColDef<GridValidRowModel>[]
): GridColDef<GridValidRowModel>[] => [
	{
		field: "edit",
		headerName: "",
		width: 50,
		renderCell: (params: GridValidRowModel) => (
			<EditRowButton id={params.row.id} onEdit={onEdit} />
		),
	},
	...columns,
];
