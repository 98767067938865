import {
	File,
	FileImage,
	FileMusic,
	FileText,
	FileTypeIcon,
} from "lucide-react";

export const FileIcon = ({ fileName }: { fileName: string }) => {
	const fileIcons = {
		pdf: <File size={20} />,
		jpg: <FileImage size={20} />,
		png: <FileImage size={20} />,
		jpeg: <FileImage size={20} />,
		mp3: <FileMusic size={20} />,
		txt: <FileText size={20} />,
		other: <FileTypeIcon size={20} />,
	};

	const fileExt = fileName?.split(".").pop();
	if (!fileExt) return fileIcons.other;

	if (Object.keys(fileIcons).includes(fileExt)) {
		return fileIcons[fileExt as keyof typeof fileIcons];
	} else {
		return fileIcons.other;
	}
};
