import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useRef, useState } from "react";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";

type Option = {
	value: string;
	label: string;
	disabled?: boolean;
	disabledExplanation?: string;
};

/**
 * ButtonGroupWithDropdown - renders a button group with a dropdown menu
 * Note: The first item in the options array will be the default selected option
 * @param onClick - provides value of selected option
 * @param options - list of options to be displayed in the dropdown, with value and label
 * @param value - optional, if provided, will be the selected option
 * @returns
 */
export const ButtonGroupWithDropdown: React.FC<{
	onClick: (value: JobDocumentTypeEnum) => void;
	options: Option[];
	value?: string;
}> = ({ onClick, options, value }) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);

	const handleClick = (value: string | JobDocumentTypeEnum) => {
		onClick(value as JobDocumentTypeEnum);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const firstOption: Option = value
		? (options.find((option) => option.value === value) as Option)
		: options[0];

	return (
		<>
			<ButtonGroup
				variant="outlined"
				ref={anchorRef}
				aria-label="split button"
			>
				<Button
					size="small"
					onClick={() => handleClick(firstOption.value)}
					data-testid="button-group-with-dropdown-button"
					disabled={firstOption.disabled}
				>
					{firstOption.label}
				</Button>
				<Button
					size="small"
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
					data-testid="button-group-with-dropdown-button-dropdown"
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom"
									? "center top"
									: "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options
										.filter(
											(option) => option.value !== value
										)
										.map((option, index) => (
											<Tooltip
												key={index}
												title={
													option.disabled
														? option?.disabledExplanation
														: ""
												}
											>
												<div>
													<MenuItem
														key={option.value}
														onClick={() =>
															handleClick(
																option.value
															)
														}
														disabled={
															option.disabled
														}
													>
														{option.label}
													</MenuItem>
												</div>
											</Tooltip>
										))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
