import React from "react";
import { JobEntityType } from "../../../../lib/supabase/supabaseTypes";

export const ActivityJobLink: React.FC<{
  job: JobEntityType;
}> = ({ job }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        gap: "10px",
        fontSize: "14px",
      }}
    >
      {" "}
      <p
        style={{
          fontWeight: "500",
        }}
      >
        {job?.title}
      </p>
      <p
        style={{
          color: "gray",
          fontWeight: "400",
        }}
      >
        {job?.code}
      </p>
    </div>
  );
};
