// Ignore naming convention from the entire file from eslint
/* eslint @typescript-eslint/naming-convention: "off" */
import {
	DesktopEntityType,
	DesktopEntityTypeWithSystems,
	DesktopJobRequestEntityType,
	DesktopJobResponseEntityType,
	DesktopSystemEntityType,
} from "@/lib/supabase/supabaseTypes";

export interface State {
	desktopsWithSystems: DesktopEntityTypeWithSystems[];
	selectedDesktopSystem: DesktopSystemWithRequests | null;
	desktopRequests: (DesktopJobRequestEntityType & {
		desktop_job_responses: DesktopJobResponseEntityType[];
		desktop_systems: DesktopSystemEntityType & {
			desktops: DesktopEntityType;
		};
	})[];
}

export type DesktopSystemWithRequests = DesktopSystemEntityType & {
	desktop_job_requests: (DesktopJobRequestEntityType & {
		desktop_job_response: DesktopJobResponseEntityType;
	})[];
};

export enum DesktopSystemRequestTypes {
	NoRequest = 0,
	PrinterConfig = 1,
	PrintDocument = 2,
	PrintLabels = 3,
	ScannerConfig = 4,
	ScanDocument = 5,
}
