import emptyBox from "../assets/empty.svg";

export const EmptyDataPlaceholder: React.FC<{
	label: string;
	width?: number;
}> = ({ label, width = 150 }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "20px",
				padding: "20px",
				height: "100%",
				width: "100%",
			}}
		>
			<img src={emptyBox} alt="404" style={{ width }} />
			<span className="text-base">{label}</span>
		</div>
	);
};
