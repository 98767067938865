import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { State } from "./types";

export const SHOW_ALL = "All";

const defaultStartDate = () => {
	const currentDate = new Date();
	currentDate.setDate(currentDate.getDate() - 14);
	return currentDate;
};

const JOB_ITEM_TYPES = [
	{
		value: JobItemTypeEnum.TARIFF,
		label: "Tarif",
	},
	{
		value: JobItemTypeEnum.ARTICLE_MATERIAL,
		label: "Material",
	},
	{
		value: JobItemTypeEnum.ARTICLE_POSTAGE,
		label: "Porto",
	},
	{
		value: JobItemTypeEnum.ARTICLE_EXTERNAL_WORK,
		label: "Fremdarbeiten",
	},
	{
		value: JobItemTypeEnum.ARTICLE_DISCOUNTS,
		label: "Abzug/Barrabatt",
	},
	{
		value: JobItemTypeEnum.ARTICLE_FIXED_RATE,
		label: "Arbeitspauschale",
	},
];
export const initialState: State = {
	selectedClientsForClientChart: [],
	selectedEmployeeForClientChart: SHOW_ALL,
	allEmployees: [],
	allClients: [],
	formattedEmployeeData: [],
	formattedClientData: [],
	error: null,
	loading: false,
	selectedJobItemTypeForClientChart: SHOW_ALL,
	JobItemTypes: JOB_ITEM_TYPES,
	dateValue: {
		start: new Date(defaultStartDate().toUTCString()),
		end: new Date(),
	},
};
