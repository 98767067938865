import { CountryEnum } from "@/lib/supabase/supabaseEnums";

export const countryOptions: {
	value: CountryEnum;
	label: string;
}[] = [
	{ value: CountryEnum.SWITZERLAND, label: "Schweiz" },
	{ value: CountryEnum.GERMANY, label: "Deutschland" },
	{ value: CountryEnum.ITALY, label: "Italien" },
	{ value: CountryEnum.FRANCE, label: "Frankreich" },
	{ value: CountryEnum.AUSTRIA, label: "Österreich" },
	{ value: CountryEnum.LIECHTENSTEIN, label: "Liechtenstein" },
];

export const countryLookup: Record<CountryEnum, string> = {
	[CountryEnum.SWITZERLAND]: "Schweiz",
	[CountryEnum.GERMANY]: "Deutschland",
	[CountryEnum.ITALY]: "Italien",
	[CountryEnum.FRANCE]: "Frankreich",
	[CountryEnum.AUSTRIA]: "Österreich",
	[CountryEnum.LIECHTENSTEIN]: "Liechtenstein",
};
