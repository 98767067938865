import { nameTitleOptions } from "@/dentlab/src/lib/constants/nameTitle";
import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

export const TitleSelect: React.FC<SelectProps> = (props) => {
	return (
		<CustomSelect
			options={nameTitleOptions}
			inputLabel="Anrede"
			{...props}
		/>
	);
};
