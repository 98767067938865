import ReceiptIcon from "@mui/icons-material/Receipt";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArticleIcon from "@mui/icons-material/Article";
import { GanttChart } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useJobStore } from "../../store/Jobs";
import { useEffect, useState } from "react";
import { JobStatusEnum } from "@/lib/types/job";
import {
	ActivityEntityType,
	JobEntityType,
} from "@/lib/supabase/supabaseTypes";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { SharedDocument } from "@/components/src/cards/shared-document.component";
import { ThreadSidebar } from "@/components/src/activity-history/thread-sidebar/thread-sidebar.component";
import { useCentralStore } from "../../store/Central";
import { TEMPLATE_CODE } from "../../store/Central/utils";

export const DashboardPage: React.FC = () => {
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));
	const navigate = useNavigate();
	const { fetchLatestJobs, latestJobs } = useJobStore((state) => ({
		fetchLatestJobs: state.fetchLatestJobs,
		latestJobs: state.latestJobs,
	}));
	const [selectedActivity, setSelectedActivity] =
		useState<ActivityEntityType | null>(null);
	const { fetchLatestSharedFiles, latestSharedFiles } = useRealtimeStore(
		(state) => ({
			fetchLatestSharedFiles: state.fetchLatestSharedFiles,
			latestSharedFiles: state.latestSharedFiles,
		})
	);
	const latestJobCount = 8;
	const latestSharedFilesCount = 8;
	useEffect(() => {
		fetchLatestJobs(latestJobCount);
		fetchLatestSharedFiles(
			organization?.id as string,
			latestSharedFilesCount
		);
	}, []);
	const organizationName = organization?.name;

	return (
		<div className="p-8 flex flex-col gap-10" data-testid="dashboard">
			<h1 className="text-2xl">{organizationName}</h1>
			<div className="flex flex-col gap-2">
				<h2 className="text-lg">Ihre Apps</h2>
				<div className="flex flex-row gap-4 flex-wrap">
					<AppCard
						title="Agenda"
						icon={<GanttChart size={40} />}
						onClick={() => navigate("/agenda")}
					/>
					<AppCard
						title="Sammelrechn."
						icon={
							<ReceiptIcon
								style={{
									fontSize: "36px",
								}}
							/>
						}
						onClick={() => navigate("/monthly-invoices")}
					/>
					<AppCard
						title="Statistik"
						icon={
							<BarChartIcon
								style={{
									fontSize: "36px",
								}}
							/>
						}
						onClick={() => navigate("/statistics")}
					/>
					<AppCard
						title="Debitoren"
						icon={
							<CalculateIcon
								style={{
									fontSize: "36px",
								}}
							/>
						}
						onClick={() => navigate("/accounting")}
					/>
					<AppCard
						title="Jumbos"
						icon={
							<WorkspacesIcon
								style={{
									fontSize: "36px",
								}}
							/>
						}
						onClick={() => navigate("/job-document-templates")}
					/>
					<AppCard
						title="PDF-Vorlagen"
						icon={
							<ArticleIcon
								style={{
									fontSize: "36px",
								}}
							/>
						}
						onClick={() => navigate("/document-settings")}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-2">
				<h2 className="text-lg">Letzte Aufträge</h2>
				<div className="flex flex-row flex-wrap gap-2">
					{latestJobs
						.filter((job) => job.code !== TEMPLATE_CODE)
						.map((job) => {
							return <JobCard job={job} />;
						})}
				</div>
			</div>
			<div className="flex flex-col gap-2">
				<h2 className="text-lg">Zuletzt geteilte Dateien</h2>
				<div className="flex flex-row flex-wrap gap-2">
					{latestSharedFiles.map((file) => {
						return (
							<SharedDocument
								openThread={setSelectedActivity}
								file={{
									...file,
									url: "",
								}}
							/>
						);
					})}
				</div>
			</div>
			{selectedActivity && (
				<ThreadSidebar
					activity={selectedActivity}
					onClose={() => {
						setSelectedActivity(null);
					}}
					top
				/>
			)}
		</div>
	);
};

const AppCard: React.FC<{
	title: string;
	icon: React.ReactNode;
	onClick: () => void;
}> = ({ title, icon, onClick }) => {
	return (
		<div className="grid grid-cols-1 gap-4">
			<div
				className="border w-40 h-40 flex flex-col rounded-sm p-4"
				onClick={onClick}
			>
				<div className="hover:cursor-pointer transform transition-transform duration-300 hover:scale-110">
					<div className="flex justify-center items-center w-full">
						<div className="flex bg-blue-100 w-full py-6 justify-center items-center rounded-sm">
							<div className="text-blue-800">{icon}</div>
						</div>
					</div>
					<div className="flex justify-start items-start pt-2">
						<h3 className="text-sm">{title}</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

const JobCard: React.FC<{
	job: JobEntityType;
}> = ({ job }) => {
	const colors = {
		[JobStatusEnum.IN_PROGRESS]: "text-blue-500",
		[JobStatusEnum.COMPLETED]: "text-green-500",
		[JobStatusEnum.NOT_STARTED]: "text-gray-500",
		[JobStatusEnum.ARCHIVED]: "text-red-500",
		[JobStatusEnum.BOOKED_SINGLE]: "text-yellow-400",
		[JobStatusEnum.BOOKED_MONTHLY]: "text-cyan-500",
	};
	const navigate = useNavigate();
	return (
		<div
			onClick={() => {
				navigate(`client/${job.client_id}/job/${job.id}`);
			}}
			className="border  rounded-sm h-18 w-96 flex flex-row items-center py-1 px-2 gap-1 hover:cursor-pointer hover:shadow"
		>
			<ArticleIcon
				style={{
					fontSize: "26px",
				}}
				className={`${colors[job.status as keyof typeof colors]}`}
			/>
			<div className="text-base w-2/3 overflow-hidden whitespace-nowrap text-ellipsis text-gray-950">
				{job.title}
			</div>
			<span className="text-base w-1/3 text-gray-600 overflow-hidden whitespace-nowrap text-ellipsis">
				{job.code}
			</span>
		</div>
	);
};
