import { ClientEntityType, JobEntityType } from "@/lib/supabase/supabaseTypes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, ListItemText } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { colors } from "./utils";

export const CollapsibleList = ({
	client,
	expanded,
	setExpanded,
	even,
}: {
	client: ClientEntityType & { jobs: JobEntityType[] };
	expanded: boolean;
	setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
	even: boolean;
}) => {
	const expandedHeight = client.jobs.length * 32 + 48;
	const navigate = useNavigate();
	return (
		<div
			style={{
				height: expanded ? expandedHeight : "48px",
			}}
			className={clsx(
				" w-full p-0 transition-all overflow-hidden duration-150  ",
				{
					"bg-neutral-100": even,
				}
			)}
		>
			<div
				onClick={() => {
					if (expanded) {
						setExpanded((prev) =>
							prev.filter((id) => id !== client.id)
						);
					} else {
						setExpanded((prev) => [...prev, client.id]);
					}
				}}
				className="flex  min-h-12 cursor-pointer pl-2 items-center gap-4"
			>
				<div className="w-8 h-full">
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</div>
				<ListItemText
					primary={`${client.first_name} ${client.last_name}`}
				/>
			</div>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<div className="flex flex-col">
					{client.jobs.map((job) => (
						<div
							key={job.id}
							className="pl-14  h-8 items-center flex gap-2 text-xs"
						>
							<div
								onClick={(e) => {
									e.stopPropagation();
									navigate(
										`/client/${client.id}/job/${job.id}`
									);
								}}
								className={`w-4 h-4 cursor-pointer rounded-sm ${
									colors[job.status as keyof typeof colors]
								}`}
							></div>

							<div>{job.code}</div>
							<div className="font-bold">{job.title}</div>
						</div>
					))}
				</div>
			</Collapse>
		</div>
	);
};
