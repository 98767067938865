import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import { useProfileContext } from "@/lib/contexts/ProfileContext";
import { useEffect, useState } from "react";
import { DesktopRequestCard } from "./components/desktop-request-card.component";
import { Button } from "@mui/material";

export const DesktopRequestPage = () => {
	const { organizationId } = useProfileContext();
	const [lastCursor, setLastCursor] = useState<number>(0);
	const { fetchRequests, desktopRequests } = useDesktopsStore();

	useEffect(() => {
		if (organizationId) {
			fetchRequests(organizationId, lastCursor);
		}
	}, [lastCursor, organizationId]);

	if (!desktopRequests?.length || !organizationId) {
		return <div>Lädt..</div>;
	}

	return (
		<div
			style={{
				padding: "20px",
			}}
		>
			<div
				style={{
					fontSize: "24px",
					fontWeight: "bold",
					color: "#000000",
					marginBottom: "20px",
				}}
			>
				Desktopaufträge
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
				}}
			>
				{desktopRequests.map((request) => {
					return (
						<div>
							<DesktopRequestCard
								desktopName={
									request.desktop_systems.desktops.name
								}
								desktopSystemName={request.desktop_systems.name}
								request={request}
								response={
									request.desktop_job_responses?.[0] ?? null
								}
							/>
						</div>
					);
				})}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "20px",
				}}
			>
				<Button
					disabled={lastCursor <= 20}
					onClick={async () => {
						setLastCursor((prev) => prev - 20);
					}}
				>
					Zürück
				</Button>
				<Button
					disabled={desktopRequests.length < 20}
					onClick={async () => {
						setLastCursor((prev) => prev + 20);
					}}
				>
					Nächster
				</Button>
			</div>
		</div>
	);
};
