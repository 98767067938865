import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { useTeamProfilesContext } from "../../../context/single-entity-contexts/TeamProfilesContext";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";

export const GroupHeaderCustomCell: React.FC<GridCustomCellProps> = (props) => {
	const {
		teamProfiles: { teamProfilesLookup },
	} = useTeamProfilesContext();
	const props_ = props;
	if (
		props.dataItem.field === "profile_id" ||
		props.dataItem.field === "profile2_id" ||
		props.dataItem.field === "profile3_id"
	) {
		const profile =
			teamProfilesLookup[props.dataItem[props.field as string]];
		return (
			props.tdProps && (
				<td {...props.tdProps}>
					{props.children && (
						<p className="k-reset">
							{/* @ts-expect-error props exist within the children*/}
							{props_.children.props.children[0]}
							{profile?.first_name} {profile?.last_name}
							{!profile?.first_name &&
								!profile?.last_name &&
								profile?.email}
							{!profile && "Keine Auswahl"}
						</p>
					)}
				</td>
			)
		);
	} else {
		let label = props.dataItem[props.field as string];
		if (props.dataItem.value === JobItemTypeEnum.TARIFF) {
			label = "Tarif";
		} else if (props.dataItem.value === JobItemTypeEnum.ARTICLE_MATERIAL) {
			label = "Material";
		} else if (props.dataItem.value === JobItemTypeEnum.ARTICLE_POSTAGE) {
			label = "Porto";
		} else if (
			props.dataItem.value === JobItemTypeEnum.ARTICLE_EXTERNAL_WORK
		) {
			label = "Fremdarbeit";
		} else if (props.dataItem.value === JobItemTypeEnum.ARTICLE_DISCOUNTS) {
			label = "Abzug/Barrabatt";
		} else if (
			props.dataItem.value === JobItemTypeEnum.ARTICLE_FIXED_RATE
		) {
			label = "Arbeitspauschale";
		} else {
			label = "Kein Positionstyp";
		}
		return (
			props.tdProps && (
				<td
					{...props.tdProps}
					style={{
						fontSize: "12px",
						padding: "0px 5px 0px 5px",
					}}
				>
					{props.children && (
						<p className="k-reset">
							{/* @ts-expect-error props exist within the children*/}
							{props_.children.props.children[0]}
							{label}
						</p>
					)}
				</td>
			)
		);
	}
};
