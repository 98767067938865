import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { Logger } from "@/lib/logger/Logger";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import { showNotification } from "../Central/selectors";
import { State } from "./types";
import { initialState } from "./utils";

export interface JobPatientSlice extends State {
	selectPatientForJob: (patientId: string) => Promise<void>;
}

export const createJobPatientStore: StateCreator<JobPatientSlice> = (
	set,
	get
) => ({
	...initialState,
	selectPatientForJob: async (patientId: string) => {
		const { job } = get();
		if (!job?.id) {
			Logger.error("NO job selected");
			return;
		}
		const oldPatientId = job.patient_id;
		set({
			job: {
				...job,
				patient_id: patientId,
			},
		});

		const { error } = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOBS)
				.update({
					patient_id: patientId,
				})
				.eq("id", job.id)
				.select()
		);

		if (error) {
			set({
				job: {
					...job,
					patient_id: oldPatientId,
				},
			});
			showNotification({
				message: "Patient konnte nicht aktualisiert werden",
				type: "error",
			});
			return;
		}
	},
});
