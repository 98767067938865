/**
 * @fileoverview useDatabase - exposes hooks to fetch and update data from database
 * When using useDatatabse utils there is no need for logging errors, they are handled by the hook.
 * The data object can be used directly.
 *
 * When importing, you can always start by typing useDatabase...
 */

import { useEffect, useState } from "react";
import { supabase } from "../../../lib/supabase";
import { handleDatabaseOperation } from "../lib/utils/utils-functions";
import { DBOperationResult } from "../types/types";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";

// This is being in used in a lot of places, changing it might require a lot of changes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EqualityFilterType = { column: string; value: any };
type OrderType = { column: string; ascending: boolean };
type GTEFilterType = { column: string; value: Date | number | string }; // Greater than or equal to filter
type LTEFilterType = { column: string; value: Date | number | string }; // Less than or equal to filter

/**
 * useDatabaseFunctionsWithPromise
 * @example
 * const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
 * const { success, data, error } = await fetchDataWithPromise(
      SupabaseTableEnum.PATIENTS,
      { column: "connect_relationship_id", value: connectRelationshipId }
    );
 * if (success && data) {setPatients(data)};
 */
export const useDatabaseFunctionsWithPromise = () => {
	/**
	 * @param {string} supabaseTable - the supabase table to fetch from
	 * @param {EqualityFilterType | EqualityFilterType[]} equalityFilters - optional, an array of equality filters to apply (e.g. [{ column: "id", value: 1 }])
	 * @param {string} returnColumn - optional, the column to fetch (defaults to "*")
	 * @param {OrderType} order - optional, the column to order by and whether to order ascending or descending
	 * @returns DBOperationResult, including success, data and error
	 */
	const fetchDataWithPromise = async (
		supabaseTable: string,
		equalityFilters?: EqualityFilterType | EqualityFilterType[],
		returnColumn?: string,
		order?: OrderType,
		orFilters?: {
			filterString: string;
			foreignTable?: string;
		}[],
		gtFilters?: GTEFilterType | GTEFilterType[],
		ltFilters?: LTEFilterType | LTEFilterType[]
	) => {
		const select = returnColumn || "*";
		let operation = supabase
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.from(supabaseTable as any)
			.select(select);
		if (order) {
			operation = operation.order(order.column, {
				ascending: order.ascending,
			});
		}
		if (equalityFilters) {
			if (equalityFilters instanceof Array) {
				equalityFilters.forEach((filter) => {
					operation = operation.eq(filter.column, filter.value);
				});
			} else {
				operation = operation.eq(
					equalityFilters.column,
					equalityFilters.value
				);
			}
		}
		if (orFilters) {
			orFilters.forEach((filter) => {
				if (filter.foreignTable) {
					operation = operation.or(filter.filterString, {
						foreignTable: filter.foreignTable,
					});
				} else {
					operation = operation.or(filter.filterString);
				}
			});
		}
		if (gtFilters) {
			if (gtFilters instanceof Array) {
				gtFilters.forEach((filter) => {
					operation = operation.gt(filter.column, filter.value);
				});
			} else {
				operation = operation.gt(gtFilters.column, gtFilters.value);
			}
		}
		if (ltFilters) {
			if (ltFilters instanceof Array) {
				ltFilters.forEach((filter) => {
					operation = operation.lt(filter.column, filter.value);
				});
			} else {
				operation = operation.lt(ltFilters.column, ltFilters.value);
			}
		}
		return handleDatabaseOperation(operation);
	};

	/**
	 * insertData - adds multiple entries to the database
	 * @param {SupabaseTable} supabaseTable - the supabase table to add to
	 * @param {any[]} entries - the entries to add
	 * @returns {any} - the added entries, success state and error
	 * @example
	 * const { success, data, error } = await insertData("jobs", jobs);
	 * const { success, data, error } = await insertData("jobs", [job]);
	 */
	const insertDataWithPromise = async (
		supabaseTable: SupabaseTableEnum,

		// TODO: change any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		entries: any[]
	): Promise<DBOperationResult> => {
		const dataToInsert = entries.length <= 1 ? entries[0] : entries; // TODO: test if this makes a difference
		return handleDatabaseOperation(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			supabase
				.from(supabaseTable as any)
				.insert(dataToInsert)
				.select() // created_at and modified_at are set by the database, so we don't set them here
		);
	};

	/**
	 * updateData - updates multiple entries in the database
	 * @param {SupabaseTable} supabaseTable - the supabase table to update
	 * @param {any[]} entries - the entries to update
	 * @returns {any} - the updated entries, success state and error
	 * @example const { success, data, error } = await updateData("jobs", jobs);
	 */
	const updateDataWithPromise = async (
		supabaseTable: SupabaseTableEnum,

		// TODO: change any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		entries: any[]
	): Promise<DBOperationResult> => {
		if (entries.length <= 1) {
			return handleDatabaseOperation(
				supabase
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					.from(supabaseTable as any)
					.update(entries[0])
					.eq("id", entries[0].id)
					.select()
			);
		}
		return handleDatabaseOperation(
			supabase
				.from(supabaseTable as any)

				// TODO: change any
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.upsert(entries as any)
				.select()
		);
	};

	/**
	 * deleteData - deletes multiple entries from the database
	 * @param {SupabaseTable} supabaseTable - the supabase table to delete from
	 * @param {string[]} entryIds - the ids of the entries to delete
	 * @returns {any} - the deleted entries, success state and error
	 * @example const { success, data, error } = await deleteData("jobs", jobIds);
	 */
	const deleteDataWithPromise = async (
		supabaseTable: SupabaseTableEnum,
		// TODO: change any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		entryIds: any[]
	): Promise<DBOperationResult> => {
		if (entryIds.length <= 1) {
			return handleDatabaseOperation(
				supabase
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					.from(supabaseTable as any)
					.delete()
					.eq("id", entryIds[0])
					.select() // TODO: test if this makes a difference
			);
		}
		return handleDatabaseOperation(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			supabase
				.from(supabaseTable as any)
				.delete()
				.in("id", entryIds)
				.select()
		);
	};

	return {
		fetchDataWithPromise,
		insertDataWithPromise,
		updateDataWithPromise,
		deleteDataWithPromise,
	};
};

/**
 * useDatabaseFetch
 * @param {string} supabaseTable - the supabase table to fetch from
 * @param {EqualityFilterType[]} equalityFilters - optional, an array of equality filters to apply (e.g. [{ column: "id", value: 1 }])
 * @param {string} returnColumn - optional, the column to fetch (defaults to "*")
 * @example const { data: job, loading, error } = useFetch("jobs", { column: "id", value: jobId });
 */
export const useDatabaseFetch = (
	supabaseTable: string,
	equalityFilters?: EqualityFilterType | EqualityFilterType[],
	returnColumn?: string,
	order?: OrderType,
	orFilters?: {
		filterString: string;
		foreignTable?: string;
	}[]
) => {
	// TODO: change any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [data, setData] = useState<any[] | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	useEffect(() => {
		setLoading(true);
		(async () => {
			const { success, data, error }: DBOperationResult =
				await fetchDataWithPromise(
					supabaseTable,
					equalityFilters,
					returnColumn,
					order,
					orFilters
				);
			if (success) {
				setData(data);
			}
			if (error) {
				setError(error);
			}
			setLoading(false);
		})();
	}, [
		supabaseTable,
		returnColumn,
		JSON.stringify(equalityFilters),
		JSON.stringify(orFilters),
		JSON.stringify(order),
	]);

	return { data, loading, error, setData };
};
