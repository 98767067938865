import { useMemo } from "react";
import PrintIcon from "@mui/icons-material/Print";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { Skeleton } from "@mui/material";
import { DesktopSystemRequestTypes } from "@/dentlab/src/store/Desktops/types";
import { StatusCircle } from "@/dentlab/src/components/status-circle/status-circle.component";

const cardStyle = {
	display: "flex",
	justifyContent: "start",
	gap: "1rem",
	padding: "16px",
	width: "98%",
	borderRadius: "8px",
	border: "1px solid #e0e0e0",
	boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
	alignItems: "center",
};

const IconWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<div
		style={{
			width: "50px",
			display: "flex",
			justifyContent: "center",
			marginRight: "1rem",
		}}
	>
		{children}
	</div>
);

const DesktopRequestCard: React.FC<{
	desktopName: string;
	desktopSystemName: string;
	response: { id: number; created_at: string } | null;
	request: {
		id: number;

		created_at: string;

		type: DesktopSystemRequestTypes;
	};
}> = ({ desktopName, desktopSystemName, response, request }) => {
	const icon = useMemo(() => {
		switch (request.type) {
			case DesktopSystemRequestTypes.PrintDocument:
			case DesktopSystemRequestTypes.PrintLabels:
				return <PrintIcon />;
			case DesktopSystemRequestTypes.ScanDocument:
				return <AdfScannerIcon />;
			default:
				return null;
		}
	}, [request.type]);

	return (
		<div style={cardStyle}>
			<div>
				<StatusCircle status={response ? "success" : "error"} />
			</div>
			<IconWrapper>{icon}</IconWrapper>
			<div style={{ width: "500px", display: "flex", gap: "3rem" }}>
				<div
					style={{
						width: "50%",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					Desktop: {desktopName}
				</div>
				<div
					style={{
						width: "50%",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					Desktop System: {desktopSystemName}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					width: "100%",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				<div>{request.id}</div>
				<div>
					{formatDistance(new Date(request.created_at), new Date(), {
						addSuffix: true,
						locale: de,
					})}
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "start",
					width: "100%",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				{response ? (
					<>
						<div>{response.id}</div>
						<div>
							{formatDistance(response.created_at, new Date(), {
								addSuffix: true,
								locale: de,
							})}
						</div>
					</>
				) : (
					<>
						<div>Status unbekannt</div>
					</>
				)}
			</div>
		</div>
	);
};

const LoadingDesktopSystemRequestCard = () => (
	<div style={cardStyle}>
		<IconWrapper>
			<Skeleton width={"50px"} height="24px" />
		</IconWrapper>
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
				alignItems: "center",
			}}
		>
			<Skeleton height={24} width={100} />
			<Skeleton height={24} width={150} />
		</div>
	</div>
);

export { DesktopRequestCard, LoadingDesktopSystemRequestCard };
