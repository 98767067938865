import { supabase } from "@/lib/supabase";
import {
	ProfileEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { useCentralStore } from "../Central";
import { Logger } from "@/lib/logger/Logger";
import { getRole } from "../../lib/util";

export interface UserManagementSlice {
	members: (ProfileEntityType & {
		allowed_signups_lab_id: string | null;
		allowed_signups_lab_email: string | null;
		allowed_signups_lab_created_at: string | null;
		allowed_signups_lab_organization_id: string | null;
		allowed_signups_lab_role: string | null;
	})[];
	getMembers: () => Promise<void>;
}

export const createUserManagementStore: StateCreator<UserManagementSlice> = (
	set
) => ({
	members: [],
	getMembers: async () => {
		const { organization, role, profile } = useCentralStore.getState();

		if (!organization || !profile) {
			Logger.error("No Organization set");
			return;
		}
		const organizationId = organization.id;
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES_TO_INVITES_VIEW)
			.select()
			.or(
				`organization_id.eq.${organizationId},allowed_signups_lab_organization_id.eq.${organizationId}`
			);

		if (error) {
			Logger.error(error);
			return;
		}
		if (!data) {
			Logger.error("No members could be fetched");
			return;
		}

		set({
			// @ts-expect-error given this view has an outer join
			members: data.map((d) => ({
				id: d.id ?? d.allowed_signups_lab_id,
				first_name: d.first_name ?? "-",
				last_name: d.last_name ?? "-",
				email: d.email ?? d.allowed_signups_lab_email,
				invited: d.allowed_signups_lab_created_at
					? new Date(
							d.allowed_signups_lab_created_at
						).toLocaleDateString("de-ch")
					: "-",
				role:
					d.id == profile.id
						? getRole(role as number)
						: getRole(d.allowed_signups_lab_role as number),
				accepted:
					d.email && d.email == d.allowed_signups_lab_email
						? new Date(d.created_at ?? "").toLocaleDateString(
								"de-ch"
							)
						: "-",
			})),
		});
	},
});
