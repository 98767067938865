import { EditableTitle } from "@/components/src/editable-title/editable-title.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

export const JobDocumentTitle: React.FC<{
	id: number;
	title: string | null;
	status: number;
}> = ({ title, id, status }) => {
	const { updateJobDocumentTitle } = useJobStore((state) => ({
		updateJobDocumentTitle: state.updateJobDocumentTitle,
	}));

	const { register, watch, handleSubmit, setValue, reset } = useForm({
		defaultValues: {
			title: title,
		},
	});

	const onSubmit = async () => {
		handleSubmit((data) => {
			if (data.title) {
				updateJobDocumentTitle(id, data.title, status);
			}
		})();
	};

	return (
		<Stack direction="row" gap={1}>
			<EditableTitle
				{...register("title")}
				value={watch("title") ?? title ?? ""}
				onSubmit={onSubmit}
				onReset={reset}
				loading={!title || !id}
				onChange={(e) => setValue("title", e.target.value)}
				type="text"
				fontSize="18px"
			/>
		</Stack>
	);
};
