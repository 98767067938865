import { ButtonGroupWithDropdown } from "../../components/buttons/button-group-with-dropdown.component";
import { useCentralStore } from "../../store/Central";
import { JobDocumentTypeEnum } from "../job-page/job-document/job-document.types";
import { JobDocumentTemplate } from "../job-page/job-document/job-document.component";
import { NEW_DOCUMENT_OPTIONS } from "../job-page/job-page-components/new-job-document-area/new-document-options";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { useRefContext } from "../../context/RefContext";

export const JobDocumentTemplatesPage = () => {
	const { jobDocumentTemplates, addJobDocumentTemplate } = useCentralStore(
		(state) => ({
			jobDocumentTemplates: Object.values(
				state.jobDocumentTemplatesLookup
			),
			addJobDocumentTemplate: state.addEmptyJobDocumentTemplate,
		})
	);
	const { container } = useRefContext();

	return (
		<div className="p-6 flex flex-col gap-4">
			<div className="flex items-center gap-4">
				<PageTitle title={[{ name: "Dokumentenvorlagen" }]} />
				<ButtonGroupWithDropdown
					onClick={(value: JobDocumentTypeEnum) =>
						addJobDocumentTemplate(value)
					}
					options={NEW_DOCUMENT_OPTIONS.map((option) => ({
						...option,
						disabled: false,
					}))}
					value={JobDocumentTypeEnum.DeliveryNote}
				/>
			</div>
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
				}}
				ref={container}
			>
				<div
					style={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<div className="flex flex-col ">
						{jobDocumentTemplates?.map((doc) => (
							<JobDocumentTemplate
								key={doc.id}
								jobDocument={doc}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
