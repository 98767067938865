import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import "../../../../styles.css";
import "./search-bar.css";
import { useState } from "react";
import { useSearchStore } from "@/dentlab/src/store/Search";

export const SearchBar: React.FC = () => {
	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const { fetchSearchResults } = useSearchStore.getState();

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleKeyDownEvent = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === "Enter" && search.length > 0) {
			fetchSearchResults(search);
			if (!window.location.pathname.startsWith("/search")) {
				navigate("/search");
			}
		}
	};

	return (
		<div className="search" data-testid="search-bar">
			<div className="search-icon-wrapper">
				<SearchIcon />
			</div>
			<InputBase
				className="styled-input-base"
				placeholder="Suche"
				inputProps={{ "aria-label": "search" }}
				value={search}
				onChange={handleInputChange}
				onKeyDown={handleKeyDownEvent}
			/>
		</div>
	);
};
