import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FileOptions } from "../components/file-display-components/file-options.component";
import { PdfFullScreen } from "../components/file-display-components/file-display.components";
import {
	FileEntityTypeWithUrl,
	FileWithShareWithUrl,
	useStorage,
} from "../hooks/useStorage";
import { Logger } from "../../../lib/logger/Logger";
import {
	FileEntityType,
	FileWithShare,
} from "../../../lib/supabase/supabaseTypes";
import { Backdrop, IconButton } from "@mui/material";
import { useToast } from "../context/ToastContext";

/**
 * useFileOverlay - hook to display a file in a fullscreen overlay
 * @returns fileOverlay - jsx element to display the file overlay
 * @returns showFileOverlay - function to show the file overlay, use this when the file has not received a url yet
 * @returns showFileOverlayWithUrl - function to show the file overlay, use this when the file has already received a url
 *
 */
export const useFileOverlay = () => {
	const { showToast } = useToast();
	const { fetchFile } = useStorage();
	const [file, setFile] = useState<FileWithShareWithUrl | undefined>(
		undefined
	);

	const handleClose = () => {
		setFile(undefined);
	};

	/**
	 * showFileOverlay - use this when the file has not received a url yet
	 * @param fileWithoutUrl - file to display, does not need to have url
	 */
	const showFileOverlay = async (fileWithoutUrl: FileWithShare) => {
		const { success, data, error } = await fetchFile(fileWithoutUrl);
		Logger.log("FileOverlayContextProvider", "data", data);
		if (success && data) {
			setFile(data as FileWithShareWithUrl);
		} else {
			showToast("Datei konnte nicht geladen werden", "error");
		}
	};

	/**
	 * showFileOverlayWithUrl - use this when the file has already received a url
	 * @param fileWithUrl - file to display, needs to have url
	 */
	const showFileOverlayWithUrl = (fileWithUrl: FileWithShareWithUrl) => {
		setFile(fileWithUrl);
	};

	const fileOverlay = file ? (
		<Backdrop sx={{ zIndex: 100 }} open={file !== undefined}>
			<div>
				<IconButton
					onClick={handleClose}
					style={{
						position: "absolute",
						top: "0.5rem",
						left: "0.5rem",
					}}
				>
					<ArrowBackIcon
						style={{
							color: "white",
							fontSize: "2.5rem",
						}}
					/>
				</IconButton>
				<div
					style={{
						position: "absolute",
						top: "0.5rem",
						right: "0.5rem",
					}}
				>
					<FileOptions
						variant="button"
						clientId={file?.client_id?.toString() ?? ""} // TODO: not good
						file={file}
						onDeleteStart={() => {
							Logger.log("");
						}} // TODO: provide user feedback/progress indicator
						onDeleteSuccess={() => {
							handleClose();
						}}
						setFile={setFile}
					/>
				</div>
				<PdfFullScreen file={file} />
			</div>
		</Backdrop>
	) : null;

	return { fileOverlay, showFileOverlay, showFileOverlayWithUrl };
};
