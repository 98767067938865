import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { Button, Dialog, Tooltip } from "@mui/material";
import { Check, Copy } from "lucide-react";
import { useEffect, useState } from "react";
import { useCentralStore } from "../../store/Central";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "../../components/ui/input-otp";
import { showNotification } from "../../store/Central/selectors";

export const MFADialog = ({
	open,
	onOpen,
}: {
	open: boolean;
	onOpen?: (open: boolean) => void;
}) => {
	const [factorId, setFactorId] = useState("");
	const [qr, setQR] = useState(""); // holds the QR code image SVG
	const [verifyCode, setVerifyCode] = useState(""); // contains the code entered by the user
	const [error, setError] = useState(""); // holds an error message
	const [secret, setSecret] = useState("");
	const [loading, setLoading] = useState(false);
	const [copied, setCopied] = useState(false);
	const { setupMFA } = useCentralStore((state) => ({
		setupMFA: state.setupMFA,
	}));

	const onEnableClicked = (verifyCode: string) => {
		setError("");
		(async () => {
			try {
				await setupMFA(verifyCode, factorId);
				onOpen && onOpen(false);
			} catch (e: unknown) {
				setError(e as string);
			}
		})();
	};

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				const { data, error } = await supabase.auth.mfa.enroll({
					factorType: "totp",
				});
				if (error) {
					Logger.error("MFAERROR", error);
					return;
				}

				setFactorId(data.id);

				setQR((data as any).totp.qr_code);
				setSecret((data as any).totp.secret);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	if (!qr) {
		return null;
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				if (!onOpen) return;
				onOpen(false);
			}}
		>
			<div className="p-4 flex gap-4 flex-col justify-center">
				{!!error && (
					<div className="error text-red-500 text-sm">{error}</div>
				)}
				{!loading && (
					<div className="flex flex-col justify-center">
						<img src={qr} />
						<div className="w-96 flex items-center flex-col gap-4 justify-center min-h-20">
							<span className="text-center w-full overflow-hidden">
								Wenn Sie Probleme mit dem obigen QR-Code haben,
								geben Sie bitte diesen geheimen Text in Ihre
								Authentifizierungs-App ein.
							</span>
							<div
								onClick={async () => {
									await navigator.clipboard.writeText(secret);
									setCopied(true);
									setTimeout(() => {
										setCopied(false);
									}, 3000);
								}}
								className=" cursor-pointer justify-between border rounded w-full flex  items-center p-1"
							>
								<div />
								{secret}
								<Tooltip
									title={copied ? "Copied" : "Copy text"}
								>
									{copied ? (
										<Check size={16} />
									) : (
										<Copy size={16} />
									)}
								</Tooltip>
							</div>
							Teilen Sie diese Daten mit niemandem!
						</div>
					</div>
				)}
				<div className="w-full flex justify-center">
					<InputOTP
						value={verifyCode}
						onChange={(value) => {
							setVerifyCode(value);
							if (value.length >= 6) {
								onEnableClicked(value);
							}
						}}
						maxLength={6}
					>
						<InputOTPGroup>
							<InputOTPSlot index={0} />
							<InputOTPSlot index={1} />
							<InputOTPSlot index={2} />
						</InputOTPGroup>
						<InputOTPSeparator />
						<InputOTPGroup>
							<InputOTPSlot index={3} />
							<InputOTPSlot index={4} />
							<InputOTPSlot index={5} />
						</InputOTPGroup>
					</InputOTP>
				</div>
				<div className="flex justify-center">
					<Button
						type="button"
						disabled={verifyCode.length < 6}
						value="Enable"
						onClick={() => {
							onEnableClicked(verifyCode);
							if (verifyCode.length < 6) {
								showNotification({
									message:
										"Bitte geben Sie die fehlenden Ziffern ein.",
									type: "error",
								});
							}
						}}
					>
						Aktivieren
					</Button>
				</div>
			</div>
		</Dialog>
	);
};
