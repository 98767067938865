import { useCentralStore } from "@/dentlab/src/store/Central";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useDesktopsStore } from "@/dentlab/src/store/Desktops";
import {
	StatusCircle,
	StatusCircleStatusType,
} from "@/dentlab/src/components/status-circle/status-circle.component";
import { dateOlderThanSeconds } from "@/dentlab/src/lib/utils/utils-functions";
import { PrinterList } from "./printers-list.component";

export const AddPrinterTabs: React.FC<{
	onPrinterAdded: () => void;
}> = ({ onPrinterAdded }) => {
	const { fetchDesktops, desktopsWithSystems } = useDesktopsStore(
		(state) => ({
			fetchDesktops: state.fetchDesktops,
			desktopsWithSystems: state.desktopsWithSystems,
		})
	);

	const organization = useCentralStore((state) => state.organization);

	const [desktopId, setDesktopId] = useState<string | null>(null);

	useEffect(() => {
		if (organization?.id && !desktopsWithSystems) {
			fetchDesktops(organization?.id);
		}
	}, [organization?.id, desktopsWithSystems]);

	useEffect(() => {
		if (!desktopId) {
			setDesktopId(desktopsWithSystems?.[0]?.id || "");
		}
	}, [desktopsWithSystems]);

	// Tabs onChange uses any type
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const goToDifferentDesktop = (event: React.SyntheticEvent, value: any) => {
		setDesktopId(value);
	};

	if (desktopId === null) {
		return <div>Kein Desktop ausgewählt...</div>;
	}
	return (
		<div>
			<Tabs
				value={desktopId}
				onChange={goToDifferentDesktop}
				variant="scrollable"
				scrollButtons="auto"
			>
				{desktopsWithSystems?.map((desktop) => {
					let status: StatusCircleStatusType = "info";
					let statusText = "";
					if (
						!dateOlderThanSeconds(
							new Date(desktop.last_active_at),
							60
						)
					) {
						status = "success";
					} else if (
						!dateOlderThanSeconds(
							new Date(desktop.last_active_at),
							300
						)
					) {
						status = "warning";
						statusText =
							"Der Desktop-Dienst ist seit kurzem inaktiv";
					} else {
						status = "error";
						statusText = "Der Desktop-Dienst ist nicht erreichbar";
					}
					return (
						<Tab
							key={desktop.id}
							label={desktop.name}
							value={desktop.id}
							icon={
								<div className="pl-2">
									<StatusCircle
										status={status}
										tooltipText={statusText}
										variant="flat"
									/>
								</div>
							}
							iconPosition="start"
						/>
					);
				})}
			</Tabs>
			<PrinterList
				desktopId={desktopId}
				onPrinterAdded={onPrinterAdded}
			/>
		</div>
	);
};
