import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useJobStore } from "../../store/Jobs";
import { useCentralStore } from "../../store/Central";
import { useEffect, useState } from "react";
import { JobRequestEntityType } from "@/lib/supabase/supabaseTypes";
import { Loading } from "@/components/src/animations/loading";

export const JobRequestPage = () => {
	const { requestId } = useParams();

	const { jobRequests, changeRequestToJob, fetchJobRequest } = useJobStore(
		(state) => ({
			jobRequests: state.jobRequests,
			changeRequestToJob: state.changeRequestToJob,
			fetchJobRequest: state.fetchJobRequest,
		})
	);
	const clientId = useCentralStore((state) => state.client?.id);
	const [jobRequest, setJobRequest] = useState<JobRequestEntityType | null>(
		null
	);
	const [loading, setLoading] = useState<boolean>(true);

	const navigate = useNavigate();
	useEffect(() => {
		if (!requestId) return;
		const fetchAndSetRequest = async () => {
			const req = await fetchJobRequest(Number(requestId));

			setJobRequest(req);

			setLoading(false);
		};
		fetchAndSetRequest();
	}, [jobRequests, requestId]);

	const createJobAndUpdateRequest = async (accepted: boolean) => {
		if (!jobRequest) return;
		const jobId = await changeRequestToJob(jobRequest, accepted);
		// If accepted and successful navigate to the new job else
		// navigate to the client page
		if (jobId) navigate(`/client/${clientId}/job/${jobId}`);
		else navigate(`/client/${clientId}`);
	};

	if (loading) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<Loading />
			</div>
		);
	}

	if (!loading && !jobRequest) {
		return (
			<div className="w-full h-full flex items-center justify-center text-3xl">
				Die Anfrage, die Sie suchen, existiert nicht.
			</div>
		);
	}

	return (
		<div className="w-full h-full flex justify-center items-center">
			<div className="w-full flex-col gap-8 h-full flex items-center justify-center">
				<span className="text-2xl font-bold">
					Eingehender Auftrag aus dentCONNECT
				</span>
				<div className="flex gap-2">
					<Button
						onClick={() => {
							createJobAndUpdateRequest(true);
						}}
					>
						Akzeptieren
					</Button>
					<Button
						onClick={() => {
							createJobAndUpdateRequest(false);
						}}
					>
						Ablehnen
					</Button>
				</div>
			</div>
		</div>
	);
};
