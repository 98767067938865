import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ErrorFallbackPage: React.FC<{
	error: Error;
	componentStack: string;
	resetError: () => void;
}> = ({ error, componentStack, resetError }) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "30px",
				height: "100vh",
				justifyContent: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					paddingBottom: "120px",
				}}
			>
				<Button
					onClick={() => {
						navigate("/");
						resetError();
					}}
					variant="outlined"
				>
					Zurück zur Startseite
				</Button>
				<Button onClick={resetError} variant="outlined">
					Nochmal versuchen
				</Button>
			</div>
		</div>
	);
};
