import { ClientEntityType, JobEntityType } from "@/lib/supabase/supabaseTypes";
import clsx from "clsx";
import { Tooltip } from "@mui/material";
import { checkDateInRange, colors } from "./utils";
import { useNavigate } from "react-router-dom";

export const JobTimeline = ({
	client,
	expanded,
	even,
	daysInRange,
}: {
	client: ClientEntityType & { jobs: JobEntityType[] };
	expanded: boolean;
	daysInRange: Date[];
	even: boolean;
}) => {
	const expandedHeight = client.jobs.length * 32 + 48;
	const navigate = useNavigate();
	return (
		<div
			style={{
				height: expanded ? expandedHeight : "48px",
				width: `${daysInRange.length * 28 * 4}px`,
			}}
			className={clsx(
				"transition-all overflow-hidden flex flex-col duration-150",
				{
					"bg-neutral-100": even,
				}
			)}
		>
			<div className="py-6 w-full" />
			{expanded &&
				client.jobs.map((job) => {
					return (
						<div key={job.id} className="w-full py-2.5   h-full">
							<div className="w-full h-full items-center flex">
								{daysInRange.map((date) => {
									const checkDate = checkDateInRange(
										new Date(
											job.incoming_order_date as string
										),
										new Date(
											job.outgoing_order_date as string
										),
										date
									);
									if (checkDate === null) {
										return (
											<div
												key={date.toString()}
												className="w-28 h-full"
											></div>
										);
									}
									return (
										<Tooltip
											key={date.toString()}
											title={`${new Date(
												job.incoming_order_date as string
											).toLocaleDateString("de-ch", {
												weekday: "short",
												day: "2-digit",
												month: "short",
											})} - ${new Date(
												job.outgoing_order_date as string
											).toLocaleDateString("de-ch", {
												weekday: "short",
												day: "2-digit",
												month: "short",
											})}`}
											arrow
											slotProps={{
												popper: {
													modifiers: [
														{
															name: "offset",
															options: {
																offset: [0, 2],
															},
														},
													],
												},
											}}
										>
											<div
												onClick={() => {
													navigate(
														`/client/${client.id}/job/${job.id}`
													);
												}}
												className={clsx(
													`w-28 cursor-pointer h-full ${
														colors[
															job.status as keyof typeof colors
														]
													}`,

													{
														"rounded-l-lg":
															checkDate === -1,
														"rounded-r-lg":
															checkDate === 1,
													}
												)}
											/>
										</Tooltip>
									);
								})}
							</div>
						</div>
					);
				})}
		</div>
	);
};
