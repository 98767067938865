import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { CustomDialog } from "../../custom-dialog/custom-dialog.component";

/**
 * LargeDialogFrameWithTabs - uses LargeDialogFrame as base component
 * @param marginTop - customize the margin top of the dialog, e.g. 0
 * @returns
 */
export const LargeDialogFrameWithTabs: React.FC<{
	id: string;
	open: boolean;
	onClose: () => void;
	tabs: {
		label: string;
		component: React.ReactNode;
		index: number;
	}[];
	height?: string;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	marginTop?: string;
}> = ({
	id,
	open,
	onClose,
	tabs,
	height = "90%",
	maxWidth = "xl",
	marginTop = "0rem",
}) => {
	const [tabValue, setTabValue] = useState(0);
	return (
		<CustomDialog
			maxWidth={maxWidth}
			open={open}
			onClose={onClose}
			height={height}
			dataTestId={`large-dialog-frame-with-tabs-${id}`}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					height: "100%",
				}}
			>
				<Tabs
					orientation="vertical"
					value={tabValue}
					onChange={(_, newValue) => {
						setTabValue(newValue);
					}}
					sx={{
						borderRight: 1,
						borderColor: "divider",
					}}
				>
					{tabs.map((tab) => (
						<Tab
							key={tab.index}
							onClick={() => setTabValue(tab.index)}
							label={tab.label}
							data-testid="large-dialog-frame-with-tabs-tab"
						/>
					))}
				</Tabs>
				<div
					style={{
						marginTop: marginTop,
						flex: 1, // takes up all remaining space
						overflowY: "auto",
					}}
				>
					{tabs[tabValue].component}
				</div>
			</div>
		</CustomDialog>
	);
};
