import { SignInLayout } from "@/components/src/signin/signin-components/sign-in-layout.component";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { Button } from "@mui/material";
import { useState } from "react";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from "../../components/ui/input-otp";

export const EnterTotp = () => {
	const [verifyCode, setVerifyCode] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState<boolean>(false);

	const onSubmitClicked = (verifyCode: string) => {
		setLoading(true);
		setError("");
		(async () => {
			try {
				const factors = await supabase.auth.mfa.listFactors();
				if (factors.error) {
					setError("Fehler beim Senden des TOTP.");
					Logger.error(error);
					return;
				}

				const totpFactor = factors.data.totp[0];

				if (!totpFactor) {
					setError("Keine TOTP-Faktoren gefunden.");
					Logger.error("No totp factors found");
					return;
				}

				const factorId = totpFactor.id;

				const challenge = await supabase.auth.mfa.challenge({
					factorId,
				});
				if (challenge.error) {
					setError(challenge.error.message);
					Logger.error(challenge.error.message);
					return;
				}

				const challengeId = challenge.data.id;

				const verify = await supabase.auth.mfa.verify({
					factorId,
					challengeId,
					code: verifyCode,
				});
				if (verify.error) {
					setError(
						"Fehler bei der Überprüfung des TOTP. Bitte versuchen Sie es erneut."
					);
					Logger.error(verify.error.message);
					return;
				}

				window.location.reload();
			} finally {
				setLoading(false);
			}
		})();
	};

	return (
		<SignInLayout title={"Eingabe TOTP"} subtitle="">
			<div className="flex flex-col  w-full items-center justify-center gap-4">
				<div className="text-center">
					Bitte geben Sie den Code aus Ihrer Authentifizierungs-App
					ein.
				</div>
				{!!error && (
					<div className="text-red-500 text-sm error">{error}</div>
				)}
				<InputOTP
					value={verifyCode}
					onChange={(value: string) => {
						setVerifyCode(value);
						if (value.length >= 6) {
							onSubmitClicked(value);
						}
					}}
					maxLength={6}
				>
					<InputOTPGroup>
						<InputOTPSlot index={0} />
						<InputOTPSlot index={1} />
						<InputOTPSlot index={2} />
					</InputOTPGroup>
					<InputOTPSeparator />
					<InputOTPGroup>
						<InputOTPSlot index={3} />
						<InputOTPSlot index={4} />
						<InputOTPSlot index={5} />
					</InputOTPGroup>
				</InputOTP>
				<Button
					disabled={loading}
					type="button"
					value="Submit"
					onClick={() => onSubmitClicked(verifyCode)}
				>
					Überprüfen
				</Button>
			</div>
		</SignInLayout>
	);
};
