import { Typography } from "@/components/src/typography/typography.component";
import { useSearchStore } from "../../store/Search";
import {
	ClientEntityType,
	JobEntityType,
	PatientWithShare,
} from "@/lib/supabase/supabaseTypes";
import { Card, Chip, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "../../store/Central";
import { useState } from "react";
import { PatientFormDialog } from "../../pages-via-dialog/patient/patient.dialog";
import { usePatientStore } from "../../store/Patient";
import { TEMPLATE_CODE } from "../../store/Central/utils";
import { LazyPatient } from "../../store/Patient/patients.store";

export const SearchPage: React.FC = () => {
	const {
		clients,
		patients,
		jobs,
		loadingClients,
		loadingPatients,
		loadingJobs,
	} = useSearchStore((state) => ({
		clients: state.searchResultsClients,
		patients: state.searchResultsPatients,
		jobs: state.searchResultsJobs,
		loadingClients: state.loadingClients,
		loadingPatients: state.loadingPatients,
		loadingJobs: state.loadingJobs,
	}));

	return (
		<div
			className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-4"
			data-testid="search-page"
		>
			<div>
				<div className="flex flex-row items-center gap-2">
					<Typography variant="title-md">Auftraggeber</Typography>
					{loadingClients && <CircularProgress size={20} />}
				</div>
				{clients
					?.filter((client) => client.code !== TEMPLATE_CODE)
					.map((client) => {
						return (
							<ClientResultCard key={client.id} client={client} />
						);
					})}
				{!loadingClients && clients.length === 0 && (
					<Typography variant="body-md">
						Keine Auftraggeber gefunden
					</Typography>
				)}
			</div>
			<div>
				<div className="flex flex-row items-center gap-2">
					<Typography variant="title-md">Patienten</Typography>
					{loadingPatients && <CircularProgress size={20} />}
				</div>
				{patients?.map((patient) => {
					return (
						<PatientResultCard
							key={patient.patient_id}
							lazyPatient={patient}
						/>
					);
				})}
				{!loadingPatients && patients.length === 0 && (
					<Typography variant="body-md">
						Keine Patienten gefunden
					</Typography>
				)}
			</div>
			<div>
				<div className="flex flex-row items-center gap-2">
					<Typography variant="title-md">Aufträge</Typography>
					{loadingJobs && <CircularProgress size={20} />}
				</div>
				{jobs
					?.filter((job) => job.code !== TEMPLATE_CODE)
					.map((job) => {
						return <JobResultCard key={job.id} job={job} />;
					})}
				{!loadingJobs && jobs.length === 0 && (
					<Typography variant="body-md">
						Keine Aufträge gefunden
					</Typography>
				)}
			</div>
		</div>
	);
};

const ResultCard: React.FC<{
	onClick: () => void;
	children: React.ReactNode;
}> = ({ onClick, children }) => {
	return (
		<Card
			sx={{
				marginBottom: "10px",
				cursor: "pointer",
			}}
			onClick={onClick}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "10px",
					padding: "10px",
				}}
			>
				{children}
			</div>
		</Card>
	);
};

const ClientResultCard: React.FC<{ client: ClientEntityType }> = ({
	client,
}) => {
	const navigate = useNavigate();
	return (
		<ResultCard
			onClick={() => {
				navigate(`/client/${client.id}`);
			}}
		>
			<Chip label={client.code} size="small" color="secondary" />
			<Typography variant="body-lg">
				{client.title} {client?.first_name} {client?.last_name}
			</Typography>
			<Typography variant="body-md">{client.email}</Typography>
			<Typography variant="body-md">
				{client.street} {client.postal_code} {client.city}
			</Typography>
		</ResultCard>
	);
};

const PatientResultCard: React.FC<{ lazyPatient: LazyPatient }> = ({
	lazyPatient,
}) => {
	const { getPatient, patientName } = usePatientStore((state) => ({
		getPatient: state.getPatient,
		patientName: state.patientName,
	}));
	const [patient, setPatient] = useState<PatientWithShare | null>(null);
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const handleOpenDialog = async () => {
		if (!patient) {
			const fetchedPatient = await getPatient(lazyPatient.patient_id);
			if (fetchedPatient) {
				setPatient(fetchedPatient);
			}
		}
		setOpenDialog(true);
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	return (
		<>
			<ResultCard onClick={handleOpenDialog}>
				<Chip
					label={lazyPatient?.code ?? ""}
					size="small"
					color="primary"
				/>
				<Typography variant="body-lg">
					{lazyPatient?.title ?? ""} {patientName(lazyPatient)}
				</Typography>
			</ResultCard>
			{patient && (
				<PatientFormDialog
					open={openDialog}
					onClose={handleCloseDialog}
					patient={patient}
				/>
			)}
		</>
	);
};

const JobResultCard: React.FC<{ job: JobEntityType }> = ({ job }) => {
	const navigate = useNavigate();
	const client =
		useCentralStore.getState().clientsLookup[job.client_id as string];
	return (
		<ResultCard
			onClick={() => {
				navigate(`/client/${job.client_id}/job/${job.id}`);
			}}
		>
			<Chip label={job.code} size="small" color="info" />
			<Chip
				label={client?.first_name + " " + client?.last_name}
				size="small"
				color="secondary"
			/>
			<Typography variant="body-lg">{job?.title}</Typography>
		</ResultCard>
	);
};
