import {
	Chip,
	CircularProgress,
	IconButton,
	Menu,
	MenuItem as MuiMenuItem,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Offset } from "@progress/kendo-react-popup";
import { ContextMenu, MenuSelectEvent } from "@progress/kendo-react-layout";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MenuItemButton } from "../../../../components/src/menu-item-button/menu-item-button.component";
import { JobStatusEnum } from "@/lib/types/job";
import { useJobActions } from "../../hooks/actions/useJobActions";
import { FilterList } from "@mui/icons-material";
import { MenuItem } from "@progress/kendo-react-layout";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useJobStore } from "../../store/Jobs";
import { useCentralStore } from "../../store/Central";
import { JobEntityTypeWithDocuments } from "../../store/Jobs/utils";
import { usePatientStore } from "../../store/Patient";

// TODO: find more elegant way for prop drilling onRefresh
// TODO: in this case don't refresh jobs in ClientJobList component but simply use setJobs, filtering out the deleted job

const jobStatusOptions = [
	{
		status: JobStatusEnum.NOT_STARTED,
		label: "Voranmeldung",
	},
	{
		status: JobStatusEnum.IN_PROGRESS,
		label: "In Arbeit",
	},
	{
		status: JobStatusEnum.COMPLETED,
		label: "Geliefert",
	},
	{
		status: JobStatusEnum.BOOKED_SINGLE,
		label: "Verbucht als Einzelrechnung",
	},
	{
		status: JobStatusEnum.BOOKED_MONTHLY,
		label: "Verbucht als Sammelrechnung",
	},
	{
		status: JobStatusEnum.ARCHIVED,
		label: "Archiviert",
	},
];

export const JobList: React.FC<{
	onRefresh?: () => void;
}> = ({ onRefresh }) => {
	return (
		<div
			style={{
				paddingTop: "6px",
			}}
		>
			{jobStatusOptions.map((option) => (
				<SingleJobList
					key={option.label}
					label={option.label}
					onRefresh={onRefresh}
					status={option.status}
				/>
			))}
		</div>
	);
};

const dateFilters = [
	{
		label: "Alle",
		value: 0, // 0 means all time
	},
	{
		label: "1 Jahr",
		value: 1,
	},
	{
		label: "2 Jahre",
		value: 2,
	},
	{
		label: "3 Jahre",
		value: 3,
	},
	{
		label: "4 Jahre",
		value: 4,
	},
	{
		label: "5 Jahre",
		value: 5,
	},
];
const SingleJobList: React.FC<{
	label: string;
	status: JobStatusEnum;
	onRefresh?: () => void;
}> = ({ label, status, onRefresh }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openFilter = Boolean(anchorEl);
	const [filterIsLoading, setFilterIsLoading] = useState(false);
	const [jobsLoading, setJobsLoading] = useState(false);

	// as a loading of less than 300ms is barely perceptible to the user, we use the
	// loading state only if loading exceeds 300ms
	const [loadingExceeded, setLoadingExceeded] = useState(false);
	const {
		jobList,
		selectedJobStatus,
		fetchJobsByStatus,
		changeSelectedJobStatus,
	} = useJobStore((state) => ({
		jobList: state.jobList,
		selectedJobStatus: state.selectedJobStatus,
		fetchJobsByStatus: state.fetchJobsByStatus,
		changeSelectedJobStatus: state.changeSelectedJobStatus,
	}));

	const handleToggleList = (status: JobStatusEnum) => {
		if (selectedJobStatus == status) changeSelectedJobStatus(null);
		else changeSelectedJobStatus(status);
	};

	const { clientId, searchConfig, updateSearchConfig } = useCentralStore();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// await fetchJobsByStatus because we need to wait for the jobs to be fetched before we can set the loading state to false
	const asyncFetchJobsByStatus = async (status: JobStatusEnum) => {
		setLoadingExceeded(false);
		setJobsLoading(true);
		setTimeout(() => {
			setLoadingExceeded(true);
		}, 300);
		await fetchJobsByStatus(status);
		setJobsLoading(false);
	};

	useEffect(() => {
		asyncFetchJobsByStatus(status);
	}, [clientId, status]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleFilter = async (filter: number) => {
		setFilterIsLoading(true);
		setAnchorEl(null);
		await updateSearchConfig({
			statuses: {
				...(searchConfig?.statuses as Record<JobStatusEnum, number>),
				[status]: filter,
			},
		});
		setFilterIsLoading(false);
	};

	const jobCount = jobList[status].jobCount;

	const jobs = jobList[status].jobs;

	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<IconButton
						aria-label="open"
						size="small"
						onClick={() => handleToggleList(status)}
					>
						{selectedJobStatus == status ? (
							<ArrowDropDownIcon fontSize="small" />
						) : (
							<ArrowRightIcon fontSize="small" />
						)}
					</IconButton>
					<div>
						{label}
						{selectedJobStatus !== status &&
							jobCount > 0 &&
							!jobsLoading && (
								<Chip
									label={jobCount}
									size="small"
									style={{
										marginLeft: "6px",
										fontSize: "12px",
									}}
								/>
							)}
						{jobsLoading && loadingExceeded && (
							<CircularProgress
								size={10}
								style={{
									marginLeft: "6px",
								}}
							/>
						)}
					</div>
				</div>
				<div
					style={{
						position: "relative",
					}}
				>
					<IconButton
						disabled={Boolean(anchorEl)}
						onClick={handleClick}
					>
						{filterIsLoading ? (
							<AutorenewIcon fontSize="small" />
						) : (
							<FilterList fontSize="small" />
						)}
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={openFilter}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{dateFilters.map((filter) => (
							<MuiMenuItem
								onClick={() => handleFilter(filter.value)}
								key={filter.label}
								sx={{
									backgroundColor:
										searchConfig?.statuses?.[status] ===
										filter.value
											? "var(--neutral-100)"
											: "white",
								}}
							>
								{filter.label}
							</MuiMenuItem>
						))}
					</Menu>
				</div>
			</div>
			{selectedJobStatus === status && (
				<div
					style={{
						maxHeight: "40vh",
						overflowY: "auto",
					}}
				>
					{!jobsLoading &&
						jobs?.map((job: JobEntityTypeWithDocuments) => (
							<JobListItem
								key={job.id}
								job={job}
								onRefresh={onRefresh}
							/>
						))}
				</div>
			)}
		</div>
	);
};

const JobListItem: React.FC<{
	job: JobEntityTypeWithDocuments;
	onRefresh?: () => void;
}> = ({ job, onRefresh }) => {
	const jobId = useJobStore((state) => state.job?.id);
	const navigate = useNavigate();
	const clientId = useCentralStore((state) => state.client?.id);
	const { deleteJob, isDeleteJobAllowed, archiveJob, isArchiveJobAllowed } =
		useJobActions();
	const { patientName, patientsLookup } = usePatientStore((state) => ({
		patientName: state.patientName,
		patientsLookup: state.patientsLookup,
	}));

	const handleClick = () => {
		if (!show) {
			const url = (job as any).request
				? `/client/${clientId}/request/${job.id}`
				: `/client/${clientId}/job/${job.id}`;
			navigate(url);
		}
	};

	const offSet = useRef<Offset>({ left: 0, top: 0 });
	const [show, setShow] = useState(false);

	const handleContextMenu = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.preventDefault();
		offSet.current = { left: e.clientX, top: e.clientY };
		setShow(true);
	};

	useEffect(() => {
		document.addEventListener("click", () => {
			show ? setShow(false) : null;
		});
	}, [show]);

	const handleOnClose = () => {
		setShow(false);
	};

	const handleOnSelect = async (e: MenuSelectEvent) => {
		switch (e.item.data.action) {
			case "new-window":
				handleNewWindow();
				break;
			case "delete":
				await deleteJob(job);
				onRefresh ? onRefresh() : null;
				break;
			case "archive":
				archiveJob(job);
				break;
			default:
		}
		setShow(false);
	};

	const handleNewWindow = () => {
		window.open(
			`${window.location.origin}/client/${clientId}/job/${job.id}`,
			"_blank"
		);
	};

	return (
		<>
			<MenuItemButton
				onClick={handleClick}
				onContextMenu={handleContextMenu}
				selected={job.id === jobId}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "2px",
					}}
				>
					<p>
						<b>
							{job?.code ?? ""}
							{job.patient_id && " "}
						</b>{" "}
						{job.patient_id &&
							patientName(patientsLookup[job.patient_id])}
					</p>
					<p
						style={{
							fontStyle: "italic",
						}}
					>
						{job.title ? job.title : ""}
					</p>
				</div>
			</MenuItemButton>
			<ContextMenu
				vertical={true}
				onSelect={handleOnSelect}
				onClose={handleOnClose}
				show={show}
				offset={offSet.current}
				style={{ display: "inline-block" }}
			>
				<MenuItem
					data={{ action: "new-window" }}
					render={() => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "6px",
									paddingTop: "6px",
									paddingBottom: "6px",
								}}
							>
								<p>In neuem Fenster öffnen</p>
								<OpenInNewIcon fontSize="small" />
							</div>
						);
					}}
				/>
				<MenuItem
					data={{ action: "archive" }}
					disabled={!isArchiveJobAllowed(job).isAllowed}
					render={() => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "6px",
									paddingTop: "6px",
									paddingBottom: "6px",
								}}
							>
								<div>Archivieren</div>
							</div>
						);
					}}
				/>
				<MenuItem
					data={{ action: "delete" }}
					disabled={!isDeleteJobAllowed(job).isAllowed}
					render={() => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "6px",
									paddingTop: "6px",
									paddingBottom: "6px",
								}}
							>
								<div>Löschen</div>
							</div>
						);
					}}
				/>
			</ContextMenu>
		</>
	);
};
