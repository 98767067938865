export const ActivityElementWrapperWithLabel: React.FC<{
  children: React.ReactNode;
  label: string;
}> = ({ children, label }) => {
  return (
    <div>
      <p
        style={{
          fontSize: "12px",
          fontWeight: "500",
          color: "gray",
          paddingBottom: "5px",
        }}
      >
        {label}
      </p>
      {children}
    </div>
  );
};
