import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";

export const FloatInput: React.FC<{
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
}> = ({ label, value, onChange }) => {
  const handleChange = (e: NumericTextBoxChangeEvent) => {
    onChange(e.value);
  };

  return (
    <NumericTextBox
      label={label}
      // Added for e2e test on tariffs and articles
      name={label}
      format="n2"
      value={value}
      onChange={handleChange}
    />
  );
};
