import { Tooltip } from "@mui/material";

export type StatusCircleStatusType = "success" | "warning" | "error" | "info";

const colorMap = {
	success: "green",
	warning: "orange",
	error: "red",
	info: "blue",
	default: "grey",
};

export const StatusCircle: React.FC<{
	status: StatusCircleStatusType;
	tooltipText?: string;
	variant?: "flat" | "raised";
}> = ({ status, tooltipText, variant = "raised" }) => {
	const color = colorMap[status] || colorMap.default;

	return (
		<Tooltip title={tooltipText || ""} data-testid="status-circle-tooltip">
			<div
				data-testid="status-circle"
				style={{
					width: "10px",
					height: "10px",
					borderRadius: "50%",
					backgroundColor: color,
					boxShadow:
						variant === "raised" ? `0 0 8px ${color}` : "none",
					cursor: tooltipText ? "pointer" : "default",
				}}
			></div>
		</Tooltip>
	);
};
