import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/**
 * DialogWithTitleAndActions (https://mui.com/material-ui/react-dialog/#customization)
 * @param children - the content of the dialog
 * @param dialogTitle
 * @param open
 * @param onClose
 * @param dialogFooterActions
 */
export const DialogWithTitleAndActions: React.FC<{
  children: React.ReactNode;
  dialogTitle: string;
  open: boolean;
  onClose: () => void;
  dialogFooterActions?: React.ReactNode;
}> = ({ children, dialogTitle, open, onClose, dialogFooterActions }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>{dialogTitle}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
      {dialogFooterActions && (
        <DialogActions>{dialogFooterActions}</DialogActions>
      )}
    </BootstrapDialog>
  );
};
