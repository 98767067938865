import { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../../../../lib/logger/Logger";
import {
	ProfileEntityType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { useCentralStore } from "../../store/Central";

type TeamProfilesContextType = {
	teamProfiles: ProfileEntityType[];
	teamProfilesLookup: {
		[key: string]: ProfileEntityType;
	};
	fetchTeamProfiles?: (organizationId: string) => void;
};

const TeamProfilesContext = createContext<{
	teamProfiles: TeamProfilesContextType;
	setTeamProfiles: React.Dispatch<
		React.SetStateAction<TeamProfilesContextType>
	>;
	fetchTeamProfiles?: (organizationId: string) => void;
}>({
	teamProfiles: {
		teamProfiles: [],
		teamProfilesLookup: {},
	},
	setTeamProfiles: () => {},
	fetchTeamProfiles: () => {},
});

export const TeamProfilesContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: any) => {
	const [teamProfiles, setTeamProfiles] = useState<TeamProfilesContextType>({
		teamProfiles: [],
		teamProfilesLookup: {},
	});
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();

	useEffect(() => {
		if (organizationId) {
			fetchTeamProfiles(organizationId);
		}
	}, [organizationId]);

	const fetchTeamProfiles = async (organizationId_: string) => {
		const { success, data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.PROFILES,
			{
				column: "organization_id",
				value: organizationId_,
			}
		);
		if (success && data) {
			Logger.log("fetchTeamProfiles", data);
			const teamProfilesLookup = data.reduce(
				(acc: any, profile: ProfileEntityType) => ({
					...acc,
					[profile.id]: profile,
				}),
				{}
			);
			Logger.log("teamProfilesLookup", teamProfilesLookup);
			setTeamProfiles({
				teamProfiles: data,
				teamProfilesLookup: teamProfilesLookup,
			});
		}
	};

	return (
		<TeamProfilesContext.Provider
			value={{ teamProfiles, setTeamProfiles, fetchTeamProfiles }}
		>
			{children}
		</TeamProfilesContext.Provider>
	);
};

/**
 * useTeamProfilesContext
 * @example const { teamProfiles: { teamProfiles, teamProfilesLookup }, setTeamProfiles } = useTeamProfilesContext();
 */
export const useTeamProfilesContext = () => {
	const context = useContext(TeamProfilesContext);
	if (!context) {
		throw new Error(
			"useTeamProfilesContext must be used within a TeamProfilesContextProvider"
		);
	}
	return context;
};
