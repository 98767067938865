import React from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useCentralStore } from "../store/Central";

export default function IntercomSnippet() {
	const { userId, userName, userEmail } = useCentralStore((state) => ({
		userId: state.userId,
		userName: state.profile?.first_name + " " + state.profile?.last_name,
		userEmail: state.userEmail,
	}));

	Intercom({
		app_id: import.meta.env.VITE_INTERCOM_APP_ID,
		user_id: userId ?? undefined,
		name: userName ?? undefined,
		email: userEmail ?? undefined,
		language_override: "de",
	});

	return <></>;
}
