import { type ChangeEvent, type FC } from "react";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";

interface MwstInputProps {
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	validationError: string | null;
	validMwst: boolean;
	setValidMwst: (validMwst: boolean) => void;
}

const MwstInput: FC<MwstInputProps> = ({
	value,
	onChange,
	validationError,
	validMwst,
	setValidMwst,
}) => {
	const validateMwst = (mwst: string): boolean => {
		const regex = /^CHE-\d{3}\.\d{3}\.\d{3}$/;
		return regex.test(mwst);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue === "") {
			setValidMwst(true);
		} else {
			const isValid = validateMwst(inputValue);
			setValidMwst(isValid);
			if (isValid) {
				onChange(e);
			}
		}
	};

	return (
		<div>
			<CustomTextField
				sx={{ width: "500px" }}
				label="MwSt.-Nummer"
				name="mwst_number"
				value={value}
				onChange={handleChange}
				validationError={validationError}
			/>
			{!validMwst && (
				<div style={{ color: "red" }}>
					MwSt.-Nummer ist nicht korrekt.
				</div>
			)}
		</div>
	);
};

export default MwstInput;
