import { productionCountriesOptions } from "@/dentlab/src/lib/constants/productionCountries";
import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

export const ProdCountryChoiceSelect: React.FC<SelectProps> = (props) => {
	return (
		<CustomSelect
			options={productionCountriesOptions}
			inputLabel="Fertigung"
			{...props}
		/>
	);
};
