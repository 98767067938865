import {
	JobWithShare,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../useDatabase";
import { useToast } from "../../context/ToastContext";
import { useDialog } from "../../context/DialogContext";
import { Logger } from "@/lib/logger/Logger";
import { PermissionStatus } from "./actions-hooks-types";
import { JobStatusEnum } from "@/lib/types/job";
import { DBOperationResult } from "../../types/types";
import { useJobStore } from "../../store/Jobs";
import { showNotification } from "../../store/Central/selectors";
import { JobEntityTypeWithDocuments } from "../../store/Jobs/utils";

/**
 * useJobActions - Custom hook to create, modify, or delete jobs
 * The hook handles whether an action is allowed so it should be used whenever a job is created, modified, or deleted
 */
export const useJobActions = () => {
	const { showToast } = useToast();
	const { showDialog } = useDialog();
	const { updateDataWithPromise, deleteDataWithPromise } =
		useDatabaseFunctionsWithPromise();
	const { deleteJobInStore, changeJobStatus, updateJob } = useJobStore(
		(state) => ({
			fetchJobsByStatus: state.fetchJobsByStatus,
			deleteJobInStore: state.deleteJob,
			changeJobStatus: state.changeJobStatus,
			updateJob: state.updateJob,
		})
	);

	const _deleteJob = async (
		job: JobEntityTypeWithDocuments
	): Promise<boolean> => {
		const { success } = await deleteDataWithPromise(
			SupabaseTableEnum.JOBS,
			[job.id]
		);

		if (success) {
			showToast("Auftrag gelöscht", "success");
			return true;
		} else {
			showToast("Fehler beim Löschen des Auftrags", "error");
			return false;
		}
	};

	/**
	 * deleteJob - Function to delete a job
	 * @param {JobEntityTypeWithDocuments} job - The job to delete
	 * @returns {Promise<boolean>}
	 *
	 * ALLOWED: see isDeleteJobAllowed
	 */
	const deleteJob = async (job: JobEntityTypeWithDocuments) => {
		const permissionStatus = isDeleteJobAllowed(job);
		if (!permissionStatus.isAllowed) {
			showToast(permissionStatus.explanation, "error");
			return;
		}
		if (permissionStatus.needsConfirmation) {
			Logger.info("deleteJob", "needs confirmation");
			showDialog({
				title: "Möchtest du diesen Auftrag wirklich löschen?",
				content: "",
				actions: [
					{
						actionLabel: "Abbrechen",
						actionHandler: () => {},
					},
					{
						actionLabel: "Löschen",
						actionHandler: () => _deleteJob(job),
					},
				],
			});
		} else {
			deleteJobInStore(job);
		}
	};

	const archiveJob = async (job: JobEntityTypeWithDocuments) => {
		const permissionStatus = isArchiveJobAllowed(job);
		if (!permissionStatus.isAllowed) {
			showToast(permissionStatus.explanation, "error");
		} else {
			const success = changeJobStatus(job, JobStatusEnum.ARCHIVED);

			if (!success) {
				showNotification({
					message: "Fehler beim Archivieren des Auftrags",
					type: "error",
				});
			}

			showNotification({
				message: "Auftrag archiviert",
				type: "success",
			});
		}
	};

	const updateJobCode = async (
		job: JobWithShare,
		code: string
	): Promise<boolean> => {
		const permissionStatus = isUpdateJobCodeAllowed(job);
		if (!permissionStatus.isAllowed) {
			showToast(permissionStatus.explanation, "error");
			return false;
		}

		const success = await updateJob(job, "code", code);
		if (!success) {
			showNotification({
				message: "Fehler beim Aktualisieren des Auftrags",
				type: "error",
			});
			return false;
		}

		showNotification({
			message: "Auftrag aktualisiert",
			type: "success",
		});
		return true;
	};

	/**
	 * updateJobStatus
	 * @param {number} jobId - The job id
	 * @param {JobStatusEnum} status - The job status
	 */
	const updateJobStatus = async (
		jobId: number,
		status: JobStatusEnum
	): Promise<DBOperationResult> => {
		return updateDataWithPromise(SupabaseTableEnum.JOBS, [
			{ id: jobId, status },
		]);
	};

	/**
	 * isUpdateJobCodeAllowed - Function to check if a job code can be updated
	 * @param {JobEntityType} job
	 *
	 * ALLOWED: If job is not completed or booked
	 */
	const isUpdateJobCodeAllowed = (job: JobWithShare): PermissionStatus => {
		if (
			job.status === JobStatusEnum.COMPLETED ||
			job.status === JobStatusEnum.BOOKED_MONTHLY ||
			job.status === JobStatusEnum.BOOKED_SINGLE
		) {
			return {
				isAllowed: false,
				explanation: "Der Auftrag ist abgeschlossen oder gebucht",
			};
		}

		return {
			isAllowed: true,
			needsConfirmation: false,
			explanation: "Der Auftrag ist nicht abgeschlossen oder gebucht",
		};
	};

	/**
	 * isDeleteJobAllowed - Function to check if a job can be deleted
	 * @param {JobEntityTypeWithDocuments} job - The job to delete
	 * @returns {PermissionStatus}
	 *
	 * ALLOWED:
	 * - If the job has no job_documents (without confirmation popup)
	 * - If the job is archived (without confirmation popup)
	 */
	const isDeleteJobAllowed = (
		job: JobEntityTypeWithDocuments
	): PermissionStatus => {
		if (job.job_documents?.length === 0) {
			return {
				isAllowed: true,
				needsConfirmation: false,
				explanation: "Der Auftrag hat keine Dokumente",
			};
		} else if (job.status === JobStatusEnum.ARCHIVED) {
			return {
				isAllowed: true,
				needsConfirmation: true,
				explanation: "Der Auftrag ist archiviert",
			};
		}

		return {
			isAllowed: false,
			explanation: "Der Auftrag hat Dokumente und ist nicht archiviert",
		};
	};

	/**
	 * isArchiveJobAllowed - Function to check if a job can be archived
	 * @param {JobEntityTypeWithDocuments} job - The job to archive
	 * @returns {PermissionStatus}
	 *
	 * ALLOWED: If the job has no job_documents or only archived job_documents
	 */
	const isArchiveJobAllowed = (
		job: JobEntityTypeWithDocuments
	): PermissionStatus => {
		if (job.job_documents?.length === 0) {
			return {
				isAllowed: true,
				needsConfirmation: false,
				explanation: "Der Auftrag hat keine Dokumente",
			};
		}

		const hasNoActiveDocuments = job.job_documents?.every(
			(doc) => doc.status === JobStatusEnum.ARCHIVED
		);

		if (hasNoActiveDocuments) {
			return {
				isAllowed: true,
				needsConfirmation: false,
				explanation: "Der Auftrag hat nur archivierte Dokumente",
			};
		}

		return {
			isAllowed: false,
			explanation: "Der Auftrag hat aktive Dokumente",
		};
	};

	return {
		deleteJob,
		isDeleteJobAllowed,
		archiveJob,
		isArchiveJobAllowed,
		updateJobCode,
		isUpdateJobCodeAllowed,
		updateJobStatus,
	};
};
