import {
	ClientEntityType,
	GuarantorEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfTemplateType } from "../document-template.types";
import { getConfig } from "../../context/util";
import clsx from "clsx";

export const PdfRecipientAddress: React.FC<{
	recipient: ClientEntityType | GuarantorEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	template?: PdfTemplateType | "general";
}> = ({ recipient, pdfTemplateConfiguration, template }) => {
	const alignment = {
		left: "start",
		center: "center",
		right: "end",
	};
	// const config = pdfTemplateConfiguration?.[template ?? "general"];
	const generalConfig = pdfTemplateConfiguration!.general;
	const pdfConfig = pdfTemplateConfiguration?.[template ?? "general"] || {};

	const config = getConfig(pdfConfig, generalConfig);

	return (
		<div
			aria-label="Empfängeradresse"
			style={{
				height: "25mm",
				paddingBottom: "10mm",
				width: "100%",
				paddingLeft: generalConfig?.clientAddressLeft ? "6mm" : "97mm", // Linksadressierungen beginnen 26mm von links, Rechtsadressierungen 117mm von links.
				fontSize: "10pt",
				display: "flex",
				...(!config.absoluteRecipientAddress
					? {
							alignItems:
								alignment[
									config.recipientAddressAlignment ?? "left"
								],
						}
					: {}),
				flexDirection: "column",
			}}
		>
			<div
				className={clsx("text-left", {
					"top-[48mm] absolute": config.absoluteRecipientAddress,
					"left-[120mm]":
						config.absoluteRecipientAddress &&
						config.recipientAddressAlignment === "right",
					"left-[20mm]":
						config.absoluteRecipientAddress &&
						config.recipientAddressAlignment !== "right",
				})}
			>
				<p>{recipient.title}</p>
				<p>
					{recipient.last_name} {recipient.first_name}
				</p>
				<p>{recipient.street}</p>
				<p>
					{recipient.postal_code} {recipient.city}
				</p>
			</div>
		</div>
	);
};
