/**
 * job_items.type
 * Enum for the different job item types
 * TARIFF = 0 -- Tarife/Arbeit
 * ARTICLE_MATERIAL = 1 -- Material
 * ARTICLE_POSTAGE = 2 -- Porto
 * ARTICLE_EXTERNAL_WORK = 3 -- Fremdarbeit
 * ARTICLE_DISCOUNTS = 4 -- Abzug/Barrabatt
 * ARTICLE_FIXED_RATE = 5 -- Arbeitspauschale
 *
 * If type < 1, it is a tariff
 * If type >= 1, it is an article
 */
export enum JobItemTypeEnum {
	TARIFF = 0, // Arbeit
	ARTICLE_MATERIAL = 1, // Material
	ARTICLE_POSTAGE = 2, // Porto (Expresszuschlag/Porto)
	ARTICLE_EXTERNAL_WORK = 3, // Fremdarbeit (Fremdarbeiten)
	ARTICLE_DISCOUNTS = 4, // Abzug/Barrabatt (Rückvergütung/Barrabatt)
	ARTICLE_FIXED_RATE = 5, // Arbeitspauschale (Pauschalzuschlag für Arbeit)
}

/**
 * articles.type
 * Enum for the different article types
 * Identical to JobItemTypeEnum, except for excluding TARIFF
 *
 * Articles are always >= 1
 */
export enum ArticleTypeEnum {
	MATERIAL = JobItemTypeEnum.ARTICLE_MATERIAL,
	POSTAGE = JobItemTypeEnum.ARTICLE_POSTAGE,
	EXTERNAL_WORK = JobItemTypeEnum.ARTICLE_EXTERNAL_WORK,
	DISCOUNTS = JobItemTypeEnum.ARTICLE_DISCOUNTS,
	FIXED_RATE = JobItemTypeEnum.ARTICLE_FIXED_RATE,
}

export enum CountryEnum {
	SWITZERLAND = "ch",
	GERMANY = "de",
	ITALY = "it",
	FRANCE = "fr",
	AUSTRIA = "at",
	LIECHTENSTEIN = "li",
}

export enum DesktopSystemTypeEnum {
	PRINTER = 1,
}
