import { Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { Database } from "../../../../../../lib/supabase";
import { FormProps } from "../../../form-types";
import { PaymentSlipAddressTypeSelect } from "../../../form-input-components/selects/payment-slip-address-type.select";
import {
	BankAccountEntityType,
	SupabaseTableEnum,
} from "../../../../../../lib/supabase/supabaseTypes";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { CurrencySelect } from "../../../form-input-components/selects/currency-select";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { Currency } from "@/dentlab/src/types/enums";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

// TODO: add explanations
// Name der Bank. Freilassen wenn Sie ein PC Konto benutzen.

export const BankAccountForm: React.FC<
	// To make this component usable both for create and update, we enable passing the bankAccountId (if the account exists)
	FormProps & {
		bankAccountId?: string;
	}
> = ({
	onSave = (bankAccount: BankAccountEntityType) => {},
	submitRef = null,
	bankAccountId = undefined,
}) => {
	const supabaseTable = SupabaseTableEnum.BANK_ACCOUNTS;

	const { organizationId, getBankAccounts } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		getBankAccounts: state.getBankAccounts,
	}));
	const { setIsPendingChanges } = useCustomDialogContext();

	const isUpdate = bankAccountId ? true : false;
	const useFormCustomFields = {
		organization_id: organizationId,
	};
	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? bankAccountId : undefined;

	const {
		handleInputChange,
		handleCreate,
		formData,
		formErrors,
		setFormData,
		handleValueChange,
		handleUpdate,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["bank_accounts"]["Insert"]>(
		useFormCustomFields,
		supabaseTable,
		useFormFetchData,
		useFormFetchId,
		"Bankkonto",
		setIsPendingChanges
	);

	const handleUpdateSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleUpdate(event, bankAccountId);
	};

	const handleInsertSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const { success, data, error } = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		if (data) {
			const bankAccount = data[0];
			onSave(bankAccount);
			getBankAccounts();
		}
		if (error) {
			showNotification({
				message: "Bankkonto konnte nicht gespeichert werden",
				type: "error",
			});
		}
	};

	const validateReferenceNumber = (value: string) => {
		if (value.length <= 13) {
			return true;
		} else {
			showNotification({
				message: "Referenznummer darf nicht länger als 13 Zeichen sein",
				type: "error",
			});

			return false;
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
		>
			<FormSectionWithTitle title="Informationen Bankkonto">
				<CustomTextField
					label="Bankname"
					value={formData.bank_name || ""}
					name="bank_name"
					onChange={handleInputChange}
					disabled={formData.is_pc_account ? true : false}
					validationError={formErrors.bank_name}
					customHelperText="Name der Bank. Freilassen wenn Sie ein PC-Konto benutzen."
				/>
				<CustomTextField
					label="Bankort"
					value={formData.bank_city || ""}
					name="bank_city"
					onChange={handleInputChange}
					disabled={formData.is_pc_account ? true : false}
					validationError={formErrors.bank_city}
					customHelperText="PLZ/Ort der Bank. Freilassen wenn Sie ein PC-Konto benutzen."
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "start",
					}}
				>
					<FormControlLabel
						control={
							<Checkbox
								checked={formData.is_pc_account ? true : false}
								name="is_pc_account"
								onChange={(e) => {
									setFormData({
										...formData,
										is_pc_account: e.target.checked,
										reference_number: "0000000000000",
									});
								}}
								color="primary"
							/>
						}
						label="PC-Konto"
						sx={{
							mt: "6.5px",
							width: "30%",
						}}
					/>
					<CustomTextField
						label="PC-Konto"
						value={formData.pc_account || ""}
						name="pc_account"
						onChange={handleInputChange}
						disabled={!formData.is_pc_account}
						validationError={formErrors.pc_account}
						customHelperText="PC-Konto der Bank (wird Ihnen von der Bank mitgeteilt) oder Ihr PC Konto, wenn Sie via Post abrechnen in der Form 01-20000-0."
						fullWidth
					/>
				</div>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Informationen Zahlungsempfänger (Zahlschein)">
				<CurrencySelect
					value={formData.currency || Currency.CHF}
					onChange={(value: any) =>
						handleValueChange("currency", value)
					}
				/>
				{/* TODO: somehow make it clear in the db that this field is now for both IBAN and QR-IBAN */}
				<CustomTextField
					label="Konto (IBAN)"
					value={formData.qr_iban || ""}
					name="qr_iban"
					onChange={handleInputChange}
					validationError={formErrors.qr_iban}
					required
				/>
				<CustomTextField
					required
					label="Name"
					value={formData.cdtr_name || ""}
					name="cdtr_name"
					onChange={handleInputChange}
					validationError={formErrors.cdtr_name}
					helperText="Name bzw. Firma des Zahlungsempfängers gemäss Kontobezeichnung"
				/>
				{/* We are using only "S" for now, so the following is not needed: */}
				{/* <PaymentSlipAddressTypeSelect
					value="S"
					onChange={(value) => handleValueChange("cdtr_adrtp", value)}
					disabled={true}
				/> */}
				<CustomTextField
					label="Strasse" // If we were using "K" (ZE_ADRESS_TYP/ctdr_adrtp), we would need to use "Strasse oder Adresszeile 1"
					value={formData.cdtr_street_line_1 || ""}
					name="cdtr_street_line_1"
					onChange={handleInputChange}
					required
					validationError={formErrors.cdtr_street_line_1}
				/>
				<CustomTextField
					label="Hausnummer" // If we were using "K" (ZE_ADRESS_TYP/ctdr_adrtp), we would need to use "Hausnummer oder Adresszeile 2"
					value={formData.cdtr_house_number_line_2 || ""}
					name="cdtr_house_number_line_2"
					onChange={handleInputChange}
					required
					validationError={formErrors.cdtr_house_number_line_2}
				/>
				<CustomTextField
					label="Postleitzahl"
					value={formData.cdtr_area_code || ""}
					name="cdtr_area_code"
					onChange={handleInputChange}
					validationError={formErrors.cdtr_area_code}
					required
				/>
				<CustomTextField
					label="Ort"
					value={formData.cdtr_city || ""}
					name="cdtr_city"
					onChange={handleInputChange}
					validationError={formErrors.cdtr_city}
					required
				/>
				<CustomTextField
					label="Referenznummer"
					value={formData.reference_number || ""}
					name="reference_number"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						validateReferenceNumber(event.target.value) &&
						handleInputChange(event)
					}
					disabled={formData.is_pc_account ? true : false}
					validationError={formErrors.reference_number}
					customHelperText="Wenn Sie von Ihrer Bank eine Referenznummer erhalten, geben Sie 0000000000000 (13 Nullen) ein."
					required
				/>
				<CustomTextField
					label="Kontoname"
					value={formData.account_name || ""}
					name="account_name"
					onChange={handleInputChange}
					validationError={formErrors.account_name}
					customHelperText="Der Name des Kontos (wird in der Fakturierung und in der Debikontrolle angezeigt)."
				/>
				<CustomTextField
					label="Briefkopf"
					value={formData.letterhead || ""}
					name="letterhead"
					onChange={handleInputChange}
					validationError={formErrors.letterhead}
					customHelperText="Auf Rechnungen, KVs etc. kann dieser Text im Briefkopf verwendet werden."
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
