import { IconButton } from "@mui/material";
import { useState } from "react";
import { ShareDialog } from "../../../components/sharing/share-dialog/share-dialog.component";
import ShareIcon from "@mui/icons-material/Share";
import {
	isShared,
	JobWithShare,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { useShared } from "../../../hooks/useActivities";
import { useSendEmail } from "../../../hooks/useSendEmail";
import { Logger } from "../../../../../lib/logger/Logger";
import { PractitionerOptionType } from "../share-dialog/practitioner-emails-autocomplete";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const ShareJobButton: React.FC<{
	job: JobWithShare;
	setJob: (job: JobWithShare) => void;
}> = ({ job, setJob }) => {
	const { addSharedJobActivity } = useShared();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
	const { showToast } = useToast();

	const handleShare = async () => {
		if (!connectRelationshipId || !job.id) {
			Logger.error("No connect relationship id found or job id is null");
			return;
		}

		const { data: activityNewEntry, error: activityError } =
			await addSharedJobActivity(connectRelationshipId, job.id);

		if (activityError || !activityNewEntry) {
			showToast("Error beim Teilen des Auftrags", "error");
			Logger.error(
				"ShareJobButton: handleShare: activitySuccess is false. Error: ",
				activityError
			);
			return;
		}

		setJob({ ...job, shared_ids: [(activityNewEntry as any).id] });
	};

	const handleUnshare = () => {
		// TODO: Re-implement unshare
	};

	const { sendSharedJobEmailTemplate } = useSendEmail();
	const handleNotify = async (
		practitioners: PractitionerOptionType[],
		message: string
	) => {
		if (!isShared(job)) {
			Logger.error(
				"ShareJobButton: handleNotify: job is not shared. Cannot send email",
				job
			);
			return;
		}

		const { label: emailAddress } = practitioners[0];

		sendSharedJobEmailTemplate({
			emailAddress,
			jobTitle: job.title ?? "",
			link: `https://dentconnect.dentdesk.ch/job/${job.id}`,
			message,
		});

		setIsSharePopupOpen(false);
	};

	if (!job) return null;
	// if the client is not connected, don't show the share button
	if (!connectRelationshipId) return null;
	return (
		<>
			<IconButton onClick={() => setIsSharePopupOpen(true)}>
				{isShared(job) ? (
					<CloudDoneIcon
						sx={{
							color: "green",
						}}
					/>
				) : (
					<ShareIcon
						sx={{
							color: "black",
						}}
					/>
				)}
			</IconButton>
			<ShareDialog
				open={isSharePopupOpen}
				onClose={() => setIsSharePopupOpen(false)}
				shared={isShared(job)}
				entityName="Auftrag"
				onShare={handleShare}
				onUnshare={handleUnshare}
				onNotify={handleNotify}
			/>
		</>
	);
};
