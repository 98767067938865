export const postalCodeToCodeAndCityString: {
	[key: string]: string;
} = {
	1000: "1000 Lausanne",
	1003: "1003 Lausanne",
	1004: "1004 Lausanne",
	1005: "1005 Lausanne",
	1006: "1006 Lausanne",
	1007: "1007 Lausanne",
	1008: "1008 Prilly",
	1009: "1009 Pully",
	1010: "1010 Lausanne",
	1011: "1011 Lausanne",
	1012: "1012 Lausanne",
	1015: "1015 Lausanne",
	1018: "1018 Lausanne",
	1020: "1020 Renens VD",
	1022: "1022 Chavannes-près-Renens",
	1023: "1023 Crissier",
	1024: "1024 Ecublens VD",
	1025: "1025 St-Sulpice VD",
	1026: "1026 Echandens",
	1027: "1027 Lonay",
	1028: "1028 Préverenges",
	1029: "1029 Villars-Ste-Croix",
	1030: "1030 Bussigny-près-Lausanne",
	1031: "1031 Mex VD",
	1032: "1032 Romanel-sur-Lausanne",
	1033: "1033 Cheseaux-sur-Lausanne",
	1034: "1034 Boussens",
	1035: "1035 Bournens",
	1036: "1036 Sullens",
	1037: "1037 Etagnières",
	1038: "1038 Bercher",
	1040: "1040 Echallens",
	1041: "1041 Poliez-le-Grand",
	1042: "1042 Assens",
	1043: "1043 Sugnens",
	1044: "1044 Fey",
	1045: "1045 Ogens",
	1046: "1046 Rueyres",
	1047: "1047 Oppens",
	1052: "1052 Le Mont-sur-Lausanne",
	1053: "1053 Cugy VD",
	1054: "1054 Morrens VD",
	1055: "1055 Froideville",
	1058: "1058 Villars-Tiercelin",
	1059: "1059 Peney-le-Jorat",
	1061: "1061 Villars-Mendraz",
	1062: "1062 Sottens",
	1063: "1063 Martherenges",
	1066: "1066 Epalinges",
	1068: "1068 Les Monts-de-Pully",
	1070: "1070 Puidoux",
	1071: "1071 Chexbres",
	1072: "1072 Forel (Lavaux)",
	1073: "1073 Savigny",
	1076: "1076 Ferlens VD",
	1077: "1077 Servion",
	1078: "1078 Essertes",
	1080: "1080 Les Cullayes",
	1081: "1081 Montpreveyres",
	1082: "1082 Corcelles-le-Jorat",
	1083: "1083 Mézières VD",
	1084: "1084 Carrouge VD",
	1085: "1085 Vulliens",
	1088: "1088 Ropraz",
	1090: "1090 La Croix (Lutry)",
	1091: "1091 Grandvaux",
	1092: "1092 Belmont-sur-Lausanne",
	1093: "1093 La Conversion",
	1094: "1094 Paudex",
	1095: "1095 Lutry",
	1096: "1096 Cully",
	1097: "1097 Riex",
	1098: "1098 Epesses",
	1110: "1110 Morges",
	1112: "1112 Echichens",
	1113: "1113 St-Saphorin-sur-Morges",
	1114: "1114 Colombier VD",
	1115: "1115 Vullierens",
	1116: "1116 Cottens VD",
	1117: "1117 Grancy",
	1121: "1121 Bremblens",
	1122: "1122 Romanel-sur-Morges",
	1123: "1123 Aclens",
	1124: "1124 Gollion",
	1125: "1125 Monnaz",
	1126: "1126 Vaux-sur-Morges",
	1127: "1127 Clarmont",
	1128: "1128 Reverolle",
	1131: "1131 Tolochenaz",
	1132: "1132 Lully VD",
	1134: "1134 Chigny",
	1135: "1135 Denens",
	1136: "1136 Bussy-Chardonney",
	1141: "1141 Sévery",
	1142: "1142 Pampigny",
	1143: "1143 Apples",
	1144: "1144 Ballens",
	1145: "1145 Bière",
	1146: "1146 Mollens VD",
	1147: "1147 Montricher",
	1148: "1148 L'Isle",
	1149: "1149 Berolle",
	1162: "1162 St-Prex",
	1163: "1163 Etoy",
	1164: "1164 Buchillon",
	1165: "1165 Allaman",
	1166: "1166 Perroy",
	1167: "1167 Lussy-sur-Morges",
	1168: "1168 Villars-sous-Yens",
	1169: "1169 Yens",
	1170: "1170 Aubonne",
	1172: "1172 Bougy-Villars",
	1173: "1173 Féchy",
	1174: "1174 Pizy",
	1175: "1175 Lavigny",
	1176: "1176 St-Livres",
	1180: "1180 Rolle",
	1182: "1182 Gilly",
	1183: "1183 Bursins",
	1184: "1184 Luins",
	1185: "1185 Mont-sur-Rolle",
	1186: "1186 Essertines-sur-Rolle",
	1187: "1187 St-Oyens",
	1188: "1188 St-George",
	1189: "1189 Saubraz",
	1195: "1195 Bursinel",
	1196: "1196 Gland",
	1197: "1197 Prangins",
	1200: "1200 Genève",
	1201: "1201 Genève",
	1202: "1202 Genève",
	1203: "1203 Genève",
	1204: "1204 Genève",
	1205: "1205 Genève",
	1206: "1206 Genève",
	1207: "1207 Genève",
	1208: "1208 Genève",
	1209: "1209 Genève",
	1212: "1212 Grand-Lancy",
	1213: "1213 Onex",
	1214: "1214 Vernier",
	1215: "1215 Genève 15 Aéroport",
	1216: "1216 Cointrin",
	1217: "1217 Meyrin",
	1218: "1218 Le Grand-Saconnex",
	1219: "1219 Châtelaine",
	1220: "1220 Les Avanchets",
	1222: "1222 Vésenaz",
	1223: "1223 Cologny",
	1224: "1224 Chêne-Bougeries",
	1225: "1225 Chêne-Bourg",
	1226: "1226 Thônex",
	1227: "1227 Carouge GE",
	1228: "1228 Plan-les-Ouates",
	1231: "1231 Conches",
	1232: "1232 Confignon",
	1233: "1233 Bernex",
	1234: "1234 Vessy",
	1236: "1236 Cartigny",
	1237: "1237 Avully",
	1239: "1239 Collex",
	1241: "1241 Puplinge",
	1242: "1242 Satigny",
	1243: "1243 Presinge",
	1244: "1244 Choulex",
	1245: "1245 Collonge-Bellerive",
	1246: "1246 Corsier GE",
	1247: "1247 Anières",
	1248: "1248 Hermance",
	1251: "1251 Gy",
	1252: "1252 Meinier",
	1253: "1253 Vandoeuvres",
	1254: "1254 Jussy",
	1255: "1255 Veyrier",
	1256: "1256 Troinex",
	1257: "1257 La Croix-de-Rozon",
	1258: "1258 Perly",
	1260: "1260 Nyon",
	1261: "1261 Le Vaud",
	1262: "1262 Eysins",
	1263: "1263 Crassier",
	1264: "1264 St-Cergue",
	1265: "1265 La Cure",
	1266: "1266 Duillier",
	1267: "1267 Coinsins",
	1268: "1268 Begnins",
	1269: "1269 Bassins",
	1270: "1270 Trélex",
	1271: "1271 Givrins",
	1272: "1272 Genolier",
	1273: "1273 Arzier",
	1274: "1274 Signy",
	1275: "1275 Chéserex",
	1276: "1276 Gingins",
	1277: "1277 Borex",
	1278: "1278 La Rippe",
	1279: "1279 Chavannes-de-Bogis",
	1281: "1281 Russin",
	1283: "1283 La Plaine",
	1284: "1284 Chancy",
	1285: "1285 Athenaz (Avusy)",
	1286: "1286 Soral",
	1287: "1287 Laconnex",
	1288: "1288 Aire-la-Ville",
	1290: "1290 Chavannes-des-Bois",
	1291: "1291 Commugny",
	1292: "1292 Chambésy",
	1293: "1293 Bellevue",
	1294: "1294 Genthod",
	1295: "1295 Tannay",
	1296: "1296 Coppet",
	1297: "1297 Founex",
	1298: "1298 Céligny",
	1299: "1299 Crans-près-Céligny",
	1302: "1302 Vufflens-la-Ville",
	1303: "1303 Penthaz",
	1304: "1304 Cossonay-Ville",
	1305: "1305 Penthalaz",
	1306: "1306 Daillens",
	1307: "1307 Lussery-Villars",
	1308: "1308 La Chaux (Cossonay)",
	1312: "1312 Eclépens",
	1313: "1313 Ferreyres",
	1315: "1315 La Sarraz",
	1316: "1316 Chevilly",
	1317: "1317 Orny",
	1318: "1318 Pompaples",
	1321: "1321 Arnex-sur-Orbe",
	1322: "1322 Croy",
	1323: "1323 Romainmôtier",
	1324: "1324 Premier",
	1325: "1325 Vaulion",
	1326: "1326 Juriens",
	1329: "1329 Bretonnières",
	1337: "1337 Vallorbe",
	1338: "1338 Ballaigues",
	1341: "1341 Orient",
	1342: "1342 Le Pont",
	1343: "1343 Les Charbonnières",
	1344: "1344 L'Abbaye",
	1345: "1345 Le Lieu",
	1346: "1346 Les Bioux",
	1347: "1347 Le Sentier",
	1348: "1348 Le Brassus",
	1350: "1350 Orbe",
	1352: "1352 Agiez",
	1353: "1353 Bofflens",
	1354: "1354 Montcherand",
	1355: "1355 L'Abergement",
	1356: "1356 La Russille",
	1357: "1357 Lignerolle",
	1358: "1358 Valeyres-sous-Rances",
	1372: "1372 Bavois",
	1373: "1373 Chavornay",
	1374: "1374 Corcelles-sur-Chavornay",
	1375: "1375 Penthéréaz",
	1376: "1376 Goumoens-la-Ville",
	1377: "1377 Oulens-sous-Echallens",
	1400: "1400 Cheseaux-Noréaz",
	1404: "1404 Villars-Epeney",
	1405: "1405 Pomy",
	1406: "1406 Cronay",
	1407: "1407 Donneloye",
	1408: "1408 Prahins",
	1409: "1409 Chanéaz",
	1410: "1410 Thierrens",
	1412: "1412 Ursins",
	1413: "1413 Orzens",
	1415: "1415 Démoret",
	1416: "1416 Pailly",
	1417: "1417 Essertines-sur-Yverdon",
	1418: "1418 Vuarrens",
	1420: "1420 Fiez",
	1421: "1421 Grandevent",
	1422: "1422 Grandson",
	1423: "1423 Villars-Burquin",
	1424: "1424 Champagne",
	1425: "1425 Onnens VD",
	1426: "1426 Concise",
	1427: "1427 Bonvillars",
	1428: "1428 Provence",
	1429: "1429 Giez",
	1430: "1430 Orges",
	1431: "1431 Novalles",
	1432: "1432 Gressy",
	1433: "1433 Suchy",
	1434: "1434 Ependes VD",
	1435: "1435 Essert-Pittet",
	1436: "1436 Chamblon",
	1437: "1437 Suscévaz",
	1438: "1438 Mathod",
	1439: "1439 Rances",
	1441: "1441 Valeyres-sous-Montagny",
	1442: "1442 Montagny-près-Yverdon",
	1443: "1443 Villars-sous-Champvent",
	1445: "1445 Vuiteboeuf",
	1446: "1446 Baulmes",
	1450: "1450 Ste-Croix",
	1452: "1452 Les Rasses",
	1453: "1453 Bullet",
	1454: "1454 L'Auberson",
	1462: "1462 Yvonand",
	1463: "1463 Rovray",
	1464: "1464 Chavannes-le-Chêne",
	1468: "1468 Cheyres",
	1470: "1470 Estavayer-le-Lac",
	1473: "1473 Châtillon FR",
	1474: "1474 Châbles FR",
	1475: "1475 Montbrelloz",
	1482: "1482 Cugy FR",
	1483: "1483 Montet (Broye)",
	1484: "1484 Granges-de-Vesin",
	1485: "1485 Nuvilly",
	1486: "1486 Vuissens",
	1489: "1489 Murist",
	1509: "1509 Vucherens",
	1510: "1510 Moudon",
	1512: "1512 Chavannes-sur-Moudon",
	1513: "1513 Rossenges",
	1514: "1514 Bussy-sur-Moudon",
	1515: "1515 Villars-le-Comte",
	1521: "1521 Curtilles",
	1522: "1522 Lucens",
	1523: "1523 Granges-près-Marnand",
	1524: "1524 Marnand",
	1525: "1525 Henniez",
	1526: "1526 Cremin",
	1527: "1527 Villeneuve FR",
	1528: "1528 Surpierre",
	1529: "1529 Cheiry",
	1530: "1530 Payerne",
	1532: "1532 Fétigny",
	1533: "1533 Ménières",
	1534: "1534 Sassel",
	1535: "1535 Combremont-le-Grand",
	1536: "1536 Combremont-le-Petit",
	1537: "1537 Champtauroz",
	1538: "1538 Treytorrens (Payerne)",
	1541: "1541 Bussy FR",
	1542: "1542 Rueyres-les-Prés",
	1543: "1543 Grandcour",
	1544: "1544 Gletterens",
	1545: "1545 Chevroux",
	1551: "1551 Vers-chez-Perrin",
	1552: "1552 Trey",
	1553: "1553 Châtonnaye",
	1554: "1554 Rossens VD",
	1555: "1555 Villarzel",
	1562: "1562 Corcelles-près-Payerne",
	1563: "1563 Dompierre FR",
	1564: "1564 Domdidier",
	1565: "1565 Missy",
	1566: "1566 St-Aubin FR",
	1567: "1567 Delley",
	1568: "1568 Portalban",
	1580: "1580 Avenches",
	1582: "1582 Donatyre",
	1583: "1583 Villarepos",
	1584: "1584 Villars-le-Grand",
	1585: "1585 Salavaux",
	1586: "1586 Vallamand",
	1587: "1587 Constantine",
	1588: "1588 Cudrefin",
	1589: "1589 Chabrey",
	1595: "1595 Faoug",
	1607: "1607 Palézieux",
	1608: "1608 Oron-le-Châtel",
	1609: "1609 St-Martin FR",
	1610: "1610 Oron-la-Ville",
	1611: "1611 Le Crêt-près-Semsales",
	1612: "1612 Ecoteaux",
	1613: "1613 Maracon",
	1614: "1614 Granges (Veveyse)",
	1615: "1615 Bossonnens",
	1616: "1616 Attalens",
	1617: "1617 Remaufens",
	1618: "1618 Châtel-St-Denis",
	1619: "1619 Les Paccots",
	1623: "1623 Semsales",
	1624: "1624 Progens",
	1625: "1625 Sâles (Gruyère)",
	1626: "1626 Rueyres-Treyfayes",
	1627: "1627 Vaulruz",
	1628: "1628 Vuadens",
	1630: "1630 Bulle",
	1632: "1632 Riaz",
	1633: "1633 Marsens",
	1634: "1634 La Roche FR",
	1635: "1635 La Tour-de-Trême",
	1636: "1636 Broc",
	1637: "1637 Charmey (Gruyère)",
	1638: "1638 Morlon",
	1642: "1642 Sorens",
	1643: "1643 Gumefens",
	1644: "1644 Avry-devant-Pont",
	1645: "1645 Le Bry",
	1646: "1646 Echarlens",
	1647: "1647 Corbières",
	1648: "1648 Hauteville",
	1649: "1649 Pont-la-Ville",
	1651: "1651 Villarvolard",
	1652: "1652 Villarbeney",
	1653: "1653 Crésuz",
	1654: "1654 Cerniat FR",
	1656: "1656 Jaun",
	1657: "1657 Abländschen",
	1658: "1658 Rossinière",
	1659: "1659 Rougemont",
	1660: "1660 La Lécherette",
	1661: "1661 Le Pâquier-Montbarry",
	1663: "1663 Epagny",
	1665: "1665 Estavannens",
	1666: "1666 Grandvillard",
	1667: "1667 Enney",
	1669: "1669 Albeuve",
	1670: "1670 Ursy",
	1673: "1673 Promasens",
	1674: "1674 Morlens",
	1675: "1675 Mossel",
	1676: "1676 Chavannes-les-Forts",
	1677: "1677 Prez-vers-Siviriez",
	1678: "1678 Siviriez",
	1679: "1679 Villaraboud",
	1680: "1680 Romont FR",
	1681: "1681 Hennens",
	1682: "1682 Prévonloup",
	1683: "1683 Sarzens",
	1684: "1684 Mézières FR",
	1685: "1685 Villariaz",
	1686: "1686 La Neirigue",
	1687: "1687 Vuisternens-devant-Romont",
	1688: "1688 Lieffrens",
	1689: "1689 Le Châtelard-près-Romont",
	1690: "1690 Villaz-St-Pierre",
	1691: "1691 Villarimboud",
	1692: "1692 Massonnens",
	1694: "1694 Villarsiviriaux",
	1695: "1695 Villarsel-le-Gibloux",
	1696: "1696 Vuisternens-en-Ogoz",
	1697: "1697 Les Ecasseys",
	1699: "1699 Porsel",
	1700: "1700 Fribourg",
	1708: "1708 Fribourg",
	1712: "1712 Tafers",
	1713: "1713 St. Antoni",
	1714: "1714 Heitenried",
	1715: "1715 Alterswil FR",
	1716: "1716 Plaffeien",
	1717: "1717 St. Ursen",
	1718: "1718 Rechthalten",
	1719: "1719 Brünisried",
	1720: "1720 Corminboeuf",
	1721: "1721 Misery",
	1722: "1722 Bourguillon",
	1723: "1723 Villarsel-sur-Marly",
	1724: "1724 Le Mouret",
	1725: "1725 Posieux",
	1726: "1726 Farvagny",
	1727: "1727 Corpataux-Magnedens",
	1728: "1728 Rossens FR",
	1730: "1730 Ecuvillens",
	1731: "1731 Ependes FR",
	1732: "1732 Arconciel",
	1733: "1733 Treyvaux",
	1734: "1734 Tentlingen",
	1735: "1735 Giffers",
	1736: "1736 St. Silvester",
	1737: "1737 Plasselb",
	1738: "1738 Sangernboden",
	1740: "1740 Neyruz FR",
	1741: "1741 Cottens FR",
	1742: "1742 Autigny",
	1744: "1744 Chénens",
	1745: "1745 Lentigny",
	1746: "1746 Prez-vers-Noréaz",
	1747: "1747 Corserey",
	1748: "1748 Torny-le-Grand",
	1749: "1749 Middes",
	1752: "1752 Villars-sur-Glâne",
	1753: "1753 Matran",
	1754: "1754 Avry-Centre FR",
	1756: "1756 Lovens",
	1757: "1757 Noréaz",
	1762: "1762 Givisiez",
	1763: "1763 Granges-Paccot",
	1772: "1772 Grolley",
	1773: "1773 Russy",
	1774: "1774 Cousset",
	1775: "1775 Grandsivaz",
	1776: "1776 Montagny-la-Ville",
	1782: "1782 Belfaux",
	1783: "1783 Barberêche",
	1784: "1784 Courtepin",
	1785: "1785 Cressier FR",
	1786: "1786 Sugiez",
	1787: "1787 Mur (Vully) VD",
	1788: "1788 Praz (Vully)",
	1789: "1789 Lugnorre",
	1791: "1791 Courtaman",
	1792: "1792 Cordast",
	1793: "1793 Jeuss",
	1794: "1794 Salvenach",
	1795: "1795 Courlevon",
	1796: "1796 Courgevaux",
	1797: "1797 Münchenwiler",
	1800: "1800 Vevey",
	1801: "1801 Le Mont-Pèlerin",
	1802: "1802 Corseaux",
	1803: "1803 Chardonne",
	1804: "1804 Corsier-sur-Vevey",
	1805: "1805 Jongny",
	1806: "1806 St-Légier-La Chiésaz",
	1807: "1807 Blonay",
	1808: "1808 Les Monts-de-Corsier",
	1809: "1809 Fenil-sur-Corsier",
	1814: "1814 La Tour-de-Peilz",
	1815: "1815 Clarens",
	1816: "1816 Chailly-Montreux",
	1817: "1817 Brent",
	1820: "1820 Territet",
	1822: "1822 Chernex",
	1823: "1823 Glion",
	1824: "1824 Caux",
	1832: "1832 Chamby",
	1833: "1833 Les Avants",
	1844: "1844 Villeneuve VD",
	1845: "1845 Noville",
	1846: "1846 Chessel",
	1847: "1847 Rennaz",
	1852: "1852 Roche VD",
	1853: "1853 Yvorne",
	1854: "1854 Leysin",
	1856: "1856 Corbeyrier",
	1860: "1860 Aigle",
	1862: "1862 Les Mosses",
	1863: "1863 Le Sépey",
	1864: "1864 Vers-l'Eglise",
	1865: "1865 Les Diablerets",
	1866: "1866 La Forclaz VD",
	1867: "1867 Ollon VD",
	1868: "1868 Collombey",
	1869: "1869 Massongex",
	1870: "1870 Monthey",
	1871: "1871 Les Giettes",
	1872: "1872 Troistorrents",
	1873: "1873 Val-d'Illiez",
	1874: "1874 Champéry",
	1875: "1875 Morgins",
	1880: "1880 Bex",
	1882: "1882 Gryon",
	1884: "1884 Villars-sur-Ollon",
	1885: "1885 Chesières",
	1890: "1890 St-Maurice",
	1891: "1891 Vérossaz",
	1892: "1892 Lavey-Village",
	1893: "1893 Muraz (Collombey)",
	1895: "1895 Vionnaz",
	1896: "1896 Vouvry",
	1897: "1897 Bouveret",
	1898: "1898 St-Gingolph",
	1899: "1899 Torgon",
	1902: "1902 Evionnaz",
	1903: "1903 Collonges",
	1904: "1904 Vernayaz",
	1905: "1905 Dorénaz",
	1906: "1906 Charrat",
	1907: "1907 Saxon",
	1908: "1908 Riddes",
	1911: "1911 Ovronnaz",
	1912: "1912 Leytron",
	1913: "1913 Saillon",
	1914: "1914 Isérables",
	1918: "1918 La Tzoumaz",
	1920: "1920 Martigny",
	1921: "1921 Martigny-Croix",
	1922: "1922 Salvan",
	1923: "1923 Les Marécottes",
	1925: "1925 Finhaut",
	1926: "1926 Fully",
	1927: "1927 Chemin",
	1928: "1928 Ravoire",
	1929: "1929 Trient",
	1932: "1932 Bovernier",
	1933: "1933 Sembrancher",
	1934: "1934 Le Châble VS",
	1936: "1936 Verbier",
	1937: "1937 Orsières",
	1938: "1938 Champex-Lac",
	1941: "1941 Vollèges",
	1942: "1942 Levron",
	1943: "1943 Praz-de-Fort",
	1944: "1944 La Fouly VS",
	1945: "1945 Petit Vichères (Liddes)",
	1946: "1946 Bourg-St-Pierre",
	1947: "1947 Versegères",
	1948: "1948 Le Morgnes (Lourtier)",
	1950: "1950 Sion",
	1955: "1955 Chamoson",
	1957: "1957 Ardon",
	1958: "1958 St-Léonard",
	1961: "1961 Vernamiège",
	1962: "1962 Pont-de-la-Morge (Sion)",
	1963: "1963 Vétroz",
	1964: "1964 Conthey",
	1965: "1965 Savièse",
	1966: "1966 Argnoud (Ayent)",
	1967: "1967 Bramois",
	1968: "1968 Mase",
	1969: "1969 St-Martin VS",
	1971: "1971 Grimisuat",
	1972: "1972 Anzère",
	1973: "1973 Nax",
	1974: "1974 Arbaz",
	1975: "1975 St-Séverin",
	1976: "1976 Erde",
	1977: "1977 Icogne",
	1978: "1978 Lens",
	1981: "1981 Vex",
	1982: "1982 Euseigne",
	1983: "1983 Evolène",
	1984: "1984 Les Haudères",
	1985: "1985 La Sage",
	1986: "1986 Arolla",
	1987: "1987 Hérémence",
	1988: "1988 Thyon",
	1991: "1991 Salins",
	1992: "1992 Crête-à-l'Oeil(Les Agettes)",
	1993: "1993 Veysonnaz",
	1994: "1994 Aproz (Nendaz)",
	1996: "1996 Basse-Nendaz",
	1997: "1997 Haute-Nendaz",
	2000: "2000 Neuchâtel",
	2012: "2012 Auvernier",
	2013: "2013 Colombier NE",
	2014: "2014 Bôle",
	2015: "2015 Areuse",
	2016: "2016 Cortaillod",
	2017: "2017 Boudry",
	2019: "2019 Rochefort",
	2022: "2022 Bevaix",
	2023: "2023 Gorgier",
	2024: "2024 St-Aubin-Sauges",
	2025: "2025 Chez-le-Bart",
	2027: "2027 Fresens",
	2028: "2028 Vaumarcus",
	2034: "2034 Peseux",
	2035: "2035 Corcelles NE",
	2036: "2036 Cormondrèche",
	2037: "2037 Montezillon",
	2042: "2042 Valangin",
	2043: "2043 Boudevilliers",
	2046: "2046 Fontaines NE",
	2052: "2052 Fontainemelon",
	2053: "2053 Cernier",
	2054: "2054 Chézard-St-Martin",
	2056: "2056 Dombresson",
	2057: "2057 Villiers",
	2058: "2058 Le Pâquier NE",
	2063: "2063 Vilars NE",
	2065: "2065 Savagnier",
	2067: "2067 Chaumont",
	2068: "2068 Hauterive NE",
	2072: "2072 St-Blaise",
	2073: "2073 Enges",
	2074: "2074 Marin-Epagnier",
	2075: "2075 Wavre",
	2087: "2087 Cornaux NE",
	2088: "2088 Cressier NE",
	2103: "2103 Noiraigue",
	2105: "2105 Travers",
	2108: "2108 Couvet",
	2112: "2112 Môtiers NE",
	2113: "2113 Boveresse",
	2114: "2114 Fleurier",
	2115: "2115 Buttes",
	2116: "2116 Mont-de-Buttes",
	2117: "2117 La Côte-aux-Fées",
	2123: "2123 St-Sulpice NE",
	2124: "2124 Les Sagnettes",
	2126: "2126 Les Verrières",
	2127: "2127 Les Bayards",
	2149: "2149 Brot-Dessous",
	2206: "2206 Les Geneveys-sur-Coffrane",
	2207: "2207 Coffrane",
	2208: "2208 Les Hauts-Geneveys",
	2300: "2300 La Cibourg",
	2314: "2314 La Sagne NE",
	2316: "2316 Les Ponts-de-Martel",
	2318: "2318 Brot-Plamboz",
	2322: "2322 Le Crêt-du-Locle",
	2325: "2325 Les Joux-Derrière",
	2333: "2333 La Ferrière",
	2336: "2336 Les Bois",
	2338: "2338 Muriaux",
	2340: "2340 Le Noirmont",
	2345: "2345 Les Breuleux",
	2350: "2350 Saignelégier",
	2353: "2353 Les Pommerats",
	2354: "2354 Goumois",
	2360: "2360 Le Bémont",
	2362: "2362 Montfaucon",
	2363: "2363 Les Enfers",
	2364: "2364 St-Brais",
	2400: "2400 Le Locle",
	2405: "2405 La Chaux-du-Milieu",
	2406: "2406 La Brévine",
	2414: "2414 Le Cerneux-Péquignot",
	2416: "2416 Les Brenets",
	2500: "2500 Biel/Bienne",
	2502: "2502 Biel/Bienne",
	2503: "2503 Biel/Bienne",
	2504: "2504 Biel/Bienne",
	2505: "2505 Biel/Bienne",
	2512: "2512 Tüscherz-Alfermée",
	2513: "2513 Twann",
	2514: "2514 Ligerz",
	2515: "2515 Prêles",
	2516: "2516 Lamboing",
	2517: "2517 Diesse",
	2518: "2518 Nods",
	2520: "2520 La Neuveville",
	2523: "2523 Lignières",
	2525: "2525 Le Landeron",
	2532: "2532 Magglingen/Macolin",
	2533: "2533 Evilard",
	2534: "2534 Orvin",
	2535: "2535 Frinvillier",
	2536: "2536 Plagne",
	2537: "2537 Vauffelin",
	2538: "2538 Romont BE",
	2540: "2540 Grenchen",
	2542: "2542 Pieterlen",
	2543: "2543 Lengnau BE",
	2544: "2544 Bettlach",
	2545: "2545 Selzach",
	2552: "2552 Orpund",
	2553: "2553 Safnern",
	2554: "2554 Meinisberg",
	2555: "2555 Brügg BE",
	2556: "2556 Schwadernau",
	2557: "2557 Studen BE",
	2558: "2558 Aegerten",
	2560: "2560 Nidau",
	2562: "2562 Port",
	2563: "2563 Ipsach",
	2564: "2564 Bellmund",
	2565: "2565 Jens",
	2572: "2572 Sutz",
	2575: "2575 Täuffelen",
	2576: "2576 Lüscherz",
	2577: "2577 Siselen BE",
	2603: "2603 Péry",
	2604: "2604 La Heutte",
	2605: "2605 Sonceboz-Sombeval",
	2606: "2606 Corgémont",
	2607: "2607 Cortébert",
	2608: "2608 Courtelary",
	2610: "2610 St-Imier",
	2612: "2612 Cormoret",
	2613: "2613 Villeret",
	2615: "2615 Sonvilier",
	2616: "2616 Renan BE",
	2710: "2710 Tavannes",
	2712: "2712 Le Fuet",
	2713: "2713 Bellelay",
	2714: "2714 Les Genevez JU",
	2715: "2715 Châtelat",
	2716: "2716 Sornetan",
	2717: "2717 Rebévelier",
	2718: "2718 Lajoux JU",
	2720: "2720 Tramelan",
	2722: "2722 Les Reussilles",
	2723: "2723 Mont-Tramelan",
	2732: "2732 Reconvilier",
	2733: "2733 Pontenet",
	2735: "2735 Malleray",
	2736: "2736 Sorvilier",
	2738: "2738 Court",
	2740: "2740 Moutier",
	2742: "2742 Perrefitte",
	2743: "2743 Eschert",
	2744: "2744 Belprahon",
	2745: "2745 Grandval",
	2746: "2746 Crémines",
	2747: "2747 Corcelles BE",
	2748: "2748 Les Ecorcheresses",
	2762: "2762 Roches BE",
	2800: "2800 Delémont",
	2802: "2802 Develier",
	2803: "2803 Bourrignon",
	2805: "2805 Soyhières",
	2806: "2806 Mettembert",
	2807: "2807 Pleigne",
	2812: "2812 Movelier",
	2813: "2813 Ederswiler",
	2814: "2814 Roggenburg",
	2822: "2822 Courroux",
	2823: "2823 Courcelon",
	2824: "2824 Vicques",
	2825: "2825 Courchapoix",
	2826: "2826 Corban",
	2827: "2827 Mervelier",
	2828: "2828 Montsevelier",
	2829: "2829 Vermes",
	2830: "2830 Courrendlin",
	2832: "2832 Rebeuvelier",
	2842: "2842 Rossemaison",
	2843: "2843 Châtillon JU",
	2852: "2852 Courtételle",
	2853: "2853 Courfaivre",
	2854: "2854 Bassecourt",
	2855: "2855 Glovelier",
	2856: "2856 Boécourt",
	2857: "2857 Montavon",
	2863: "2863 Undervelier",
	2864: "2864 Soulce",
	2873: "2873 Saulcy",
	2874: "2874 St-Brais",
	2875: "2875 Montfaucon",
	2877: "2877 Le Bémont JU",
	2882: "2882 St-Ursanne",
	2883: "2883 Montmelon",
	2884: "2884 Montenol",
	2885: "2885 Epauvillers",
	2886: "2886 Epiquerez",
	2887: "2887 Soubey",
	2888: "2888 Seleute",
	2889: "2889 Ocourt",
	2900: "2900 Porrentruy",
	2902: "2902 Fontenais",
	2903: "2903 Villars-sur Fontenais",
	2904: "2904 Bressaucourt",
	2905: "2905 Courtedoux",
	2906: "2906 Chevenez",
	2907: "2907 Rocourt",
	2908: "2908 Grandfontaine",
	2912: "2912 Réclère",
	2914: "2914 Damvant",
	2915: "2915 Bure",
	2916: "2916 Fahy",
	2922: "2922 Courchavon",
	2923: "2923 Courtemaîche",
	2924: "2924 Montignez",
	2925: "2925 Buix",
	2926: "2926 Boncourt",
	2932: "2932 Coeuve",
	2933: "2933 Lugnez",
	2935: "2935 Beurnevésin",
	2942: "2942 Alle",
	2943: "2943 Vendlincourt",
	2944: "2944 Bonfol",
	2946: "2946 Miécourt",
	2947: "2947 Charmoille",
	2950: "2950 Courgenay",
	2952: "2952 Cornol",
	2953: "2953 Pleujouse",
	2954: "2954 Asuel",
	3000: "3000 Bern",
	3004: "3004 Bern",
	3005: "3005 Bern",
	3006: "3006 Bern",
	3007: "3007 Bern",
	3008: "3008 Bern",
	3010: "3010 Bern",
	3011: "3011 Bern",
	3012: "3012 Bern",
	3013: "3013 Bern",
	3014: "3014 Bern",
	3015: "3015 Bern",
	3018: "3018 Bern",
	3019: "3019 Bern",
	3020: "3020 Bern",
	3027: "3027 Bern",
	3032: "3032 Hinterkappelen",
	3033: "3033 Wohlen b. Bern",
	3034: "3034 Murzelen",
	3035: "3035 Frieswil",
	3036: "3036 Detligen",
	3037: "3037 Herrenschwanden",
	3038: "3038 Kirchlindach",
	3042: "3042 Ortschwaben",
	3043: "3043 Uettligen",
	3044: "3044 Innerberg",
	3045: "3045 Meikirch",
	3046: "3046 Wahlendorf",
	3047: "3047 Bremgarten b. Bern",
	3048: "3048 Worblaufen",
	3049: "3049 Säriswil",
	3052: "3052 Zollikofen",
	3053: "3053 Münchenbuchsee",
	3054: "3054 Schüpfen",
	3063: "3063 Ittigen",
	3065: "3065 Bolligen",
	3066: "3066 Stettlen",
	3067: "3067 Boll",
	3068: "3068 Utzigen",
	3072: "3072 Ostermundigen",
	3073: "3073 Gümligen",
	3074: "3074 Muri b. Bern",
	3075: "3075 Rüfenacht BE",
	3076: "3076 Worb",
	3077: "3077 Enggistein",
	3078: "3078 Richigen",
	3082: "3082 Schlosswil",
	3083: "3083 Trimstein",
	3084: "3084 Wabern",
	3086: "3086 Zimmerwald",
	3087: "3087 Niedermuhlern",
	3088: "3088 Rüeggisberg",
	3089: "3089 Hinterfultigen",
	3095: "3095 Spiegel b. Bern",
	3096: "3096 Oberbalm",
	3097: "3097 Liebefeld",
	3098: "3098 Schliern b. Köniz",
	3099: "3099 Rüti b. Riggisberg",
	3110: "3110 Münsingen",
	3111: "3111 Tägertschi",
	3112: "3112 Allmendingen b. Bern",
	3113: "3113 Rubigen",
	3114: "3114 Wichtrach",
	3115: "3115 Gerzensee",
	3116: "3116 Kirchdorf BE",
	3122: "3122 Kehrsatz",
	3123: "3123 Belp",
	3124: "3124 Belpberg",
	3125: "3125 Toffen",
	3126: "3126 Kaufdorf",
	3127: "3127 Mühlethurnen",
	3128: "3128 Rümligen",
	3132: "3132 Riggisberg",
	3144: "3144 Gasel",
	3145: "3145 Niederscherli",
	3147: "3147 Mittelhäusern",
	3148: "3148 Lanzenhäusern",
	3150: "3150 Schwarzenburg",
	3152: "3152 Mamishaus",
	3153: "3153 Rüschegg Gambach",
	3154: "3154 Rüschegg Heubach",
	3155: "3155 Helgisried-Rohrbach",
	3156: "3156 Riffenmatt",
	3157: "3157 Milken",
	3158: "3158 Guggisberg",
	3159: "3159 Riedstätt",
	3172: "3172 Niederwangen b. Bern",
	3173: "3173 Oberwangen b. Bern",
	3174: "3174 Thörishaus",
	3175: "3175 Flamatt",
	3176: "3176 Neuenegg",
	3177: "3177 Laupen BE",
	3178: "3178 Bösingen",
	3179: "3179 Kriechenwil",
	3182: "3182 Ueberstorf",
	3183: "3183 Albligen",
	3184: "3184 Wünnewil",
	3185: "3185 Schmitten FR",
	3186: "3186 Düdingen",
	3202: "3202 Frauenkappelen",
	3203: "3203 Mühleberg",
	3204: "3204 Rosshäusern",
	3205: "3205 Gümmenen",
	3206: "3206 Wallenbuch",
	3207: "3207 Golaten",
	3208: "3208 Gurbrü",
	3210: "3210 Kerzers",
	3212: "3212 Gurmels",
	3213: "3213 Kleinbösingen",
	3214: "3214 Ulmiz",
	3215: "3215 Lurtigen",
	3216: "3216 Ried b. Kerzers",
	3225: "3225 Müntschemier",
	3226: "3226 Treiten",
	3232: "3232 Ins",
	3233: "3233 Tschugg",
	3234: "3234 Vinelz",
	3235: "3235 Erlach",
	3236: "3236 Gampelen",
	3237: "3237 Brüttelen",
	3238: "3238 Gals",
	3250: "3250 Lyss",
	3251: "3251 Wengi b. Büren",
	3252: "3252 Worben",
	3253: "3253 Schnottwil",
	3254: "3254 Messen",
	3255: "3255 Rapperswil BE",
	3256: "3256 Seewil",
	3257: "3257 Grossaffoltern",
	3262: "3262 Suberg",
	3263: "3263 Büetigen",
	3264: "3264 Diessbach b. Büren",
	3266: "3266 Wiler b. Seedorf",
	3267: "3267 Seedorf BE",
	3268: "3268 Lobsigen",
	3270: "3270 Aarberg",
	3271: "3271 Radelfingen b. Aarberg",
	3272: "3272 Walperswil",
	3273: "3273 Kappelen",
	3274: "3274 Merzligen",
	3280: "3280 Murten",
	3282: "3282 Bargen BE",
	3283: "3283 Kallnach",
	3284: "3284 Fräschels",
	3285: "3285 Galmiz",
	3286: "3286 Muntelier",
	3292: "3292 Busswil b. Büren",
	3293: "3293 Dotzigen",
	3294: "3294 Büren an der Aare",
	3295: "3295 Rüti b. Büren",
	3296: "3296 Arch",
	3297: "3297 Leuzigen",
	3298: "3298 Oberwil b. Büren",
	3302: "3302 Moosseedorf",
	3303: "3303 Jegenstorf",
	3305: "3305 Scheunen",
	3306: "3306 Etzelkofen",
	3307: "3307 Brunnenthal",
	3308: "3308 Grafenried",
	3309: "3309 Zauggenried",
	3312: "3312 Fraubrunnen",
	3313: "3313 Büren zum Hof",
	3314: "3314 Schalunen",
	3315: "3315 Bätterkinden",
	3317: "3317 Mülchi",
	3322: "3322 Urtenen-Schönbühl",
	3323: "3323 Bäriswil BE",
	3324: "3324 Hindelbank",
	3325: "3325 Hettiswil b. Hindelbank",
	3326: "3326 Krauchthal",
	3360: "3360 Herzogenbuchsee",
	3362: "3362 Niederönz",
	3363: "3363 Oberönz",
	3365: "3365 Grasswil",
	3366: "3366 Bollodingen",
	3367: "3367 Thörigen",
	3368: "3368 Bleienbach",
	3372: "3372 Wanzwil",
	3373: "3373 Röthenbach Herzogenbuchsee",
	3374: "3374 Wangenried",
	3375: "3375 Inkwil",
	3376: "3376 Berken",
	3377: "3377 Walliswil b. Wangen",
	3380: "3380 Wangen an der Aare",
	3400: "3400 Burgdorf",
	3412: "3412 Heimiswil",
	3413: "3413 Kaltacker",
	3414: "3414 Oberburg",
	3415: "3415 Hasle b. Burgdorf",
	3416: "3416 Affoltern im Emmental",
	3417: "3417 Rüegsau",
	3418: "3418 Rüegsbach",
	3419: "3419 Biembach im Emmental",
	3421: "3421 Lyssach",
	3422: "3422 Kirchberg BE",
	3423: "3423 Ersigen",
	3424: "3424 Oberösch",
	3425: "3425 Koppigen",
	3426: "3426 Aefligen",
	3427: "3427 Utzenstorf",
	3428: "3428 Wiler b. Utzenstorf",
	3429: "3429 Höchstetten",
	3432: "3432 Lützelflüh-Goldbach",
	3433: "3433 Schwanden im Emmental",
	3434: "3434 Obergoldbach",
	3435: "3435 Ramsei",
	3436: "3436 Zollbrück",
	3437: "3437 Rüderswil",
	3438: "3438 Lauperswil",
	3439: "3439 Ranflüh",
	3452: "3452 Grünenmatt",
	3453: "3453 Heimisbach",
	3454: "3454 Sumiswald",
	3455: "3455 Grünen",
	3456: "3456 Trachselwald",
	3457: "3457 Wasen im Emmental",
	3462: "3462 Weier im Emmental",
	3463: "3463 Häusernmoos im Emmental",
	3464: "3464 Schmidigen-Mühleweg",
	3465: "3465 Dürrenroth",
	3472: "3472 Wynigen",
	3473: "3473 Alchenstorf",
	3474: "3474 Rüedisbach",
	3475: "3475 Hermiswil",
	3476: "3476 Oschwand",
	3503: "3503 Gysenstein",
	3504: "3504 Oberhünigen",
	3506: "3506 Grosshöchstetten",
	3507: "3507 Biglen",
	3508: "3508 Arni BE",
	3510: "3510 Konolfingen",
	3512: "3512 Walkringen",
	3513: "3513 Bigenthal",
	3531: "3531 Oberthal",
	3532: "3532 Zäziwil",
	3533: "3533 Bowil",
	3534: "3534 Signau",
	3535: "3535 Schüpbach",
	3536: "3536 Aeschau",
	3537: "3537 Eggiwil",
	3538: "3538 Röthenbach im Emmental",
	3543: "3543 Emmenmatt",
	3550: "3550 Langnau im Emmental",
	3551: "3551 Oberfrittenbach",
	3552: "3552 Bärau",
	3553: "3553 Gohl",
	3555: "3555 Trubschachen",
	3556: "3556 Trub",
	3557: "3557 Fankhaus (Trub)",
	3600: "3600 Thun",
	3603: "3603 Thun",
	3604: "3604 Thun",
	3608: "3608 Thun",
	3612: "3612 Steffisburg",
	3613: "3613 Steffisburg",
	3614: "3614 Unterlangenegg",
	3615: "3615 Heimenschwand",
	3616: "3616 Schwarzenegg",
	3617: "3617 Fahrni b. Thun",
	3618: "3618 Süderen",
	3619: "3619 Innereriz",
	3622: "3622 Homberg b. Thun",
	3623: "3623 Buchen BE",
	3624: "3624 Goldiwil (Thun)",
	3625: "3625 Heiligenschwendi",
	3626: "3626 Hünibach",
	3627: "3627 Heimberg",
	3628: "3628 Uttigen",
	3629: "3629 Kiesen",
	3631: "3631 Höfen b. Thun",
	3632: "3632 Oberstocken",
	3633: "3633 Amsoldingen",
	3634: "3634 Thierachern",
	3635: "3635 Uebeschi",
	3636: "3636 Forst b. Längenbühl",
	3638: "3638 Blumenstein",
	3645: "3645 Zwieselberg",
	3646: "3646 Einigen",
	3647: "3647 Reutigen",
	3652: "3652 Hilterfingen",
	3653: "3653 Oberhofen am Thunersee",
	3654: "3654 Gunten",
	3655: "3655 Sigriswil",
	3656: "3656 Ringoldswil",
	3657: "3657 Schwanden (Sigriswil)",
	3658: "3658 Merligen",
	3661: "3661 Uetendorf",
	3662: "3662 Seftigen",
	3663: "3663 Gurzelen",
	3664: "3664 Burgistein",
	3665: "3665 Wattenwil",
	3671: "3671 Brenzikofen",
	3672: "3672 Oberdiessbach",
	3673: "3673 Linden",
	3674: "3674 Bleiken b. Oberdiessbach",
	3700: "3700 Spiez",
	3702: "3702 Hondrich",
	3703: "3703 Aeschi b. Spiez",
	3704: "3704 Krattigen",
	3705: "3705 Faulensee",
	3706: "3706 Leissigen",
	3707: "3707 Därligen",
	3711: "3711 Mülenen",
	3713: "3713 Reichenbach im Kandertal",
	3714: "3714 Frutigen",
	3715: "3715 Adelboden",
	3716: "3716 Kandergrund",
	3717: "3717 Blausee-Mitholz",
	3718: "3718 Kandersteg",
	3722: "3722 Scharnachtal",
	3723: "3723 Kiental",
	3724: "3724 Ried (Frutigen)",
	3725: "3725 Achseten",
	3752: "3752 Wimmis",
	3753: "3753 Oey",
	3754: "3754 Diemtigen",
	3755: "3755 Horboden",
	3756: "3756 Zwischenflüh",
	3757: "3757 Schwenden im Diemtigtal",
	3758: "3758 Latterbach",
	3762: "3762 Erlenbach im Simmental",
	3763: "3763 Därstetten",
	3764: "3764 Weissenburg",
	3765: "3765 Oberwil im Simmental",
	3766: "3766 Boltigen",
	3770: "3770 Zweisimmen",
	3771: "3771 Blankenburg",
	3772: "3772 St. Stephan",
	3773: "3773 Matten (St. Stephan)",
	3775: "3775 Lenk im Simmental",
	3776: "3776 Oeschseite",
	3777: "3777 Saanenmöser",
	3778: "3778 Schönried",
	3780: "3780 Gstaad",
	3781: "3781 Turbach",
	3782: "3782 Lauenen b. Gstaad",
	3783: "3783 Grund b. Gstaad",
	3784: "3784 Feutersoey",
	3785: "3785 Gsteig b. Gstaad",
	3792: "3792 Saanen",
	3800: "3800 Matten b. Interlaken",
	3801: "3801 Jungfraujoch",
	3803: "3803 Beatenberg",
	3804: "3804 Habkern",
	3805: "3805 Goldswil b. Interlaken",
	3806: "3806 Bönigen b. Interlaken",
	3807: "3807 Iseltwald",
	3812: "3812 Wilderswil",
	3813: "3813 Saxeten",
	3814: "3814 Gsteigwiler",
	3815: "3815 Gündlischwand",
	3816: "3816 Burglauenen",
	3818: "3818 Grindelwald",
	3822: "3822 Lauterbrunnen",
	3823: "3823 Wengen",
	3824: "3824 Stechelberg",
	3825: "3825 Mürren",
	3826: "3826 Gimmelwald",
	3852: "3852 Ringgenberg BE",
	3853: "3853 Niederried b. Interlaken",
	3854: "3854 Oberried am Brienzersee",
	3855: "3855 Axalp",
	3856: "3856 Brienzwiler",
	3857: "3857 Unterbach BE",
	3858: "3858 Hofstetten b. Brienz",
	3860: "3860 Brünig",
	3862: "3862 Innertkirchen",
	3863: "3863 Gadmen",
	3864: "3864 Guttannen",
	3900: "3900 Brig",
	3901: "3901 Rothwald",
	3902: "3902 Glis",
	3903: "3903 Mund",
	3904: "3904 Naters",
	3905: "3905 Saas-Almagell",
	3906: "3906 Saas-Fee",
	3907: "3907 Simplon Dorf",
	3908: "3908 Saas-Balen",
	3910: "3910 Saas-Grund",
	3911: "3911 Ried-Brig",
	3912: "3912 Termen",
	3913: "3913 Rosswald",
	3914: "3914 Blatten b. Naters",
	3916: "3916 Ferden",
	3917: "3917 Kippel",
	3918: "3918 Wiler (Lötschen)",
	3919: "3919 Blatten (Lötschen)",
	3920: "3920 Zermatt",
	3922: "3922 Stalden VS",
	3923: "3923 Törbel",
	3924: "3924 St. Niklaus VS",
	3925: "3925 Grächen",
	3926: "3926 Embd",
	3927: "3927 Herbriggen",
	3928: "3928 Randa",
	3929: "3929 Täsch",
	3930: "3930 Visp",
	3931: "3931 Lalden",
	3932: "3932 Visperterminen",
	3933: "3933 Staldenried",
	3934: "3934 Zeneggen",
	3935: "3935 Bürchen",
	3937: "3937 Baltschieder",
	3938: "3938 Ausserberg",
	3939: "3939 Eggerberg",
	3940: "3940 Steg VS",
	3942: "3942 Raron",
	3943: "3943 Eischoll",
	3944: "3944 Unterbäch VS",
	3945: "3945 Niedergampel",
	3946: "3946 Turtmann",
	3947: "3947 Ergisch",
	3948: "3948 Oberems",
	3949: "3949 Hohtenn",
	3951: "3951 Agarn",
	3952: "3952 Susten",
	3953: "3953 Leuk Stadt",
	3954: "3954 Leukerbad",
	3955: "3955 Albinen",
	3956: "3956 Guttet-Feschel",
	3957: "3957 Erschmatt",
	3960: "3960 Sierre",
	3961: "3961 Grimentz",
	3963: "3963 Aminona",
	3965: "3965 Chippis",
	3966: "3966 Chalais",
	3967: "3967 Vercorin",
	3968: "3968 Veyras",
	3970: "3970 Salgesch",
	3971: "3971 Chermignon",
	3972: "3972 Miège",
	3973: "3973 Venthône",
	3974: "3974 Mollens VS",
	3975: "3975 Randogne",
	3976: "3976 Noës",
	3977: "3977 Granges VS",
	3978: "3978 Flanthey",
	3979: "3979 Grône",
	3982: "3982 Bitsch",
	3983: "3983 Mörel",
	3984: "3984 Fiesch",
	3985: "3985 Münster VS",
	3986: "3986 Ried-Mörel",
	3987: "3987 Riederalp",
	3988: "3988 Ulrichen",
	3989: "3989 Selkingen",
	3991: "3991 Betten",
	3992: "3992 Bettmeralp",
	3993: "3993 Grengiols",
	3994: "3994 Martisberg",
	3995: "3995 Ernen",
	3996: "3996 Binn",
	3997: "3997 Bellwald",
	3998: "3998 Reckingen VS",
	3999: "3999 Oberwald",
	4000: "4000 Basel",
	4001: "4001 Basel",
	4031: "4031 Basel Universitätsspital",
	4051: "4051 Basel",
	4052: "4052 Basel",
	4053: "4053 Basel",
	4054: "4054 Basel",
	4055: "4055 Basel",
	4056: "4056 Basel",
	4057: "4057 Basel",
	4058: "4058 Basel",
	4059: "4059 Basel",
	4101: "4101 Bruderholz",
	4102: "4102 Binningen",
	4103: "4103 Bottmingen",
	4104: "4104 Oberwil BL",
	4105: "4105 Biel-Benken BL",
	4106: "4106 Therwil",
	4107: "4107 Ettingen",
	4108: "4108 Witterswil",
	4112: "4112 Flüh",
	4114: "4114 Hofstetten SO",
	4115: "4115 Mariastein",
	4116: "4116 Metzerlen",
	4117: "4117 Burg im Leimental",
	4118: "4118 Rodersdorf",
	4123: "4123 Allschwil",
	4124: "4124 Schönenbuch",
	4125: "4125 Riehen",
	4126: "4126 Bettingen",
	4127: "4127 Birsfelden",
	4132: "4132 Muttenz",
	4133: "4133 Pratteln",
	4142: "4142 Münchenstein",
	4143: "4143 Dornach",
	4144: "4144 Arlesheim",
	4145: "4145 Gempen",
	4146: "4146 Hochwald",
	4147: "4147 Aesch BL",
	4148: "4148 Pfeffingen",
	4153: "4153 Reinach BL",
	4202: "4202 Duggingen",
	4203: "4203 Grellingen",
	4204: "4204 Himmelried",
	4206: "4206 Seewen SO",
	4207: "4207 Bretzwil",
	4208: "4208 Nunningen",
	4222: "4222 Zwingen",
	4223: "4223 Blauen",
	4224: "4224 Nenzlingen",
	4225: "4225 Brislach",
	4226: "4226 Breitenbach",
	4227: "4227 Büsserach",
	4228: "4228 Erschwil",
	4229: "4229 Beinwil SO",
	4232: "4232 Fehren",
	4233: "4233 Meltingen",
	4234: "4234 Zullwil",
	4242: "4242 Laufen",
	4243: "4243 Dittingen",
	4244: "4244 Röschenz",
	4245: "4245 Kleinlützel",
	4246: "4246 Wahlen b. Laufen",
	4247: "4247 Grindel",
	4252: "4252 Bärschwil",
	4253: "4253 Liesberg",
	4254: "4254 Liesberg Dorf",
	4302: "4302 Augst BL",
	4303: "4303 Kaiseraugst",
	4304: "4304 Giebenach",
	4305: "4305 Olsberg",
	4310: "4310 Rheinfelden",
	4312: "4312 Magden",
	4313: "4313 Möhlin",
	4314: "4314 Zeiningen",
	4315: "4315 Zuzgen",
	4316: "4316 Hellikon",
	4317: "4317 Wegenstetten",
	4322: "4322 Mumpf",
	4323: "4323 Wallbach",
	4324: "4324 Obermumpf",
	4325: "4325 Schupfart",
	4332: "4332 Stein AG",
	4333: "4333 Münchwilen AG",
	4334: "4334 Sisseln AG",
	4402: "4402 Frenkendorf",
	4410: "4410 Liestal",
	4411: "4411 Seltisberg",
	4412: "4412 Nuglar",
	4413: "4413 Büren SO",
	4414: "4414 Füllinsdorf",
	4415: "4415 Lausen",
	4416: "4416 Bubendorf",
	4417: "4417 Ziefen",
	4418: "4418 Reigoldswil",
	4419: "4419 Lupsingen",
	4421: "4421 St. Pantaleon",
	4422: "4422 Arisdorf",
	4423: "4423 Hersberg",
	4424: "4424 Arboldswil",
	4425: "4425 Titterten",
	4426: "4426 Lauwil",
	4431: "4431 Bennwil",
	4432: "4432 Lampenberg",
	4433: "4433 Ramlinsburg",
	4434: "4434 Hölstein",
	4435: "4435 Niederdorf",
	4436: "4436 Oberdorf BL",
	4437: "4437 Waldenburg",
	4438: "4438 Langenbruck",
	4441: "4441 Thürnen",
	4442: "4442 Diepflingen",
	4443: "4443 Wittinsburg",
	4444: "4444 Rümlingen",
	4445: "4445 Häfelfingen",
	4446: "4446 Buckten",
	4447: "4447 Känerkinden",
	4448: "4448 Läufelfingen",
	4450: "4450 Sissach",
	4451: "4451 Wintersingen",
	4452: "4452 Itingen",
	4453: "4453 Nusshof",
	4455: "4455 Zunzgen",
	4456: "4456 Tenniken",
	4457: "4457 Diegten",
	4458: "4458 Eptingen",
	4460: "4460 Gelterkinden",
	4461: "4461 Böckten",
	4462: "4462 Rickenbach BL",
	4463: "4463 Buus",
	4464: "4464 Maisprach",
	4465: "4465 Hemmiken",
	4466: "4466 Ormalingen",
	4467: "4467 Rothenfluh",
	4468: "4468 Kienberg",
	4469: "4469 Anwil",
	4492: "4492 Tecknau",
	4493: "4493 Wenslingen",
	4494: "4494 Oltingen",
	4495: "4495 Zeglingen",
	4496: "4496 Kilchberg BL",
	4497: "4497 Rünenberg",
	4500: "4500 Solothurn",
	4512: "4512 Bellach",
	4513: "4513 Langendorf",
	4514: "4514 Lommiswil",
	4515: "4515 Oberdorf SO",
	4522: "4522 Rüttenen",
	4523: "4523 Niederwil SO",
	4524: "4524 Günsberg",
	4525: "4525 Balm b. Günsberg",
	4528: "4528 Zuchwil",
	4532: "4532 Feldbrunnen",
	4533: "4533 Riedholz",
	4534: "4534 Flumenthal",
	4535: "4535 Kammersrohr",
	4536: "4536 Attiswil",
	4537: "4537 Wiedlisbach",
	4538: "4538 Oberbipp",
	4539: "4539 Farnern",
	4542: "4542 Luterbach",
	4543: "4543 Deitingen",
	4552: "4552 Derendingen",
	4553: "4553 Subingen",
	4554: "4554 Etziken",
	4556: "4556 Aeschi SO",
	4557: "4557 Horriwil",
	4558: "4558 Winistorf",
	4562: "4562 Biberist",
	4563: "4563 Gerlafingen",
	4564: "4564 Obergerlafingen",
	4565: "4565 Recherswil",
	4566: "4566 Kriegstetten",
	4571: "4571 Lüterkofen",
	4573: "4573 Lohn-Ammannsegg",
	4574: "4574 Nennigkofen",
	4576: "4576 Tscheppach",
	4577: "4577 Hessigkofen",
	4578: "4578 Bibern SO",
	4579: "4579 Gossliwil",
	4581: "4581 Küttigkofen",
	4582: "4582 Brügglen",
	4583: "4583 Mühledorf SO",
	4584: "4584 Lüterswil",
	4585: "4585 Biezwil",
	4586: "4586 Kyburg-Buchegg",
	4587: "4587 Aetingen",
	4588: "4588 Brittern",
	4600: "4600 Olten",
	4612: "4612 Wangen b. Olten",
	4613: "4613 Rickenbach SO",
	4614: "4614 Hägendorf",
	4615: "4615 Allerheiligenberg",
	4616: "4616 Kappel SO",
	4617: "4617 Gunzgen",
	4618: "4618 Boningen",
	4622: "4622 Egerkingen",
	4623: "4623 Neuendorf",
	4624: "4624 Härkingen",
	4625: "4625 Oberbuchsiten",
	4626: "4626 Niederbuchsiten",
	4628: "4628 Wolfwil",
	4629: "4629 Fulenbach",
	4632: "4632 Trimbach",
	4633: "4633 Hauenstein",
	4634: "4634 Wisen SO",
	4652: "4652 Winznau",
	4653: "4653 Obergösgen",
	4654: "4654 Lostorf",
	4655: "4655 Stüsslingen",
	4656: "4656 Starrkirch-Wil",
	4657: "4657 Dulliken",
	4658: "4658 Däniken SO",
	4663: "4663 Aarburg",
	4665: "4665 Oftringen",
	4702: "4702 Oensingen",
	4703: "4703 Kestenholz",
	4704: "4704 Niederbipp",
	4710: "4710 Balsthal",
	4712: "4712 Laupersdorf",
	4713: "4713 Matzendorf",
	4714: "4714 Aedermannsdorf",
	4715: "4715 Herbetswil",
	4716: "4716 Welschenrohr",
	4717: "4717 Mümliswil",
	4718: "4718 Holderbank SO",
	4719: "4719 Ramiswil",
	4800: "4800 Zofingen",
	4802: "4802 Strengelbach",
	4803: "4803 Vordemwald",
	4805: "4805 Brittnau",
	4806: "4806 Wikon",
	4812: "4812 Mühlethal",
	4813: "4813 Uerkheim",
	4814: "4814 Bottenwil",
	4852: "4852 Rothrist",
	4853: "4853 Murgenthal",
	4856: "4856 Glashütten",
	4900: "4900 Langenthal",
	4911: "4911 Schwarzhäusern",
	4912: "4912 Aarwangen",
	4913: "4913 Bannwil",
	4914: "4914 Roggwil BE",
	4915: "4915 St. Urban",
	4916: "4916 Untersteckholz",
	4917: "4917 Melchnau",
	4919: "4919 Reisiswil",
	4922: "4922 Bützberg",
	4923: "4923 Wynau",
	4924: "4924 Obersteckholz",
	4932: "4932 Lotzwil",
	4933: "4933 Rütschelen",
	4934: "4934 Madiswil",
	4935: "4935 Leimiswil",
	4936: "4936 Kleindietwil",
	4937: "4937 Ursenbach",
	4938: "4938 Rohrbach",
	4942: "4942 Walterswil BE",
	4943: "4943 Oeschenbach",
	4944: "4944 Auswil",
	4950: "4950 Huttwil",
	4952: "4952 Eriswil",
	4953: "4953 Schwarzenbach (Huttwil)",
	4954: "4954 Wyssachen",
	4955: "4955 Gondiswil",
	5000: "5000 Aarau",
	5004: "5004 Aarau",
	5012: "5012 Schönenwerd",
	5013: "5013 Niedergösgen",
	5014: "5014 Gretzenbach",
	5015: "5015 Erlinsbach SO",
	5017: "5017 Barmelweid",
	5018: "5018 Erlinsbach",
	5022: "5022 Rombach",
	5023: "5023 Biberstein",
	5024: "5024 Küttigen",
	5025: "5025 Asp",
	5026: "5026 Densbüren",
	5027: "5027 Herznach",
	5028: "5028 Ueken",
	5032: "5032 Aarau Rohr",
	5033: "5033 Buchs AG",
	5034: "5034 Suhr",
	5035: "5035 Unterentfelden",
	5036: "5036 Oberentfelden",
	5037: "5037 Muhen",
	5040: "5040 Schöftland",
	5042: "5042 Hirschthal",
	5043: "5043 Holziken",
	5044: "5044 Schlossrued",
	5046: "5046 Schmiedrued-Walde",
	5053: "5053 Staffelbach",
	5054: "5054 Moosleerau",
	5056: "5056 Attelwil",
	5057: "5057 Reitnau",
	5058: "5058 Wiliberg",
	5062: "5062 Oberhof",
	5063: "5063 Wölflinswil",
	5064: "5064 Wittnau",
	5070: "5070 Frick",
	5072: "5072 Oeschgen",
	5073: "5073 Gipf-Oberfrick",
	5074: "5074 Eiken",
	5075: "5075 Hornussen",
	5076: "5076 Bözen",
	5077: "5077 Elfingen",
	5078: "5078 Effingen",
	5079: "5079 Zeihen",
	5080: "5080 Laufenburg",
	5082: "5082 Kaisten",
	5083: "5083 Ittenthal",
	5084: "5084 Rheinsulz",
	5085: "5085 Sulz AG",
	5102: "5102 Rupperswil",
	5103: "5103 Möriken AG",
	5105: "5105 Auenstein",
	5106: "5106 Veltheim AG",
	5107: "5107 Schinznach Dorf",
	5108: "5108 Oberflachs",
	5112: "5112 Thalheim AG",
	5113: "5113 Holderbank AG",
	5116: "5116 Schinznach Bad",
	5200: "5200 Brugg AG",
	5210: "5210 Windisch",
	5212: "5212 Hausen AG",
	5213: "5213 Villnachern",
	5222: "5222 Umiken",
	5223: "5223 Riniken",
	5224: "5224 Unterbözberg",
	5225: "5225 Oberbözberg",
	5233: "5233 Stilli",
	5234: "5234 Villigen",
	5235: "5235 Rüfenach AG",
	5236: "5236 Remigen",
	5237: "5237 Mönthal",
	5242: "5242 Lupfig",
	5243: "5243 Mülligen",
	5244: "5244 Birrhard",
	5245: "5245 Habsburg",
	5246: "5246 Scherz",
	5272: "5272 Gansingen",
	5273: "5273 Oberhofen AG",
	5274: "5274 Mettau",
	5275: "5275 Etzgen",
	5276: "5276 Wil AG",
	5277: "5277 Hottwil",
	5300: "5300 Turgi",
	5301: "5301 Siggenthal Station",
	5303: "5303 Würenlingen",
	5304: "5304 Endingen",
	5305: "5305 Unterendingen",
	5306: "5306 Tegerfelden",
	5312: "5312 Döttingen",
	5313: "5313 Klingnau",
	5314: "5314 Kleindöttingen",
	5315: "5315 Böttstein",
	5316: "5316 Leuggern",
	5317: "5317 Hettenschwil",
	5318: "5318 Mandach",
	5322: "5322 Koblenz",
	5323: "5323 Rietheim",
	5324: "5324 Full-Reuenthal",
	5325: "5325 Leibstadt",
	5326: "5326 Schwaderloch",
	5330: "5330 Bad Zurzach",
	5332: "5332 Rekingen AG",
	5333: "5333 Baldingen",
	5334: "5334 Böbikon",
	5400: "5400 Baden",
	5404: "5404 Baden",
	5405: "5405 Dättwil AG",
	5406: "5406 Rütihof",
	5408: "5408 Ennetbaden",
	5412: "5412 Gebenstorf",
	5413: "5413 Birmenstorf AG",
	5415: "5415 Nussbaumen AG",
	5416: "5416 Kirchdorf AG",
	5417: "5417 Untersiggenthal",
	5420: "5420 Ehrendingen",
	5423: "5423 Freienwil",
	5425: "5425 Schneisingen",
	5426: "5426 Lengnau AG",
	5430: "5430 Wettingen",
	5432: "5432 Neuenhof",
	5436: "5436 Würenlos",
	5442: "5442 Fislisbach",
	5443: "5443 Niederrohrdorf",
	5444: "5444 Künten",
	5445: "5445 Eggenwil",
	5452: "5452 Oberrohrdorf",
	5453: "5453 Remetschwil",
	5454: "5454 Bellikon",
	5462: "5462 Siglistorf",
	5463: "5463 Wislikofen",
	5464: "5464 Rümikon AG",
	5465: "5465 Mellikon",
	5466: "5466 Kaiserstuhl AG",
	5467: "5467 Fisibach",
	5502: "5502 Hunzenschwil",
	5503: "5503 Schafisheim",
	5504: "5504 Othmarsingen",
	5505: "5505 Brunegg",
	5506: "5506 Mägenwil",
	5507: "5507 Mellingen",
	5512: "5512 Wohlenschwil",
	5522: "5522 Tägerig",
	5524: "5524 Niederwil AG",
	5525: "5525 Fischbach-Göslikon",
	5600: "5600 Ammerswil AG",
	5603: "5603 Staufen",
	5604: "5604 Hendschiken",
	5605: "5605 Dottikon",
	5606: "5606 Dintikon",
	5607: "5607 Hägglingen",
	5608: "5608 Stetten AG",
	5610: "5610 Wohlen AG",
	5611: "5611 Anglikon",
	5612: "5612 Villmergen",
	5613: "5613 Hilfikon",
	5614: "5614 Sarmenstorf",
	5615: "5615 Fahrwangen",
	5616: "5616 Meisterschwanden",
	5617: "5617 Tennwil",
	5618: "5618 Bettwil",
	5619: "5619 Uezwil",
	5620: "5620 Bremgarten AG",
	5621: "5621 Zufikon",
	5622: "5622 Waltenschwil",
	5623: "5623 Boswil",
	5624: "5624 Bünzen",
	5625: "5625 Kallern",
	5626: "5626 Hermetschwil-Staffeln",
	5627: "5627 Besenbüren",
	5628: "5628 Aristau",
	5630: "5630 Muri AG",
	5632: "5632 Buttwil",
	5634: "5634 Merenschwand",
	5636: "5636 Benzenschwil",
	5637: "5637 Beinwil (Freiamt)",
	5642: "5642 Mühlau",
	5643: "5643 Sins",
	5644: "5644 Auw",
	5645: "5645 Fenkrieden",
	5646: "5646 Abtwil AG",
	5647: "5647 Oberrüti",
	5702: "5702 Niederlenz",
	5703: "5703 Seon",
	5704: "5704 Egliswil",
	5705: "5705 Hallwil",
	5706: "5706 Boniswil",
	5707: "5707 Seengen",
	5708: "5708 Birrwil",
	5712: "5712 Beinwil am See",
	5722: "5722 Gränichen",
	5723: "5723 Teufenthal AG",
	5724: "5724 Dürrenäsch",
	5725: "5725 Leutwil",
	5726: "5726 Unterkulm",
	5727: "5727 Oberkulm",
	5728: "5728 Gontenschwil",
	5732: "5732 Zetzwil",
	5733: "5733 Leimbach AG",
	5734: "5734 Reinach AG",
	5735: "5735 Pfeffikon LU",
	5736: "5736 Burg AG",
	5737: "5737 Menziken",
	5742: "5742 Kölliken",
	5745: "5745 Safenwil",
	5746: "5746 Walterswil SO",
	6000: "6000 Luzern",
	6003: "6003 Luzern",
	6004: "6004 Luzern",
	6005: "6005 St. Niklausen LU",
	6006: "6006 Luzern",
	6010: "6010 Pilatus Kulm",
	6012: "6012 Obernau",
	6013: "6013 Eigenthal",
	6014: "6014 Luzern",
	6015: "6015 Luzern",
	6016: "6016 Hellbühl",
	6017: "6017 Ruswil",
	6018: "6018 Buttisholz",
	6019: "6019 Sigigen",
	6020: "6020 Emmenbrücke",
	6022: "6022 Grosswangen",
	6023: "6023 Rothenburg",
	6024: "6024 Hildisrieden",
	6025: "6025 Neudorf",
	6026: "6026 Rain",
	6027: "6027 Römerswil LU",
	6028: "6028 Herlisberg",
	6030: "6030 Ebikon",
	6032: "6032 Emmen",
	6033: "6033 Buchrain",
	6034: "6034 Inwil",
	6035: "6035 Perlen",
	6036: "6036 Dierikon",
	6037: "6037 Root",
	6038: "6038 Honau",
	6039: "6039 Root Längenbold",
	6042: "6042 Dietwil",
	6043: "6043 Adligenswil",
	6044: "6044 Udligenswil",
	6045: "6045 Meggen",
	6047: "6047 Kastanienbaum",
	6048: "6048 Horw",
	6052: "6052 Hergiswil NW",
	6053: "6053 Alpnachstad",
	6055: "6055 Alpnach Dorf",
	6056: "6056 Kägiswil",
	6060: "6060 Ramersberg",
	6062: "6062 Wilen (Sarnen)",
	6063: "6063 Stalden (Sarnen)",
	6064: "6064 Kerns",
	6066: "6066 St. Niklausen OW",
	6067: "6067 Melchtal",
	6068: "6068 Melchsee-Frutt",
	6072: "6072 Sachseln",
	6073: "6073 Flüeli-Ranft",
	6074: "6074 Giswil",
	6078: "6078 Lungern",
	6083: "6083 Hasliberg Hohfluh",
	6084: "6084 Hasliberg Wasserwendi",
	6085: "6085 Hasliberg Goldern",
	6086: "6086 Hasliberg Reuti",
	6102: "6102 Malters",
	6103: "6103 Schwarzenberg LU",
	6105: "6105 Schachen LU",
	6106: "6106 Werthenstein",
	6110: "6110 Wolhusen",
	6112: "6112 Doppleschwand",
	6113: "6113 Romoos",
	6114: "6114 Steinhuserberg",
	6122: "6122 Menznau",
	6123: "6123 Geiss",
	6125: "6125 Menzberg",
	6126: "6126 Daiwil",
	6130: "6130 Willisau",
	6132: "6132 Rohrmatt",
	6133: "6133 Hergiswil b. Willisau",
	6142: "6142 Gettnau",
	6143: "6143 Ohmstal",
	6144: "6144 Zell LU",
	6145: "6145 Fischbach LU",
	6146: "6146 Grossdietwil",
	6147: "6147 Altbüron",
	6152: "6152 Hüswil",
	6153: "6153 Ufhusen",
	6154: "6154 Hofstatt",
	6156: "6156 Luthern",
	6162: "6162 Entlebuch",
	6163: "6163 Ebnet",
	6166: "6166 Hasle LU",
	6167: "6167 Bramboden",
	6170: "6170 Schüpfheim",
	6173: "6173 Flühli LU",
	6174: "6174 Sörenberg",
	6182: "6182 Escholzmatt",
	6192: "6192 Wiggen",
	6196: "6196 Marbach LU",
	6197: "6197 Schangnau",
	6203: "6203 Sempach Station",
	6204: "6204 Sempach",
	6205: "6205 Eich",
	6206: "6206 Neuenkirch",
	6207: "6207 Nottwil",
	6208: "6208 Oberkirch LU",
	6210: "6210 Sursee",
	6211: "6211 Buchs LU",
	6212: "6212 St. Erhard",
	6213: "6213 Knutwil",
	6214: "6214 Schenkon",
	6215: "6215 Beromünster",
	6216: "6216 Mauensee",
	6217: "6217 Kottwil",
	6218: "6218 Ettiswil",
	6221: "6221 Rickenbach LU",
	6222: "6222 Gunzwil",
	6231: "6231 Schlierbach",
	6232: "6232 Geuensee",
	6233: "6233 Büron",
	6234: "6234 Triengen",
	6235: "6235 Winikon",
	6236: "6236 Wilihof",
	6242: "6242 Wauwil",
	6243: "6243 Egolzwil",
	6244: "6244 Nebikon",
	6245: "6245 Ebersecken",
	6246: "6246 Altishofen",
	6247: "6247 Schötz",
	6248: "6248 Alberswil",
	6252: "6252 Dagmersellen",
	6253: "6253 Uffikon",
	6260: "6260 Reiden",
	6262: "6262 Langnau b. Reiden",
	6263: "6263 Richenthal",
	6264: "6264 Pfaffnau",
	6265: "6265 Roggliswil",
	6274: "6274 Eschenbach LU",
	6275: "6275 Ballwil",
	6276: "6276 Hohenrain",
	6277: "6277 Lieli LU",
	6280: "6280 Urswil",
	6283: "6283 Baldegg",
	6284: "6284 Gelfingen",
	6285: "6285 Hitzkirch",
	6286: "6286 Altwis",
	6287: "6287 Aesch LU",
	6288: "6288 Schongau",
	6289: "6289 Hämikon",
	6294: "6294 Ermensee",
	6295: "6295 Mosen",
	6300: "6300 Zugerberg",
	6312: "6312 Steinhausen",
	6313: "6313 Menzingen",
	6314: "6314 Unterägeri",
	6315: "6315 Oberägeri",
	6317: "6317 Oberwil b. Zug",
	6318: "6318 Walchwil",
	6319: "6319 Allenwinden",
	6330: "6330 Cham",
	6331: "6331 Hünenberg",
	6332: "6332 Hagendorn",
	6333: "6333 Hünenberg See",
	6340: "6340 Sihlbrugg",
	6343: "6343 Rotkreuz",
	6344: "6344 Meierskappel",
	6345: "6345 Neuheim",
	6353: "6353 Weggis",
	6354: "6354 Vitznau",
	6356: "6356 Rigi Kaltbad",
	6362: "6362 Stansstad",
	6363: "6363 Fürigen",
	6365: "6365 Kehrsiten",
	6370: "6370 Oberdorf NW",
	6372: "6372 Ennetmoos",
	6373: "6373 Ennetbürgen",
	6374: "6374 Buochs",
	6375: "6375 Beckenried",
	6376: "6376 Emmetten",
	6377: "6377 Seelisberg",
	6382: "6382 Büren NW",
	6383: "6383 Dallenwil",
	6386: "6386 Wolfenschiessen",
	6387: "6387 Oberrickenbach",
	6388: "6388 Grafenort",
	6390: "6390 Engelberg",
	6402: "6402 Merlischachen",
	6403: "6403 Küssnacht am Rigi",
	6404: "6404 Greppen",
	6405: "6405 Immensee",
	6410: "6410 Goldau",
	6414: "6414 Oberarth",
	6415: "6415 Arth",
	6416: "6416 Steinerberg",
	6417: "6417 Sattel",
	6418: "6418 Rothenthurm",
	6422: "6422 Steinen",
	6423: "6423 Seewen SZ",
	6424: "6424 Lauerz",
	6430: "6430 Schwyz",
	6432: "6432 Rickenbach b. Schwyz",
	6433: "6433 Stoos SZ",
	6434: "6434 Illgau",
	6436: "6436 Muotathal",
	6438: "6438 Ibach",
	6440: "6440 Brunnen",
	6441: "6441 Rütli",
	6442: "6442 Gersau",
	6443: "6443 Morschach",
	6452: "6452 Sisikon",
	6454: "6454 Flüelen",
	6460: "6460 Altdorf UR",
	6461: "6461 Isenthal",
	6462: "6462 Seedorf UR",
	6463: "6463 Bürglen UR",
	6464: "6464 Spiringen",
	6465: "6465 Unterschächen",
	6466: "6466 Bauen",
	6467: "6467 Schattdorf",
	6468: "6468 Attinghausen",
	6469: "6469 Haldi b. Schattdorf",
	6472: "6472 Erstfeld",
	6473: "6473 Silenen",
	6474: "6474 Amsteg",
	6475: "6475 Bristen",
	6476: "6476 Intschi",
	6482: "6482 Gurtnellen",
	6484: "6484 Wassen UR",
	6485: "6485 Meien",
	6487: "6487 Göschenen",
	6490: "6490 Andermatt",
	6491: "6491 Realp",
	6493: "6493 Hospental",
	6500: "6500 Bellinzona",
	6503: "6503 Bellinzona",
	6512: "6512 Giubiasco",
	6513: "6513 Monte Carasso",
	6514: "6514 Sementina",
	6515: "6515 Gudo",
	6516: "6516 Cugnasco",
	6517: "6517 Arbedo",
	6518: "6518 Gorduno",
	6523: "6523 Preonzo",
	6524: "6524 Moleno",
	6525: "6525 Gnosca",
	6526: "6526 Prosito",
	6527: "6527 Lodrino",
	6528: "6528 Camorino",
	6532: "6532 Castione",
	6533: "6533 Lumino",
	6534: "6534 S. Vittore",
	6535: "6535 Roveredo GR",
	6537: "6537 Grono",
	6538: "6538 Verdabbio",
	6540: "6540 Castaneda",
	6541: "6541 Sta. Maria in Calanca",
	6542: "6542 Buseno",
	6543: "6543 Arvigo",
	6544: "6544 Braggio",
	6545: "6545 Selma",
	6546: "6546 Cauco",
	6547: "6547 Augio",
	6548: "6548 Rossa",
	6549: "6549 Laura",
	6556: "6556 Leggia",
	6557: "6557 Cama",
	6558: "6558 Lostallo",
	6562: "6562 Soazza",
	6563: "6563 Mesocco",
	6565: "6565 S. Bernardino",
	6571: "6571 Indemini",
	6572: "6572 Quartino",
	6573: "6573 Magadino",
	6574: "6574 Vira (Gambarogno)",
	6575: "6575 S. Nazzaro",
	6576: "6576 Gerra (Gambarogno)",
	6577: "6577 Ranzo",
	6578: "6578 Caviano",
	6579: "6579 Piazzogna",
	6582: "6582 Pianezzo",
	6583: "6583 S. Antonio (Val Morobbia)",
	6584: "6584 Carena",
	6592: "6592 S. Antonino",
	6593: "6593 Cadenazzo",
	6594: "6594 Contone",
	6595: "6595 Riazzino",
	6596: "6596 Gordola",
	6597: "6597 Agarone",
	6598: "6598 Tenero",
	6599: "6599 Robasacco",
	6600: "6600 Solduno",
	6605: "6605 Locarno",
	6611: "6611 Crana",
	6612: "6612 Ascona",
	6613: "6613 Porto Ronco",
	6614: "6614 Brissago",
	6616: "6616 Losone",
	6618: "6618 Arcegno",
	6622: "6622 Ronco sopra Ascona",
	6631: "6631 Corippo",
	6632: "6632 Vogorno",
	6633: "6633 Lavertezzo",
	6634: "6634 Brione (Verzasca)",
	6635: "6635 Gerra (Verzasca)",
	6636: "6636 Frasco",
	6637: "6637 Sonogno",
	6644: "6644 Orselina",
	6645: "6645 Brione sopra Minusio",
	6646: "6646 Contra",
	6647: "6647 Mergoscia",
	6648: "6648 Minusio",
	6652: "6652 Tegna",
	6653: "6653 Verscio",
	6654: "6654 Cavigliano",
	6655: "6655 Intragna",
	6656: "6656 Golino",
	6657: "6657 Palagnedra",
	6658: "6658 Borgnone",
	6659: "6659 Camedo",
	6661: "6661 Loco",
	6662: "6662 Russo",
	6663: "6663 Spruga",
	6664: "6664 Vergeletto",
	6670: "6670 Avegno",
	6672: "6672 Gordevio",
	6673: "6673 Maggia",
	6674: "6674 Someo",
	6675: "6675 Cevio",
	6676: "6676 Bignasco",
	6677: "6677 Aurigeno",
	6678: "6678 Giumaglio",
	6682: "6682 Linescio",
	6683: "6683 Niva (Vallemaggia)",
	6684: "6684 Cimalmotto",
	6685: "6685 Bosco/Gurin",
	6690: "6690 S. Carlo (Val Bavona)",
	6692: "6692 Menzonio",
	6693: "6693 Broglio",
	6694: "6694 Prato-Sornico",
	6695: "6695 Piano di Peccia",
	6696: "6696 Fusio",
	6702: "6702 Claro",
	6703: "6703 Osogna",
	6705: "6705 Cresciano",
	6707: "6707 Iragna",
	6710: "6710 Biasca Stazione",
	6713: "6713 Malvaglia",
	6714: "6714 Semione",
	6715: "6715 Dongio",
	6716: "6716 Acquarossa",
	6717: "6717 Torre",
	6718: "6718 Olivone",
	6719: "6719 Aquila",
	6720: "6720 Ghirone",
	6721: "6721 Motto (Blenio)",
	6722: "6722 Corzoneso",
	6723: "6723 Marolta",
	6724: "6724 Largario",
	6742: "6742 Pollegio",
	6743: "6743 Bodio TI",
	6744: "6744 Personico",
	6745: "6745 Giornico",
	6746: "6746 Lavorgo",
	6747: "6747 Chironico",
	6748: "6748 Anzonico",
	6749: "6749 Cavagnago",
	6760: "6760 Campello",
	6763: "6763 Osco",
	6764: "6764 Chiggiogna",
	6772: "6772 Rodi-Fiesso",
	6773: "6773 Prato (Leventina)",
	6774: "6774 Dalpe",
	6775: "6775 Ambrì",
	6776: "6776 Piotta",
	6777: "6777 Varenzo",
	6780: "6780 Airolo",
	6781: "6781 Bedretto",
	6802: "6802 Rivera",
	6803: "6803 Camignolo",
	6804: "6804 Bironico",
	6805: "6805 Mezzovico",
	6806: "6806 Sigirino",
	6807: "6807 Taverne",
	6808: "6808 Torricella",
	6809: "6809 Medeglia",
	6810: "6810 Isone",
	6814: "6814 Cadempino",
	6815: "6815 Melide",
	6816: "6816 Bissone",
	6817: "6817 Maroggia",
	6818: "6818 Melano",
	6821: "6821 Rovio",
	6822: "6822 Arogno",
	6823: "6823 Pugerna",
	6825: "6825 Capolago",
	6826: "6826 Riva San Vitale",
	6827: "6827 Brusino Arsizio",
	6828: "6828 Balerna",
	6830: "6830 Chiasso",
	6832: "6832 Pedrinate",
	6833: "6833 Vacallo",
	6834: "6834 Morbio Inferiore",
	6835: "6835 Morbio Superiore",
	6837: "6837 Caneggio",
	6838: "6838 Muggio",
	6839: "6839 Sagno",
	6850: "6850 Mendrisio",
	6852: "6852 Genestrerio",
	6853: "6853 Ligornetto",
	6854: "6854 S. Pietro",
	6855: "6855 Stabio",
	6862: "6862 Rancate",
	6863: "6863 Besazio",
	6864: "6864 Arzo",
	6865: "6865 Tremona",
	6866: "6866 Meride",
	6867: "6867 Serpiano",
	6872: "6872 Somazzo",
	6873: "6873 Corteglia",
	6874: "6874 Castel San Pietro",
	6875: "6875 Monte",
	6877: "6877 Coldrerio",
	6883: "6883 Novazzano",
	6900: "6900 Paradiso",
	6911: "6911 Campione d'Italia",
	6912: "6912 Pazzallo",
	6913: "6913 Carabbia",
	6914: "6914 Carona",
	6915: "6915 Pambio-Noranco",
	6916: "6916 Grancia",
	6917: "6917 Barbengo",
	6918: "6918 Figino",
	6919: "6919 Carabietta",
	6921: "6921 Vico Morcote",
	6922: "6922 Morcote",
	6924: "6924 Sorengo",
	6925: "6925 Gentilino",
	6926: "6926 Montagnola",
	6927: "6927 Agra",
	6928: "6928 Manno",
	6929: "6929 Gravesano",
	6930: "6930 Bedano",
	6932: "6932 Breganzona",
	6933: "6933 Muzzano",
	6934: "6934 Bioggio",
	6935: "6935 Bosco Luganese",
	6936: "6936 Cademario",
	6937: "6937 Breno",
	6938: "6938 Fescoggia",
	6939: "6939 Mugena",
	6942: "6942 Savosa",
	6943: "6943 Vezia",
	6944: "6944 Cureglia",
	6945: "6945 Origlio",
	6946: "6946 Ponte Capriasca",
	6947: "6947 Vaglio",
	6948: "6948 Porza",
	6949: "6949 Comano",
	6950: "6950 Tesserete",
	6951: "6951 Scareglia",
	6952: "6952 Canobbio",
	6953: "6953 Lugaggia",
	6954: "6954 Bigorio",
	6955: "6955 Oggio",
	6956: "6956 Lopagno",
	6957: "6957 Roveredo TI",
	6958: "6958 Corticiasca",
	6959: "6959 Maglio di Colla",
	6962: "6962 Viganello",
	6963: "6963 Pregassona",
	6964: "6964 Davesco-Soragno",
	6965: "6965 Cadro",
	6966: "6966 Villa Luganese",
	6967: "6967 Dino",
	6968: "6968 Sonvico",
	6974: "6974 Aldesago",
	6976: "6976 Castagnola",
	6977: "6977 Ruvigliana",
	6978: "6978 Gandria",
	6979: "6979 Brè sopra Lugano",
	6980: "6980 Castelrotto",
	6981: "6981 Banco",
	6982: "6982 Agno",
	6983: "6983 Magliaso",
	6984: "6984 Pura",
	6986: "6986 Novaggio",
	6987: "6987 Caslano",
	6988: "6988 Ponte Tresa",
	6989: "6989 Purasca",
	6990: "6990 Cassina d'Agno",
	6991: "6991 Neggio",
	6992: "6992 Vernate",
	6993: "6993 Iseo",
	6994: "6994 Aranno",
	6995: "6995 Molinazzo di Monteggio",
	6996: "6996 Ponte Cremenaga",
	6997: "6997 Sessa",
	6998: "6998 Termine",
	6999: "6999 Astano",
	7000: "7000 Chur",
	7012: "7012 Felsberg",
	7013: "7013 Domat/Ems",
	7014: "7014 Trin",
	7015: "7015 Tamins",
	7016: "7016 Trin Mulin",
	7017: "7017 Flims Dorf",
	7018: "7018 Flims Waldhaus",
	7019: "7019 Fidaz",
	7023: "7023 Haldenstein",
	7026: "7026 Maladers",
	7027: "7027 Calfreisen",
	7028: "7028 St. Peter",
	7029: "7029 Peist",
	7031: "7031 Laax GR",
	7032: "7032 Laax GR 2",
	7050: "7050 Arosa",
	7056: "7056 Molinis",
	7057: "7057 Langwies",
	7058: "7058 Litzirüti",
	7062: "7062 Passugg-Araschgen",
	7063: "7063 Praden",
	7064: "7064 Tschiertschen",
	7074: "7074 Malix",
	7075: "7075 Churwalden",
	7076: "7076 Parpan",
	7077: "7077 Valbella",
	7078: "7078 Lenzerheide/Lai",
	7082: "7082 Vaz/Obervaz",
	7083: "7083 Lantsch/Lenz",
	7084: "7084 Brienz/Brinzauls GR",
	7104: "7104 Versam",
	7106: "7106 Tenna",
	7107: "7107 Safien Platz",
	7109: "7109 Thalkirch",
	7110: "7110 Peiden",
	7111: "7111 Pitasch",
	7112: "7112 Duvin",
	7113: "7113 Camuns",
	7114: "7114 Uors (Lumnezia)",
	7115: "7115 Surcasti",
	7116: "7116 St. Martin (Lugnez)",
	7122: "7122 Carrera",
	7126: "7126 Castrisch",
	7127: "7127 Sevgein",
	7128: "7128 Riein",
	7130: "7130 Ilanz",
	7132: "7132 Vals",
	7133: "7133 Obersaxen Affeier",
	7134: "7134 Obersaxen Meierhof",
	7135: "7135 Obersaxen Giraniga",
	7136: "7136 Obersaxen Friggahüs",
	7137: "7137 Flond",
	7138: "7138 Surcuolm",
	7141: "7141 Luven",
	7142: "7142 Cumbel",
	7143: "7143 Morissen",
	7144: "7144 Vella",
	7145: "7145 Degen",
	7146: "7146 Vattiz",
	7147: "7147 Vignogn",
	7148: "7148 Lumbrein",
	7149: "7149 Vrin",
	7151: "7151 Schluein",
	7152: "7152 Sagogn",
	7153: "7153 Falera",
	7154: "7154 Ruschein",
	7155: "7155 Ladir",
	7156: "7156 Rueun",
	7157: "7157 Siat",
	7158: "7158 Waltensburg/Vuorz",
	7159: "7159 Andiast",
	7162: "7162 Tavanasa",
	7163: "7163 Danis",
	7164: "7164 Dardin",
	7165: "7165 Breil/Brigels",
	7166: "7166 Trun",
	7167: "7167 Zignau",
	7168: "7168 Schlans",
	7172: "7172 Rabius",
	7173: "7173 Surrein",
	7174: "7174 S. Benedetg",
	7175: "7175 Sumvitg",
	7176: "7176 Cumpadials",
	7180: "7180 Disentis/Mustér",
	7182: "7182 Cavardiras",
	7183: "7183 Mompé Medel",
	7184: "7184 Curaglia",
	7185: "7185 Platta",
	7186: "7186 Segnas",
	7187: "7187 Camischolas",
	7188: "7188 Sedrun",
	7189: "7189 Rueras",
	7201: "7201 Untervaz Bahnhof",
	7202: "7202 Says",
	7203: "7203 Trimmis",
	7204: "7204 Untervaz",
	7205: "7205 Zizers",
	7206: "7206 Igis",
	7208: "7208 Malans GR",
	7212: "7212 Seewis Dorf",
	7213: "7213 Valzeina",
	7214: "7214 Grüsch",
	7215: "7215 Fanas",
	7220: "7220 Schiers",
	7222: "7222 Mittellunden",
	7223: "7223 Buchen im Prättigau",
	7224: "7224 Putz",
	7226: "7226 Fajauna",
	7228: "7228 Pusserein",
	7231: "7231 Pragg-Jenaz",
	7232: "7232 Furna",
	7233: "7233 Jenaz",
	7235: "7235 Fideris",
	7240: "7240 Küblis",
	7241: "7241 Conters im Prättigau",
	7242: "7242 Luzein",
	7243: "7243 Pany",
	7244: "7244 Gadenstätt",
	7245: "7245 Ascharina",
	7246: "7246 St. Antönien",
	7247: "7247 Saas im Prättigau",
	7249: "7249 Serneus",
	7250: "7250 Klosters",
	7252: "7252 Klosters Dorf",
	7260: "7260 Davos Dorf",
	7265: "7265 Davos Wolfgang",
	7270: "7270 Davos Platz",
	7272: "7272 Davos Clavadel",
	7276: "7276 Davos Frauenkirch",
	7277: "7277 Davos Glaris",
	7278: "7278 Davos Monstein",
	7302: "7302 Landquart",
	7303: "7303 Mastrils",
	7304: "7304 Maienfeld",
	7306: "7306 Fläsch",
	7307: "7307 Jenins",
	7310: "7310 Bad Ragaz",
	7312: "7312 Pfäfers",
	7313: "7313 St. Margrethenberg",
	7314: "7314 Vadura",
	7315: "7315 Vättis",
	7317: "7317 Valens",
	7320: "7320 Sargans",
	7323: "7323 Wangs",
	7324: "7324 Vilters",
	7325: "7325 Schwendi im Weisstannental",
	7326: "7326 Weisstannen",
	7402: "7402 Bonaduz",
	7403: "7403 Rhäzüns",
	7404: "7404 Feldis/Veulden",
	7405: "7405 Rothenbrunnen",
	7407: "7407 Trans",
	7408: "7408 Cazis",
	7411: "7411 Sils im Domleschg",
	7412: "7412 Scharans",
	7413: "7413 Fürstenaubruck",
	7414: "7414 Fürstenau",
	7415: "7415 Rodels",
	7416: "7416 Almens",
	7417: "7417 Paspels",
	7418: "7418 Tumegl/Tomils",
	7419: "7419 Scheid",
	7421: "7421 Summaprada",
	7422: "7422 Tartar",
	7423: "7423 Portein",
	7424: "7424 Dalin",
	7425: "7425 Masein",
	7426: "7426 Flerden",
	7427: "7427 Urmein",
	7428: "7428 Glaspass",
	7430: "7430 Thusis",
	7431: "7431 Obermutten",
	7432: "7432 Zillis",
	7433: "7433 Mathon",
	7434: "7434 Sufers",
	7435: "7435 Splügen",
	7436: "7436 Medels im Rheinwald",
	7437: "7437 Nufenen",
	7438: "7438 Hinterrhein",
	7440: "7440 Andeer",
	7442: "7442 Clugin",
	7443: "7443 Pignia",
	7444: "7444 Ausserferrera",
	7445: "7445 Innerferrera",
	7446: "7446 Campsut-Cröt",
	7447: "7447 Am Bach (Avers)",
	7448: "7448 Juf",
	7450: "7450 Tiefencastel",
	7451: "7451 Alvaschein",
	7452: "7452 Cunter",
	7453: "7453 Tinizong",
	7454: "7454 Rona",
	7455: "7455 Mulegns",
	7456: "7456 Marmorera",
	7457: "7457 Bivio",
	7458: "7458 Mon",
	7459: "7459 Stierva",
	7460: "7460 Savognin",
	7462: "7462 Salouf",
	7463: "7463 Riom",
	7464: "7464 Parsonz",
	7472: "7472 Surava",
	7473: "7473 Alvaneu Bad",
	7477: "7477 Filisur",
	7482: "7482 Bergün/Bravuogn",
	7484: "7484 Latsch",
	7492: "7492 Alvaneu Dorf",
	7493: "7493 Schmitten (Albula)",
	7494: "7494 Wiesen GR",
	7500: "7500 St. Moritz",
	7502: "7502 Bever",
	7503: "7503 Samedan",
	7504: "7504 Pontresina",
	7505: "7505 Celerina/Schlarigna",
	7512: "7512 Champfèr",
	7513: "7513 Silvaplana",
	7514: "7514 Sils/Segl Maria",
	7515: "7515 Sils/Segl Baselgia",
	7516: "7516 Maloja",
	7517: "7517 Plaun da Lej",
	7522: "7522 La Punt-Chamues-ch",
	7523: "7523 Madulain",
	7524: "7524 Zuoz",
	7525: "7525 S-chanf",
	7526: "7526 Cinuos-chel",
	7527: "7527 Brail",
	7530: "7530 Zernez",
	7532: "7532 Tschierv",
	7533: "7533 Fuldera",
	7534: "7534 Lü",
	7535: "7535 Valchava",
	7536: "7536 Sta. Maria Val Müstair",
	7537: "7537 Müstair",
	7542: "7542 Susch",
	7543: "7543 Lavin",
	7545: "7545 Guarda",
	7546: "7546 Ardez",
	7550: "7550 Scuol",
	7551: "7551 Ftan",
	7552: "7552 Vulpera",
	7553: "7553 Tarasp",
	7554: "7554 Sent",
	7556: "7556 Ramosch",
	7557: "7557 Vnà",
	7558: "7558 Strada",
	7559: "7559 Tschlin",
	7560: "7560 Martina",
	7562: "7562 Samnaun-Compatsch",
	7563: "7563 Samnaun Dorf",
	7602: "7602 Casaccia",
	7603: "7603 Vicosoprano",
	7604: "7604 Borgonovo",
	7605: "7605 Stampa",
	7606: "7606 Promontogno",
	7608: "7608 Castasegna",
	7610: "7610 Soglio",
	7710: "7710 Alp Grüm",
	7741: "7741 S. Carlo (Poschiavo)",
	7742: "7742 Poschiavo",
	7743: "7743 Brusio",
	7744: "7744 Campocologno",
	7745: "7745 Li Curt",
	7746: "7746 Le Prese",
	7747: "7747 Viano",
	7748: "7748 Campascio",
	8000: "8000 Zürich",
	8001: "8001 Zürich",
	8002: "8002 Zürich",
	8003: "8003 Zürich",
	8004: "8004 Zürich",
	8005: "8005 Zürich",
	8006: "8006 Zürich",
	8008: "8008 Zürich",
	8032: "8032 Zürich",
	8037: "8037 Zürich",
	8038: "8038 Zürich",
	8041: "8041 Zürich",
	8044: "8044 Zürich",
	8045: "8045 Zürich",
	8046: "8046 Zürich",
	8047: "8047 Zürich",
	8048: "8048 Zürich",
	8049: "8049 Zürich",
	8050: "8050 Zürich",
	8051: "8051 Zürich",
	8052: "8052 Zürich",
	8053: "8053 Zürich",
	8055: "8055 Zürich",
	8057: "8057 Zürich",
	8063: "8063 Zürich",
	8064: "8064 Zürich",
	8099: "8099 Zürich",
	8102: "8102 Oberengstringen",
	8103: "8103 Unterengstringen",
	8104: "8104 Weiningen ZH",
	8105: "8105 Watt",
	8106: "8106 Adlikon b. Regensdorf",
	8107: "8107 Buchs ZH",
	8108: "8108 Dällikon",
	8109: "8109 Kloster Fahr",
	8112: "8112 Otelfingen",
	8113: "8113 Boppelsen",
	8114: "8114 Dänikon ZH",
	8115: "8115 Hüttikon",
	8117: "8117 Fällanden",
	8118: "8118 Pfaffhausen",
	8121: "8121 Benglen",
	8122: "8122 Binz",
	8123: "8123 Ebmatingen",
	8124: "8124 Maur",
	8125: "8125 Zollikerberg",
	8126: "8126 Zumikon",
	8127: "8127 Forch",
	8132: "8132 Egg b. Zürich",
	8133: "8133 Esslingen",
	8134: "8134 Adliswil",
	8135: "8135 Langnau am Albis",
	8136: "8136 Gattikon",
	8142: "8142 Uitikon Waldegg",
	8143: "8143 Stallikon",
	8152: "8152 Opfikon",
	8153: "8153 Rümlang",
	8154: "8154 Oberglatt ZH",
	8155: "8155 Niederhasli",
	8156: "8156 Oberhasli",
	8157: "8157 Dielsdorf",
	8158: "8158 Regensberg",
	8162: "8162 Steinmaur",
	8164: "8164 Bachs",
	8165: "8165 Schöfflisdorf",
	8166: "8166 Niederweningen",
	8172: "8172 Niederglatt ZH",
	8173: "8173 Neerach",
	8174: "8174 Stadel b. Niederglatt",
	8175: "8175 Windlach",
	8180: "8180 Bülach",
	8181: "8181 Höri",
	8182: "8182 Hochfelden",
	8184: "8184 Bachenbülach",
	8185: "8185 Winkel",
	8187: "8187 Weiach",
	8192: "8192 Glattfelden",
	8193: "8193 Eglisau",
	8194: "8194 Hüntwangen",
	8195: "8195 Wasterkingen",
	8196: "8196 Wil ZH",
	8197: "8197 Rafz",
	8200: "8200 Schaffhausen",
	8203: "8203 Schaffhausen",
	8207: "8207 Schaffhausen",
	8208: "8208 Schaffhausen",
	8212: "8212 Nohl",
	8213: "8213 Neunkirch",
	8214: "8214 Gächlingen",
	8215: "8215 Hallau",
	8216: "8216 Oberhallau",
	8217: "8217 Wilchingen",
	8218: "8218 Osterfingen",
	8219: "8219 Trasadingen",
	8222: "8222 Beringen",
	8223: "8223 Guntmadingen",
	8224: "8224 Löhningen",
	8225: "8225 Siblingen",
	8226: "8226 Schleitheim",
	8228: "8228 Beggingen",
	8231: "8231 Hemmental",
	8232: "8232 Merishausen",
	8233: "8233 Bargen SH",
	8234: "8234 Stetten SH",
	8235: "8235 Lohn SH",
	8236: "8236 Büttenhardt",
	8238: "8238 Büsingen",
	8239: "8239 Dörflingen",
	8240: "8240 Thayngen",
	8241: "8241 Barzheim",
	8242: "8242 Bibern SH",
	8243: "8243 Altdorf SH",
	8245: "8245 Feuerthalen",
	8246: "8246 Langwiesen",
	8247: "8247 Flurlingen",
	8248: "8248 Uhwiesen",
	8252: "8252 Schlatt TG",
	8253: "8253 Diessenhofen",
	8254: "8254 Basadingen",
	8255: "8255 Schlattingen",
	8259: "8259 Rheinklingen",
	8260: "8260 Stein am Rhein",
	8261: "8261 Hemishofen",
	8262: "8262 Ramsen",
	8263: "8263 Buch SH",
	8264: "8264 Eschenz",
	8265: "8265 Mammern",
	8266: "8266 Steckborn",
	8267: "8267 Berlingen",
	8268: "8268 Mannenbach-Salenstein",
	8269: "8269 Fruthwilen",
	8272: "8272 Ermatingen",
	8273: "8273 Triboltingen",
	8274: "8274 Tägerwilen",
	8280: "8280 Kreuzlingen",
	8302: "8302 Kloten",
	8303: "8303 Bassersdorf",
	8304: "8304 Wallisellen",
	8305: "8305 Dietlikon",
	8306: "8306 Brüttisellen",
	8307: "8307 Effretikon",
	8308: "8308 Illnau",
	8309: "8309 Nürensdorf",
	8310: "8310 Grafstal",
	8311: "8311 Brütten",
	8312: "8312 Winterberg ZH",
	8314: "8314 Kyburg",
	8315: "8315 Lindau",
	8317: "8317 Tagelswangen",
	8320: "8320 Fehraltorf",
	8322: "8322 Madetswil",
	8330: "8330 Pfäffikon ZH",
	8331: "8331 Auslikon",
	8332: "8332 Russikon",
	8335: "8335 Hittnau",
	8340: "8340 Hinwil",
	8342: "8342 Wernetshausen",
	8344: "8344 Bäretswil",
	8345: "8345 Adetswil",
	8352: "8352 Elsau",
	8353: "8353 Elgg",
	8354: "8354 Hofstetten ZH",
	8355: "8355 Aadorf",
	8356: "8356 Ettenhausen TG",
	8357: "8357 Guntershausen b. Aadorf",
	8360: "8360 Eschlikon TG",
	8362: "8362 Balterswil",
	8363: "8363 Bichelsee",
	8370: "8370 Sirnach",
	8372: "8372 Wiezikon b. Sirnach",
	8374: "8374 Dussnang",
	8376: "8376 Au TG",
	8400: "8400 Winterthur",
	8404: "8404 Winterthur",
	8405: "8405 Winterthur",
	8406: "8406 Winterthur",
	8408: "8408 Winterthur",
	8409: "8409 Winterthur",
	8412: "8412 Hünikon (Neftenbach)",
	8413: "8413 Neftenbach",
	8414: "8414 Buch am Irchel",
	8415: "8415 Gräslikon",
	8416: "8416 Flaach",
	8418: "8418 Schlatt b. Winterthur",
	8421: "8421 Dättlikon",
	8422: "8422 Pfungen",
	8424: "8424 Embrach",
	8425: "8425 Oberembrach",
	8426: "8426 Lufingen",
	8427: "8427 Rorbas",
	8428: "8428 Teufen ZH",
	8442: "8442 Hettlingen",
	8444: "8444 Henggart",
	8447: "8447 Dachsen",
	8450: "8450 Andelfingen",
	8451: "8451 Kleinandelfingen",
	8452: "8452 Adlikon b. Andelfingen",
	8453: "8453 Alten",
	8454: "8454 Buchberg",
	8455: "8455 Rüdlingen",
	8457: "8457 Humlikon",
	8458: "8458 Dorf",
	8459: "8459 Volken",
	8460: "8460 Marthalen",
	8461: "8461 Oerlingen",
	8462: "8462 Rheinau",
	8463: "8463 Benken ZH",
	8464: "8464 Ellikon am Rhein",
	8465: "8465 Wildensbuch",
	8466: "8466 Trüllikon",
	8467: "8467 Truttikon",
	8468: "8468 Guntalingen",
	8471: "8471 Bänk (Dägerlen)",
	8472: "8472 Seuzach",
	8474: "8474 Dinhard",
	8475: "8475 Ossingen",
	8476: "8476 Unterstammheim",
	8477: "8477 Oberstammheim",
	8478: "8478 Thalheim an der Thur",
	8479: "8479 Altikon",
	8482: "8482 Sennhof (Winterthur)",
	8483: "8483 Kollbrunn",
	8484: "8484 Weisslingen",
	8486: "8486 Rikon im Tösstal",
	8487: "8487 Zell ZH",
	8488: "8488 Turbenthal",
	8489: "8489 Wildberg",
	8492: "8492 Wila",
	8493: "8493 Saland",
	8494: "8494 Bauma",
	8495: "8495 Schmidrüti",
	8496: "8496 Steg im Tösstal",
	8497: "8497 Fischenthal",
	8498: "8498 Gibswil-Ried",
	8499: "8499 Sternenberg",
	8500: "8500 Gerlikon",
	8505: "8505 Pfyn",
	8506: "8506 Lanzenneunforn",
	8507: "8507 Hörhausen",
	8508: "8508 Homburg",
	8512: "8512 Thundorf",
	8514: "8514 Amlikon-Bissegg",
	8522: "8522 Aawangen",
	8523: "8523 Hagenbuch ZH",
	8524: "8524 Uesslingen",
	8525: "8525 Wilen b. Neunforn",
	8526: "8526 Oberneunforn",
	8532: "8532 Warth",
	8535: "8535 Herdern",
	8536: "8536 Hüttwilen",
	8537: "8537 Uerschhausen",
	8542: "8542 Wiesendangen",
	8543: "8543 Kefikon ZH",
	8544: "8544 Attikon",
	8545: "8545 Rickenbach Sulz",
	8546: "8546 Menzengrüt",
	8547: "8547 Gachnang",
	8548: "8548 Ellikon an der Thur",
	8552: "8552 Felben-Wellhausen",
	8553: "8553 Hüttlingen",
	8554: "8554 Bonau",
	8555: "8555 Müllheim Dorf",
	8556: "8556 Wigoltingen",
	8558: "8558 Raperswilen",
	8560: "8560 Märstetten",
	8561: "8561 Ottoberg",
	8564: "8564 Hefenhausen",
	8565: "8565 Hugelshofen",
	8566: "8566 Lippoldswilen",
	8570: "8570 Weinfelden",
	8572: "8572 Berg TG",
	8573: "8573 Siegershausen",
	8574: "8574 Lengwil-Oberhofen",
	8575: "8575 Bürglen TG",
	8576: "8576 Mauren TG",
	8577: "8577 Schönholzerswilen",
	8580: "8580 Amriswil",
	8581: "8581 Schocherswil",
	8582: "8582 Dozwil",
	8583: "8583 Sulgen",
	8584: "8584 Opfershofen TG",
	8585: "8585 Langrickenbach",
	8586: "8586 Erlen",
	8587: "8587 Oberaach",
	8588: "8588 Zihlschlacht",
	8589: "8589 Sitterdorf",
	8590: "8590 Romanshorn",
	8592: "8592 Uttwil",
	8593: "8593 Kesswil",
	8594: "8594 Güttingen",
	8595: "8595 Altnau",
	8596: "8596 Scherzingen",
	8597: "8597 Landschlacht",
	8598: "8598 Bottighofen",
	8599: "8599 Salmsach",
	8600: "8600 Dübendorf",
	8602: "8602 Wangen b. Dübendorf",
	8603: "8603 Schwerzenbach",
	8604: "8604 Volketswil",
	8605: "8605 Gutenswil",
	8606: "8606 Greifensee",
	8607: "8607 Aathal-Seegräben",
	8608: "8608 Bubikon",
	8610: "8610 Uster",
	8614: "8614 Bertschikon (Gossau ZH)",
	8615: "8615 Freudwil",
	8616: "8616 Riedikon",
	8617: "8617 Mönchaltorf",
	8618: "8618 Oetwil am See",
	8620: "8620 Wetzikon ZH",
	8623: "8623 Wetzikon ZH",
	8624: "8624 Grüt (Gossau ZH)",
	8625: "8625 Gossau ZH",
	8626: "8626 Ottikon (Gossau ZH)",
	8627: "8627 Grüningen",
	8630: "8630 Rüti ZH",
	8632: "8632 Tann",
	8633: "8633 Wolfhausen",
	8634: "8634 Hombrechtikon",
	8635: "8635 Dürnten",
	8636: "8636 Wald ZH",
	8637: "8637 Laupen ZH",
	8638: "8638 Goldingen",
	8639: "8639 Faltigberg",
	8640: "8640 Hurden",
	8645: "8645 Jona",
	8646: "8646 Wagen",
	8700: "8700 Küsnacht ZH",
	8702: "8702 Zollikon",
	8703: "8703 Erlenbach ZH",
	8704: "8704 Herrliberg",
	8706: "8706 Meilen",
	8707: "8707 Uetikon am See",
	8708: "8708 Männedorf",
	8712: "8712 Stäfa",
	8713: "8713 Uerikon",
	8714: "8714 Feldbach",
	8715: "8715 Bollingen",
	8716: "8716 Schmerikon",
	8717: "8717 Benken SG",
	8718: "8718 Schänis",
	8722: "8722 Kaltbrunn",
	8723: "8723 Rufi",
	8725: "8725 Ernetschwil",
	8726: "8726 Ricken SG",
	8727: "8727 Walde SG",
	8730: "8730 Uznach",
	8732: "8732 Neuhaus SG",
	8733: "8733 Eschenbach SG",
	8734: "8734 Ermenswil",
	8735: "8735 St. Gallenkappel",
	8737: "8737 Gommiswald",
	8738: "8738 Uetliburg SG",
	8739: "8739 Rieden SG",
	8750: "8750 Riedern",
	8751: "8751 Urnerboden",
	8752: "8752 Näfels",
	8753: "8753 Mollis",
	8754: "8754 Netstal",
	8755: "8755 Ennenda",
	8756: "8756 Mitlödi",
	8757: "8757 Filzbach",
	8758: "8758 Obstalden",
	8762: "8762 Schwanden GL",
	8765: "8765 Engi",
	8766: "8766 Matt",
	8767: "8767 Elm",
	8772: "8772 Nidfurn",
	8773: "8773 Haslen GL",
	8774: "8774 Leuggelbach",
	8775: "8775 Hätzingen",
	8777: "8777 Betschwanden",
	8782: "8782 Rüti GL",
	8783: "8783 Linthal",
	8784: "8784 Braunwald",
	8800: "8800 Thalwil",
	8802: "8802 Kilchberg ZH",
	8803: "8803 Rüschlikon",
	8804: "8804 Au ZH",
	8805: "8805 Richterswil",
	8806: "8806 Bäch SZ",
	8807: "8807 Freienbach",
	8808: "8808 Pfäffikon SZ",
	8810: "8810 Horgen",
	8815: "8815 Horgenberg",
	8816: "8816 Hirzel",
	8820: "8820 Wädenswil",
	8824: "8824 Schönenberg ZH",
	8825: "8825 Hütten",
	8832: "8832 Wollerau",
	8833: "8833 Samstagern",
	8834: "8834 Schindellegi",
	8835: "8835 Feusisberg",
	8836: "8836 Bennau",
	8840: "8840 Einsiedeln",
	8841: "8841 Gross",
	8842: "8842 Unteriberg",
	8843: "8843 Oberiberg",
	8844: "8844 Euthal",
	8845: "8845 Studen SZ",
	8846: "8846 Willerzell",
	8847: "8847 Egg SZ",
	8849: "8849 Alpthal",
	8852: "8852 Altendorf",
	8853: "8853 Lachen SZ",
	8854: "8854 Galgenen",
	8855: "8855 Wangen SZ",
	8856: "8856 Tuggen",
	8857: "8857 Vorderthal",
	8858: "8858 Innerthal",
	8862: "8862 Schübelbach",
	8863: "8863 Buttikon SZ",
	8864: "8864 Reichenburg",
	8865: "8865 Bilten",
	8866: "8866 Ziegelbrücke",
	8867: "8867 Niederurnen",
	8868: "8868 Oberurnen",
	8872: "8872 Weesen",
	8873: "8873 Amden",
	8874: "8874 Mühlehorn",
	8877: "8877 Murg",
	8878: "8878 Quinten",
	8880: "8880 Walenstadt",
	8881: "8881 Walenstadtberg",
	8882: "8882 Unterterzen",
	8883: "8883 Quarten",
	8884: "8884 Oberterzen",
	8885: "8885 Mols",
	8886: "8886 Mädris-Vermol",
	8887: "8887 Mels",
	8888: "8888 Heiligkreuz (Mels)",
	8889: "8889 Plons",
	8890: "8890 Flums",
	8892: "8892 Berschis",
	8893: "8893 Flums Hochwiese",
	8894: "8894 Flumserberg Saxli",
	8895: "8895 Flumserberg Portels",
	8896: "8896 Flumserberg Bergheim",
	8897: "8897 Flumserberg Tannenheim",
	8898: "8898 Flumserberg Tannenbodenalp",
	8902: "8902 Urdorf",
	8903: "8903 Birmensdorf ZH",
	8904: "8904 Aesch ZH",
	8905: "8905 Arni AG",
	8906: "8906 Bonstetten",
	8907: "8907 Wettswil",
	8908: "8908 Hedingen",
	8909: "8909 Zwillikon",
	8910: "8910 Affoltern am Albis",
	8911: "8911 Rifferswil",
	8912: "8912 Obfelden",
	8913: "8913 Ottenbach",
	8914: "8914 Aeugst am Albis",
	8915: "8915 Hausen am Albis",
	8916: "8916 Jonen",
	8917: "8917 Oberlunkhofen",
	8918: "8918 Unterlunkhofen",
	8919: "8919 Rottenschwil",
	8925: "8925 Ebertswil",
	8926: "8926 Kappel am Albis",
	8932: "8932 Mettmenstetten",
	8933: "8933 Maschwanden",
	8934: "8934 Knonau",
	8942: "8942 Oberrieden",
	8951: "8951 Fahrweid",
	8952: "8952 Schlieren",
	8953: "8953 Dietikon",
	8954: "8954 Geroldswil",
	8955: "8955 Oetwil an der Limmat",
	8956: "8956 Killwangen",
	8957: "8957 Spreitenbach",
	8962: "8962 Bergdietikon",
	8964: "8964 Rudolfstetten",
	8965: "8965 Berikon",
	8966: "8966 Oberwil-Lieli",
	8967: "8967 Widen",
	9000: "9000 St. Gallen",
	9007: "9007 St. Gallen",
	9008: "9008 St. Gallen",
	9010: "9010 St. Gallen",
	9011: "9011 St. Gallen",
	9012: "9012 St. Gallen",
	9014: "9014 St. Gallen",
	9015: "9015 St. Gallen",
	9016: "9016 St. Gallen",
	9030: "9030 Abtwil SG",
	9032: "9032 Engelburg",
	9033: "9033 Untereggen",
	9034: "9034 Eggersriet",
	9035: "9035 Grub AR",
	9036: "9036 Grub SG",
	9037: "9037 Speicherschwendi",
	9038: "9038 Rehetobel",
	9042: "9042 Speicher",
	9043: "9043 Trogen",
	9044: "9044 Wald AR",
	9050: "9050 Appenzell",
	9052: "9052 Niederteufen",
	9053: "9053 Teufen AR",
	9054: "9054 Haslen AI",
	9055: "9055 Bühler",
	9056: "9056 Gais",
	9057: "9057 Weissbad",
	9058: "9058 Brülisau",
	9062: "9062 Lustmühle",
	9063: "9063 Stein AR",
	9064: "9064 Hundwil",
	9100: "9100 Herisau",
	9103: "9103 Schwellbrunn",
	9104: "9104 Waldstatt",
	9105: "9105 Schönengrund",
	9107: "9107 Urnäsch",
	9108: "9108 Gonten",
	9112: "9112 Schachen b. Herisau",
	9113: "9113 Degersheim",
	9114: "9114 Hoffeld",
	9115: "9115 Dicken",
	9116: "9116 Wolfertswil",
	9122: "9122 Mogelsberg",
	9123: "9123 Nassen",
	9125: "9125 Brunnadern",
	9126: "9126 Necker",
	9127: "9127 St. Peterzell",
	9200: "9200 Gossau SG",
	9203: "9203 Niederwil SG",
	9204: "9204 Andwil SG",
	9205: "9205 Waldkirch",
	9212: "9212 Arnegg",
	9213: "9213 Hauptwil",
	9214: "9214 Kradolf",
	9215: "9215 Buhwil",
	9216: "9216 Hohentannen",
	9217: "9217 Neukirch an der Thur",
	9220: "9220 Bischofszell",
	9223: "9223 Halden",
	9225: "9225 St. Pelagiberg",
	9230: "9230 Flawil",
	9231: "9231 Egg (Flawil)",
	9240: "9240 Uzwil",
	9242: "9242 Oberuzwil",
	9243: "9243 Jonschwil",
	9244: "9244 Niederuzwil",
	9245: "9245 Oberbüren",
	9246: "9246 Niederbüren",
	9247: "9247 Henau",
	9248: "9248 Bichwil",
	9249: "9249 Algetshausen",
	9300: "9300 Wittenbach",
	9304: "9304 Bernhardzell",
	9305: "9305 Berg SG",
	9306: "9306 Freidorf TG",
	9308: "9308 Lömmenschwil",
	9312: "9312 Häggenschwil",
	9313: "9313 Muolen",
	9314: "9314 Steinebrunn",
	9315: "9315 Neukirch (Egnach)",
	9320: "9320 Arbon",
	9322: "9322 Egnach",
	9323: "9323 Steinach",
	9325: "9325 Roggwil TG",
	9326: "9326 Horn",
	9327: "9327 Tübach",
	9400: "9400 Rorschach",
	9402: "9402 Mörschwil",
	9403: "9403 Goldach",
	9404: "9404 Rorschacherberg",
	9405: "9405 Wienacht-Tobel",
	9410: "9410 Heiden",
	9411: "9411 Reute AR",
	9413: "9413 Oberegg",
	9414: "9414 Schachen b. Reute",
	9422: "9422 Staad SG",
	9423: "9423 Altenrhein",
	9424: "9424 Rheineck",
	9425: "9425 Thal",
	9426: "9426 Lutzenberg",
	9427: "9427 Wolfhalden",
	9428: "9428 Walzenhausen",
	9430: "9430 St. Margrethen SG",
	9434: "9434 Au SG",
	9435: "9435 Heerbrugg",
	9436: "9436 Balgach",
	9437: "9437 Marbach SG",
	9442: "9442 Berneck",
	9443: "9443 Widnau",
	9444: "9444 Diepoldsau",
	9445: "9445 Rebstein",
	9450: "9450 Altstätten SG",
	9451: "9451 Kriessern",
	9452: "9452 Hinterforst",
	9453: "9453 Eichberg",
	9462: "9462 Montlingen",
	9463: "9463 Oberriet SG",
	9464: "9464 Rüthi (Rheintal)",
	9465: "9465 Salez",
	9466: "9466 Sennwald",
	9467: "9467 Frümsen",
	9468: "9468 Sax",
	9469: "9469 Haag (Rheintal)",
	9470: "9470 Werdenberg",
	9472: "9472 Grabs",
	9473: "9473 Gams",
	9475: "9475 Sevelen",
	9476: "9476 Weite",
	9477: "9477 Trübbach",
	9478: "9478 Azmoos",
	9479: "9479 Oberschan",
	9485: "9485 Nendeln",
	9486: "9486 Schaanwald",
	9487: "9487 Gamprin-Bendern",
	9488: "9488 Schellenberg",
	9490: "9490 Vaduz",
	9491: "9491 Ruggell",
	9492: "9492 Eschen",
	9493: "9493 Mauren FL",
	9494: "9494 Schaan",
	9495: "9495 Triesen",
	9496: "9496 Balzers",
	9497: "9497 Triesenberg",
	9498: "9498 Planken",
	9500: "9500 Wil SG",
	9502: "9502 Braunau",
	9503: "9503 Lanterswil",
	9504: "9504 Friltschen",
	9506: "9506 Lommis",
	9507: "9507 Stettfurt",
	9508: "9508 Weingarten-Kalthäusern",
	9512: "9512 Rossrüti",
	9514: "9514 Wuppenau",
	9515: "9515 Hosenruck",
	9517: "9517 Mettlen",
	9523: "9523 Züberwangen",
	9524: "9524 Zuzwil SG",
	9525: "9525 Lenggenwil",
	9526: "9526 Zuckenriet",
	9527: "9527 Niederhelfenschwil",
	9532: "9532 Rickenbach b. Wil",
	9533: "9533 Kirchberg SG",
	9534: "9534 Gähwil",
	9535: "9535 Wilen b. Wil",
	9536: "9536 Schwarzenbach SG",
	9542: "9542 Münchwilen TG",
	9543: "9543 St. Margarethen TG",
	9545: "9545 Wängi",
	9546: "9546 Tuttwil",
	9547: "9547 Wittenwil",
	9548: "9548 Matzingen",
	9552: "9552 Bronschhofen",
	9553: "9553 Bettwiesen",
	9554: "9554 Tägerschen",
	9555: "9555 Tobel",
	9556: "9556 Affeltrangen",
	9562: "9562 Märwil",
	9565: "9565 Bussnang",
	9573: "9573 Littenheid",
	9601: "9601 Lütisburg Station",
	9602: "9602 Bazenheid",
	9604: "9604 Lütisburg",
	9606: "9606 Bütschwil",
	9607: "9607 Mosnang",
	9608: "9608 Ganterschwil",
	9612: "9612 Dreien",
	9613: "9613 Mühlrüti",
	9614: "9614 Libingen",
	9615: "9615 Dietfurt",
	9620: "9620 Lichtensteig",
	9621: "9621 Oberhelfenschwil",
	9622: "9622 Krinau",
	9630: "9630 Wattwil",
	9631: "9631 Ulisbach",
	9633: "9633 Hemberg",
	9642: "9642 Ebnat-Kappel",
	9643: "9643 Krummenau",
	9650: "9650 Nesslau",
	9651: "9651 Ennetbühl",
	9652: "9652 Neu St. Johann",
	9655: "9655 Stein SG",
	9656: "9656 Alt St. Johann",
	9657: "9657 Unterwasser",
	9658: "9658 Wildhaus",
};
