import React, { createContext, ReactNode, useContext, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

type DialogAction = {
	actionLabel: string;
	actionHandler: () => void;
};

type ShowDialogParams = {
	title: string;
	content: string;
	actions?: DialogAction[];
	onCloseHandler?: () => void;
};

interface DialogContextData {
	showDialog: (params: ShowDialogParams) => void;
}

const DialogContext = createContext<DialogContextData>({
	showDialog: () => {},
});

export const DialogContextProvider: React.FC<{ children: ReactNode }> = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [open, setOpen] = useState(false);
	const [dialogState, setDialogState] = useState<ShowDialogParams>({
		title: "",
		content: "",
		actions: [],
		onCloseHandler: undefined,
	});

	const showDialog = ({
		title,
		content,
		actions,
		onCloseHandler,
	}: ShowDialogParams) => {
		setOpen(true);
		setDialogState({
			title,
			content,
			actions: actions || [],
			onCloseHandler,
		});
	};

	const handleDialogClose = () => {
		setOpen(false);
		dialogState.onCloseHandler && dialogState.onCloseHandler();
	};

	const handleAction = (action: DialogAction) => {
		action.actionHandler();
		handleDialogClose();
	};

	const DialogComponent = (
		<Dialog
			open={open}
			onClose={handleDialogClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{dialogState.title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{dialogState.content}
				</DialogContentText>
			</DialogContent>
			{dialogState.actions && (
				<DialogActions>
					{dialogState.actions.map((action) => (
						<Button
							key={action.actionLabel}
							onClick={() => handleAction(action)}
						>
							{action.actionLabel}
						</Button>
					))}
				</DialogActions>
			)}
		</Dialog>
	);

	return (
		<DialogContext.Provider value={{ showDialog }}>
			{children}
			{DialogComponent}
		</DialogContext.Provider>
	);
};

export const useDialog = () => {
	const { showDialog } = useContext(DialogContext);
	if (!showDialog) {
		throw new Error(
			"useDialog must be used within a DialogContextProvider"
		);
	}
	return { showDialog };
};
