import { create } from "zustand";
import {
	UserManagementSlice,
	createUserManagementStore,
} from "./user-management.store";

interface UserManagementStoreType extends UserManagementSlice {}

export const useUserManagementStore = create<UserManagementStoreType>(
	(...args) => ({
		...createUserManagementStore(...args),
	})
);
