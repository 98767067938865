import React from "react";
import { getDayAndTime } from "../../../../../dentlab/src/lib/utils/utils-functions";
import parse from "html-react-parser";

export const Reply: React.FC<{
	reply: any;
	currentUser: boolean;
}> = ({ reply, currentUser }) => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: currentUser ? "end" : "start",
			}}
		>
			<div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "start",
						alignItems: "flex-end",
						gap: "5px",
					}}
				>
					<p
						style={{
							fontWeight: "bold",
							fontSize: "0.9rem",
						}}
					>
						{reply.sender_name}
					</p>
					<p
						style={{
							fontSize: "0.7rem",
						}}
					>
						{getDayAndTime(reply.created_at)}
					</p>
				</div>
				<div
					style={{
						maxWidth: 300,
						backgroundColor: "var(--neutral-100)",
						padding: 8,
						borderRadius: 5,
						marginLeft: currentUser ? "20px" : 0,
						marginRight: currentUser ? 0 : "20px",
						overflowWrap: "break-word",
					}}
				>
					{parse(reply.text)}
				</div>
			</div>
		</div>
	);
};
