import { useEffect, useState, useRef } from "react";
import { Logger } from "../logger/Logger";

/**
 * useDimensions hook
 * Get the current width or height of a div element
 * @returns width - width of the element
 * @returns height - height of the element
 * @returns updateDimensions - function to update the width and height, e.g. when a sidebar is opened
 * @returns measuredDivRef - reference to the div element that is measured
 */

export const useDimensions = () => {
  const measuredDivRef = useRef<HTMLDivElement | null>(null);
  const [divWidth, setDivWidth] = useState<number | null>(null);
  const [divHeight, setDivHeight] = useState<number | null>(null);

  useEffect(() => {
    // Initial width calculation
    updateDimensions();

    // Attach resize event listener
    window.addEventListener("resize", updateDimensions);

    // Cleanup: remove event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    const currentDiv = measuredDivRef.current;
    if (currentDiv) {
      const width = currentDiv.offsetWidth;
      const height = currentDiv.offsetHeight;
      setDivWidth(width);
      setDivHeight(height);
      Logger.log(`Updated width: ${width}, height: ${height}`);
    }
  };

  return {
    width: divWidth,
    height: divHeight,
    updateDimensions,
    measuredDivRef,
  };
};
