import { IconButton } from "@mui/material";
import { useState } from "react";
import { ShareDialog } from "../../../components/sharing/share-dialog/share-dialog.component";
import ShareIcon from "@mui/icons-material/Share";
import {
	isShared,
	PatientWithShare,
} from "../../../../../lib/supabase/supabaseTypes";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { useShared } from "../../../hooks/useActivities";
import { useSendEmail } from "../../../hooks/useSendEmail";
import { Logger } from "../../../../../lib/logger/Logger";
import { PractitionerOptionType } from "../share-dialog/practitioner-emails-autocomplete";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const SharePatientButton: React.FC<{
	patient: PatientWithShare;
	setPatient: (patient: PatientWithShare) => void;
}> = ({ patient, setPatient }) => {
	const { addSharedPatientActivity } = useShared();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const { showToast } = useToast();

	const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

	const handleShare = async () => {
		if (!connectRelationshipId) {
			showToast("Keine Verbindung zum Labor", "error");
			return;
		}
		if (!patient || !patient.id) {
			showToast("Kein Patient", "error");
			return;
		}

		const { data: activityNewEntry, error: activityError } =
			await addSharedPatientActivity(connectRelationshipId, patient.id);
		if (activityError || !activityNewEntry) {
			showToast("Error beim Teilen des Patienten", "error");
			Logger.error(
				"SharePatientButton: handleShare: activitySuccess is false. Error: ",
				activityError
			);
			return;
		}

		setPatient({
			...patient,
			shared_ids: [activityNewEntry.id],
		});
	};

	const handleUnshare = () => {
		// TODO: reimplement unshare
	};

	const { sendSharedPatientEmailTemplate } = useSendEmail();
	const handleNotify = async (
		practitioners: PractitionerOptionType[],
		message: string
	) => {
		const { label: emailAddress } = practitioners[0];

		sendSharedPatientEmailTemplate({
			emailAddress,
			patientCode: patient.code ?? "Kein Patientencode",
			link: `${import.meta.env.VITE_DENTCONNECT_URL}patient/${
				patient.id
			}`,
			message,
		});

		setIsSharePopupOpen(false);
	};

	if (!patient) return null;
	// if the client is not connected, don't show the share button
	if (!connectRelationshipId) return null;
	return (
		<div>
			<IconButton onClick={() => setIsSharePopupOpen(true)}>
				{isShared(patient) ? (
					<CloudDoneIcon
						sx={{
							color: "green",
						}}
					/>
				) : (
					<ShareIcon
						sx={{
							color: "gray",
						}}
					/>
				)}
			</IconButton>
			<ShareDialog
				shared={isShared(patient)}
				open={isSharePopupOpen}
				onClose={() => setIsSharePopupOpen(false)}
				entityName="Patient"
				onShare={handleShare}
				onUnshare={handleUnshare}
				onNotify={handleNotify}
			/>
		</div>
	);
};
