import * as Sentry from "@sentry/react";

const isLoggerEnabled: boolean =
	process.env.NODE_ENV === "test" ||
	process.env.NODE_ENV === "development" ||
	import.meta.env.VITE_APP_TITLE === "dentLAB-dev_testing" ||
	import.meta.env.VITE_APP_TITLE === "dentCONNECT-dev_testing";

const isProduction: boolean =
	import.meta.env.VITE_APP_TITLE === "dentLAB" ||
	import.meta.env.VITE_APP_TITLE === "dentCONNECT";

export class Logger {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static log = (...params: any[]): void => {
		if (isLoggerEnabled) {
			console.log(...params);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static warn = (...params: any[]): void => {
		if (isLoggerEnabled) {
			console.warn(...params);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static error = (...params: any[]): void => {
		// in all environments, send errors to Sentry
		Sentry.captureException({ exception: params });

		// show errors in all environments except production
		if (!isProduction) {
			console.error(...params);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static info = (...params: any[]): void => {
		if (isLoggerEnabled) {
			console.info(
				"%cINFO\n\n",
				"font-weight: bold; color: blue;",
				...params
			);
		}
	};
}
