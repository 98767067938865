import {
	IconButton,
	InputAdornment,
	StandardTextFieldProps,
	TextField,
	Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

/**
 * CustomTextFieldProps
 * @param {StandardTextFieldProps} props - props from MUI TextField https://mui.com/material-ui/api/text-field/#props
 * @param {string} validationError - error message to be displayed
 * @param {string} customHelperText - helper text to be displayed
 * @param {function} onSave - function to call when save button is clicked
 * @param {function} onReset - function to call when reset button is clicked
 */
export interface CustomTextFieldProps extends StandardTextFieldProps {
	validationError: string | null;
	customHelperText?: string;
	name?: string;
	onSave: (value: string) => void;
	onReset?: () => void;
}

/**
 * Set onChange to use as a controlled component
 */
export const CustomTextFieldWithSaveButton: React.FC<CustomTextFieldProps> = (
	props
) => {
	const [newError, setNewError] = useState<string | null>(null);
	// remove validationError from props
	const {
		validationError,
		customHelperText,
		InputProps,
		onSave,
		onReset = undefined,
		value,
		...rest
	} = props;

	// Internal state for uncontrolled usage
	const [internalValue, setInternalValue] = useState<string>(
		(props.value as string) || ""
	);

	// Effect to update internal value when external value changes
	useEffect(() => {
		// If there exists an external value, update the internal value
		if (props.value !== undefined) {
			setInternalValue(props.value as string);
		}
	}, [props.value]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setInternalValue(newValue);
		if (props.onChange) {
			props.onChange(event);
		}
	};

	const handleBlur = () => {
		setNewError(validationError);
	};

	const isPendingChange =
		internalValue !== props.value && internalValue !== "";
	const color = isPendingChange ? "primary" : "default";
	const disabled = !isPendingChange;
	const endAdornment = (
		<InputAdornment
			position="end"
			sx={{
				position: "absolute",
				right: "7.5px",
				bottom: "20px",
			}}
		>
			<Tooltip title="Speichern">
				<span>
					<IconButton
						edge="end"
						onClick={() => {
							onSave(internalValue as string);
						}}
						color={color}
						disabled={disabled}
						aria-label="save-icon-button"
					>
						<SaveIcon fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
			{onReset && (
				<span>
					<Tooltip title="Zurücksetzen">
						<IconButton
							edge="end"
							onClick={onReset}
							color="primary"
							aria-label="reset-icon-button"
						>
							<RestartAltIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</span>
			)}
		</InputAdornment>
	);

	return (
		<TextField
			data-testid={`custom-text-field-${props.name}`}
			size="small" // default to small, will be overwritten if size is passed in
			value={internalValue}
			onChange={handleChange}
			{...rest}
			error={newError ? true : false}
			helperText={newError ? newError : props?.helperText ?? ""}
			onBlur={handleBlur}
			InputProps={{
				endAdornment: endAdornment,
				...InputProps, // override endAdornment if passed in
			}}
		/>
	);
};
