import {
	FileWithShare,
	isShared,
	JobEntityType,
} from "@/lib/supabase/supabaseTypes";
import { useState } from "react";
import { useStorage } from "../../hooks/useStorage";

import {
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/dentlab/src/components/ui/dropdown-menu";
import { ShareFileButton } from "@/dentlab/src/components/sharing/share-button/share-file-button.component";
import { Check, Link } from "lucide-react";
import clsx from "clsx";
import { useCentralStore } from "../../store/Central";
import { Badge, Tooltip } from "@mui/material";
import { FileIcon } from "./file-icon.component";

/**
 * Displays a file with a dropdown menu that allows the user to open the file in a new tab or share it.
 * Recommended to use in a list with gap-1
 */
export const FileDropDown = ({
	file,
	updateFile,
	job,
}: {
	file: FileWithShare;
	updateFile: (file: FileWithShare) => void;
	job?: JobEntityType;
}) => {
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const { openFileInNewTab } = useStorage();
	const { connectRelationshipId, organization } = useCentralStore(
		(state) => ({
			connectRelationshipId: state.connectRelationshipId,
			organization: state.organization,
		})
	);
	return (
		<>
			<div className="flex gap-1 items-center hover:bg-neutral-100 px-2 rounded-sm">
				<FileIcon fileName={file.file_name ?? ""} />
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Badge
							sx={{
								"& .MuiBadge-badge": {
									right: -3,
									top: 13,
									padding: "0 4px",
								},
							}}
							color="success"
							badgeContent={
								file.organization_id !== organization?.id ? (
									<Link size={10} />
								) : null
							}
						>
							<div
								className={clsx(
									"max-w-48 cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
								)}
							>
								{file.file_name}
							</div>
						</Badge>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem
							onClick={() =>
								openFileInNewTab(file.path_name as string)
							}
							className="flex justify-between items-center cursor-pointer"
						>
							In neuem Tab öffnen
						</DropdownMenuItem>
						{connectRelationshipId && (
							<DropdownMenuItem
								onClick={() => setOpenShareDialog(true)}
								className="flex justify-between items-center cursor-pointer"
							>
								Teilen
								{isShared(file) && (
									<div className="bg-green-500 rounded-full p-0.5">
										<Tooltip
											placement="right"
											title="Datei bereits geteilt"
										>
											<Check color={"white"} size={16} />
										</Tooltip>
									</div>
								)}
							</DropdownMenuItem>
						)}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			{openShareDialog && (
				<ShareFileButton
					setFile={updateFile}
					open={openShareDialog}
					setOpen={setOpenShareDialog}
					file={file}
					associatedEntity={job ? { job, patient: null } : undefined}
				/>
			)}
		</>
	);
};
