import React from "react";
import { PatientWithShare } from "../../../../lib/supabase/supabaseTypes";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { usePatientStore } from "@/dentlab/src/store/Patient";

export const ActivityPatientLink: React.FC<{
	patient: PatientWithShare;
}> = ({ patient }) => {
	const { patientName } = usePatientStore((state) => ({
		patientName: state.patientName,
	}));

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "start",
				gap: "5px",
				alignItems: "center",
			}}
		>
			<AccountBoxIcon
				sx={{
					fontSize: "20px",
				}}
			/>
			<p
				style={{
					fontWeight: "500",
					fontSize: "14px",
					lineHeight: 0, // to align with the icon
				}}
			>
				{patientName(patient)}
			</p>
		</div>
	);
};
