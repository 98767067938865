import { Logger } from "../../../lib/logger/Logger";
import { useToast } from "../context/ToastContext";

export enum TemplateEndpoint {
	SHARED_JOB = "shared-job",
	SHARED_FILE = "shared-file",
	SHARED_PATIENT = "shared-patient",
	SHARED_MESSAGE = "shared-message",
	INVITE = "invite",
}

interface SendEmailTemplateProps {
	emailAddress: string;
	templateEndpoint: TemplateEndpoint;
	variables: Record<string, string>;
}

/**
 * @example const { sendEmail } = useSendEmail();
 * const { success, response, error } = await sendEmail(...)
 */
export function useSendEmail() {
	const { showToast } = useToast();

	const sendEmailTemplate = async ({
		emailAddress,
		templateEndpoint,
		variables,
	}: SendEmailTemplateProps) => {
		const requestOptions: any = {
			method: "POST",
			redirect: "follow",
		};

		let url = `${
			import.meta.env.VITE_PDF_MICROSERVICE_URL
		}/send/${templateEndpoint}?email_address=${encodeURIComponent(
			emailAddress
		)}`;

		Object.keys(variables).forEach((key) => {
			url += `&${key}=${encodeURIComponent(variables[key])}`;
		});

		try {
			const response = await fetch(url, requestOptions);
			Logger.log("useSendEmail", "response", response);
			if (response.status === 200) {
				showToast("E-Mail erfolgreich gesendet", "success");
			} else {
				showToast("Fehler beim Senden der E-Mail", "error");
				Logger.error("useSendEmail", "response", response);
			}
		} catch (err) {
			showToast("Fehler beim Senden der E-Mail", "error");
			Logger.error("useSendEmail", "error", err);
		}
	};

	const sendSharedJobEmailTemplate = async ({
		emailAddress,
		jobTitle,
		link,
		message,
	}: {
		emailAddress: string;
		jobTitle: string;
		link: string;
		message: string;
	}) => {
		sendEmailTemplate({
			emailAddress,
			templateEndpoint: TemplateEndpoint.SHARED_JOB,
			variables: {
				job_title: jobTitle,
				link,
				message,
			},
		});
	};

	const sendSharedFileEmailTemplate = async ({
		emailAddress,
		fileName,
		link,
		message,
	}: {
		emailAddress: string;
		fileName: string;
		link: string;
		message: string;
	}) => {
		sendEmailTemplate({
			emailAddress,
			templateEndpoint: TemplateEndpoint.SHARED_FILE,
			variables: {
				file_name: fileName,
				link,
				message,
			},
		});
	};

	const sendSharedPatientEmailTemplate = async ({
		emailAddress,
		patientCode,
		link,
		message,
	}: {
		emailAddress: string;
		patientCode: string;
		link: string;
		message: string;
	}) => {
		sendEmailTemplate({
			emailAddress,
			templateEndpoint: TemplateEndpoint.SHARED_PATIENT,
			variables: {
				patient_code: patientCode,
				link,
				message,
			},
		});
	};

	const sendSharedMessageEmailTemplate = async ({
		emailAddress,
		message,
		senderName,
	}: {
		emailAddress: string;
		message: string;
		senderName: string;
	}) => {
		sendEmailTemplate({
			emailAddress,
			templateEndpoint: TemplateEndpoint.SHARED_MESSAGE,
			variables: {
				message,
				sender_name: senderName,
			},
		});
	};

	const sendInviteEmailTemplate = async ({
		emailAddress,
		labName,
	}: {
		emailAddress: string;
		labName: string;
	}) => {
		sendEmailTemplate({
			emailAddress,
			templateEndpoint: TemplateEndpoint.INVITE,
			variables: {
				lab_name: labName,
			},
		});
	};

	return {
		sendSharedJobEmailTemplate,
		sendSharedFileEmailTemplate,
		sendSharedPatientEmailTemplate,
		sendSharedMessageEmailTemplate,
		sendInviteEmailTemplate,
	};
}
