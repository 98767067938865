/* eslint-disable @typescript-eslint/naming-convention */
import { supabase } from "@/lib/supabase";
import {
	blobToBase64,
	handleDatabaseOperation,
	repeatFunctionEveryIntervalUntilTimeout,
} from "../../lib/utils/utils-functions";
import {
	DesktopJobResponseEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { useCentralStore } from "../../store/Central";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { useDesktopsStore } from "../../store/Desktops";
import { showNotification } from "../../store/Central/selectors";
import { Logger } from "@/lib/logger/Logger";

export enum RequestType {
	NoRequests = 0,
	GetPrinters = 1,
	PrintDocument = 2,
	PrintLabels = 3,
	ScannersConfig = 4,
	ScanDocument = 5,
	PrintersConfigLocal = 101,
	PrintDocumentLocal = 102,
	PrintLabelsLocal = 103,
	GetScanners = 104,
	ScanDocumentLocal = 105,
	VDDSBVSNames = 201,
	VDDSInterface = 202,
	SLIDAInterface = 203,
	TestRequestPrint = 1001,
}

enum PaperSourceKind {
	StandardTray = 0,
}

type FileType = "pdf" | "docx" | "html" | "rtf" | "xml" | "jpg" | "png";

interface RequestGetPrinters {
	RequestType: RequestType.GetPrinters;
}

export type Printer = {
	PrinterName: string;
	IsDefault: boolean;
	IsPhysical: boolean;
	IsShared: boolean;
	PaperSources: {
		Index: number;
		PaperSourceKind: PaperSourceKind | number;
		PaperSourceName: string;
	}[];
	PaperSizes: {
		Index: number;
		PaperKind: number;
		PaperName: string;
	}[];
};

export interface ResponseToGetPrinters {
	RequestType: RequestType.GetPrinters;
	Count: number;
	Status: 0 | 1; // 0 = success, 1 = error
	Printers: Printer[];
}

interface RequestPrintDocument {
	RequestType: RequestType.PrintDocument;
	FileType: FileType;
	PrinterName: string;
	PaperSourceKind: PaperSourceKind | number;
	Data: string; // base64 encoded
}

enum ResponseToPrintDocumentStatus {
	RequestFullfilled = 0,
	GeneralError = 1,
	PrinterNotAccessible = 2,
	PrinterOffline = 3,
	PaperJam = 4,
	OutOfPaper = 5,
	ManualFeed = 6,
	PaperProblem = 7,
}

// Using this as documentation (currently not used in the code)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ResponseToPrintDocument {
	RequestType: RequestType.PrintDocument;
	Status: ResponseToPrintDocumentStatus;
}

// Using this as documentation (currently not used in the code)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface RequestPrintLabels {
	RequestType: RequestType.PrintLabels;
	LabelLines: string; // Lines separated by tab character (ASCII-9)
	HorOffset: number;
	VertOffset: number;
	PrinterName: string;
	PaperKind: number;
	PaperSourceKind: PaperSourceKind | number;
	Landscape: boolean;
	Count: number;
}

enum ResponseToPrintLabelsStatus {
	RequestFullfilled = 0,
	GeneralError = 1,
	PrinterNotAccessible = 2,
	PrinterOffline = 3,
	PaperJam = 4,
	OutOfPaper = 5,
	ManualFeed = 6,
	PaperProblem = 7,
	LabelCountZero = 8,
}

// Using this as documentation (currently not used in the code)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ResponseToPrintLabels {
	RequestType: RequestType.PrintLabels;
	Status: ResponseToPrintLabelsStatus;
}

interface GetResponseReturnType {
	success: boolean;
	data: DesktopJobResponseEntityType[];
	error: unknown;
}

export const useDesktop = () => {
	const printersConfig = useCentralStore((state) => state.printersConfig);

	const getResponse = async ({
		desktopJobRequestId,
		pollingInterval,
		pollingTimeout,
	}: {
		desktopJobRequestId: number;
		pollingInterval: number;
		pollingTimeout: number;
	}): Promise<GetResponseReturnType> => {
		const response = (await repeatFunctionEveryIntervalUntilTimeout({
			interval: pollingInterval,
			timeout: pollingTimeout,
			functionToCall: async () => {
				Logger.info(
					"Getting response for desktop job request with id: " +
						desktopJobRequestId
				);
				return await handleDatabaseOperation(
					supabase
						.from(SupabaseTableEnum.DESKTOP_JOB_RESPONSES)
						.select("*")
						.eq("desktop_job_request_id", desktopJobRequestId)
				);
			},
			resolveOnSuccessAndData: true, // Resolves only if data is found
		})) as GetResponseReturnType;

		if (!response.success) {
			return {
				success: false,
				data: [],
				error: response.error,
			};
		}

		return response;
	};

	/**
	 * Posts a print document request to the database
	 * Returns the id of the request
	 */
	const printDocument = async ({
		pdfTemplateType,
		fileType,
		documentData,
	}: {
		pdfTemplateType: PdfTemplateType;
		fileType: FileType;
		documentData: Blob;
	}): Promise<number | null> => {
		let desktopSystemId = printersConfig
			? printersConfig[pdfTemplateType]
			: null;
		if (!desktopSystemId) {
			showNotification({
				message:
					"Kein Drucker konfiguriert für diesen Dokumententyp. Nutze Standarddrucker.",
				type: "warning",
			});
			desktopSystemId = printersConfig?.default ?? null;
			if (!desktopSystemId) {
				showNotification({
					message: "Kein Standarddrucker konnte gefunden werden.",
					type: "error",
				});
				return null;
			}
		}

		const desktopId = useDesktopsStore
			.getState()
			.getSystemIdToDesktopIdLookup()[desktopSystemId];
		if (!desktopId) {
			Logger.log("No desktop found for printer", desktopSystemId);
			showNotification({
				message: "Kein Desktop für den Drucker gefunden",
				type: "error",
			});
			return null;
		}

		const desktop = useDesktopsStore
			.getState()
			.desktopsWithSystems.find((desktop) => desktop.id === desktopId);
		if (!desktop) {
			Logger.log("No desktop found for printer", desktopSystemId);
			showNotification({
				message: "Kein Desktop für den Drucker gefunden.",
				type: "error",
			});
			return null;
		}
		const printer = desktop?.desktop_systems.find(
			(system) => system.id === desktopSystemId
		);
		if (!printer) {
			Logger.log("No printer found for desktop", desktopSystemId);
			showNotification({
				message: "Kein Drucker gefunden für den Desktop.",
				type: "error",
			});
			return null;
		}

		// Currently the printer name is used as identifier for the desktop
		// TODO: MAC address would be a more reliable identifier
		const data: RequestPrintDocument = {
			RequestType: RequestType.PrintDocument,
			FileType: fileType,
			PrinterName: printer?.name || "", // "" = standard printer
			PaperSourceKind: PaperSourceKind.StandardTray,
			Data: (await blobToBase64(documentData)) as string,
		};
		const {
			success,
			data: responseData,
			error,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_JOB_REQUESTS)
				.insert({
					type: RequestType.PrintDocument,
					data: JSON.stringify(data),
					desktop_id: desktopId,
					desktop_system_id: desktopSystemId,
				})
				.select("*")
		);
		if (success) {
			showNotification({
				message: "Druckprozess gestartet!",
				type: "info",
			});
			return responseData[0].id as number;
		} else {
			Logger.log("Error printing document", error);
			showNotification({
				message: "Fehler beim Drucken des Dokuments",
				type: "error",
			});
			return null;
		}
	};

	/**
	 * Posts a get printers request to the database
	 */
	const postRequestToGetPrinters = async (
		desktopId: string
	): Promise<null | number> => {
		const data: RequestGetPrinters = {
			RequestType: RequestType.GetPrinters,
		};
		const {
			success,
			data: responseData,
			error,
		} = await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.DESKTOP_JOB_REQUESTS)
				.insert({
					type: RequestType.GetPrinters,
					desktop_id: desktopId,
					data: JSON.stringify(data),
				})
				.select("*")
		);
		if (!success) {
			Logger.log("Error getting printers 1", error);
			showNotification({
				message: "Fehler beim Abrufen der Drucker",
				type: "error",
			});
			return null;
		}

		if (!responseData[0].id) {
			Logger.log("Error getting printers 2", responseData);
			showNotification({
				message: "Fehler beim Abrufen der Drucker",
				type: "error",
			});
			return null;
		}

		return responseData[0].id as number;
	};

	return {
		getResponse,
		printDocument,
		postRequestToGetPrinters,
	};
};
