import { useEffect } from "react";

type CmdKey = "c" | "v" | "x" | "Backspace";

/**
 * useShortCutListener - Hook to listen to clipboard paste events
 * @param callback - Callback function to be called when paste event is triggered
 * @example useShortCutListener((key) => {
    switch (key) {
      case "c":
        Logger.log("Copy");
        break;
      case "x":
        Logger.log("Cut");
        break;
      case "v":
        Logger.log("Paste");
        break;
      case "Backspace":
        Logger.log("Backspace");
        break;
      default:
        break;
    }
  });
 */
export const useShortCutListener = (callback: (key: CmdKey) => void) => {
  useEffect(() => {
    const keyListener = (event: KeyboardEvent) => {
      if (!(event.metaKey || event.ctrlKey)) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case "c":
        case "v":
        case "x":
        case "backspace":
          callback(event.key as CmdKey);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", keyListener);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("keydown", keyListener);
    };
  }, [callback]);
};
