import React from "react";
import { ActivityElementWrapperWithLabel } from "./activity-elements-components/activity-element-wrapper-with-label.component";
import { useStorage } from "@/dentlab/src/hooks/useStorage";
import { useDatabaseFetch } from "@/dentlab/src/hooks/useDatabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { CircularProgress, Paper } from "@mui/material";

export const ActivityNotPdfFile = ({ fileId }: { fileId: string }) => {
	const { data, loading } = useDatabaseFetch(SupabaseTableEnum.FILES, {
		column: "id",
		value: fileId,
	});

	const { openFileInNewTab } = useStorage();

	const file = data?.[0];

	if (!file) return;

	return (
		<ActivityElementWrapperWithLabel label={"Verlinkte Dateien"}>
			<Paper
				sx={{
					width: "100%",
					padding: "10px",
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					// and hover
					"&:hover": {
						backgroundColor: "#f5f5f5",
					},
				}}
				className="activity-job-link"
				variant="outlined"
				onClick={() => openFileInNewTab(file.path_name)}
			>
				{loading ? <CircularProgress size="20px" /> : file.file_name}
			</Paper>
		</ActivityElementWrapperWithLabel>
	);
};
