import React, { useEffect, useState } from "react";
import { RightSidebar } from "../../../../dentlab/src/pages/job-page/job-page-components/right-sidebar.component";
import { ConnectTextInputForReply } from "./thread-sidebar-components/connect-text-input.component";
import { Reply } from "./thread-sidebar-components/reply.component";
import { Divider } from "@mui/material";
import { ActivityInThread } from "./thread-sidebar-components/activity-in-thread.component";
import { useCentralStore } from "../../../../dentlab/src/store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { ActivityEntityType } from "@/lib/supabase/supabaseTypes";
import { Loading } from "../../animations/loading";

export const ThreadSidebar: React.FC<{
	activity: ActivityEntityType;
	onClose: () => void;
	top?: boolean;
}> = ({ activity, onClose, top }) => {
	const [internalActivity, setInternalActivity] =
		useState<ActivityEntityType | null>(null);
	const [loading, setLoading] = useState(true);
	const {
		replies,
		setActivityId,
		emptyReplies,
		activitiesLookup,
		fetchActivity,
	} = useRealtimeStore((state) => ({
		replies: state.replies,
		setActivityId: state.setActivityId,
		emptyReplies: state.emptyReplies,
		fetchActivity: state.fetchActivity,
		activitiesLookup: state.activitiesLookup,
	}));

	useEffect(() => {
		setActivityId(activity.id);
		if (activitiesLookup[activity.id]) {
			setInternalActivity(activitiesLookup[activity.id]);
			setLoading(false);
		} else {
			const fetchAndSetActivity = async () => {
				const fetchedActivity = await fetchActivity(activity.id);
				setInternalActivity(fetchedActivity);
			};

			fetchAndSetActivity().then(() => {
				setLoading(false);
			});
		}
	}, [activity]);

	if (!loading && !internalActivity) return null;

	return (
		<div
			style={{
				position: "fixed",
				height: top ? "100vh" : "95vh",
				right: 0,
				...(top && { top: 0 }),
				backgroundColor: "white",
			}}
		>
			<RightSidebar
				maximizeWidth
				title="Gespräch"
				onClose={() => {
					onClose();
					emptyReplies();
				}}
			>
				{!internalActivity ? (
					<Loading />
				) : (
					<div
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
					>
						<div>
							<div
								style={{
									padding: "10px",
								}}
							>
								<ActivityInThread activity={internalActivity} />
							</div>
							<Divider>{replies.length} Antworten</Divider>
							<RepliesList replies={replies} />
						</div>
						<ConnectTextInputForReply
							messageId={internalActivity?.id}
							muiTextFieldProps={{
								fullWidth: true,
							}}
						/>
					</div>
				)}
			</RightSidebar>
		</div>
	);
};

const RepliesList: React.FC<{ replies: any[] }> = ({ replies }) => {
	const p = useCentralStore((state) => state.profile);
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				paddingLeft: "10px",
				paddingTop: "10px",
				paddingRight: "10px",
				gap: "10px",
			}}
		>
			{replies.map((reply) => (
				<Reply
					key={reply.id}
					reply={reply}
					currentUser={p?.id == reply.profile_id}
				/>
			))}
		</div>
	);
};
