import { Button } from "@mui/material";
import { CustomMarginType } from "../../../types/types";
import { convertCustomMarginTypeToRem } from "../../../lib/utils/utils-functions";
import { useCustomDialogContext } from "../custom-dialog/custom-dialog.component";

interface FormSectionWithSaveButtonProps {
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
	onResetForm: () => void;
	children: React.ReactNode;
	disabled?: boolean;
	margin?: CustomMarginType;
}

/**
 * Can only be used inside a CustomDialog component as it uses the context to prevent closing the dialog when there are unsaved changes.
 */
export const FormSectionWithSaveButton: React.FC<
	FormSectionWithSaveButtonProps & {
		isRenderedInDialog?: boolean;
	}
> = (props) => {
	// If isRenderedInDialog is not set, it will default to true
	const { isRenderedInDialog = true } = props;
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
			}}
			data-testid="form-section-with-save-button"
		>
			{isRenderedInDialog ? (
				<FormSectionWithSaveButtonDialog {...props} />
			) : (
				<FormSectionWithSaveButtonStandard {...props} />
			)}
		</div>
	);
};

export const FormSectionWithSaveButtonDialog: React.FC<
	FormSectionWithSaveButtonProps
> = ({ onSubmit, onResetForm, children, disabled, margin = "normal" }) => {
	const customMargin = convertCustomMarginTypeToRem(margin);

	const { isCloseAttempt, setIsCloseAttempt } = useCustomDialogContext();

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (isCloseAttempt) {
			setIsCloseAttempt(false);
		}
		onSubmit(event);
	};

	return (
		<form onSubmit={handleSubmit}>
			<div
				style={{
					marginLeft: customMargin,
					marginRight: customMargin,
					// If is close attempt, overlay the form with a transparent layer to prevent interaction
					pointerEvents: isCloseAttempt ? "none" : "auto",
					opacity: isCloseAttempt ? 0.5 : 1,
				}}
			>
				{children}
			</div>
			<div
				style={{
					borderTop: "1px solid lightgray",
					width: "100%",
					marginTop: "2rem",
					position: "sticky",
					bottom: 0,
					backgroundColor: "white",
					zIndex: 100,
					paddingTop: "1rem",
					paddingBottom: "1rem",
				}}
			>
				{isCloseAttempt ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							marginLeft: customMargin,
							marginRight: customMargin,
						}}
					>
						<Button
							onClick={() => {
								setIsCloseAttempt(false);
								onResetForm && onResetForm();
							}}
							color="error"
							variant="contained"
							data-testid="form-section-undo-button"
						>
							Rückgängig machen?
						</Button>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "5px",
							}}
						>
							<Button
								onClick={() => {
									setIsCloseAttempt(false);
								}}
								variant="text"
							>
								Weiter bearbeiten
							</Button>
							<Button
								type="submit"
								variant="contained"
								color="success"
							>
								Änderungen speichern
							</Button>
						</div>
					</div>
				) : (
					<div
						style={{
							marginLeft: customMargin,
							marginRight: customMargin,
						}}
					>
						<Button
							variant="contained"
							type="submit"
							disabled={disabled}
							data-testid="form-section-with-save-button-submit-button"
							color="success"
						>
							Speichern
						</Button>
					</div>
				)}
			</div>
		</form>
	);
};

export const FormSectionWithSaveButtonStandard: React.FC<
	FormSectionWithSaveButtonProps
> = ({ onSubmit, children, disabled, margin = "normal" }) => {
	const customMargin = convertCustomMarginTypeToRem(margin);

	return (
		<form onSubmit={onSubmit}>
			<div
				style={{
					marginLeft: customMargin,
					marginRight: customMargin,
				}}
			>
				{children}
			</div>
			<div
				style={{
					borderTop: "1px solid lightgray",
					width: "100%",
					marginTop: "2rem",
					position: "sticky",
					bottom: 0,
					backgroundColor: "white",
					zIndex: 100,
					paddingTop: "1rem",
					paddingBottom: "1rem",
				}}
			>
				<div
					style={{
						marginLeft: customMargin,
						marginRight: customMargin,
					}}
				>
					<Button
						variant="contained"
						type="submit"
						disabled={disabled}
						data-testid="form-section-with-save-button-submit-button"
						color="success"
					>
						Speichern
					</Button>
				</div>
			</div>
		</form>
	);
};
