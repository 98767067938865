import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { RefContextProvider, useRefContext } from "../../../context/RefContext";

// TODO: maybe move this to components folder
export const RightSidebar: React.FC<{
	title: string;
	children: React.ReactNode;
	onClose: () => void;
	onExpand?: () => void;
	tools?: React.ReactNode;
	maximizeWidth?: boolean;
}> = ({ title, children, onClose, onExpand, tools, maximizeWidth }) => {
	return (
		<RefContextProvider>
			<div
				style={{
					borderLeft: "var(--border-md)",
					height: "inherit",
					width: maximizeWidth ? "100%" : "20%",
					minWidth: "300px",
					...(!maximizeWidth && { maxWidth: "500px" }),
					display: "flex",
					flexDirection: "column",
					overflow: "auto",
					zIndex: 50,
				}}
				data-testid={`right-sidebar-${title.split(" ").join("-")}`}
			>
				<div
					style={{
						borderBottom: "var(--border-sm)",
						fontSize: "var(--font-size-title-sm)",
						fontWeight: "var(--font-weight-title)",
						paddingLeft: "10px",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "white",
						minHeight: "var(--height-header-md)",
					}}
				>
					{title}
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						{tools}
						{onExpand && (
							<IconButton
								onClick={onExpand}
								data-testid="right-sidebar-open-in-full-button"
							>
								<OpenInFullIcon />
							</IconButton>
						)}
						<IconButton
							data-testid="close-right-sidebar"
							onClick={onClose}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<SideBarChildren>{children}</SideBarChildren>
			</div>
		</RefContextProvider>
	);
};

const SideBarChildren: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { container } = useRefContext();
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
			ref={container}
		>
			{children}
		</div>
	);
};
