import { useCentralStore } from "@/dentlab/src/store/Central";
import { PdfTemplateType } from "@/dentlab/src/pdf-templates/document-template.types";
import { PrinterSelect } from "@/dentlab/src/forms/form-input-components/selects/printer.select";
import {
	PrintersConfig,
	TemplateTypeOptionsValue,
} from "@/dentlab/src/store/Central/types";
import { Typography } from "@/components/src/typography/typography.component";
import { Button, Dialog, DialogTitle } from "@mui/material";
import { AddPrinterTabs } from "./manager-printers-tab-components/add-printer-tabs.component";
import { useState } from "react";

const pdfTemplateTypeOptions: {
	label: string;
	value: TemplateTypeOptionsValue;
}[] = [
	{ label: "Standard-Drucker", value: "default" },
	{ label: "Kostenvoranschlag", value: PdfTemplateType.KOSTENVORANSCHLAG },
	{ label: "Lieferschein", value: PdfTemplateType.LIEFERSCHEIN },
	{ label: "Rechnung", value: PdfTemplateType.RECHNUNG },
	{ label: "Rechnungsdoppel", value: PdfTemplateType.RECHNUNGSDOPPEL },
	{ label: "Zahlungserinnerung", value: PdfTemplateType.ZAHLUNGSERRINERUNG },
	{ label: "Erste Mahnung", value: PdfTemplateType.ERSTE_MAHNUNG },
	{ label: "Folgemahnung", value: PdfTemplateType.FOLGEMAHNUNG },
	{
		label: "Barzahlungsquittung",
		value: PdfTemplateType.BARZAHLUNGSQUITTUNG,
	},
	{
		label: "Kartenzahlungsquittung",
		value: PdfTemplateType.KARTENZAHLUNGSQUITTUNG,
	},
	{ label: "Akontorechnung", value: PdfTemplateType.AKONTORECHNUNG },
	{ label: "Gutschrift", value: PdfTemplateType.GUTSCHRIFT },
	{ label: "Auftragsblatt", value: PdfTemplateType.AUFTRAGSBLATT },
	{ label: "Sammelrechnung", value: PdfTemplateType.SAMMELRECHNUNG },
	{ label: "Auftragsetiketten", value: "etik_auftrag" },
	{ label: "Versandetiketten", value: "etik_versand" },
	{ label: "Modelletiketten", value: "etik_modell" },
];

export const ManagePrintersTab: React.FC = () => {
	const { printersConfig, updatePrintersConfig } = useCentralStore(
		(state) => ({
			printersConfig: state.printersConfig,
			updatePrintersConfig: state.updatePrintersConfig,
		})
	);

	const [addPrinterDialogOpen, setAddPrinterDialogOpen] = useState(false);

	const handlePrinterChange = (
		columnToChange: TemplateTypeOptionsValue,
		value: number
	) => {
		if (!printersConfig) return;
		updatePrintersConfig({
			...(printersConfig as PrintersConfig),
			[columnToChange]: value,
		});
	};

	if (!printersConfig) {
		return (
			<div>
				<Typography variant="title-md">Meine Drucker</Typography>
				<Typography variant="body-md">
					Keine Druckerkonfiguration gefunden
				</Typography>
			</div>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "0px",
				padding: "0px 0px 20px 0px",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					padding: "0px 20px 20px 20px",
					borderBottom: "1px solid lightgray",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "20px",
						alignItems: "center",
					}}
				>
					<Typography variant="title-md">Meine Drucker</Typography>
					<Button
						variant="outlined"
						onClick={() => setAddPrinterDialogOpen(true)}
					>
						Drucker hinzufügen
					</Button>
				</div>
				<div>
					<Typography variant="body-md">
						Die Konfiguration der Drucker gilt nur für Dokumente,
						die von Ihrem Account gedruckt werden.
					</Typography>
				</div>
			</div>
			{pdfTemplateTypeOptions.map((option) => {
				return (
					<div
						key={option.value}
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr",
							gap: "20px",
							alignItems: "center",
							padding: "10px 20px",
							borderBottom: "1px solid lightgray",
						}}
					>
						<Typography variant="title-sm">
							{option.label}
						</Typography>
						<PrinterSelect
							value={printersConfig[option.value]?.toString()}
							onChange={(value) =>
								handlePrinterChange(
									option.value,
									parseInt(value)
								)
							}
						/>
					</div>
				);
			})}
			<Dialog
				open={addPrinterDialogOpen}
				onClose={() => setAddPrinterDialogOpen(false)}
			>
				<DialogTitle>Drucker hinzufügen</DialogTitle>
				<AddPrinterTabs
					onPrinterAdded={() => setAddPrinterDialogOpen(false)}
				/>
			</Dialog>
		</div>
	);
};
