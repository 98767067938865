import {
	StatusCircle,
	StatusCircleStatusType,
} from "@/dentlab/src/components/status-circle/status-circle.component";
import {
	DesktopEntityTypeWithSystems,
	DesktopSystemEntityType,
} from "@/lib/supabase/supabaseTypes";
import { Button, Paper } from "@mui/material";
import { Typography } from "@/components/src/typography/typography.component";
import { useNavigate } from "react-router-dom";
import { dateOlderThanSeconds } from "@/dentlab/src/lib/utils/utils-functions";

export const DesktopCard: React.FC<{
	desktop: DesktopEntityTypeWithSystems;
}> = ({ desktop }) => {
	let status: StatusCircleStatusType = "info";
	let statusText = "Unbekannt";
	if (!dateOlderThanSeconds(new Date(desktop.last_active_at), 60)) {
		status = "success";
		statusText = "Der Desktop-Dienst ist aktiv";
	} else if (!dateOlderThanSeconds(new Date(desktop.last_active_at), 300)) {
		status = "warning";
		statusText = "Der Desktop-Dienst ist seit kurzem inaktiv";
	} else {
		status = "error";
		statusText = "Der Desktop-Dienst ist nicht erreichbar";
	}

	return (
		<div key={desktop.id}>
			<Paper
				style={{
					padding: "20px 30px 50px 30px",
					maxWidth: "500px",
					height: "100%",
				}}
				variant="outlined"
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "15px",
						marginBottom: "20px",
					}}
				>
					<StatusCircle
						status={status}
						tooltipText={statusText}
						variant="flat"
					/>
					<Typography variant="title-md">{desktop.name}</Typography>
				</div>

				<SystemsList desktopSystems={desktop.desktop_systems} />
			</Paper>
		</div>
	);
};

const SystemsList: React.FC<{
	desktopSystems: DesktopSystemEntityType[];
}> = ({ desktopSystems }) => {
	const navigate = useNavigate();
	const handleEditSystem = () => {
		navigate(`/desktop-systems/${desktopSystems[0].id}`);
	};
	return (
		<div className="flex flex-col gap-2">
			{desktopSystems.map((system) => {
				return (
					<div
						className="rounded bg-slate-100 flex justify-between items-center px-6 py-2"
						key={system.id}
					>
						<Typography variant="title-sm">
							{system.name}
						</Typography>
						<Button onClick={handleEditSystem}>Bearbeiten</Button>
					</div>
				);
			})}
		</div>
	);
};
