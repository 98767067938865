import { AppBarClientsList } from "./app-bar-components/app-bar-lists/app-bar-clients-list.component";
import { AppBarAppsList } from "./app-bar-components/app-bar-lists/app-bar-apps-list.component";
import "./app-bar.css";

export const AppBar: React.FC = () => {
  return (
    <div className="app-bar">
      <AppBarClientsList />
      <AppBarAppsList />
    </div>
  );
};
