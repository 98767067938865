import { State } from "./types";

export const initialState: State = {
	searchResultsClients: [],
	searchResultsPatients: [],
	searchResultsJobs: [],
	loadingClients: false,
	loadingPatients: false,
	loadingJobs: false,
};
