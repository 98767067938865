import { parseStringPromise } from "xml2js";

interface Transaction {
	Amount: number;
	AmountCurrency: string;
	RemittanceInformation: string;
	ValueDate: string;
	BookingDate: string;
}

export async function parseCAMTTransactions(
	xmlData: string
): Promise<Transaction[]> {
	const parsedXml = await parseStringPromise(xmlData);

	const transactions: Transaction[] = [];
	const entries =
		parsedXml?.Document?.BkToCstmrDbtCdtNtfctn[0].Ntfctn[0]?.Ntry || [];

	entries.forEach((entry: any) => {
		const entryDetails = entry?.NtryDtls?.[0]?.TxDtls || [];
		entryDetails.forEach((txDetail: any) => {
			const transaction: Transaction = {
				Amount: parseFloat(txDetail?.Amt?.[0]?._ || "???"),
				AmountCurrency: txDetail?.Amt?.[0]?.$?.Ccy || "???",
				RemittanceInformation:
					txDetail?.RmtInf?.[0]?.Strd?.[0]?.CdtrRefInf?.[0]
						?.Ref?.[0] || "???",
				ValueDate: entry?.ValDt?.[0]?.Dt?.[0] || "???",
				BookingDate: entry?.BookgDt?.[0]?.Dt?.[0] || "???",
			};
			transactions.push(transaction);
		});
	});

	return transactions;
}
