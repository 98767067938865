import { useForm } from "../../hooks/useForm/useForm";
import { Database } from "../../../../lib/supabase";
import { FormProps } from "../form-types";
import { FormSectionWithSaveButton } from "../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { Logger } from "../../../../lib/logger/Logger";
import { useCustomDialogContext } from "../../components/dialog-components/custom-dialog/custom-dialog.component";
import { NumberSelect } from "../form-input-components/selects/number.select";
import { CustomTextField } from "../form-input-components/custom-input-components/custom-text-field.component";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useCentralStore } from "../../store/Central";
import { showNotification } from "../../store/Central/selectors";

export const CompanyMahnungswesenInformation: React.FC<FormProps> = ({
	onSave = (org: any) => {},
	submitRef = null,
}) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { setIsPendingChanges } = useCustomDialogContext();

	const {
		handleInputChange,
		handleUpdate,
		formData,
		formErrors,
		resetFormData,
		handleValueChange,
	} = useForm<
		Database["public"]["Tables"][SupabaseTableEnum.ORGANIZATIONS]["Update"]
	>(
		{},
		SupabaseTableEnum.ORGANIZATIONS,
		true,
		organizationId,
		"Mahnungswesen",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!organizationId) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			Logger.error(
				"No organizationId found in CompanyMahnungswesenInformation"
			);
			return;
		}
		const { success, error, data } = await handleUpdate(
			event,
			organizationId
		);
		if (data) {
			const org = data[0];
			onSave(org);
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={false}
			margin="none"
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "500px",
					paddingTop: "1rem",
				}}
			>
				<NumberSelect
					label="Zahlungsziel (Rechnung zahlaber innert ... Tagen)"
					number={30}
					value={formData.days_payment?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange("days_payment", parseInt(value))
					}
				/>
				<NumberSelect
					label="Zahlungsziel Akonto (Akonto zahlaber innert ... Tagen)"
					number={30}
					value={formData.days_payment_akonto?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange(
							"days_payment_akonto",
							parseInt(value)
						)
					}
				/>
				<NumberSelect
					label="Tage bis zur Zahlungserinnerung"
					number={30}
					value={formData.days_payment_reminder?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange(
							"days_payment_reminder",
							parseInt(value)
						)
					}
				/>
				<NumberSelect
					label="Tage bis zur 1. Mahnung"
					number={30}
					value={formData.days_payment_mahnung_1?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange(
							"days_payment_mahnung_1",
							parseInt(value)
						)
					}
				/>
				<NumberSelect
					label="Tage zwischen folgenden Mahnungen (2. und 3. Mahnung)"
					number={30}
					value={formData.days_payment_mahnung_2?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange(
							"days_payment_mahnung_2",
							parseInt(value)
						)
					}
				/>
				<NumberSelect
					label="Maximale Anzahl Mahnungen (inkl. Zahlungserinnerung)"
					number={4}
					value={formData.mahnung_max_count?.toString() || ""}
					onChange={(value: any) =>
						handleValueChange("mahnung_max_count", parseInt(value))
					}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={
								formData.mahnung_amount_start === 0
									? false
									: true
							}
							onChange={(event) => {
								handleValueChange(
									"mahnung_amount_start",
									event.target.checked ? 1 : 0
								);
							}}
						/>
					}
					label="Artikel und Leistungen inkl. Steuer?"
				/>
				{formData.mahnung_amount_start !== 0 && (
					<>
						<CustomTextField
							label="Mahnzuschlag (Betrag)"
							name="mahnung_amount"
							value={formData.mahnung_amount || ""}
							onChange={handleInputChange}
							validationError={formErrors.mahnung_amount}
						/>
						<NumberSelect
							label="Mahnuzuschlag ab Mahnung"
							number={4}
							value={
								formData.mahnung_amount_start?.toString() || ""
							}
							onChange={(value: any) =>
								handleValueChange(
									"mahnung_amount_start",
									parseInt(value)
								)
							}
						/>
					</>
				)}
			</div>
		</FormSectionWithSaveButton>
	);
};
