import {
	GuarantorEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfJobItemsTable } from "../pdf-template-components/pdf-job-items-table";
import { calculatePrePayment } from "../../lib/utils/calculate";
import "../fonts.css";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { PdfJobInformation } from "../pdf-template-components/pdf-job-information.component";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PdfTemplateType } from "../document-template.types";
import "../fonts.css";
import { CommonPDFTemplateProps } from "./types";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";

export interface KostenvoranschlagTemplateProps extends CommonPDFTemplateProps {
	patient?: PatientEntityType;
	guarantor?: GuarantorEntityType;
}

// References:
// https://www.edv-lehrgang.de/din-5008/
// https://www.kfmv.ch/wissen/berufsalltag/professionelle-briefe-schreiben#:~:text=Aufbau%20eines%20Gesch%C3%A4ftsbriefs,%2C%20der%20rechte%2015%2D20mm
// Briefkopf und Empfängerzeile müssen den Massen entsprechend implementiert werden
// Der Rest kann chronologisch abfolgen und durch Page Breaks geteilt werden
// Padding should always be used assuming it's in addition to height / width
export const KostenvoranschlagTemplate: React.FC<
	KostenvoranschlagTemplateProps
> = ({
	client,
	job,
	organization,
	jobItems,
	jobDocument,
	pdfTemplateConfiguration,
	patient,
	guarantor,
	jobTotals,
}) => {
	// TODO: Solve this in a better way
	if (!jobItems || !job || !organization || !client || !jobDocument) {
		return (
			<div>
				Kostenvoranschlag konnte nicht generiert werden. Beachten Sie
				fehlende Felder
			</div>
		);
	}

	//TODO(pdf)
	const prepayment = calculatePrePayment(3000, 0.3, jobTotals.total.value);
	if (!pdfTemplateConfiguration) {
		return <div>Keine PDF Konfiguration gefunden</div>;
	}

	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.KOSTENVORANSCHLAG}
			/>
			<PdfRecipientAddress
				recipient={job.guarantor_id && guarantor ? guarantor : client}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.KOSTENVORANSCHLAG}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate
					title={jobDocument?.title ?? "Kostenvoranschlag"}
					date={jobDocument?.date}
				/>
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<PdfJobInformation
						pdfTemplateType={PdfTemplateType.KOSTENVORANSCHLAG}
						client={client}
						job={job}
						jobItems={jobItems}
						patient={patient}
					/>
				</div>
				<div
					style={{
						paddingTop: "5mm",
					}}
				>
					{jobItems && job && (
						<PdfJobItemsTable
							pdfTemplateConfiguration={pdfTemplateConfiguration}
							jobTotals={jobTotals}
							jobDocument={jobDocument}
							jobItems={jobItems}
							organization={organization}
							showDrTax={false}
						/>
					)}
				</div>

				<PdfFooter
					templateType={PdfTemplateType.KOSTENVORANSCHLAG}
					pdfTemplateConfiguration={pdfTemplateConfiguration}
				/>
				{/* {prepayment.value > 0 && (
					<div
						aria-label="Anzahlung"
						style={{
							paddingTop: "5mm",
							fontSize: "10pt",
							fontWeight: "bold",
						}}
					>
						Wir bitten Sie eine Anzahlung von {prepayment.text} zu
						leisten.
					</div>
				)} */}
			</div>
			{pdfTemplateConfiguration[PdfTemplateType.KOSTENVORANSCHLAG]
				.showSignature && (
				<div
					aria-label="Fusszeile mit Unterschrift und Datum"
					style={{
						// for some reason, this is not supposed to work (https://www.telerik.com/kendo-react-ui/components/drawing/limitations-browser-support/)
						// ...but it does
						position: "absolute", // fixed position at the bottom of the page
						bottom: "10mm",
						left:
							pdfTemplateConfiguration?.general?.marginLeft +
							"mm",
						right:
							pdfTemplateConfiguration?.general?.marginRight +
							"mm",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							borderBottom: pdfTemplateConfiguration.general
								.showBorder
								? "1px solid black"
								: "",
						}}
					>
						<div
							style={{
								width: "50%",
							}}
						>
							<p>Ort/Datum</p>
						</div>
						<div
							style={{
								width: "50%",
							}}
						>
							<p>Unterschrift</p>
						</div>
					</div>
				</div>
			)}
		</PdfLayout>
	);
};
