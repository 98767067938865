import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface PageTitleProps {
	title: {
		name: string;
		link?: string;
	}[];
}

/**
 * @param title - Array of objects with name and link
 */
export const PageTitle = ({ title }: PageTitleProps) => {
	const navigate = useNavigate();

	const handleClick = (link: string | undefined) => {
		if (link) {
			navigate(link);
		}
	};

	return (
		<div className="flex flex-row text-xl gap-2">
			{title.map((item, index) => (
				<div key={index} className="flex flex-row gap-2">
					{index !== 0 && (
						<span>
							<ChevronRight />
						</span>
					)}
					<span
						onClick={() => handleClick(item.link)}
						className={`${
							item.link
								? "hover:underline hover:text-blue-900 cursor-pointer"
								: ""
						} transition-all duration-150 font-normal c`}
					>
						{item.name}
					</span>
				</div>
			))}
		</div>
	);
};
