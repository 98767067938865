export const ClientDashboardPage = () => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				padding: "20px",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				alignItems: "center",
			}}
		></div>
	);
};
