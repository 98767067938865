// I'm commenting out German values, because there should not be more than value per
// document interface. If needed, the English value should be mapped to the code in another language.
/**
 * JobDocumentTypeEnum - Codes for types of document interfaces within a job
 * VA/PR - Voranmeldung/Pre-Registration
 * AA/WO - Arbeitsauftrag/Work Order
 * KV/QT - Kostenvoranschlag/Quotation
 * ZI/IN - Zusatzinformationen/Information
 * LS/DN - Lieferschein/Delivery Note
 * S/D - Standard/Default
 */
export enum JobDocumentTypeEnum {
	// Voranmeldung = "VA"
	// TODO: PreRegistration = "pr",
	// Arbeitsauftrag = "AA",
	// WorkOrder = "wo",
	// TODO: Kostenvoranschlag = "KV",
	// eslint-disable-next-line @typescript-eslint/naming-convention
	Quotation = "qt",
	// Zusatzinformationen = "ZI",
	// TODO: Information = "in",
	// Lieferschein = "LS",
	// eslint-disable-next-line @typescript-eslint/naming-convention
	DeliveryNote = "dn",
	// Standard = "S",
	// TODO: Default = "d",
	// eslint-disable-next-line @typescript-eslint/naming-convention
	CreditNote = "cn",
	MATERIALS = "materials",
}

export const MapJobDocumentTypeToColor: Record<JobDocumentTypeEnum, string> = {
	// TODO: [JobDocumentTypeEnum.Default]: "#797B84", // gray
	[JobDocumentTypeEnum.DeliveryNote]: "#BF8B85",
	[JobDocumentTypeEnum.Quotation]: "#689689",
	[JobDocumentTypeEnum.CreditNote]: "#5BC3EB",
	[JobDocumentTypeEnum.MATERIALS]: "#797B84",
	// [JobDocumentTypeEnum.Information]: "#B98B82",
	// [JobDocumentTypeEnum.PreRegistration]: "#AA78A6",
	// [JobDocumentTypeEnum.WorkOrder]: "#5BC3EB",
};
