import { CountryEnum } from "@/lib/supabase/supabaseEnums";
import { CitySelect } from "../../forms/form-input-components/selects/city-select/city-select";
import { CustomTextField } from "../../forms/form-input-components/custom-input-components/custom-text-field.component";

export const PostalCodeAndCityInput: React.FC<{
	country: CountryEnum;
	postalCodeValue: string;
	cityValue: string;
	onPostalCodeChange: (value: string) => void;
	onCityChange: (value: string) => void;
	required: boolean;
}> = ({
	country,
	postalCodeValue,
	cityValue,
	onPostalCodeChange,
	onCityChange,
	required,
}) => {
	return (
		<>
			{country === CountryEnum.SWITZERLAND ? (
				<CitySelect
					onChange={(value) => {
						onPostalCodeChange(value.postalCode);
						onCityChange(value.city);
					}}
					value={postalCodeValue}
					required={required}
				/>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "8px",
					}}
				>
					<CustomTextField
						label="PLZ"
						value={postalCodeValue}
						onChange={(event) =>
							onPostalCodeChange(event.target.value)
						}
						size="small"
						fullWidth
						required={required}
					/>
					<CustomTextField
						label="Ort"
						value={cityValue}
						onChange={(event) => onCityChange(event.target.value)}
						size="small"
						fullWidth
						required={required}
					/>
				</div>
			)}
		</>
	);
};
