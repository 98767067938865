import React from "react";
import { ActivityEntityType } from "../../../../../lib/supabase/supabaseTypes";
import { ActivityMessage } from "../../activity-elements/activity-message.component";
import { ActivityTitleWithUserAndDate } from "../../activity-elements/activity-title-with-user-and-date.component";

export const ActivityInThread: React.FC<{
  activity: ActivityEntityType;
}> = ({ activity }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <ActivityTitleWithUserAndDate activity={activity} />
      {activity.text && <ActivityMessage message={activity.text} />}
    </div>
  );
};
