import { ExtendedArticleEntityType, ExtendedTariffEntityType } from "./types";
import { JobStatusEnum } from "@/lib/types/job";
import { State } from "./types";
import {
	JobDocumentEntityType,
	JobItemEntityTypeInsert,
	JobWithShare,
} from "@/lib/supabase/supabaseTypes";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { useJobStore } from ".";
import { v4 as uuidv4 } from "uuid";
import { JobsSlice } from "./job.store";
import { showNotification } from "../Central/selectors";
import { TpTier, TpValue } from "../../types/enums";
import { Logger } from "@/lib/logger/Logger";

const tpTierName = {
	0: "tp_sv",
	1: "tp_pp1",
	2: "tp_pp2",
	3: "tp_pp3",
};

export interface JobEntityTypeWithDocuments extends JobWithShare {
	job_documents: JobDocumentEntityType[];
}

export const initialState: State = {
	jobDocuments: [],
	timers: {},
	jobRequests: null,
	job: null,
	selectedJobStatus: null,
	jobItemsForDocuments: {},
	jobList: {
		[JobStatusEnum.BOOKED_SINGLE]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.IN_PROGRESS]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.COMPLETED]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.NOT_STARTED]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.BOOKED_MONTHLY]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.ARCHIVED]: {
			jobCount: 0,
			jobs: [],
		},
	},
	jobLoading: false,
};

export const formatTariff = (
	item: ExtendedTariffEntityType,
	jobDocumentId: number,
	clientId: string,
	guarantorId: string | null,
	jobTpTier: TpTier,
	jobTpValue: TpValue,
	jobTpVariation: number
): JobItemEntityTypeInsert | null => {
	Logger.info(`[formatTariff] item: ${JSON.stringify(item)}`);
	const {
		id,
		code,
		code_e,
		description_de,
		description_fr,
		description_it,
		is_new,
		own_tp,
		is_own_tp,
		composition,
		standard_quantity,
		tarifwerk,
		tp_pp1,
		tp_pp2,
		tp_pp3,
		tp_sv,
		custom,
		description_other,
		organization_id,
		tariff_default_id,
		tar_id,
		...filteredItem
	} = item;

	// @ts-expect-error - This is a workaround to avoid having to change the type of the item object
	let tp = item[tpTierName[jobTpTier]] ?? item[tpTierName[TpTier.PP2]];
	const taxpunktwert = is_own_tp ? own_tp : jobTpValue;
	if (taxpunktwert === null) {
		showNotification({
			message: "Taxpunktwert ist null",
			type: "error",
		});
		return null;
	}

	// Warn the user if is_own_tp is true but own_tp is 0
	if (taxpunktwert === 0) {
		showNotification({
			message: `${is_own_tp && "Eigener "}Taxpunktwert ist 0`,
			type: "warning",
		});
	}

	// If the job's tp_variation != 0, we need to adjust the taxpunkte with the variation
	// ...and round to the nearest 0.05
	if (jobTpVariation && jobTpVariation !== 0 && jobTpVariation !== null) {
		tp = tp + (tp * jobTpVariation) / 100;
		tp = Math.round(tp * 20) / 20;
	}

	Logger.info(`[formatTariff] tp: ${tp}`);

	const newRow: JobItemEntityTypeInsert = {
		...filteredItem,
		id: uuidv4(),
		created_at: undefined,
		modified_at: undefined,
		price: tp,
		quantity: standard_quantity ?? 1,
		description: description_de, // TODO: localize
		code, // for display on documents
		code_e, // for display in the jobs table
		tp_value: taxpunktwert,
		job_document_id: jobDocumentId,
		tariff_id: item?.custom ? item?.id : null, // If the tariff is custom, we use the id from the custom tariffs table
		type: JobItemTypeEnum.TARIFF,
		// TS was complaining about types needed explicit assertion
		tax_rate: (useJobStore.getState().job?.tax_rate as number) ?? 8.1,
	};

	return newRow;
};

export const formatArticle = (
	item: ExtendedArticleEntityType,
	jobDocumentId: number
): JobItemEntityTypeInsert => {
	Logger.info(`[formatArticle] item: ${JSON.stringify(item)}`);

	// Filter out properties that are not needed in the job item
	const {
		id,
		code_e, // for articles we only have code_e because there's no need for an official code
		description_de,
		description_fr,
		description_it,
		is_new,
		standard_quantity,
		article_default_id,
		cluster,
		custom,
		description_other,
		manufacturer,
		margin,
		modified_stock_quantity_at,
		organization_id,
		purchase_price,
		purchase_quantity,
		stock_quantity,
		unit,
		art_id,
		...filteredItem
	} = item;

	const newRow: JobItemEntityTypeInsert = {
		...filteredItem,
		id: uuidv4(),
		quantity: standard_quantity ?? 1,
		description: description_de, // TODO: localize
		code: code_e,
		code_e: code_e,
		job_document_id: jobDocumentId,
		price: item.price ?? 0,
		article_id: item.custom ? item.id : null, // If the article is custom, we use the id from the custom articles table
		tax_rate: (useJobStore.getState().job?.tax_rate as number) ?? 8.1,
		tp_value: 0, // Articles do not have a TP value
	};

	return newRow;
};

export const adjustJobStatus = (
	state: JobsSlice,
	job: JobEntityTypeWithDocuments,
	targetStatus: JobStatusEnum,
	currentStatus: JobStatusEnum
) => {
	state.job!.status = targetStatus;
	state.jobList[currentStatus as JobStatusEnum].jobCount -= 1;
	state.jobList[currentStatus as JobStatusEnum].jobs = state.jobList[
		currentStatus as JobStatusEnum
	].jobs.filter((j: JobEntityTypeWithDocuments) => j.id !== job.id);
	state.jobList[targetStatus].jobCount += 1;
	state.jobList[targetStatus].jobs.unshift(job);
};
