import { useEffect } from "react";
import { Button } from "@mui/material";
import { useDesktopsStore } from "../../../store/Desktops";
import { DesktopCard } from "./desktop-systems-overview-components/desktop-card.component";
import { Typography } from "@/components/src/typography/typography.component";
import DownloadIcon from "@mui/icons-material/Download";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const DesktopOverviewPage: React.FC = () => {
	const { fetchDesktops, desktopsWithSystems } = useDesktopsStore();
	const organization = useCentralStore((state) => state.organization);

	useEffect(() => {
		if (!organization?.id) {
			return;
		}
		fetchDesktops(organization?.id);
	}, [organization?.id]);

	return (
		<div
			style={{
				gap: "20px",
				padding: "30px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					display: "flex",
					gap: "20px",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography variant="title-lg">Desktop-Systeme</Typography>
				<Button variant="contained" startIcon={<DownloadIcon />}>
					Desktop-Klienten herunterladen
				</Button>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
				{desktopsWithSystems?.map((desktop) => {
					return <DesktopCard key={desktop.id} desktop={desktop} />;
				})}
			</div>
		</div>
	);
};
