import { IconButton } from "@mui/material";
import { CustomButtonProps } from "./button-types";
import CloseIcon from "@mui/icons-material/Close";

export const CloseButtonWithAbsolutePosition: React.FC<CustomButtonProps> = (
	props
) => {
	return (
		<div
			style={{
				height: "2.8rem", // taken from DialogPageWithToolbar
				padding: "0.25rem", // taken from DialogPageWithToolbar
				position: "absolute",
				right: "0.75rem",
				paddingLeft: "0.75rem",
				top: 0,
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
			data-testid="close-button"
		>
			<IconButton aria-label="close" onClick={props.onClick}>
				<CloseIcon />
			</IconButton>
		</div>
	);
};
