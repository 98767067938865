import { useState } from "react";
import { DialogPageWithAddEntityForm } from "../../../../components/dialog-components/dialog-pages/dialog-page-with-add-entity-form/dialog-page-with-add-entity-form.component";
import { BankAccountList } from "../../../../components/list-components/bank-account-list.component";
import { BankAccountForm } from "../../../../forms/form-components/bank-account/insert-update/bank-account.form";

export const ManageBankAccountsTab: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [bankAccountId, setBankAccountId] = useState<number | undefined>(
		undefined
	);
	return (
		<DialogPageWithAddEntityForm
			FormComponent={BankAccountForm}
			onClose={onClose}
			tools={<></>}
			isFormOpen={isFormOpen}
			onIsFormOpenChange={(isFormOpen) => {
				setIsFormOpen(isFormOpen);
				setBankAccountId(undefined);
			}}
			formAddButtonLabel="Neues Konto"
			formProps={{
				bankAccountId: bankAccountId?.toString(),
			}}
			formAddTitle="Bankkonto hinzufügen"
			formUpdateTitle="Bankkonto bearbeiten"
		>
			<BankAccountList
				onEdit={(bankAccountId: string) => {
					setIsFormOpen(true);
					setBankAccountId(Number(bankAccountId));
				}}
			/>
		</DialogPageWithAddEntityForm>
	);
};
