import { Tooltip } from "@mui/material";
import "../../../../styles.css";

/**
 * AppBarButton
 */
export const AppBarButton: React.FC<{
  onClick: () => void;
  children: React.ReactNode;
  isImage?: boolean;
  isSelected?: boolean;
  style?: React.CSSProperties;
  tooltipTitle?: string;
  dataTestId?: string;
}> = ({
  onClick,
  children,
  isImage,
  isSelected,
  style,
  tooltipTitle = "",
  dataTestId,
}) => {
  return (
    <Tooltip title={tooltipTitle} placement="right">
      <div
        className={`app-bar-button ${isSelected ? "selected" : ""}`}
        onClick={onClick}
        style={style}
        data-testid={dataTestId ? dataTestId : "app-bar-button"}
      >
        {isImage ? <img src={children as string} /> : children}
      </div>
    </Tooltip>
  );
};
