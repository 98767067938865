import { Skeleton, TableCell, TableRow } from "@mui/material";
import { v4 } from "uuid";

export const LoadingSkeletonRows = ({
	rows,
	columns = 6,
}: {
	rows: number;
	columns?: number;
}) => {
	return (
		<>
			{Array.from({ length: rows }).map(() => (
				<TableRow key={v4()}>
					{Array.from({ length: columns }).map(() => (
						<TableCell key={v4()}>
							<Skeleton />
						</TableCell>
					))}
				</TableRow>
			))}
		</>
	);
};
