import { Logger } from "@/lib/logger/Logger";
import { z } from "zod";

const environmentSchema = z.object({
	VITE_APP_TITLE: z.string(),
	VITE_SUPABASE_PROJECT_URL: z.string(),
	VITE_SUPABASE_PUBLIC_API_ANON_KEY: z.string(),
	VITE_FIREBASE_API_KEY: z.string(),
	VITE_FIREBASE_AUTH_DOMAIN: z.string(),
	VITE_FIREBASE_PROJECT_ID: z.string(),
	VITE_FIREBASE_STORAGE_BUCKET: z.string(),
	VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
	VITE_FIREBASE_APP_ID: z.string(),
	VITE_FIREBASE_MEASUREMENT_ID: z.string(),
	VITE_PDF_MICROSERVICE_URL: z.string(),
	VITE_MIXPANEL_PROJECT_TOKEN: z.string(),
	VITE_SENTRY_DSN: z.string(),
	VITE_SENTRY_AUTH_TOKEN: z.string(),
	VITE_DENTCONNECT_URL: z.string(),
	VITE_XML_MICROSERVICE_URL: z.string(),
});

export const environment = {
	VITE_APP_TITLE: import.meta.env.VITE_APP_TITLE,
	VITE_SUPABASE_PROJECT_URL: import.meta.env.VITE_SUPABASE_PROJECT_URL,
	VITE_SUPABASE_PUBLIC_API_ANON_KEY: import.meta.env
		.VITE_SUPABASE_PUBLIC_API_ANON_KEY,
	VITE_FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY,
	VITE_FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	VITE_FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	VITE_FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	VITE_FIREBASE_MESSAGING_SENDER_ID: import.meta.env
		.VITE_FIREBASE_MESSAGING_SENDER_ID,
	VITE_FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID,
	VITE_FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
	VITE_PDF_MICROSERVICE_URL: import.meta.env.VITE_PDF_MICROSERVICE_URL,
	VITE_MIXPANEL_PROJECT_TOKEN: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
	VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
	VITE_SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
	VITE_DENTCONNECT_URL: import.meta.env.VITE_DENTCONNECT_URL,
	VITE_XML_MICROSERVICE_URL: import.meta.env.VITE_XML_MICROSERVICE_URL,
};

export const validateEnvironment = () => {
	Logger.info("Validating environment variables");
	const parsedEnvironment = environmentSchema.safeParse(environment);
	if (!parsedEnvironment.success) {
		Logger.error("Invalid environment variables", parsedEnvironment.error);
		throw new Error("Invalid environment variables");
	}
};
