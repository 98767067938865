import { OutlinedInput } from "@mui/material";
import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { TextCustomCell } from "./text-custom-cell.component";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import "./custom-cells.css";
import {
	DropdownMenu,
	DropdownMenuItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import clsx from "clsx";

export const PriceSelect: React.FC<GridCustomCellProps> = (props) => {
	const [price, setPrice] = useState<number | string | null>(null);
	const [openDropdown, setOpenDropdown] = useState(false);
	const { tariffs } = useCentralStore((state) => ({
		tariffs: state.tariffs,
	}));

	const isTariff = props.dataItem?.type === JobItemTypeEnum.TARIFF;

	// check if item is tarifff
	const tariff = isTariff ? tariffs[props.dataItem?.code] : null;

	useEffect(() => {
		if (props.field) {
			setPrice(props.dataItem[props.field]);
		}
	}, [props.dataItem, props.field]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key.toLowerCase() === "enter") {
			handleChange(event);
			(event.target as HTMLInputElement).blur();
		}
	};

	// if not tariff, return text cell
	// No drop down needed
	if (!tariff) {
		return <TextCustomCell {...props} />;
	}

	const options: { price: string; label: string }[] = [
		{
			price: tariff.tp_sv?.toString() ?? "",
			label: "SV",
		},
		{
			price: tariff.tp_pp1?.toString() ?? "",
			label: "PP1",
		},
		{
			price: tariff.tp_pp2?.toString() ?? "",
			label: "PP2",
		},
		{
			price: tariff.tp_pp3?.toString() ?? "",
			label: "PP3",
		},
	].filter((o) => o.price);

	const handleChange = (e: React.SyntheticEvent<EventTarget, Event>) => {
		// @ts-expect-error value is actually present
		const value = Number(e.target?.value);
		if (isNaN(value)) {
			showNotification({
				message: "Bitte geben Sie eine gültige Zahl ein.",
				type: "error",
			});
			// @ts-expect-error value is actually present
			setPrice(props.dataItem[props.field]);
			return;
		}
		const maxPrice = Math.max(...options.map((o) => Number(o.price)));
		const minPrice = Math.min(...options.map((o) => Number(o.price)));
		if (value > maxPrice || value < minPrice) {
			showNotification({
				message:
					"Gewählter Preis ist ausserhalb der Tarifpunktgrenzen!",
				type: "error",
			});
		}

		if (props.onChange) {
			props.onChange({
				dataItem: props.dataItem,
				field: props.field,
				syntheticEvent: e,
				value,
				dataIndex: props.dataIndex,
			});
		}

		setPrice(value);
	};

	return (
		<td
			style={{
				fontSize: "12px",
				display: "flex",
				padding: "2px",
			}}
		>
			<OutlinedInput
				value={price}
				onKeyDown={handleKeyDown}
				onBlur={handleChange}
				onChange={(e) => setPrice(e.target.value)}
				fullWidth
				sx={{
					height: "22px",
					fontSize: "12px",
					paddingLeft: "auto",
					textAlign: "right",
					borderBottomRightRadius: 0,
					borderTopRightRadius: 0,
					"& .MuiOutlinedInput-input": {
						textOverflow: "ellipsis",
					},
				}}
			/>
			<DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
				<DropdownMenuTrigger asChild>
					<div
						className={clsx(
							"rounded-sm h-[22px] rounded-l-none flex items-center ",

							{
								"border-neutral-400/70 border-l-0 border":
									!openDropdown,
								"border-[#036] border-2 border-l-2":
									openDropdown,
							}
						)}
					>
						<ChevronDown size={16} />
					</div>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					{options.map((o) => {
						return (
							<DropdownMenuItem
								onClick={async () => {
									setPrice(Number(o.price));
									handleChange({
										target: { value: o.price },
									} as any);
								}}
								className="flex justify-between items-center cursor-pointer"
							>
								<span>{o.price}</span>
								<span>({o.label})</span>
							</DropdownMenuItem>
						);
					})}
				</DropdownMenuContent>
			</DropdownMenu>
		</td>
	);
};
