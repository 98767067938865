import { useEffect, useState } from "react";

export const useClientManagement = () => {
	const [isClientManagementOpen, setIsClientManagementOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const handleOpen = () => {
		setOpenDialog((prev) => !prev);
	};

	const handleOpenForm = () => {
		setOpenDialog(false);
		setIsClientManagementOpen(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
		setIsClientManagementOpen(false);
	};

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				if (isClientManagementOpen) return;
				setOpenDialog((open) => !open);
			}
		};
		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, [isClientManagementOpen]);

	return {
		isClientManagementOpen,
		openDialog,
		handleOpen,
		handleOpenForm,
		handleClose,
		setOpenDialog,
	};
};
