import { Currency } from "@/dentlab/src/types/enums";
import { CustomSelect } from "../../../components/custom-select.component";
import { SelectProps } from "../../form-types";

const options = [
	{ value: Currency.CHF, label: "CHF" },
	{ value: Currency.EUR, label: "EUR" },
];

export const CurrencySelect: React.FC<SelectProps> = (props) => {
	return <CustomSelect options={options} inputLabel="Währung" {...props} />;
};
