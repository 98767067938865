// TODO: Letzte Aktivität (falls Kommentarfunktion)?
export const archiveColumns: any[] = [
  {
    field: "name",
    headerName: "Name",
  },
  {
    field: "modifed_at",
    headerName: "Zuletzt geändert",
  },
  {
    field: "size",
    headerName: "Größe",
  },
  {
    field: "fileType",
    headerName: "Art",
  },
  {
    field: "client", // TODO: currently files in storage are not linked to a client
    headerName: "Kunde",
    width: 200,
  },
];
