import { formatDate } from "../../lib/utils/utils-functions";

export const PdfTitleAndDate: React.FC<{
  title: string;
  date: string;
}> = ({ title, date }) => {
  return (
    <>
      <div
        aria-label="Betreff"
        style={{
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      <div aria-label="Datum">{formatDate(date)}</div>
    </>
  );
};
