import { MenuItem, Tooltip, Typography } from "@mui/material";
import { FC } from "react";

export const CustomMenuItem: FC<{
	title: string | boolean;
	menuFunction: () => void;
	text: string;
	placement?: "right" | "left";
	disabled?: boolean;
	dataTestId?: string;
}> = ({ title, placement, dataTestId, menuFunction, disabled, text }) => {
	return (
		<Tooltip title={title} placement={placement}>
			<div data-testid={dataTestId}>
				<MenuItem
					onClick={menuFunction}
					sx={{ pl: "20px" }}
					disabled={disabled}
				>
					<Typography textAlign="center">{text}</Typography>
				</MenuItem>
			</div>
		</Tooltip>
	);
};
