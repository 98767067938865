import { CustomSelect } from "../../../../components/custom-select.component";
import { SelectProps } from "../../../form-types";

const options = [
  { value: "weggezogen", label: "weggezogen" },
  { value: "Labor gewechsel", label: "Labor gewechselt" },
  { value: "verstorben", label: "verstorben" },
  { value: "other", label: "Andere" },
];

export const InactiveReasonSelect: React.FC<SelectProps> = ({
  onChange,
  value,
  width,
  size,
  disabled,
}) => {
  return (
    <CustomSelect
      options={options}
      inputLabel="Grund"
      onChange={onChange}
      value={value}
      size={size}
      disabled={disabled}
      width={width}
    />
  );
};
