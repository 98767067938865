import { PermissionStatus } from "./actions-hooks-types";
import { JobStatusEnum } from "@/lib/types/job";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import {
	JobDocumentEntityType,
	JobItemEntityType,
} from "@/lib/supabase/supabaseTypes";
import { useJobStore } from "../../store/Jobs";

function jobDocumentIsBooked(jobDocument: JobDocumentEntityType) {
	return (
		jobDocument.status === JobStatusEnum.BOOKED_SINGLE ||
		jobDocument.status === JobStatusEnum.BOOKED_MONTHLY
	);
}

function jobDocumentIsInProgress(jobDocument: JobDocumentEntityType) {
	return jobDocument.status === JobStatusEnum.IN_PROGRESS;
}

function jobDocumentIsDeliveryNote(jobDocument: JobDocumentEntityType) {
	return jobDocument.type === JobDocumentTypeEnum.DeliveryNote;
}

function jobDocumentIsCreditNote(jobDocument: JobDocumentEntityType) {
	return jobDocument.type === JobDocumentTypeEnum.CreditNote;
}

function jobDocumentIsQuotation(jobDocument: JobDocumentEntityType) {
	return jobDocument.type === JobDocumentTypeEnum.Quotation;
}

/**
 * useJobDocumentActions - Custom hook to create, modify, or delete job documents
 * Can only be used within the client and job context
 */
export const useJobDocumentActions = () => {
	const { updateJobDocumentStatus, job } = useJobStore((state) => ({
		updateJobDocumentStatus: state.updateJobDocumentStatus,
		job: state.job,
	}));

	const archiveJobDocument = (jobDocument: JobDocumentEntityType) => {
		updateJobDocumentStatus(jobDocument.id, JobStatusEnum.ARCHIVED);
	};

	const unarchiveJobDocument = (jobDocument: JobDocumentEntityType) => {
		updateJobDocumentStatus(jobDocument.id, JobStatusEnum.IN_PROGRESS);
		if (!job) {
			return;
		}
	};

	/**
	 * isArchiveJobDocumentAllowed - Function to check if a job document can be archived
	 *
	 * ALLOWED:
	 * - Provided the job document is not already archived
	 * - Then, always if the job document is a quote
	 * - Then, if the job document is a quote, only if it is not booked
	 */
	const isArchiveJobDocumentAllowed = (
		jobDocument: JobDocumentEntityType
	): PermissionStatus => {
		if (jobDocument.status === JobStatusEnum.ARCHIVED) {
			return {
				isAllowed: false,
				explanation: "Das Dokument ist bereits archiviert.",
			};
		}
		if (
			(jobDocumentIsDeliveryNote(jobDocument) ||
				jobDocumentIsCreditNote(jobDocument)) &&
			jobDocumentIsBooked(jobDocument)
		) {
			return {
				isAllowed: false,
				explanation: "Das Dokument ist bereits gebucht.",
			};
		}

		return {
			isAllowed: true,
			explanation: "",
		};
	};

	/**
	 * isUnarchiveJobDocumentAllowed - Function to check if a job document can be unarchived
	 *
	 * ALLOWED:
	 * - Provided the job document is archived
	 * - Always if document is quotation or credit note
	 * - If the document is a delivery note, only if it is not booked
	 */
	const isUnarchiveJobDocumentAllowed = (
		jobDocument: JobDocumentEntityType
	): PermissionStatus => {
		if (jobDocument.status !== JobStatusEnum.ARCHIVED) {
			return {
				isAllowed: false,
				explanation: "Das Dokument ist nicht archiviert.",
			};
		}
		if (
			jobDocumentIsQuotation(jobDocument) ||
			jobDocumentIsCreditNote(jobDocument)
		) {
			return {
				isAllowed: true,
				explanation: "",
			};
		}
		if (
			jobDocumentIsDeliveryNote(jobDocument) &&
			jobDocumentIsBooked(jobDocument)
		) {
			return {
				isAllowed: false,
				explanation: "Das Dokument ist bereits gebucht.",
			};
		} else {
			return {
				isAllowed: true,
				explanation: "",
			};
		}
	};

	/**
	 * isExportJobDocumentAllowed - Function to check if a job document can be exported
	 *
	 * @param {JobDocumentEntityType} jobDocument
	 * @param {JobItemEntityType[]} jobItems
	 *
	 * ALLOWED:
	 * - If the job document is in progress
	 * - If the job document has job items
	 */
	const isExportJobDocumentAllowed = (
		jobDocument: JobDocumentEntityType,
		jobItems: JobItemEntityType[]
	): PermissionStatus => {
		if (jobItems.length === 0) {
			return {
				isAllowed: false,
				explanation: "Das Dokument hat keine Positionen.",
			};
		}
		if (!jobDocumentIsInProgress(jobDocument)) {
			return {
				isAllowed: false,
				explanation: "Das Dokument ist nicht in Bearbeitung.",
			};
		}
		return {
			isAllowed: true,
			explanation: "",
		};
	};

	/** Exporting an Auftragsblatt is always allowed if the document is a quotation or delivery note */
	const isExportAuftragsblattAllowed = (
		jobDocument: JobDocumentEntityType
	): PermissionStatus => {
		if (
			jobDocumentIsQuotation(jobDocument) ||
			jobDocumentIsDeliveryNote(jobDocument)
		) {
			return { isAllowed: true, explanation: "" };
		} else {
			return {
				isAllowed: false,
				explanation:
					"Das Dokument ist kein Kostenvoranschlag oder Lieferschein.",
			};
		}
	};

	const isExportRechnungAllowed = (
		jobDocument: JobDocumentEntityType
	): PermissionStatus => {
		if (jobDocument.type !== JobDocumentTypeEnum.DeliveryNote) {
			return {
				isAllowed: false,
				explanation: "Diese Funktion ist derzeit nicht verfügbar.",
			};
		}
		if (jobDocument.status !== JobStatusEnum.COMPLETED) {
			return {
				isAllowed: false,
				explanation:
					"Die Rechnung kann erst nach Abschluss des Auftrags erstellt werden.",
			};
		}
		if (jobDocumentIsBooked(jobDocument)) {
			return {
				isAllowed: false,
				explanation: "Das Dokument wurde bereits verbucht.",
			};
		}
		return {
			isAllowed: true,
			explanation: "",
		};
	};

	const isExportKartenzahlungAllowed = (
		jobDocument: JobDocumentEntityType
	) => {
		return isExportRechnungAllowed(jobDocument);
	};

	const isExportBarzahlungAllowed = (jobDocument: JobDocumentEntityType) => {
		return isExportRechnungAllowed(jobDocument);
	};

	const isExportXmlAllowed = (
		jobItems: JobItemEntityType[]
	): PermissionStatus => {
		if (jobItems.length <= 0) {
			return {
				isAllowed: false,
				explanation: "Keine Positionen vorhanden",
			};
		}
		return {
			isAllowed: true,
			explanation: "",
		};
	};

	return {
		isArchiveJobDocumentAllowed,
		isExportJobDocumentAllowed,
		isUnarchiveJobDocumentAllowed,
		archiveJobDocument,
		unarchiveJobDocument,
		isExportAuftragsblattAllowed,
		isExportRechnungAllowed,
		isExportKartenzahlungAllowed,
		isExportBarzahlungAllowed,
		isExportXmlAllowed,
	};
};
