import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";

export const TypeCustomCell = (props: { dataItem: JobItemEntityType }) => {
	const { dataItem } = props;
	const type = dataItem.type;
	let typeString = "";
	switch (type) {
		case JobItemTypeEnum.TARIFF:
			typeString = "Tarif";
			break;
		case JobItemTypeEnum.ARTICLE_MATERIAL:
			typeString = "Material";
			break;
		case JobItemTypeEnum.ARTICLE_POSTAGE:
			typeString = "Porto";
			break;
		case JobItemTypeEnum.ARTICLE_EXTERNAL_WORK:
			typeString = "Fremdarbeit";
			break;
		case JobItemTypeEnum.ARTICLE_DISCOUNTS:
			typeString = "Abzug/Barrabatt";
			break;
		case JobItemTypeEnum.ARTICLE_FIXED_RATE:
			typeString = "Arbeitspauschale";
			break;
		default:
			typeString = "Unbekannt";
			break;
	}
	return <td className="custom-td">{typeString}</td>;
};
