// This file contains the actions for the statistics page in general
import { StateCreator } from "zustand";
import { supabase } from "@/lib/supabase";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { Logger } from "@/lib/logger/Logger";
import { State } from "./types";
import { initialState } from "./utils";
import { useCentralStore } from "../Central";

interface Actions {
	initialize: () => Promise<void>;
	changeDate: (value: { start: Date | null; end: Date | null }) => void;
}

export type CentralStatisticsSlice = State & Actions;

export const createCentralStatisticsStore: StateCreator<
	CentralStatisticsSlice
> = (set) => ({
	...initialState,

	// This function is used to initialize the store using the organization_id
	// It fetches all employees and clients from the database and sets the State
	// It also sets the selectedClients to the first 5 clients
	initialize: async () => {
		set({ loading: true });
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) return;

		const { data: employees, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select("*")
			.eq("organization_id", organizationId);

		if (error) {
			Logger.error("Error fetching profiles", error);
			set({ error: error.message });
			return;
		}

		const { data: clients, error: clientError } = await supabase
			.from(SupabaseTableEnum.CLIENTS)
			.select("*")
			.eq("organization_id", organizationId);

		if (clientError) {
			Logger.error("Error fetching clients", clientError);
			set({ error: clientError.message });
			return;
		}

		set({
			allEmployees: employees,
			allClients: clients,
			error: null,
			loading: false,
			selectedClientsForClientChart: clients.slice(0, 5).map((c) => c.id),
		});
	},

	// This function is used to change the date range value in the store
	changeDate: (value: { start: Date | null; end: Date | null }) => {
		if (value.start !== null && value.end !== null) {
			if (
				// Typescript doesn't allow for difference between dates to be calculated, so we have to suppress the ts error
				// @ts-expect-error cast
				((value.end - value.start) as number) >
				1000 * 60 * 60 * 24 * 366
			) {
				return;
			}
		}
		set({
			dateValue: {
				start: value.start,
				end: value.end,
			},
		});
	},
});
