import {
	JobDocumentEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { CalculateTotalsResult } from "../../lib/utils/calculate";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";

export const PdfTableTotalsSection: React.FC<{
	totals: CalculateTotalsResult;
	organization: OrganizationEntityType;
	numberOfColumns: number;
	numberOfAdditionalColumns: number; // additional columns to the right of the total values
	jobDocument?: JobDocumentEntityType; // optional, if totals are for one document (which might have discounts)
	monthlyDiscount?: string; // optional, if totals are for a monthly invoice (which might have a monthly discount)
	pdfTemplateConfiguration: PdfTemplateConfiguration;
}> = ({
	totals,
	organization,
	numberOfColumns,
	numberOfAdditionalColumns,
	jobDocument,
	monthlyDiscount,
	pdfTemplateConfiguration,
}) => {
	const {
		amountMaterials,
		amountPostage,
		amountExternalWork,
		amountDiscounts,
		amountWorkAndFixedRate,
		deductions,
		subTotal,
		mwst,
		total,
		taxpunkte,
	} = totals;

	const generalConfig = pdfTemplateConfiguration?.general;

	const summaryRows: { label: string; value: string }[] = [];

	if (amountWorkAndFixedRate.value > 0) {
		summaryRows.push({
			label: `Arbeit (${taxpunkte.text}Tp)`,
			value: amountWorkAndFixedRate.text,
		});
	}

	if (amountMaterials.value > 0) {
		summaryRows.push({
			label: "Material",
			value: amountMaterials.text,
		});
	}

	if (jobDocument?.discount_work || jobDocument?.discount_material) {
		summaryRows.push({
			label: `Abzüge (${
				jobDocument?.discount_work
					? "Arbeit -" + jobDocument.discount_work
					: ""
			}%, 
      ${
			jobDocument?.discount_material
				? "Mat. -" + jobDocument.discount_material
				: ""
		}%,
      ${
			amountDiscounts.value > 0
				? "Fixe Abzüge " + amountDiscounts.text
				: ""
		})`,
			value: "-" + deductions.text,
		});
	}

	if (amountPostage.value > 0) {
		summaryRows.push({
			label: "Porto",
			value: amountPostage.text,
		});
	}

	if (amountExternalWork.value > 0) {
		summaryRows.push({
			label: "Fremdarbeiten",
			value: amountExternalWork.text,
		});
	}

	// Add empty row
	summaryRows.push({
		label: "",
		value: "",
	});

	if (monthlyDiscount && monthlyDiscount !== "0.00") {
		summaryRows.push({
			label: "Monatsrabatt",
			value: "-" + monthlyDiscount,
		});
	}

	summaryRows.push({
		label: "Subtotal",
		value: subTotal.text,
	});

	// The Mwst. (/EUSt.) could be zero if public.organizations.is_mwst_included is true (meaning the organization has selected that the tariffs and articles already include MwSt./EUSt.)
	if (mwst.value > 0) {
		summaryRows.push({
			label: `${organization.is_mwst_eust ? "EUSt." : "MwSt."} (${
				organization.mwst_percentage
			}%)`,
			value: mwst.text,
		});
	}

	// Add empty row
	summaryRows.push({
		label: "",
		value: "",
	});
	summaryRows.push({
		label: "Total",
		value: total.text,
	});

	return (
		<>
			<div
				className="absolute -z-50"
				style={{
					backgroundColor: "lightgrey",
					height: `${5.25 * summaryRows.length}mm`,
					width: `calc(100% - ${
						(generalConfig?.marginLeft ?? 0) +
						(generalConfig?.marginRight ?? 0) +
						15
					}mm)`,
				}}
			></div>
			{summaryRows.map((row, i) => {
				// Add empty rows
				if (row.label === "" && row.value === "") {
					return (
						<tr
							key={i}
							style={{
								height: "5mm",
							}}
						>
							<td
								colSpan={
									numberOfColumns + numberOfAdditionalColumns
								}
								style={{
									height: "5mm",
								}}
							></td>
						</tr>
					);
				}
				return (
					<SummaryRow
						key={row.label}
						label={row.label}
						value={row.value}
						numberOfColumns={numberOfColumns}
						numberOfAdditionalColumns={numberOfAdditionalColumns}
						// Add borders to the first and last summary rows
						style={
							i === 0
								? { borderTop: "1px solid black" }
								: i === summaryRows.length - 1
									? { borderBottom: "1px solid black" }
									: {}
						}
					/>
				);
			})}
		</>
	);
};

const SummaryRow: React.FC<{
	label: string;
	value: string;
	numberOfColumns: number;
	numberOfAdditionalColumns: number;
	style?: React.CSSProperties;
}> = ({ label, value, style, numberOfColumns, numberOfAdditionalColumns }) => (
	<tr
		style={{
			...style,
			height: "5mm",
		}}
	>
		<td colSpan={2} />
		<td align="left" colSpan={numberOfColumns - 3}>
			{label}
		</td>
		<td align="right" colSpan={1}>
			{value}
		</td>
		{numberOfAdditionalColumns > 0 && (
			<td colSpan={numberOfAdditionalColumns} />
		)}
	</tr>
);
