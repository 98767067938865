import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";

export function getItemCode(item: any) {
	if (!item.code_e) {
		return undefined;
	} else if (item.type === JobItemTypeEnum.TARIFF) {
		return item.code; // code is the code for official use
	} else if (item.cluster && typeof item.cluster === "string") {
		return item.cluster?.concat(".0");
	} else {
		return "2900";
	}
}
