import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

// https://mui.com/material-ui/react-menu/
/**
 * SimpleMenu - Simple menu component
 * @param buttonLabel - label of button to open menu
 * @param menuItems - array of menu items to display
 * @param onSelect - function to execute when menu item is selected
 * @example <SimpleMenu buttonLabel="Menu" menuItems={[{label: "Item 1", value: "item1"}, {label: "Item 2", value: "item2"}]} onSelect={(value) => Logger.log(value)} />
 */
export const SimpleMenu: React.FC<{
	menuItems: { label: string; value: string }[];
	onSelect: (value: string) => void;
	buttonVariant: "standard" | "icon";
	buttonContent: string | JSX.Element;
}> = ({ menuItems, onSelect, buttonVariant, buttonContent }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			{buttonVariant === "icon" ? (
				<IconButton aria-label="settings" onClick={handleClick}>
					{buttonContent}
				</IconButton>
			) : (
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					{buttonContent}
				</Button>
			)}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{menuItems.map((item) => (
					<MenuItem
						key={item.value}
						onClick={() => {
							handleClose();
							onSelect(item.value);
						}}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
