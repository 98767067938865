import React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { supabase } from "../../../../lib/supabase";
import { Logger } from "../../../../lib/logger/Logger";
import { SignInLayout } from "./sign-in-layout.component";
import { validateEmail } from "../../../../dentlab/src/hooks/useForm/form-field-validation-functions";

// TODO: If the user doesn't exist, signInWithOtp() will signup the user instead. To restrict this behaviour, you can set shouldCreateUser in SignInWithPasswordlessCredentials.options to false.
// --> either check yourself whether user exist or do config in supabase

export const DentistSignIn: React.FC<{
	onSignIn: (data: any) => void;
}> = ({ onSignIn }) => {
	const [searchParams, _] = useSearchParams();

	const [email, setEmail] = useState<string>("");
	const [isOTPSent, setIsOTPSent] = useState<boolean>(false);
	const [otp, setOTP] = useState<string>("");

	useEffect(() => {
		if (searchParams.has("email")) {
			setEmail(searchParams.get("email") as string);
		}
	}, [searchParams]);

	const handleSendOTP = async () => {
		Logger.log("DentistSignIn", "handleSendOTP", email);
		const { data, error } = await supabase.auth.signInWithOtp({
			email: email,
		});
		if (error) {
			// Using a toast here is hard to render in the root layout so we are using an alert
			alert(
				"Fehler beim Einloggen. Bitte stellen Sie sicher, dass Sie die richtigen Anmeldedaten eingegeben haben."
			);
		} else {
			setIsOTPSent(true);
		}
		Logger.log("DentistSignIn", "handleSendOTP", data, error);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOTP(event.target.value);
	};

	const handleSubmitOtp = async () => {
		Logger.log("DentistSignIn", "handleSubmitOtp", email, otp);
		const token = otp;
		const { data, error } = await supabase.auth.verifyOtp({
			email,
			token,
			type: "email",
		});
		Logger.log("DentistSignIn", "handleSubmitOtp", data, error);
		if (error) {
			alert(
				"Fehler beim Einloggen. Bitte stellen Sie sicher, dass Sie die richtigen Anmeldedaten eingegeben haben."
			);
		} else {
			onSignIn(data);
		}
	};

	const [error, setError] = useState(false);

	const handleBlur = (e: any) => {
		const email = e.target.value;
		setError(!validateEmail(email));
	};

	return (
		<SignInLayout
			title="Willkommen zurück!"
			subtitle="Geben Sie Ihre E-Mail ein, um ein Einmalpasswort zu erhalten."
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "20px",
				}}
			>
				{isOTPSent ? (
					<>
						<TextField
							onChange={handleOtpChange}
							value={otp || ""}
							label={"Zugangscode"}
							size="medium"
							fullWidth
						/>
						<Button
							onClick={handleSubmitOtp}
							size="large"
							variant="contained"
							fullWidth
						>
							Eingabe
						</Button>
					</>
				) : (
					<>
						<TextField
							label="E-Mail"
							type="email"
							value={email ?? ""}
							onChange={handleEmailChange}
							onBlur={handleBlur}
							error={error}
							helperText={error ? "Format stimmt nicht" : ""}
							variant="outlined"
							fullWidth
							required
						/>
						<Button
							onClick={handleSendOTP}
							size="large"
							variant="contained"
							fullWidth
						>
							Zugangscode senden
						</Button>
					</>
				)}
			</div>
			<a
				style={{
					position: "absolute",
					top: "0",
					right: "0",
					margin: "20px",
					fontSize: "16px",
					color: "var(--neutral-light)",
					textDecoration: "none",
				}}
				// @ts-ignore
				href={import.meta.env.VITE_DENTLAB_URL}
				rel="noopener noreferrer"
			>
				Ich bin Zahntechniker
			</a>
		</SignInLayout>
	);
};
