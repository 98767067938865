import React from "react";
import { getDayAndTime } from "../../../../dentlab/src/lib/utils/utils-functions";

export const ActivityReplies: React.FC<{
	activity: any;
	onThreadOpen: () => void;
}> = ({ activity, onThreadOpen }) => {
	const time = activity.last_reply_at
		? getDayAndTime(activity.last_reply_at)
		: "";

	return (
		<>
			{activity.reply_count > 0 ? (
				<div
					onClick={onThreadOpen}
					style={{
						cursor: "pointer",
						fontSize: "15px",
					}}
				>
					<div>{activity.reply_count} Antworten</div>
					<div
						style={{
							color: "grey",
							fontSize: "12px",
						}}
					>
						{time}
					</div>
				</div>
			) : (
				<div
					onClick={onThreadOpen}
					style={{
						cursor: "pointer",
						fontSize: "15px",
					}}
				>
					Gespräch beginnen
				</div>
			)}
		</>
	);
};
