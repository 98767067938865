import { create } from "zustand";
import { CentralSlice, createCentralStore } from "./central.store";
import { ClientSlice, createClientStore } from "./client.store";
import { ConfigSlice, createConfigStore } from "./config.store";

interface CentralStoreTypes extends CentralSlice, ClientSlice, ConfigSlice {}

export const useCentralStore = create<CentralStoreTypes>((...args) => ({
	...createCentralStore(...args),
	...createClientStore(...args),
	...createConfigStore(...args),
}));
