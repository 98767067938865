import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { GridCustomFilterCellProps } from "@progress/kendo-react-grid";

export const HeaderCustomCell = (props: GridCustomFilterCellProps) => {
	// @ts-expect-error props exist within the children
	if (props?.children?.[0]?.props?.field === "selected") {
		return (
			<CustomHeaderThElement {...props}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "25px",
					}}
				>
					{props.children}
				</div>
			</CustomHeaderThElement>
		);
	}
	return <CustomHeaderThElement {...props} />;
};

const CustomHeaderThElement = (props: GridCustomFilterCellProps) => (
	<HeaderThElement
		columnId={props.thProps?.columnId || ""}
		{...props.thProps}
		style={{
			fontSize: "12px",
			padding: 0,
			margin: 0,
			color: "var(--neutral-dark)",
		}}
	>
		{props.children}
	</HeaderThElement>
);
