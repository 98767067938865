import { Dialog } from "@mui/material";
import React, { createContext } from "react";

const CustomDialogContext = createContext<
  | {
      isPendingChanges: boolean;
      setIsPendingChanges: (isPendingChanges: boolean) => void;
      isCloseAttempt: boolean;
      setIsCloseAttempt: (isCloseAttempt: boolean) => void;
    }
  | undefined
>(undefined);

export const CustomDialogContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isPendingChanges, setIsPendingChanges] = React.useState(false);
  const [isCloseAttempt, setIsCloseAttempt] = React.useState(false);

  return (
    <CustomDialogContext.Provider
      value={{
        isPendingChanges,
        setIsPendingChanges,
        isCloseAttempt,
        setIsCloseAttempt,
      }}
    >
      {children}
    </CustomDialogContext.Provider>
  );
};

export const useCustomDialogContext = () => {
  const context = React.useContext(CustomDialogContext);
  if (!context) {
    throw new Error(
      "useCustomDialogContext must be used within a CustomDialogContextProvider",
    );
  }
  return context;
};

interface CustomDialogProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  dataTestId?: string;
  height?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

/**
 * Components using CustomDialog should decide when to open or close the dialog.
 * However, components inside the dialog will want to tell the parent when they think the dialog should close.
 * Children of the dialog can use CustomDialogContext to decide when to send up a close request.
 */
const CustomDialogChild: React.FC<CustomDialogProps> = ({
  children,
  open,
  onClose,
  dataTestId,
  height = "90%",
  maxWidth = "xl",
}) => {
  const { isPendingChanges, setIsCloseAttempt } = useCustomDialogContext();

  const handleClose = () => {
    if (isPendingChanges) {
      setIsCloseAttempt(true);
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      data-testid={dataTestId}
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          height: height,
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  return (
    <CustomDialogContextProvider>
      <CustomDialogChild {...props}>{props.children}</CustomDialogChild>
    </CustomDialogContextProvider>
  );
};
