import {
	PatientWithShare,
	SupabaseShareEnitityViewEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { useCentralStore } from "../Central";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { createSecurePatientName } from "../../lib/utils/utils-functions";
import { showNotification } from "../Central/selectors";

/* Checks if the patient is a PatientWithShare, i.e. has all information
 * as opposed to LazyPatient, which only fetches the minimum information lazily.
 */
function isLazyPatient(patient: LazyPatient | PatientWithShare): boolean {
	return (patient as LazyPatient).patient_id !== undefined;
}

function getLazyPatientLookup(
	data: JobsWithPatientResult[]
): Record<string, LazyPatient> {
	return data.reduce(
		(acc: Record<string, LazyPatient>, patient) => {
			if (patient?.patient_id) {
				acc[patient.patient_id] = {
					patient_id: patient?.patient_id,
					first_name: patient?.first_name ?? "",
					last_name: patient?.last_name ?? "",
					code: patient?.code ?? "",
					title: patient?.title ?? "",
				};
			}
			return acc;
		},
		{} as Record<string, LazyPatient>
	);
}

interface JobsWithPatientResult {
	client_id: string | null;
	code: string | null;
	first_name: string | null;
	last_name: string | null;
	patient_id: string | null;
	title: string | null;
}

export interface LazyPatient {
	patient_id: string;
	first_name: string;
	last_name: string;
	code: string;
	title: string;
}

/**
 * - fetchPatients is called whenever a client is opened for all patients of that client to improve performance.
 * - getPatient is called whenever a patient is opened to fetch the patient data.
 */
export interface PatientStoreSlice {
	patientsLookup: Record<string, LazyPatient | PatientWithShare>;
	fetchedClientsCache: Record<string, boolean>;
	fetchPatients: (clientId: string) => Promise<void>;
	fetchPatientSearchResults: (query: string) => Promise<LazyPatient[] | null>;
	fetchPatientsByIds: (patientIds: string[]) => Promise<void>;
	getPatient: (patientId: string) => Promise<PatientWithShare | null>;
	upsertPatient: (patient: PatientWithShare) => void;
	getIsPatientShared: (patientId: string | null) => Promise<boolean>;
	patientName: (patient: LazyPatient | PatientWithShare) => string;
}

export const createPatientStore: StateCreator<PatientStoreSlice> = (
	set,
	get
) => ({
	patientsLookup: {},
	fetchedClientsCache: {},
	fetchPatients: async (clientId: string) => {
		const organizationId = useCentralStore.getState().organization?.id;
		if (!organizationId) {
			Logger.error("No organization ID");
			return;
		}

		if (get().fetchedClientsCache[clientId]) {
			return;
		}

		let allData: JobsWithPatientResult[] = [];
		let hasMore = true;
		let page = 0;
		const pageSize = 1000;

		while (hasMore) {
			const { data, error } = await supabase
				.from(SupabaseShareEnitityViewEnum.JOBS_WITH_PATIENT)
				.select()
				.eq("client_id", clientId)
				.range(page * pageSize, (page + 1) * pageSize - 1);

			if (error) {
				Logger.error(error);
				return;
			}

			if (data) {
				allData = [...allData, ...data];
				hasMore = data.length === pageSize;
				page++;
			} else {
				hasMore = false;
			}
		}

		// Merge the lazy patients lookup with the existing patients lookup; where the existing patients lookup takes precedence
		// because it has more data
		set((state) => ({
			patientsLookup: {
				...getLazyPatientLookup(allData),
				...state.patientsLookup,
			},
		}));
		set((state) => ({
			fetchedClientsCache: {
				...state.fetchedClientsCache,
				[clientId]: true,
			},
		}));
	},
	fetchPatientSearchResults: async (query: string) => {
		const { data, error } = await supabase
			.from(SupabaseShareEnitityViewEnum.JOBS_WITH_PATIENT)
			.select()
			.or(query);

		if (error) {
			Logger.error(error);
			return null;
		}
		const lazyPatientsLookup = getLazyPatientLookup(data);
		set((state) => ({
			patientsLookup: {
				...lazyPatientsLookup,
				...state.patientsLookup,
			},
		}));

		return Object.values(lazyPatientsLookup);
	},
	fetchPatientsByIds: async (patientIds: string[]) => {
		if (patientIds.length === 0) {
			return;
		} else if (patientIds.length > 1000) {
			showNotification({
				message: "Es wurden mehr als 1000 Patienten ausgewählt.",
				type: "warning",
			});
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseShareEnitityViewEnum.PATIENTS_WITH_SHARES)
			.select()
			.in("id", patientIds);

		if (error) {
			Logger.error(error);
			return;
		}

		const newPatientsLookup = data.reduce(
			(acc, patient) => {
				if (patient.id) {
					acc[patient.id] = patient;
				}
				return acc;
			},
			{} as Record<string, PatientWithShare>
		);

		set((state) => ({
			patientsLookup: {
				...state.patientsLookup,
				...newPatientsLookup,
			},
		}));
	},
	fetchPatientsForMonthlyInvoices: async (clientId: string) => {
		await get().fetchPatients(clientId);
	},
	getPatient: async (patientId: string) => {
		const patientsLookup = get().patientsLookup;
		const existingPatient = patientsLookup[patientId];

		if (existingPatient && !isLazyPatient(existingPatient)) {
			return existingPatient as PatientWithShare;
		} else {
			const { data, error } = await supabase
				.from(SupabaseShareEnitityViewEnum.PATIENTS_WITH_SHARES)
				.select()
				.eq("id", patientId)
				.single();

			if (error) {
				Logger.error(error);
				return null;
			}

			set((state) => ({
				patientsLookup: {
					...state.patientsLookup,
					[patientId]: data,
				},
			}));

			return data;
		}
	},
	getIsPatientShared: async (patientId: string | null) => {
		if (!patientId) {
			return false;
		}
		const patient = await get().getPatient(patientId);
		if (!patient) {
			return false;
		}
		return (
			patient.connect_relationship_id ===
			useCentralStore.getState().connectRelationshipId
		);
	},
	upsertPatient: (patient) => {
		if (!patient.id) {
			return;
		}
		set((state) => ({
			patientsLookup: {
				...state.patientsLookup,
				[patient.id as string]: {
					...patient,
					shared_ids: patient.shared_ids ?? null,
				},
			},
		}));
	},
	patientName: (patient) => {
		const isPrivacyOn =
			!!useCentralStore.getState().profile?.data_privacy_mode;

		return createSecurePatientName(
			(patient as PatientWithShare)?.id ??
				(patient as LazyPatient)?.patient_id ??
				null,
			patient?.first_name ?? "",
			patient?.last_name ?? "",
			isPrivacyOn
		);
	},
});
