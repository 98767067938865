import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_APP_TITLE,
	// Performance monitoring, sample rate 100%
	tracesSampleRate: process.env.NODE_ENV === "development" ? 0.0 : 1.0,
	profilesSampleRate: 1.0, // relative to tracesSampleRate, final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// Session replays, sample rate 100%
	replaysSessionSampleRate: 0.0,
	replaysOnErrorSampleRate:
		process.env.NODE_ENV === "development" ? 0.0 : 1.0,
});

export const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);
