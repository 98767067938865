import React from "react";
import { Paper } from "@mui/material";
import { ActivityElementWrapperWithLabel } from "./activity-elements-components/activity-element-wrapper-with-label.component";
import parse from "html-react-parser";

// TODO: add is_email_was_sent data ({email} wurde über Email benachrichtigt)

export const ActivityMessage: React.FC<{
	message: string;
}> = ({ message }) => {
	return (
		<ActivityElementWrapperWithLabel label="Nachricht">
			<Paper
				variant="outlined"
				sx={{
					minWidth: 275,
					paddingLeft: "10px",
					paddingRight: "10px",
					paddingTop: "5px",
					paddingBottom: "5px",
					width: "100%",
				}}
			>
				<div
					style={{
						fontSize: "15px",
						color: "#383838",
					}}
				>
					{parse(message)}
				</div>
			</Paper>
		</ActivityElementWrapperWithLabel>
	);
};
