export const uniqueCantons = [
	"NE",
	"TG",
	"GE",
	"SH",
	"JU",
	"LU",
	"NW",
	"GR",
	"DE",
	"VS",
	"ZG",
	"SZ",
	"AI",
	"FR",
	"AG",
	"BL",
	"GL",
	"FL",
	"ZH",
	"OW",
	"BE",
	"SO",
	"AR",
	"SG",
	"BS",
	"UR",
	"VD",
	"IT",
	"TI",
];

export const postalCodeToCanton: {
	[key: number]: string;
} = {
	1000: "VD",
	1003: "VD",
	1004: "VD",
	1005: "VD",
	1006: "VD",
	1007: "VD",
	1008: "VD",
	1009: "VD",
	1010: "VD",
	1011: "VD",
	1012: "VD",
	1015: "VD",
	1018: "VD",
	1020: "VD",
	1022: "VD",
	1023: "VD",
	1024: "VD",
	1025: "VD",
	1026: "VD",
	1027: "VD",
	1028: "VD",
	1029: "VD",
	1030: "VD",
	1031: "VD",
	1032: "VD",
	1033: "VD",
	1034: "VD",
	1035: "VD",
	1036: "VD",
	1037: "VD",
	1038: "VD",
	1040: "VD",
	1041: "VD",
	1042: "VD",
	1043: "VD",
	1044: "VD",
	1045: "VD",
	1046: "VD",
	1047: "VD",
	1052: "VD",
	1053: "VD",
	1054: "VD",
	1055: "VD",
	1058: "VD",
	1059: "VD",
	1061: "VD",
	1062: "VD",
	1063: "VD",
	1066: "VD",
	1068: "VD",
	1070: "VD",
	1071: "VD",
	1072: "VD",
	1073: "VD",
	1076: "VD",
	1077: "VD",
	1078: "VD",
	1080: "VD",
	1081: "VD",
	1082: "VD",
	1083: "VD",
	1084: "VD",
	1085: "VD",
	1088: "VD",
	1090: "VD",
	1091: "VD",
	1092: "VD",
	1093: "VD",
	1094: "VD",
	1095: "VD",
	1096: "VD",
	1097: "VD",
	1098: "VD",
	1110: "VD",
	1112: "VD",
	1113: "VD",
	1114: "VD",
	1115: "VD",
	1116: "VD",
	1117: "VD",
	1121: "VD",
	1122: "VD",
	1123: "VD",
	1124: "VD",
	1125: "VD",
	1126: "VD",
	1127: "VD",
	1128: "VD",
	1131: "VD",
	1132: "VD",
	1134: "VD",
	1135: "VD",
	1136: "VD",
	1141: "VD",
	1142: "VD",
	1143: "VD",
	1144: "VD",
	1145: "VD",
	1146: "VD",
	1147: "VD",
	1148: "VD",
	1149: "VD",
	1162: "VD",
	1163: "VD",
	1164: "VD",
	1165: "VD",
	1166: "VD",
	1167: "VD",
	1168: "VD",
	1169: "VD",
	1170: "VD",
	1172: "VD",
	1173: "VD",
	1174: "VD",
	1175: "VD",
	1176: "VD",
	1180: "VD",
	1182: "VD",
	1183: "VD",
	1184: "VD",
	1185: "VD",
	1186: "VD",
	1187: "VD",
	1188: "VD",
	1189: "VD",
	1195: "VD",
	1196: "VD",
	1197: "VD",
	1200: "GE",
	1201: "GE",
	1202: "GE",
	1203: "GE",
	1204: "GE",
	1205: "GE",
	1206: "GE",
	1207: "GE",
	1208: "GE",
	1209: "GE",
	1212: "GE",
	1213: "GE",
	1214: "GE",
	1215: "GE",
	1216: "GE",
	1217: "GE",
	1218: "GE",
	1219: "GE",
	1220: "GE",
	1222: "GE",
	1223: "GE",
	1224: "GE",
	1225: "GE",
	1226: "GE",
	1227: "GE",
	1228: "GE",
	1231: "GE",
	1232: "GE",
	1233: "GE",
	1234: "GE",
	1236: "GE",
	1237: "GE",
	1239: "GE",
	1241: "GE",
	1242: "GE",
	1243: "GE",
	1244: "GE",
	1245: "GE",
	1246: "GE",
	1247: "GE",
	1248: "GE",
	1251: "GE",
	1252: "GE",
	1253: "GE",
	1254: "GE",
	1255: "GE",
	1256: "GE",
	1257: "GE",
	1258: "GE",
	1260: "VD",
	1261: "VD",
	1262: "VD",
	1263: "VD",
	1264: "VD",
	1265: "VD",
	1266: "VD",
	1267: "VD",
	1268: "VD",
	1269: "VD",
	1270: "VD",
	1271: "VD",
	1272: "VD",
	1273: "VD",
	1274: "VD",
	1275: "VD",
	1276: "VD",
	1277: "VD",
	1278: "VD",
	1279: "VD",
	1281: "GE",
	1283: "GE",
	1284: "GE",
	1285: "GE",
	1286: "GE",
	1287: "GE",
	1288: "GE",
	1290: "VD",
	1291: "VD",
	1292: "GE",
	1293: "GE",
	1294: "GE",
	1295: "VD",
	1296: "VD",
	1297: "VD",
	1298: "GE",
	1299: "VD",
	1302: "VD",
	1303: "VD",
	1304: "VD",
	1305: "VD",
	1306: "VD",
	1307: "VD",
	1308: "VD",
	1312: "VD",
	1313: "VD",
	1315: "VD",
	1316: "VD",
	1317: "VD",
	1318: "VD",
	1321: "VD",
	1322: "VD",
	1323: "VD",
	1324: "VD",
	1325: "VD",
	1326: "VD",
	1329: "VD",
	1337: "VD",
	1338: "VD",
	1341: "VD",
	1342: "VD",
	1343: "VD",
	1344: "VD",
	1345: "VD",
	1346: "VD",
	1347: "VD",
	1348: "VD",
	1350: "VD",
	1352: "VD",
	1353: "VD",
	1354: "VD",
	1355: "VD",
	1356: "VD",
	1357: "VD",
	1358: "VD",
	1372: "VD",
	1373: "VD",
	1374: "VD",
	1375: "VD",
	1376: "VD",
	1377: "VD",
	1400: "VD",
	1404: "VD",
	1405: "VD",
	1406: "VD",
	1407: "VD",
	1408: "VD",
	1409: "VD",
	1410: "VD",
	1412: "VD",
	1413: "VD",
	1415: "VD",
	1416: "VD",
	1417: "VD",
	1418: "VD",
	1420: "VD",
	1421: "VD",
	1422: "VD",
	1423: "VD",
	1424: "VD",
	1425: "VD",
	1426: "VD",
	1427: "VD",
	1428: "VD",
	1429: "VD",
	1430: "VD",
	1431: "VD",
	1432: "VD",
	1433: "VD",
	1434: "VD",
	1435: "VD",
	1436: "VD",
	1437: "VD",
	1438: "VD",
	1439: "VD",
	1441: "VD",
	1442: "VD",
	1443: "VD",
	1445: "VD",
	1446: "VD",
	1450: "VD",
	1452: "VD",
	1453: "VD",
	1454: "VD",
	1462: "VD",
	1463: "VD",
	1464: "VD",
	1468: "FR",
	1470: "FR",
	1473: "FR",
	1474: "FR",
	1475: "FR",
	1482: "FR",
	1483: "FR",
	1484: "FR",
	1485: "FR",
	1486: "FR",
	1489: "FR",
	1509: "VD",
	1510: "VD",
	1512: "VD",
	1513: "VD",
	1514: "VD",
	1515: "VD",
	1521: "VD",
	1522: "VD",
	1523: "VD",
	1524: "VD",
	1525: "VD",
	1526: "VD",
	1527: "FR",
	1528: "FR",
	1529: "FR",
	1530: "VD",
	1532: "FR",
	1533: "FR",
	1534: "VD",
	1535: "VD",
	1536: "VD",
	1537: "VD",
	1538: "VD",
	1541: "FR",
	1542: "FR",
	1543: "VD",
	1544: "FR",
	1545: "VD",
	1551: "VD",
	1552: "VD",
	1553: "FR",
	1554: "VD",
	1555: "VD",
	1562: "VD",
	1563: "FR",
	1564: "FR",
	1565: "VD",
	1566: "FR",
	1567: "FR",
	1568: "FR",
	1580: "VD",
	1582: "VD",
	1583: "FR",
	1584: "VD",
	1585: "VD",
	1586: "VD",
	1587: "VD",
	1588: "VD",
	1589: "VD",
	1595: "VD",
	1607: "VD",
	1608: "VD",
	1609: "FR",
	1610: "VD",
	1611: "FR",
	1612: "VD",
	1613: "VD",
	1614: "FR",
	1615: "FR",
	1616: "FR",
	1617: "FR",
	1618: "FR",
	1619: "FR",
	1623: "FR",
	1624: "FR",
	1625: "FR",
	1626: "FR",
	1627: "FR",
	1628: "FR",
	1630: "FR",
	1632: "FR",
	1633: "FR",
	1634: "FR",
	1635: "FR",
	1636: "FR",
	1637: "FR",
	1638: "FR",
	1642: "FR",
	1643: "FR",
	1644: "FR",
	1645: "FR",
	1646: "FR",
	1647: "FR",
	1648: "FR",
	1649: "FR",
	1651: "FR",
	1652: "FR",
	1653: "FR",
	1654: "FR",
	1656: "FR",
	1657: "BE",
	1658: "VD",
	1659: "VD",
	1660: "VD",
	1661: "FR",
	1663: "FR",
	1665: "FR",
	1666: "FR",
	1667: "FR",
	1669: "FR",
	1670: "FR",
	1673: "FR",
	1674: "FR",
	1675: "FR",
	1676: "FR",
	1677: "FR",
	1678: "FR",
	1679: "FR",
	1680: "FR",
	1681: "FR",
	1682: "VD",
	1683: "VD",
	1684: "FR",
	1685: "FR",
	1686: "FR",
	1687: "FR",
	1688: "FR",
	1689: "FR",
	1690: "FR",
	1691: "FR",
	1692: "FR",
	1694: "FR",
	1695: "FR",
	1696: "FR",
	1697: "FR",
	1699: "FR",
	1700: "FR",
	1708: "FR",
	1712: "FR",
	1713: "FR",
	1714: "FR",
	1715: "FR",
	1716: "FR",
	1717: "FR",
	1718: "FR",
	1719: "FR",
	1720: "FR",
	1721: "FR",
	1722: "FR",
	1723: "FR",
	1724: "FR",
	1725: "FR",
	1726: "FR",
	1727: "FR",
	1728: "FR",
	1730: "FR",
	1731: "FR",
	1732: "FR",
	1733: "FR",
	1734: "FR",
	1735: "FR",
	1736: "FR",
	1737: "FR",
	1738: "BE",
	1740: "FR",
	1741: "FR",
	1742: "FR",
	1744: "FR",
	1745: "FR",
	1746: "FR",
	1747: "FR",
	1748: "FR",
	1749: "FR",
	1752: "FR",
	1753: "FR",
	1754: "FR",
	1756: "FR",
	1757: "FR",
	1762: "FR",
	1763: "FR",
	1772: "FR",
	1773: "FR",
	1774: "FR",
	1775: "FR",
	1776: "FR",
	1782: "FR",
	1783: "FR",
	1784: "FR",
	1785: "FR",
	1786: "FR",
	1787: "VD",
	1788: "FR",
	1789: "FR",
	1791: "FR",
	1792: "FR",
	1793: "FR",
	1794: "FR",
	1795: "FR",
	1796: "FR",
	1797: "BE",
	1800: "VD",
	1801: "VD",
	1802: "VD",
	1803: "VD",
	1804: "VD",
	1805: "VD",
	1806: "VD",
	1807: "VD",
	1808: "VD",
	1809: "VD",
	1814: "VD",
	1815: "VD",
	1816: "VD",
	1817: "VD",
	1820: "VD",
	1822: "VD",
	1823: "VD",
	1824: "VD",
	1832: "VD",
	1833: "VD",
	1844: "VD",
	1845: "VD",
	1846: "VD",
	1847: "VD",
	1852: "VD",
	1853: "VD",
	1854: "VD",
	1856: "VD",
	1860: "VD",
	1862: "VD",
	1863: "VD",
	1864: "VD",
	1865: "VD",
	1866: "VD",
	1867: "VD",
	1868: "VS",
	1869: "VS",
	1870: "VS",
	1871: "VS",
	1872: "VS",
	1873: "VS",
	1874: "VS",
	1875: "VS",
	1880: "VD",
	1882: "VD",
	1884: "VD",
	1885: "VD",
	1890: "VS",
	1891: "VS",
	1892: "VD",
	1893: "VS",
	1895: "VS",
	1896: "VS",
	1897: "VS",
	1898: "VS",
	1899: "VS",
	1902: "VS",
	1903: "VS",
	1904: "VS",
	1905: "VS",
	1906: "VS",
	1907: "VS",
	1908: "VS",
	1911: "VS",
	1912: "VS",
	1913: "VS",
	1914: "VS",
	1918: "VS",
	1920: "VS",
	1921: "VS",
	1922: "VS",
	1923: "VS",
	1925: "VS",
	1926: "VS",
	1927: "VS",
	1928: "VS",
	1929: "VS",
	1932: "VS",
	1933: "VS",
	1934: "VS",
	1936: "VS",
	1937: "VS",
	1938: "VS",
	1941: "VS",
	1942: "VS",
	1943: "VS",
	1944: "VS",
	1945: "VS",
	1946: "VS",
	1947: "VS",
	1948: "VS",
	1950: "VS",
	1955: "VS",
	1957: "VS",
	1958: "VS",
	1961: "VS",
	1962: "VS",
	1963: "VS",
	1964: "VS",
	1965: "VS",
	1966: "VS",
	1967: "VS",
	1968: "VS",
	1969: "VS",
	1971: "VS",
	1972: "VS",
	1973: "VS",
	1974: "VS",
	1975: "VS",
	1976: "VS",
	1977: "VS",
	1978: "VS",
	1981: "VS",
	1982: "VS",
	1983: "VS",
	1984: "VS",
	1985: "VS",
	1986: "VS",
	1987: "VS",
	1988: "VS",
	1991: "VS",
	1992: "VS",
	1993: "VS",
	1994: "VS",
	1996: "VS",
	1997: "VS",
	2000: "NE",
	2012: "NE",
	2013: "NE",
	2014: "NE",
	2015: "NE",
	2016: "NE",
	2017: "NE",
	2019: "NE",
	2022: "NE",
	2023: "NE",
	2024: "NE",
	2025: "NE",
	2027: "NE",
	2028: "NE",
	2034: "NE",
	2035: "NE",
	2036: "NE",
	2037: "NE",
	2042: "NE",
	2043: "NE",
	2046: "NE",
	2052: "NE",
	2053: "NE",
	2054: "NE",
	2056: "NE",
	2057: "NE",
	2058: "NE",
	2063: "NE",
	2065: "NE",
	2067: "NE",
	2068: "NE",
	2072: "NE",
	2073: "NE",
	2074: "NE",
	2075: "NE",
	2087: "NE",
	2088: "NE",
	2103: "NE",
	2105: "NE",
	2108: "NE",
	2112: "NE",
	2113: "NE",
	2114: "NE",
	2115: "NE",
	2116: "NE",
	2117: "NE",
	2123: "NE",
	2124: "NE",
	2126: "NE",
	2127: "NE",
	2149: "NE",
	2206: "NE",
	2207: "NE",
	2208: "NE",
	2300: "NE",
	2314: "NE",
	2316: "NE",
	2318: "NE",
	2322: "NE",
	2325: "NE",
	2333: "BE",
	2336: "JU",
	2338: "JU",
	2340: "JU",
	2345: "JU",
	2350: "JU",
	2353: "JU",
	2354: "JU",
	2360: "JU",
	2362: "JU",
	2363: "JU",
	2364: "JU",
	2400: "NE",
	2405: "NE",
	2406: "NE",
	2414: "NE",
	2416: "NE",
	2500: "BE",
	2502: "BE",
	2503: "BE",
	2504: "BE",
	2505: "BE",
	2512: "BE",
	2513: "BE",
	2514: "BE",
	2515: "BE",
	2516: "BE",
	2517: "BE",
	2518: "BE",
	2520: "BE",
	2523: "NE",
	2525: "NE",
	2532: "BE",
	2533: "BE",
	2534: "BE",
	2535: "BE",
	2536: "BE",
	2537: "BE",
	2538: "BE",
	2540: "SO",
	2542: "BE",
	2543: "BE",
	2544: "SO",
	2545: "SO",
	2552: "BE",
	2553: "BE",
	2554: "BE",
	2555: "BE",
	2556: "BE",
	2557: "BE",
	2558: "BE",
	2560: "BE",
	2562: "BE",
	2563: "BE",
	2564: "BE",
	2565: "BE",
	2572: "BE",
	2575: "BE",
	2576: "BE",
	2577: "BE",
	2603: "BE",
	2604: "BE",
	2605: "BE",
	2606: "BE",
	2607: "BE",
	2608: "BE",
	2610: "BE",
	2612: "BE",
	2613: "BE",
	2615: "BE",
	2616: "BE",
	2710: "BE",
	2712: "BE",
	2713: "BE",
	2714: "JU",
	2715: "BE",
	2716: "BE",
	2717: "BE",
	2718: "JU",
	2720: "BE",
	2722: "BE",
	2723: "BE",
	2732: "BE",
	2733: "BE",
	2735: "BE",
	2736: "BE",
	2738: "BE",
	2740: "BE",
	2742: "BE",
	2743: "BE",
	2744: "BE",
	2745: "BE",
	2746: "BE",
	2747: "BE",
	2748: "BE",
	2762: "BE",
	2800: "JU",
	2802: "JU",
	2803: "JU",
	2805: "JU",
	2806: "JU",
	2807: "JU",
	2812: "JU",
	2813: "JU",
	2814: "BL",
	2822: "JU",
	2823: "JU",
	2824: "JU",
	2825: "JU",
	2826: "JU",
	2827: "JU",
	2828: "JU",
	2829: "JU",
	2830: "JU",
	2832: "JU",
	2842: "JU",
	2843: "JU",
	2852: "JU",
	2853: "JU",
	2854: "JU",
	2855: "JU",
	2856: "JU",
	2857: "JU",
	2863: "JU",
	2864: "JU",
	2873: "JU",
	2874: "JU",
	2875: "JU",
	2877: "JU",
	2882: "JU",
	2883: "JU",
	2884: "JU",
	2885: "JU",
	2886: "JU",
	2887: "JU",
	2888: "JU",
	2889: "JU",
	2900: "JU",
	2902: "JU",
	2903: "JU",
	2904: "JU",
	2905: "JU",
	2906: "JU",
	2907: "JU",
	2908: "JU",
	2912: "JU",
	2914: "JU",
	2915: "JU",
	2916: "JU",
	2922: "JU",
	2923: "JU",
	2924: "JU",
	2925: "JU",
	2926: "JU",
	2932: "JU",
	2933: "JU",
	2935: "JU",
	2942: "JU",
	2943: "JU",
	2944: "JU",
	2946: "JU",
	2947: "JU",
	2950: "JU",
	2952: "JU",
	2953: "JU",
	2954: "JU",
	3000: "BE",
	3004: "BE",
	3005: "BE",
	3006: "BE",
	3007: "BE",
	3008: "BE",
	3010: "BE",
	3011: "BE",
	3012: "BE",
	3013: "BE",
	3014: "BE",
	3015: "BE",
	3018: "BE",
	3019: "BE",
	3020: "BE",
	3027: "BE",
	3032: "BE",
	3033: "BE",
	3034: "BE",
	3035: "BE",
	3036: "BE",
	3037: "BE",
	3038: "BE",
	3042: "BE",
	3043: "BE",
	3044: "BE",
	3045: "BE",
	3046: "BE",
	3047: "BE",
	3048: "BE",
	3049: "BE",
	3052: "BE",
	3053: "BE",
	3054: "BE",
	3063: "BE",
	3065: "BE",
	3066: "BE",
	3067: "BE",
	3068: "BE",
	3072: "BE",
	3073: "BE",
	3074: "BE",
	3075: "BE",
	3076: "BE",
	3077: "BE",
	3078: "BE",
	3082: "BE",
	3083: "BE",
	3084: "BE",
	3086: "BE",
	3087: "BE",
	3088: "BE",
	3089: "BE",
	3095: "BE",
	3096: "BE",
	3097: "BE",
	3098: "BE",
	3099: "BE",
	3110: "BE",
	3111: "BE",
	3112: "BE",
	3113: "BE",
	3114: "BE",
	3115: "BE",
	3116: "BE",
	3122: "BE",
	3123: "BE",
	3124: "BE",
	3125: "BE",
	3126: "BE",
	3127: "BE",
	3128: "BE",
	3132: "BE",
	3144: "BE",
	3145: "BE",
	3147: "BE",
	3148: "BE",
	3150: "BE",
	3152: "BE",
	3153: "BE",
	3154: "BE",
	3155: "BE",
	3156: "BE",
	3157: "BE",
	3158: "BE",
	3159: "BE",
	3172: "BE",
	3173: "BE",
	3174: "BE",
	3175: "FR",
	3176: "BE",
	3177: "BE",
	3178: "FR",
	3179: "BE",
	3182: "FR",
	3183: "BE",
	3184: "FR",
	3185: "FR",
	3186: "FR",
	3202: "BE",
	3203: "BE",
	3204: "BE",
	3205: "BE",
	3206: "FR",
	3207: "BE",
	3208: "BE",
	3210: "FR",
	3212: "FR",
	3213: "FR",
	3214: "FR",
	3215: "FR",
	3216: "FR",
	3225: "BE",
	3226: "BE",
	3232: "BE",
	3233: "BE",
	3234: "BE",
	3235: "BE",
	3236: "BE",
	3237: "BE",
	3238: "BE",
	3250: "BE",
	3251: "BE",
	3252: "BE",
	3253: "SO",
	3254: "SO",
	3255: "BE",
	3256: "BE",
	3257: "BE",
	3262: "BE",
	3263: "BE",
	3264: "BE",
	3266: "BE",
	3267: "BE",
	3268: "BE",
	3270: "BE",
	3271: "BE",
	3272: "BE",
	3273: "BE",
	3274: "BE",
	3280: "FR",
	3282: "BE",
	3283: "BE",
	3284: "FR",
	3285: "FR",
	3286: "FR",
	3292: "BE",
	3293: "BE",
	3294: "BE",
	3295: "BE",
	3296: "BE",
	3297: "BE",
	3298: "BE",
	3302: "BE",
	3303: "BE",
	3305: "BE",
	3306: "BE",
	3307: "SO",
	3308: "BE",
	3309: "BE",
	3312: "BE",
	3313: "BE",
	3314: "BE",
	3315: "BE",
	3317: "BE",
	3322: "BE",
	3323: "BE",
	3324: "BE",
	3325: "BE",
	3326: "BE",
	3360: "BE",
	3362: "BE",
	3363: "BE",
	3365: "BE",
	3366: "BE",
	3367: "BE",
	3368: "BE",
	3372: "BE",
	3373: "BE",
	3374: "BE",
	3375: "BE",
	3376: "BE",
	3377: "BE",
	3380: "BE",
	3400: "BE",
	3412: "BE",
	3413: "BE",
	3414: "BE",
	3415: "BE",
	3416: "BE",
	3417: "BE",
	3418: "BE",
	3419: "BE",
	3421: "BE",
	3422: "BE",
	3423: "BE",
	3424: "BE",
	3425: "BE",
	3426: "BE",
	3427: "BE",
	3428: "BE",
	3429: "BE",
	3432: "BE",
	3433: "BE",
	3434: "BE",
	3435: "BE",
	3436: "BE",
	3437: "BE",
	3438: "BE",
	3439: "BE",
	3452: "BE",
	3453: "BE",
	3454: "BE",
	3455: "BE",
	3456: "BE",
	3457: "BE",
	3462: "BE",
	3463: "BE",
	3464: "BE",
	3465: "BE",
	3472: "BE",
	3473: "BE",
	3474: "BE",
	3475: "BE",
	3476: "BE",
	3503: "BE",
	3504: "BE",
	3506: "BE",
	3507: "BE",
	3508: "BE",
	3510: "BE",
	3512: "BE",
	3513: "BE",
	3531: "BE",
	3532: "BE",
	3533: "BE",
	3534: "BE",
	3535: "BE",
	3536: "BE",
	3537: "BE",
	3538: "BE",
	3543: "BE",
	3550: "BE",
	3551: "BE",
	3552: "BE",
	3553: "BE",
	3555: "BE",
	3556: "BE",
	3557: "BE",
	3600: "BE",
	3603: "BE",
	3604: "BE",
	3608: "BE",
	3612: "BE",
	3613: "BE",
	3614: "BE",
	3615: "BE",
	3616: "BE",
	3617: "BE",
	3618: "BE",
	3619: "BE",
	3622: "BE",
	3623: "BE",
	3624: "BE",
	3625: "BE",
	3626: "BE",
	3627: "BE",
	3628: "BE",
	3629: "BE",
	3631: "BE",
	3632: "BE",
	3633: "BE",
	3634: "BE",
	3635: "BE",
	3636: "BE",
	3638: "BE",
	3645: "BE",
	3646: "BE",
	3647: "BE",
	3652: "BE",
	3653: "BE",
	3654: "BE",
	3655: "BE",
	3656: "BE",
	3657: "BE",
	3658: "BE",
	3661: "BE",
	3662: "BE",
	3663: "BE",
	3664: "BE",
	3665: "BE",
	3671: "BE",
	3672: "BE",
	3673: "BE",
	3674: "BE",
	3700: "BE",
	3702: "BE",
	3703: "BE",
	3704: "BE",
	3705: "BE",
	3706: "BE",
	3707: "BE",
	3711: "BE",
	3713: "BE",
	3714: "BE",
	3715: "BE",
	3716: "BE",
	3717: "BE",
	3718: "BE",
	3722: "BE",
	3723: "BE",
	3724: "BE",
	3725: "BE",
	3752: "BE",
	3753: "BE",
	3754: "BE",
	3755: "BE",
	3756: "BE",
	3757: "BE",
	3758: "BE",
	3762: "BE",
	3763: "BE",
	3764: "BE",
	3765: "BE",
	3766: "BE",
	3770: "BE",
	3771: "BE",
	3772: "BE",
	3773: "BE",
	3775: "BE",
	3776: "BE",
	3777: "BE",
	3778: "BE",
	3780: "BE",
	3781: "BE",
	3782: "BE",
	3783: "BE",
	3784: "BE",
	3785: "BE",
	3792: "BE",
	3800: "BE",
	3801: "VS",
	3803: "BE",
	3804: "BE",
	3805: "BE",
	3806: "BE",
	3807: "BE",
	3812: "BE",
	3813: "BE",
	3814: "BE",
	3815: "BE",
	3816: "BE",
	3818: "BE",
	3822: "BE",
	3823: "BE",
	3824: "BE",
	3825: "BE",
	3826: "BE",
	3852: "BE",
	3853: "BE",
	3854: "BE",
	3855: "BE",
	3856: "BE",
	3857: "BE",
	3858: "BE",
	3860: "BE",
	3862: "BE",
	3863: "BE",
	3864: "BE",
	3900: "VS",
	3901: "VS",
	3902: "VS",
	3903: "VS",
	3904: "VS",
	3905: "VS",
	3906: "VS",
	3907: "VS",
	3908: "VS",
	3910: "VS",
	3911: "VS",
	3912: "VS",
	3913: "VS",
	3914: "VS",
	3916: "VS",
	3917: "VS",
	3918: "VS",
	3919: "VS",
	3920: "VS",
	3922: "VS",
	3923: "VS",
	3924: "VS",
	3925: "VS",
	3926: "VS",
	3927: "VS",
	3928: "VS",
	3929: "VS",
	3930: "VS",
	3931: "VS",
	3932: "VS",
	3933: "VS",
	3934: "VS",
	3935: "VS",
	3937: "VS",
	3938: "VS",
	3939: "VS",
	3940: "VS",
	3942: "VS",
	3943: "VS",
	3944: "VS",
	3945: "VS",
	3946: "VS",
	3947: "VS",
	3948: "VS",
	3949: "VS",
	3951: "VS",
	3952: "VS",
	3953: "VS",
	3954: "VS",
	3955: "VS",
	3956: "VS",
	3957: "VS",
	3960: "VS",
	3961: "VS",
	3963: "VS",
	3965: "VS",
	3966: "VS",
	3967: "VS",
	3968: "VS",
	3970: "VS",
	3971: "VS",
	3972: "VS",
	3973: "VS",
	3974: "VS",
	3975: "VS",
	3976: "VS",
	3977: "VS",
	3978: "VS",
	3979: "VS",
	3982: "VS",
	3983: "VS",
	3984: "VS",
	3985: "VS",
	3986: "VS",
	3987: "VS",
	3988: "VS",
	3989: "VS",
	3991: "VS",
	3992: "VS",
	3993: "VS",
	3994: "VS",
	3995: "VS",
	3996: "VS",
	3997: "VS",
	3998: "VS",
	3999: "VS",
	4000: "BS",
	4001: "BS",
	4031: "BS",
	4051: "BS",
	4052: "BS",
	4053: "BS",
	4054: "BS",
	4055: "BS",
	4056: "BS",
	4057: "BS",
	4058: "BS",
	4059: "BS",
	4101: "BL",
	4102: "BL",
	4103: "BL",
	4104: "BL",
	4105: "BL",
	4106: "BL",
	4107: "BL",
	4108: "SO",
	4112: "SO",
	4114: "SO",
	4115: "SO",
	4116: "SO",
	4117: "BL",
	4118: "SO",
	4123: "BL",
	4124: "BL",
	4125: "BS",
	4126: "BS",
	4127: "BL",
	4132: "BL",
	4133: "BL",
	4142: "BL",
	4143: "SO",
	4144: "BL",
	4145: "SO",
	4146: "SO",
	4147: "BL",
	4148: "BL",
	4153: "BL",
	4202: "BL",
	4203: "BL",
	4204: "SO",
	4206: "SO",
	4207: "BL",
	4208: "SO",
	4222: "BL",
	4223: "BL",
	4224: "BL",
	4225: "BL",
	4226: "SO",
	4227: "SO",
	4228: "SO",
	4229: "SO",
	4232: "SO",
	4233: "SO",
	4234: "SO",
	4242: "BL",
	4243: "BL",
	4244: "BL",
	4245: "SO",
	4246: "BL",
	4247: "SO",
	4252: "SO",
	4253: "BL",
	4254: "BL",
	4302: "BL",
	4303: "AG",
	4304: "BL",
	4305: "AG",
	4310: "AG",
	4312: "AG",
	4313: "AG",
	4314: "AG",
	4315: "AG",
	4316: "AG",
	4317: "AG",
	4322: "AG",
	4323: "AG",
	4324: "AG",
	4325: "AG",
	4332: "AG",
	4333: "AG",
	4334: "AG",
	4402: "BL",
	4410: "BL",
	4411: "BL",
	4412: "SO",
	4413: "SO",
	4414: "BL",
	4415: "BL",
	4416: "BL",
	4417: "BL",
	4418: "BL",
	4419: "BL",
	4421: "SO",
	4422: "BL",
	4423: "BL",
	4424: "BL",
	4425: "BL",
	4426: "BL",
	4431: "BL",
	4432: "BL",
	4433: "BL",
	4434: "BL",
	4435: "BL",
	4436: "BL",
	4437: "BL",
	4438: "BL",
	4441: "BL",
	4442: "BL",
	4443: "BL",
	4444: "BL",
	4445: "BL",
	4446: "BL",
	4447: "BL",
	4448: "BL",
	4450: "BL",
	4451: "BL",
	4452: "BL",
	4453: "BL",
	4455: "BL",
	4456: "BL",
	4457: "BL",
	4458: "BL",
	4460: "BL",
	4461: "BL",
	4462: "BL",
	4463: "BL",
	4464: "BL",
	4465: "BL",
	4466: "BL",
	4467: "BL",
	4468: "SO",
	4469: "BL",
	4492: "BL",
	4493: "BL",
	4494: "BL",
	4495: "BL",
	4496: "BL",
	4497: "BL",
	4500: "SO",
	4512: "SO",
	4513: "SO",
	4514: "SO",
	4515: "SO",
	4522: "SO",
	4523: "SO",
	4524: "SO",
	4525: "SO",
	4528: "SO",
	4532: "SO",
	4533: "SO",
	4534: "SO",
	4535: "SO",
	4536: "BE",
	4537: "BE",
	4538: "BE",
	4539: "BE",
	4542: "SO",
	4543: "SO",
	4552: "SO",
	4553: "SO",
	4554: "SO",
	4556: "SO",
	4557: "SO",
	4558: "SO",
	4562: "SO",
	4563: "SO",
	4564: "SO",
	4565: "SO",
	4566: "SO",
	4571: "SO",
	4573: "SO",
	4574: "SO",
	4576: "SO",
	4577: "SO",
	4578: "SO",
	4579: "SO",
	4581: "SO",
	4582: "SO",
	4583: "SO",
	4584: "SO",
	4585: "SO",
	4586: "SO",
	4587: "SO",
	4588: "SO",
	4600: "SO",
	4612: "SO",
	4613: "SO",
	4614: "SO",
	4615: "SO",
	4616: "SO",
	4617: "SO",
	4618: "SO",
	4622: "SO",
	4623: "SO",
	4624: "SO",
	4625: "SO",
	4626: "SO",
	4628: "SO",
	4629: "SO",
	4632: "SO",
	4633: "SO",
	4634: "SO",
	4652: "SO",
	4653: "SO",
	4654: "SO",
	4655: "SO",
	4656: "SO",
	4657: "SO",
	4658: "SO",
	4663: "AG",
	4665: "AG",
	4702: "SO",
	4703: "SO",
	4704: "BE",
	4710: "SO",
	4712: "SO",
	4713: "SO",
	4714: "SO",
	4715: "SO",
	4716: "SO",
	4717: "SO",
	4718: "SO",
	4719: "SO",
	4800: "AG",
	4802: "AG",
	4803: "AG",
	4805: "AG",
	4806: "LU",
	4812: "AG",
	4813: "AG",
	4814: "AG",
	4852: "AG",
	4853: "AG",
	4856: "AG",
	4900: "BE",
	4911: "BE",
	4912: "BE",
	4913: "BE",
	4914: "BE",
	4915: "LU",
	4916: "BE",
	4917: "BE",
	4919: "BE",
	4922: "BE",
	4923: "BE",
	4924: "BE",
	4932: "BE",
	4933: "BE",
	4934: "BE",
	4935: "BE",
	4936: "BE",
	4937: "BE",
	4938: "BE",
	4942: "BE",
	4943: "BE",
	4944: "BE",
	4950: "BE",
	4952: "BE",
	4953: "BE",
	4954: "BE",
	4955: "BE",
	5000: "AG",
	5004: "AG",
	5012: "SO",
	5013: "SO",
	5014: "SO",
	5015: "SO",
	5017: "AG",
	5018: "AG",
	5022: "AG",
	5023: "AG",
	5024: "AG",
	5025: "AG",
	5026: "AG",
	5027: "AG",
	5028: "AG",
	5032: "AG",
	5033: "AG",
	5034: "AG",
	5035: "AG",
	5036: "AG",
	5037: "AG",
	5040: "AG",
	5042: "AG",
	5043: "AG",
	5044: "AG",
	5046: "AG",
	5053: "AG",
	5054: "AG",
	5056: "AG",
	5057: "AG",
	5058: "AG",
	5062: "AG",
	5063: "AG",
	5064: "AG",
	5070: "AG",
	5072: "AG",
	5073: "AG",
	5074: "AG",
	5075: "AG",
	5076: "AG",
	5077: "AG",
	5078: "AG",
	5079: "AG",
	5080: "AG",
	5082: "AG",
	5083: "AG",
	5084: "AG",
	5085: "AG",
	5102: "AG",
	5103: "AG",
	5105: "AG",
	5106: "AG",
	5107: "AG",
	5108: "AG",
	5112: "AG",
	5113: "AG",
	5116: "AG",
	5200: "AG",
	5210: "AG",
	5212: "AG",
	5213: "AG",
	5222: "AG",
	5223: "AG",
	5224: "AG",
	5225: "AG",
	5233: "AG",
	5234: "AG",
	5235: "AG",
	5236: "AG",
	5237: "AG",
	5242: "AG",
	5243: "AG",
	5244: "AG",
	5245: "AG",
	5246: "AG",
	5272: "AG",
	5273: "AG",
	5274: "AG",
	5275: "AG",
	5276: "AG",
	5277: "AG",
	5300: "AG",
	5301: "AG",
	5303: "AG",
	5304: "AG",
	5305: "AG",
	5306: "AG",
	5312: "AG",
	5313: "AG",
	5314: "AG",
	5315: "AG",
	5316: "AG",
	5317: "AG",
	5318: "AG",
	5322: "AG",
	5323: "AG",
	5324: "AG",
	5325: "AG",
	5326: "AG",
	5330: "AG",
	5332: "AG",
	5333: "AG",
	5334: "AG",
	5400: "AG",
	5404: "AG",
	5405: "AG",
	5406: "AG",
	5408: "AG",
	5412: "AG",
	5413: "AG",
	5415: "AG",
	5416: "AG",
	5417: "AG",
	5420: "AG",
	5423: "AG",
	5425: "AG",
	5426: "AG",
	5430: "AG",
	5432: "AG",
	5436: "AG",
	5442: "AG",
	5443: "AG",
	5444: "AG",
	5445: "AG",
	5452: "AG",
	5453: "AG",
	5454: "AG",
	5462: "AG",
	5463: "AG",
	5464: "AG",
	5465: "AG",
	5466: "AG",
	5467: "AG",
	5502: "AG",
	5503: "AG",
	5504: "AG",
	5505: "AG",
	5506: "AG",
	5507: "AG",
	5512: "AG",
	5522: "AG",
	5524: "AG",
	5525: "AG",
	5600: "AG",
	5603: "AG",
	5604: "AG",
	5605: "AG",
	5606: "AG",
	5607: "AG",
	5608: "AG",
	5610: "AG",
	5611: "AG",
	5612: "AG",
	5613: "AG",
	5614: "AG",
	5615: "AG",
	5616: "AG",
	5617: "AG",
	5618: "AG",
	5619: "AG",
	5620: "AG",
	5621: "AG",
	5622: "AG",
	5623: "AG",
	5624: "AG",
	5625: "AG",
	5626: "AG",
	5627: "AG",
	5628: "AG",
	5630: "AG",
	5632: "AG",
	5634: "AG",
	5636: "AG",
	5637: "AG",
	5642: "AG",
	5643: "AG",
	5644: "AG",
	5645: "AG",
	5646: "AG",
	5647: "AG",
	5702: "AG",
	5703: "AG",
	5704: "AG",
	5705: "AG",
	5706: "AG",
	5707: "AG",
	5708: "AG",
	5712: "AG",
	5722: "AG",
	5723: "AG",
	5724: "AG",
	5725: "AG",
	5726: "AG",
	5727: "AG",
	5728: "AG",
	5732: "AG",
	5733: "AG",
	5734: "AG",
	5735: "LU",
	5736: "AG",
	5737: "AG",
	5742: "AG",
	5745: "AG",
	5746: "SO",
	6000: "LU",
	6003: "LU",
	6004: "LU",
	6005: "LU",
	6006: "LU",
	6010: "OW",
	6012: "LU",
	6013: "LU",
	6014: "LU",
	6015: "LU",
	6016: "LU",
	6017: "LU",
	6018: "LU",
	6019: "LU",
	6020: "LU",
	6022: "LU",
	6023: "LU",
	6024: "LU",
	6025: "LU",
	6026: "LU",
	6027: "LU",
	6028: "LU",
	6030: "LU",
	6032: "LU",
	6033: "LU",
	6034: "LU",
	6035: "LU",
	6036: "LU",
	6037: "LU",
	6038: "LU",
	6039: "LU",
	6042: "AG",
	6043: "LU",
	6044: "LU",
	6045: "LU",
	6047: "LU",
	6048: "LU",
	6052: "NW",
	6053: "OW",
	6055: "OW",
	6056: "OW",
	6060: "OW",
	6062: "OW",
	6063: "OW",
	6064: "OW",
	6066: "OW",
	6067: "OW",
	6068: "OW",
	6072: "OW",
	6073: "OW",
	6074: "OW",
	6078: "OW",
	6083: "BE",
	6084: "BE",
	6085: "BE",
	6086: "BE",
	6102: "LU",
	6103: "LU",
	6105: "LU",
	6106: "LU",
	6110: "LU",
	6112: "LU",
	6113: "LU",
	6114: "LU",
	6122: "LU",
	6123: "LU",
	6125: "LU",
	6126: "LU",
	6130: "LU",
	6132: "LU",
	6133: "LU",
	6142: "LU",
	6143: "LU",
	6144: "LU",
	6145: "LU",
	6146: "LU",
	6147: "LU",
	6152: "LU",
	6153: "LU",
	6154: "LU",
	6156: "LU",
	6162: "LU",
	6163: "LU",
	6166: "LU",
	6167: "LU",
	6170: "LU",
	6173: "LU",
	6174: "LU",
	6182: "LU",
	6192: "LU",
	6196: "LU",
	6197: "BE",
	6203: "LU",
	6204: "LU",
	6205: "LU",
	6206: "LU",
	6207: "LU",
	6208: "LU",
	6210: "LU",
	6211: "LU",
	6212: "LU",
	6213: "LU",
	6214: "LU",
	6215: "LU",
	6216: "LU",
	6217: "LU",
	6218: "LU",
	6221: "LU",
	6222: "LU",
	6231: "LU",
	6232: "LU",
	6233: "LU",
	6234: "LU",
	6235: "LU",
	6236: "LU",
	6242: "LU",
	6243: "LU",
	6244: "LU",
	6245: "LU",
	6246: "LU",
	6247: "LU",
	6248: "LU",
	6252: "LU",
	6253: "LU",
	6260: "LU",
	6262: "LU",
	6263: "LU",
	6264: "LU",
	6265: "LU",
	6274: "LU",
	6275: "LU",
	6276: "LU",
	6277: "LU",
	6280: "LU",
	6283: "LU",
	6284: "LU",
	6285: "LU",
	6286: "LU",
	6287: "LU",
	6288: "LU",
	6289: "LU",
	6294: "LU",
	6295: "LU",
	6300: "ZG",
	6312: "ZG",
	6313: "ZG",
	6314: "ZG",
	6315: "ZG",
	6317: "ZG",
	6318: "ZG",
	6319: "ZG",
	6330: "ZG",
	6331: "ZG",
	6332: "ZG",
	6333: "ZG",
	6340: "ZG",
	6343: "ZG",
	6344: "LU",
	6345: "ZG",
	6353: "LU",
	6354: "LU",
	6356: "LU",
	6362: "NW",
	6363: "NW",
	6365: "NW",
	6370: "NW",
	6372: "NW",
	6373: "NW",
	6374: "NW",
	6375: "NW",
	6376: "NW",
	6377: "UR",
	6382: "NW",
	6383: "NW",
	6386: "NW",
	6387: "NW",
	6388: "OW",
	6390: "OW",
	6402: "SZ",
	6403: "SZ",
	6404: "LU",
	6405: "SZ",
	6410: "SZ",
	6414: "SZ",
	6415: "SZ",
	6416: "SZ",
	6417: "SZ",
	6418: "SZ",
	6422: "SZ",
	6423: "SZ",
	6424: "SZ",
	6430: "SZ",
	6432: "SZ",
	6433: "SZ",
	6434: "SZ",
	6436: "SZ",
	6438: "SZ",
	6440: "SZ",
	6441: "UR",
	6442: "SZ",
	6443: "SZ",
	6452: "UR",
	6454: "UR",
	6460: "UR",
	6461: "UR",
	6462: "UR",
	6463: "UR",
	6464: "UR",
	6465: "UR",
	6466: "UR",
	6467: "UR",
	6468: "UR",
	6469: "UR",
	6472: "UR",
	6473: "UR",
	6474: "UR",
	6475: "UR",
	6476: "UR",
	6482: "UR",
	6484: "UR",
	6485: "UR",
	6487: "UR",
	6490: "UR",
	6491: "UR",
	6493: "UR",
	6500: "TI",
	6503: "TI",
	6512: "TI",
	6513: "TI",
	6514: "TI",
	6515: "TI",
	6516: "TI",
	6517: "TI",
	6518: "TI",
	6523: "TI",
	6524: "TI",
	6525: "TI",
	6526: "TI",
	6527: "TI",
	6528: "TI",
	6532: "TI",
	6533: "TI",
	6534: "GR",
	6535: "GR",
	6537: "GR",
	6538: "GR",
	6540: "GR",
	6541: "GR",
	6542: "GR",
	6543: "GR",
	6544: "GR",
	6545: "GR",
	6546: "GR",
	6547: "GR",
	6548: "GR",
	6549: "GR",
	6556: "GR",
	6557: "GR",
	6558: "GR",
	6562: "GR",
	6563: "GR",
	6565: "GR",
	6571: "TI",
	6572: "TI",
	6573: "TI",
	6574: "TI",
	6575: "TI",
	6576: "TI",
	6577: "TI",
	6578: "TI",
	6579: "TI",
	6582: "TI",
	6583: "TI",
	6584: "TI",
	6592: "TI",
	6593: "TI",
	6594: "TI",
	6595: "TI",
	6596: "TI",
	6597: "TI",
	6598: "TI",
	6599: "TI",
	6600: "TI",
	6605: "TI",
	6611: "TI",
	6612: "TI",
	6613: "TI",
	6614: "TI",
	6616: "TI",
	6618: "TI",
	6622: "TI",
	6631: "TI",
	6632: "TI",
	6633: "TI",
	6634: "TI",
	6635: "TI",
	6636: "TI",
	6637: "TI",
	6644: "TI",
	6645: "TI",
	6646: "TI",
	6647: "TI",
	6648: "TI",
	6652: "TI",
	6653: "TI",
	6654: "TI",
	6655: "TI",
	6656: "TI",
	6657: "TI",
	6658: "TI",
	6659: "TI",
	6661: "TI",
	6662: "TI",
	6663: "TI",
	6664: "TI",
	6670: "TI",
	6672: "TI",
	6673: "TI",
	6674: "TI",
	6675: "TI",
	6676: "TI",
	6677: "TI",
	6678: "TI",
	6682: "TI",
	6683: "TI",
	6684: "TI",
	6685: "TI",
	6690: "TI",
	6692: "TI",
	6693: "TI",
	6694: "TI",
	6695: "TI",
	6696: "TI",
	6702: "TI",
	6703: "TI",
	6705: "TI",
	6707: "TI",
	6710: "TI",
	6713: "TI",
	6714: "TI",
	6715: "TI",
	6716: "TI",
	6717: "TI",
	6718: "TI",
	6719: "TI",
	6720: "TI",
	6721: "TI",
	6722: "TI",
	6723: "TI",
	6724: "TI",
	6742: "TI",
	6743: "TI",
	6744: "TI",
	6745: "TI",
	6746: "TI",
	6747: "TI",
	6748: "TI",
	6749: "TI",
	6760: "TI",
	6763: "TI",
	6764: "TI",
	6772: "TI",
	6773: "TI",
	6774: "TI",
	6775: "TI",
	6776: "TI",
	6777: "TI",
	6780: "TI",
	6781: "TI",
	6802: "TI",
	6803: "TI",
	6804: "TI",
	6805: "TI",
	6806: "TI",
	6807: "TI",
	6808: "TI",
	6809: "TI",
	6810: "TI",
	6814: "TI",
	6815: "TI",
	6816: "TI",
	6817: "TI",
	6818: "TI",
	6821: "TI",
	6822: "TI",
	6823: "TI",
	6825: "TI",
	6826: "TI",
	6827: "TI",
	6828: "TI",
	6830: "TI",
	6832: "TI",
	6833: "TI",
	6834: "TI",
	6835: "TI",
	6837: "TI",
	6838: "TI",
	6839: "TI",
	6850: "TI",
	6852: "TI",
	6853: "TI",
	6854: "TI",
	6855: "TI",
	6862: "TI",
	6863: "TI",
	6864: "TI",
	6865: "TI",
	6866: "TI",
	6867: "TI",
	6872: "TI",
	6873: "TI",
	6874: "TI",
	6875: "TI",
	6877: "TI",
	6883: "TI",
	6900: "TI",
	6911: "IT",
	6912: "TI",
	6913: "TI",
	6914: "TI",
	6915: "TI",
	6916: "TI",
	6917: "TI",
	6918: "TI",
	6919: "TI",
	6921: "TI",
	6922: "TI",
	6924: "TI",
	6925: "TI",
	6926: "TI",
	6927: "TI",
	6928: "TI",
	6929: "TI",
	6930: "TI",
	6932: "TI",
	6933: "TI",
	6934: "TI",
	6935: "TI",
	6936: "TI",
	6937: "TI",
	6938: "TI",
	6939: "TI",
	6942: "TI",
	6943: "TI",
	6944: "TI",
	6945: "TI",
	6946: "TI",
	6947: "TI",
	6948: "TI",
	6949: "TI",
	6950: "TI",
	6951: "TI",
	6952: "TI",
	6953: "TI",
	6954: "TI",
	6955: "TI",
	6956: "TI",
	6957: "TI",
	6958: "TI",
	6959: "TI",
	6962: "TI",
	6963: "TI",
	6964: "TI",
	6965: "TI",
	6966: "TI",
	6967: "TI",
	6968: "TI",
	6974: "TI",
	6976: "TI",
	6977: "TI",
	6978: "TI",
	6979: "TI",
	6980: "TI",
	6981: "TI",
	6982: "TI",
	6983: "TI",
	6984: "TI",
	6986: "TI",
	6987: "TI",
	6988: "TI",
	6989: "TI",
	6990: "TI",
	6991: "TI",
	6992: "TI",
	6993: "TI",
	6994: "TI",
	6995: "TI",
	6996: "TI",
	6997: "TI",
	6998: "TI",
	6999: "TI",
	7000: "GR",
	7012: "GR",
	7013: "GR",
	7014: "GR",
	7015: "GR",
	7016: "GR",
	7017: "GR",
	7018: "GR",
	7019: "GR",
	7023: "GR",
	7026: "GR",
	7027: "GR",
	7028: "GR",
	7029: "GR",
	7031: "GR",
	7032: "GR",
	7050: "GR",
	7056: "GR",
	7057: "GR",
	7058: "GR",
	7062: "GR",
	7063: "GR",
	7064: "GR",
	7074: "GR",
	7075: "GR",
	7076: "GR",
	7077: "GR",
	7078: "GR",
	7082: "GR",
	7083: "GR",
	7084: "GR",
	7104: "GR",
	7106: "GR",
	7107: "GR",
	7109: "GR",
	7110: "GR",
	7111: "GR",
	7112: "GR",
	7113: "GR",
	7114: "GR",
	7115: "GR",
	7116: "GR",
	7122: "GR",
	7126: "GR",
	7127: "GR",
	7128: "GR",
	7130: "GR",
	7132: "GR",
	7133: "GR",
	7134: "GR",
	7135: "GR",
	7136: "GR",
	7137: "GR",
	7138: "GR",
	7141: "GR",
	7142: "GR",
	7143: "GR",
	7144: "GR",
	7145: "GR",
	7146: "GR",
	7147: "GR",
	7148: "GR",
	7149: "GR",
	7151: "GR",
	7152: "GR",
	7153: "GR",
	7154: "GR",
	7155: "GR",
	7156: "GR",
	7157: "GR",
	7158: "GR",
	7159: "GR",
	7162: "GR",
	7163: "GR",
	7164: "GR",
	7165: "GR",
	7166: "GR",
	7167: "GR",
	7168: "GR",
	7172: "GR",
	7173: "GR",
	7174: "GR",
	7175: "GR",
	7176: "GR",
	7180: "GR",
	7182: "GR",
	7183: "GR",
	7184: "GR",
	7185: "GR",
	7186: "GR",
	7187: "GR",
	7188: "GR",
	7189: "GR",
	7201: "GR",
	7202: "GR",
	7203: "GR",
	7204: "GR",
	7205: "GR",
	7206: "GR",
	7208: "GR",
	7212: "GR",
	7213: "GR",
	7214: "GR",
	7215: "GR",
	7220: "GR",
	7222: "GR",
	7223: "GR",
	7224: "GR",
	7226: "GR",
	7228: "GR",
	7231: "GR",
	7232: "GR",
	7233: "GR",
	7235: "GR",
	7240: "GR",
	7241: "GR",
	7242: "GR",
	7243: "GR",
	7244: "GR",
	7245: "GR",
	7246: "GR",
	7247: "GR",
	7249: "GR",
	7250: "GR",
	7252: "GR",
	7260: "GR",
	7265: "GR",
	7270: "GR",
	7272: "GR",
	7276: "GR",
	7277: "GR",
	7278: "GR",
	7302: "GR",
	7303: "GR",
	7304: "GR",
	7306: "GR",
	7307: "GR",
	7310: "SG",
	7312: "SG",
	7313: "SG",
	7314: "SG",
	7315: "SG",
	7317: "SG",
	7320: "SG",
	7323: "SG",
	7324: "SG",
	7325: "SG",
	7326: "SG",
	7402: "GR",
	7403: "GR",
	7404: "GR",
	7405: "GR",
	7407: "GR",
	7408: "GR",
	7411: "GR",
	7412: "GR",
	7413: "GR",
	7414: "GR",
	7415: "GR",
	7416: "GR",
	7417: "GR",
	7418: "GR",
	7419: "GR",
	7421: "GR",
	7422: "GR",
	7423: "GR",
	7424: "GR",
	7425: "GR",
	7426: "GR",
	7427: "GR",
	7428: "GR",
	7430: "GR",
	7431: "GR",
	7432: "GR",
	7433: "GR",
	7434: "GR",
	7435: "GR",
	7436: "GR",
	7437: "GR",
	7438: "GR",
	7440: "GR",
	7442: "GR",
	7443: "GR",
	7444: "GR",
	7445: "GR",
	7446: "GR",
	7447: "GR",
	7448: "GR",
	7450: "GR",
	7451: "GR",
	7452: "GR",
	7453: "GR",
	7454: "GR",
	7455: "GR",
	7456: "GR",
	7457: "GR",
	7458: "GR",
	7459: "GR",
	7460: "GR",
	7462: "GR",
	7463: "GR",
	7464: "GR",
	7472: "GR",
	7473: "GR",
	7477: "GR",
	7482: "GR",
	7484: "GR",
	7492: "GR",
	7493: "GR",
	7494: "GR",
	7500: "GR",
	7502: "GR",
	7503: "GR",
	7504: "GR",
	7505: "GR",
	7512: "GR",
	7513: "GR",
	7514: "GR",
	7515: "GR",
	7516: "GR",
	7517: "GR",
	7522: "GR",
	7523: "GR",
	7524: "GR",
	7525: "GR",
	7526: "GR",
	7527: "GR",
	7530: "GR",
	7532: "GR",
	7533: "GR",
	7534: "GR",
	7535: "GR",
	7536: "GR",
	7537: "GR",
	7542: "GR",
	7543: "GR",
	7545: "GR",
	7546: "GR",
	7550: "GR",
	7551: "GR",
	7552: "GR",
	7553: "GR",
	7554: "GR",
	7556: "GR",
	7557: "GR",
	7558: "GR",
	7559: "GR",
	7560: "GR",
	7562: "GR",
	7563: "GR",
	7602: "GR",
	7603: "GR",
	7604: "GR",
	7605: "GR",
	7606: "GR",
	7608: "GR",
	7610: "GR",
	7710: "GR",
	7741: "GR",
	7742: "GR",
	7743: "GR",
	7744: "GR",
	7745: "GR",
	7746: "GR",
	7747: "GR",
	7748: "GR",
	8000: "ZH",
	8001: "ZH",
	8002: "ZH",
	8003: "ZH",
	8004: "ZH",
	8005: "ZH",
	8006: "ZH",
	8008: "ZH",
	8032: "ZH",
	8037: "ZH",
	8038: "ZH",
	8041: "ZH",
	8044: "ZH",
	8045: "ZH",
	8046: "ZH",
	8047: "ZH",
	8048: "ZH",
	8049: "ZH",
	8050: "ZH",
	8051: "ZH",
	8052: "ZH",
	8053: "ZH",
	8055: "ZH",
	8057: "ZH",
	8063: "ZH",
	8064: "ZH",
	8099: "ZH",
	8102: "ZH",
	8103: "ZH",
	8104: "ZH",
	8105: "ZH",
	8106: "ZH",
	8107: "ZH",
	8108: "ZH",
	8109: "AG",
	8112: "ZH",
	8113: "ZH",
	8114: "ZH",
	8115: "ZH",
	8117: "ZH",
	8118: "ZH",
	8121: "ZH",
	8122: "ZH",
	8123: "ZH",
	8124: "ZH",
	8125: "ZH",
	8126: "ZH",
	8127: "ZH",
	8132: "ZH",
	8133: "ZH",
	8134: "ZH",
	8135: "ZH",
	8136: "ZH",
	8142: "ZH",
	8143: "ZH",
	8152: "ZH",
	8153: "ZH",
	8154: "ZH",
	8155: "ZH",
	8156: "ZH",
	8157: "ZH",
	8158: "ZH",
	8162: "ZH",
	8164: "ZH",
	8165: "ZH",
	8166: "ZH",
	8172: "ZH",
	8173: "ZH",
	8174: "ZH",
	8175: "ZH",
	8180: "ZH",
	8181: "ZH",
	8182: "ZH",
	8184: "ZH",
	8185: "ZH",
	8187: "ZH",
	8192: "ZH",
	8193: "ZH",
	8194: "ZH",
	8195: "ZH",
	8196: "ZH",
	8197: "ZH",
	8200: "SH",
	8203: "SH",
	8207: "SH",
	8208: "SH",
	8212: "ZH",
	8213: "SH",
	8214: "SH",
	8215: "SH",
	8216: "SH",
	8217: "SH",
	8218: "SH",
	8219: "SH",
	8222: "SH",
	8223: "SH",
	8224: "SH",
	8225: "SH",
	8226: "SH",
	8228: "SH",
	8231: "SH",
	8232: "SH",
	8233: "SH",
	8234: "SH",
	8235: "SH",
	8236: "SH",
	8238: "DE",
	8239: "SH",
	8240: "SH",
	8241: "SH",
	8242: "SH",
	8243: "SH",
	8245: "ZH",
	8246: "ZH",
	8247: "ZH",
	8248: "ZH",
	8252: "TG",
	8253: "TG",
	8254: "TG",
	8255: "TG",
	8259: "TG",
	8260: "SH",
	8261: "SH",
	8262: "SH",
	8263: "SH",
	8264: "TG",
	8265: "TG",
	8266: "TG",
	8267: "TG",
	8268: "TG",
	8269: "TG",
	8272: "TG",
	8273: "TG",
	8274: "TG",
	8280: "TG",
	8302: "ZH",
	8303: "ZH",
	8304: "ZH",
	8305: "ZH",
	8306: "ZH",
	8307: "ZH",
	8308: "ZH",
	8309: "ZH",
	8310: "ZH",
	8311: "ZH",
	8312: "ZH",
	8314: "ZH",
	8315: "ZH",
	8317: "ZH",
	8320: "ZH",
	8322: "ZH",
	8330: "ZH",
	8331: "ZH",
	8332: "ZH",
	8335: "ZH",
	8340: "ZH",
	8342: "ZH",
	8344: "ZH",
	8345: "ZH",
	8352: "ZH",
	8353: "ZH",
	8354: "ZH",
	8355: "TG",
	8356: "TG",
	8357: "TG",
	8360: "TG",
	8362: "TG",
	8363: "TG",
	8370: "TG",
	8372: "TG",
	8374: "TG",
	8376: "TG",
	8400: "ZH",
	8404: "ZH",
	8405: "ZH",
	8406: "ZH",
	8408: "ZH",
	8409: "ZH",
	8412: "ZH",
	8413: "ZH",
	8414: "ZH",
	8415: "ZH",
	8416: "ZH",
	8418: "ZH",
	8421: "ZH",
	8422: "ZH",
	8424: "ZH",
	8425: "ZH",
	8426: "ZH",
	8427: "ZH",
	8428: "ZH",
	8442: "ZH",
	8444: "ZH",
	8447: "ZH",
	8450: "ZH",
	8451: "ZH",
	8452: "ZH",
	8453: "ZH",
	8454: "SH",
	8455: "SH",
	8457: "ZH",
	8458: "ZH",
	8459: "ZH",
	8460: "ZH",
	8461: "ZH",
	8462: "ZH",
	8463: "ZH",
	8464: "ZH",
	8465: "ZH",
	8466: "ZH",
	8467: "ZH",
	8468: "ZH",
	8471: "ZH",
	8472: "ZH",
	8474: "ZH",
	8475: "ZH",
	8476: "ZH",
	8477: "ZH",
	8478: "ZH",
	8479: "ZH",
	8482: "ZH",
	8483: "ZH",
	8484: "ZH",
	8486: "ZH",
	8487: "ZH",
	8488: "ZH",
	8489: "ZH",
	8492: "ZH",
	8493: "ZH",
	8494: "ZH",
	8495: "ZH",
	8496: "ZH",
	8497: "ZH",
	8498: "ZH",
	8499: "ZH",
	8500: "TG",
	8505: "TG",
	8506: "TG",
	8507: "TG",
	8508: "TG",
	8512: "TG",
	8514: "TG",
	8522: "TG",
	8523: "ZH",
	8524: "TG",
	8525: "TG",
	8526: "TG",
	8532: "TG",
	8535: "TG",
	8536: "TG",
	8537: "TG",
	8542: "ZH",
	8543: "ZH",
	8544: "ZH",
	8545: "ZH",
	8546: "ZH",
	8547: "TG",
	8548: "ZH",
	8552: "TG",
	8553: "TG",
	8554: "TG",
	8555: "TG",
	8556: "TG",
	8558: "TG",
	8560: "TG",
	8561: "TG",
	8564: "TG",
	8565: "TG",
	8566: "TG",
	8570: "TG",
	8572: "TG",
	8573: "TG",
	8574: "TG",
	8575: "TG",
	8576: "TG",
	8577: "TG",
	8580: "TG",
	8581: "TG",
	8582: "TG",
	8583: "TG",
	8584: "TG",
	8585: "TG",
	8586: "TG",
	8587: "TG",
	8588: "TG",
	8589: "TG",
	8590: "TG",
	8592: "TG",
	8593: "TG",
	8594: "TG",
	8595: "TG",
	8596: "TG",
	8597: "TG",
	8598: "TG",
	8599: "TG",
	8600: "ZH",
	8602: "ZH",
	8603: "ZH",
	8604: "ZH",
	8605: "ZH",
	8606: "ZH",
	8607: "ZH",
	8608: "ZH",
	8610: "ZH",
	8614: "ZH",
	8615: "ZH",
	8616: "ZH",
	8617: "ZH",
	8618: "ZH",
	8620: "ZH",
	8623: "ZH",
	8624: "ZH",
	8625: "ZH",
	8626: "ZH",
	8627: "ZH",
	8630: "ZH",
	8632: "ZH",
	8633: "ZH",
	8634: "ZH",
	8635: "ZH",
	8636: "ZH",
	8637: "ZH",
	8638: "SG",
	8639: "ZH",
	8640: "SZ",
	8645: "SG",
	8646: "SG",
	8700: "ZH",
	8702: "ZH",
	8703: "ZH",
	8704: "ZH",
	8706: "ZH",
	8707: "ZH",
	8708: "ZH",
	8712: "ZH",
	8713: "ZH",
	8714: "ZH",
	8715: "SG",
	8716: "SG",
	8717: "SG",
	8718: "SG",
	8722: "SG",
	8723: "SG",
	8725: "SG",
	8726: "SG",
	8727: "SG",
	8730: "SG",
	8732: "SG",
	8733: "SG",
	8734: "SG",
	8735: "SG",
	8737: "SG",
	8738: "SG",
	8739: "SG",
	8750: "GL",
	8751: "UR",
	8752: "GL",
	8753: "GL",
	8754: "GL",
	8755: "GL",
	8756: "GL",
	8757: "GL",
	8758: "GL",
	8762: "GL",
	8765: "GL",
	8766: "GL",
	8767: "GL",
	8772: "GL",
	8773: "GL",
	8774: "GL",
	8775: "GL",
	8777: "GL",
	8782: "GL",
	8783: "GL",
	8784: "GL",
	8800: "ZH",
	8802: "ZH",
	8803: "ZH",
	8804: "ZH",
	8805: "ZH",
	8806: "SZ",
	8807: "SZ",
	8808: "SZ",
	8810: "ZH",
	8815: "ZH",
	8816: "ZH",
	8820: "ZH",
	8824: "ZH",
	8825: "ZH",
	8832: "SZ",
	8833: "ZH",
	8834: "SZ",
	8835: "SZ",
	8836: "SZ",
	8840: "SZ",
	8841: "SZ",
	8842: "SZ",
	8843: "SZ",
	8844: "SZ",
	8845: "SZ",
	8846: "SZ",
	8847: "SZ",
	8849: "SZ",
	8852: "SZ",
	8853: "SZ",
	8854: "SZ",
	8855: "SZ",
	8856: "SZ",
	8857: "SZ",
	8858: "SZ",
	8862: "SZ",
	8863: "SZ",
	8864: "SZ",
	8865: "GL",
	8866: "GL",
	8867: "GL",
	8868: "GL",
	8872: "SG",
	8873: "SG",
	8874: "GL",
	8877: "SG",
	8878: "SG",
	8880: "SG",
	8881: "SG",
	8882: "SG",
	8883: "SG",
	8884: "SG",
	8885: "SG",
	8886: "SG",
	8887: "SG",
	8888: "SG",
	8889: "SG",
	8890: "SG",
	8892: "SG",
	8893: "SG",
	8894: "SG",
	8895: "SG",
	8896: "SG",
	8897: "SG",
	8898: "SG",
	8902: "ZH",
	8903: "ZH",
	8904: "ZH",
	8905: "AG",
	8906: "ZH",
	8907: "ZH",
	8908: "ZH",
	8909: "ZH",
	8910: "ZH",
	8911: "ZH",
	8912: "ZH",
	8913: "ZH",
	8914: "ZH",
	8915: "ZH",
	8916: "AG",
	8917: "AG",
	8918: "AG",
	8919: "AG",
	8925: "ZH",
	8926: "ZH",
	8932: "ZH",
	8933: "ZH",
	8934: "ZH",
	8942: "ZH",
	8951: "ZH",
	8952: "ZH",
	8953: "ZH",
	8954: "ZH",
	8955: "ZH",
	8956: "AG",
	8957: "AG",
	8962: "AG",
	8964: "AG",
	8965: "AG",
	8966: "AG",
	8967: "AG",
	9000: "SG",
	9007: "SG",
	9008: "SG",
	9010: "SG",
	9011: "SG",
	9012: "SG",
	9014: "SG",
	9015: "SG",
	9016: "SG",
	9030: "SG",
	9032: "SG",
	9033: "SG",
	9034: "SG",
	9035: "AR",
	9036: "SG",
	9037: "AR",
	9038: "AR",
	9042: "AR",
	9043: "AR",
	9044: "AR",
	9050: "AI",
	9052: "AR",
	9053: "AR",
	9054: "AI",
	9055: "AR",
	9056: "AR",
	9057: "AI",
	9058: "AI",
	9062: "AR",
	9063: "AR",
	9064: "AR",
	9100: "AR",
	9103: "AR",
	9104: "AR",
	9105: "AR",
	9107: "AR",
	9108: "AI",
	9112: "AR",
	9113: "SG",
	9114: "SG",
	9115: "SG",
	9116: "SG",
	9122: "SG",
	9123: "SG",
	9125: "SG",
	9126: "SG",
	9127: "SG",
	9200: "SG",
	9203: "SG",
	9204: "SG",
	9205: "SG",
	9212: "SG",
	9213: "TG",
	9214: "TG",
	9215: "TG",
	9216: "TG",
	9217: "TG",
	9220: "TG",
	9223: "TG",
	9225: "TG",
	9230: "SG",
	9231: "SG",
	9240: "SG",
	9242: "SG",
	9243: "SG",
	9244: "SG",
	9245: "SG",
	9246: "SG",
	9247: "SG",
	9248: "SG",
	9249: "SG",
	9300: "SG",
	9304: "SG",
	9305: "SG",
	9306: "TG",
	9308: "SG",
	9312: "SG",
	9313: "SG",
	9314: "TG",
	9315: "TG",
	9320: "TG",
	9322: "TG",
	9323: "SG",
	9325: "TG",
	9326: "TG",
	9327: "SG",
	9400: "SG",
	9402: "SG",
	9403: "SG",
	9404: "SG",
	9405: "AR",
	9410: "AR",
	9411: "AR",
	9413: "AI",
	9414: "AR",
	9422: "SG",
	9423: "SG",
	9424: "SG",
	9425: "SG",
	9426: "AR",
	9427: "AR",
	9428: "AR",
	9430: "SG",
	9434: "SG",
	9435: "SG",
	9436: "SG",
	9437: "SG",
	9442: "SG",
	9443: "SG",
	9444: "SG",
	9445: "SG",
	9450: "SG",
	9451: "SG",
	9452: "SG",
	9453: "SG",
	9462: "SG",
	9463: "SG",
	9464: "SG",
	9465: "SG",
	9466: "SG",
	9467: "SG",
	9468: "SG",
	9469: "SG",
	9470: "SG",
	9472: "SG",
	9473: "SG",
	9475: "SG",
	9476: "SG",
	9477: "SG",
	9478: "SG",
	9479: "SG",
	9485: "FL",
	9486: "FL",
	9487: "FL",
	9488: "FL",
	9490: "FL",
	9491: "FL",
	9492: "FL",
	9493: "FL",
	9494: "FL",
	9495: "FL",
	9496: "FL",
	9497: "FL",
	9498: "FL",
	9500: "SG",
	9502: "TG",
	9503: "TG",
	9504: "TG",
	9506: "TG",
	9507: "TG",
	9508: "TG",
	9512: "SG",
	9514: "TG",
	9515: "TG",
	9517: "TG",
	9523: "SG",
	9524: "SG",
	9525: "SG",
	9526: "SG",
	9527: "SG",
	9532: "TG",
	9533: "SG",
	9534: "SG",
	9535: "TG",
	9536: "SG",
	9542: "TG",
	9543: "TG",
	9545: "TG",
	9546: "TG",
	9547: "TG",
	9548: "TG",
	9552: "SG",
	9553: "TG",
	9554: "TG",
	9555: "TG",
	9556: "TG",
	9562: "TG",
	9565: "TG",
	9573: "TG",
	9601: "SG",
	9602: "SG",
	9604: "SG",
	9606: "SG",
	9607: "SG",
	9608: "SG",
	9612: "SG",
	9613: "SG",
	9614: "SG",
	9615: "SG",
	9620: "SG",
	9621: "SG",
	9622: "SG",
	9630: "SG",
	9631: "SG",
	9633: "SG",
	9642: "SG",
	9643: "SG",
	9650: "SG",
	9651: "SG",
	9652: "SG",
	9655: "SG",
	9656: "SG",
	9657: "SG",
	9658: "SG",
};
