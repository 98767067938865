import { useCentralStore } from "@/dentlab/src/store/Central";
import { Logger } from "../../../../../../lib/logger/Logger";
import { TariffEntityType } from "../../../../../../lib/supabase/supabaseTypes";
import { DialogPageWithAddEntityForm } from "../../../../components/dialog-components/dialog-pages/dialog-page-with-add-entity-form/dialog-page-with-add-entity-form.component";
import { DefaultPositionsList } from "../../../../components/list-components/default-positions-list.component";
import { useJobItemsContext } from "../../../../context/JobItemsContext";
import { TariffForm } from "../../../../forms/form-components/tariff-form";
import { tariffsColumns } from "./tariffs.columns";

export const ManageTariffsTab: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const { recognizeNewTariff, handleUpdateTariff } = useJobItemsContext();
	const { tariffs } = useCentralStore((state) => ({
		tariffs: Object.values(state.tariffs),
	}));
	return (
		<DialogPageWithAddEntityForm
			FormComponent={TariffForm}
			onClose={onClose}
			tools={<></>}
			isFormOpen={false}
			formAddButtonLabel="Neuer Tarif"
			formAddTitle="Tarif hinzufügen"
			onAddEntity={(tariff: TariffEntityType) => {
				recognizeNewTariff(tariff);
			}}
		>
			<DefaultPositionsList
				rows={tariffs}
				columns={tariffsColumns}
				gridConfigName="tariffs"
				onRowUpdate={(row: any) => {
					Logger.info("ManageTariffsTab: onRowUpdate", row);
					handleUpdateTariff(row);
				}}
			/>
		</DialogPageWithAddEntityForm>
	);
};
