import { useToast } from "../../../../../context/ToastContext";
import { Logger } from "../../../../../../../lib/logger/Logger";
import { CustomTable } from "../../../../../components/custom-table/custom-table.component";
import { TableConfig } from "../../../../../types/types";
import { jobItemsTableConfigDefault } from "./job-items-table-config-default";
import { JobItemEntityType } from "../../../../../../../lib/supabase/supabaseTypes";
import { Box, TextField } from "@mui/material";
import { JobItemsTariffsSelection } from "./job-items-table-components/job-item-tariffs-selection.component";
import { JobItemsArticlesSelection } from "./job-items-table-components/job-items-articles-selection.component";
import { useEffect, useState } from "react";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { JobStatusEnum } from "@/lib/types/job";
import { showNotification } from "@/dentlab/src/store/Central/selectors";

function addJobItemAllowed(status: JobStatusEnum) {
	const allowed = status === JobStatusEnum.IN_PROGRESS;
	if (!allowed) {
		showNotification({
			message:
				"Positionen können nur bearbeitet werden, wenn der Auftrag noch nicht abgeschlossen ist.",
			type: "error",
		});
	}
	return allowed;
}

export const JobItemsTable: React.FC<{
	jobDocumentId: number;
	status: JobStatusEnum;
	disabled?: boolean;
}> = ({ jobDocumentId, status, disabled = false }) => {
	const { gridConfig, updateGridConfig } = useCentralStore((state) => ({
		gridConfig: state.gridConfig,
		updateGridConfig: state.updateGridConfig,
	}));

	const {
		addTariff,
		addArticle,
		deleteJobItems,
		pasteJobItems,
		changeJobItem,
		jobItemsForDocuments,
		fetchJobItems,
	} = useJobStore((state) => ({
		jobItemsForDocuments: state.jobItemsForDocuments,
		addTariff: state.addTariff,
		addArticle: state.addArticle,
		deleteJobItems: state.deleteJobItems,
		pasteJobItems: state.pasteJobItems,
		changeJobItem: state.changeJobItem,
		fetchJobItems: state.fetchJobItems,
	}));

	useEffect(() => {
		fetchJobItems(jobDocumentId);
	}, []);

	const jobItems = jobItemsForDocuments?.[jobDocumentId] ?? [];
	const handleRowChange = (newRow: any) => {
		if (addJobItemAllowed(status)) changeJobItem(newRow);
	};

	const handleAddTariff = (value: string, options?: { force: boolean }) => {
		if (addJobItemAllowed(status)) addTariff(value, jobDocumentId, options);
	};
	const handleAddArticle = (value: string, options?: { force: boolean }) => {
		if (addJobItemAllowed(status))
			addArticle(value, jobDocumentId, options);
	};

	const handleRowDelete = (rowIds: string[]) => {
		if (addJobItemAllowed(status)) deleteJobItems(rowIds, jobDocumentId);
	};

	const handlePasteRows = (pastedRows: any[]) => {
		if (addJobItemAllowed(status)) pasteJobItems(pastedRows, jobDocumentId);
	};

	const handleCutOrDeleteRows = (cutRows: any[]) => {
		if (addJobItemAllowed(status))
			handleRowDelete(cutRows.map((row) => row.id));
	};

	const handleConfigChange = async (newConfig: TableConfig) => {
		updateGridConfig(newConfig);
	};

	return (
		<div>
			<CustomTable
				rows={jobItems}
				userConfig={gridConfig}
				defaultConfig={jobItemsTableConfigDefault}
				onConfigChange={handleConfigChange}
				onRowChange={handleRowChange}
				onSelectedChange={(selected) => {
					Logger.log("onSelectedChange", selected);
				}}
				onPasteRows={handlePasteRows}
				onCutRows={handleCutOrDeleteRows}
				dataCompatibility={["job_items"]}
				onDeleteRows={handleCutOrDeleteRows}
				toolbarChildren={
					<Toolbar
						onAddTariff={handleAddTariff}
						onAddArticle={handleAddArticle}
						jobItems={jobItems}
						jobDocumentId={jobDocumentId}
					/>
				}
				disabled={disabled}
			/>
		</div>
	);
};

// TODO: NON-DEMO add density selection
// import { CustomDensitySelector } from "../../../../components/custom-density-selector.component";
// <CustomDensitySelector density="standard" onChange={() => {}} />

const Toolbar: React.FC<{
	onAddTariff: (value: string) => void;
	onAddArticle: (value: string) => void;
	jobItems: JobItemEntityType[];
	jobDocumentId: number;
}> = ({ onAddTariff, onAddArticle, jobItems, jobDocumentId }) => {
	const { articles, tariffs } = useCentralStore((state) => ({
		articles: Object.values(state.articles).sort((a, b) =>
			a.code_e.localeCompare(b.code_e)
		),
		tariffs: Object.values(state.tariffs).sort((a, b) =>
			a.code_e.localeCompare(b.code_e)
		),
	}));

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				width: "100%",
				height: "30.75px", // TODO: fix this hack
			}}
		>
			<TarifcodeButton
				onSubmit={(val) => {
					onAddTariff(val);
				}}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<JobItemsTariffsSelection
					jobItems={jobItems}
					rows={tariffs}
					onSelect={onAddTariff}
					jobDocumentId={jobDocumentId}
				/>
				<JobItemsArticlesSelection
					rows={articles}
					label="Artikel"
					onSelect={onAddArticle}
					jobItems={jobItems}
					jobDocumentId={jobDocumentId}
				/>
			</div>
		</div>
	);
};

const TarifcodeButton: React.FC<{
	onSubmit: (value: string) => void;
}> = ({ onSubmit }) => {
	const [value, setValue] = useState<string>("");

	const { showToast } = useToast();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const handleClick = () => {
		if (value) {
			onSubmit(value);
			setValue("");
		} else {
			showToast("Bitte einen Wert eingeben", "error");
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleClick();
		}
	};

	return (
		<Box display="flex" justifyContent="center" alignItems="center" gap={1}>
			<TextField
				value={value || ""}
				onChange={handleChange}
				placeholder="Via Tarifcode"
				variant="outlined"
				size="small"
				sx={{
					width: "105px",
					// match styles of MUI buttons to the right
					"& .MuiInputBase-input": {
						padding: "3px 9px",
						height: "24px",
					},
				}}
				InputProps={{
					onKeyDown: handleKeyPress,
					sx: {
						fontSize: "14px",
					},
				}}
			/>
		</Box>
	);
};
