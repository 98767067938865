import { useAccountingStore } from "@/dentlab/src/store/Accounting";
import { AccountingViewType } from "@/lib/supabase/supabaseTypes";
import { CancelOutlined } from "@mui/icons-material";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from "@mui/material";
import { FileIcon } from "lucide-react";
import { useMemo, useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { LoadingSkeletonRows } from "@/components/src/loading/loading-skeleton-rows.component";
import { useStorage } from "@/dentlab/src/hooks/useStorage";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { AccountingStatus } from "../types";
import { EmptyDataPlaceholder } from "@/components/src/empty-with-label/empty-data-placeholder.component";

const headCells = [
	{
		label: "Rechnungsnr.",
	},
	{
		label: "Rechnungsdatum",
	},
	{
		label: "Betrag",
	},
	{
		label: "Empfänger",
	},
	{
		label: "Zahlstatus",
	},
];

export const AccountingTable: React.FC<{
	accountingData: AccountingViewType[];
	loading?: boolean;
}> = ({ accountingData, loading }) => {
	const [page, setPage] = useState(0);
	const { openFileInNewTab } = useStorage();
	const { markInvoiceAsPaid, cancelInvoice } = useAccountingStore(
		(state) => ({
			markInvoiceAsPaid: state.markInvoiceAsPaid,
			cancelInvoice: state.cancelInvoice,
		})
	);
	const rowsPerPage = 100;
	const cursor = page * rowsPerPage;

	const theme = useTheme();

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales.deDE),
		[theme]
	);

	if (!loading && accountingData.length === 0) {
		return <EmptyDataPlaceholder label="Keine Rechnungen vorhanden" />;
	}

	return (
		<ThemeProvider theme={themeWithLocale}>
			<Table>
				<TableHead>
					<TableRow>
						{headCells.map((headCell) => (
							<TableCell key={headCell.label}>
								<div className="text-md">{headCell.label}</div>
							</TableCell>
						))}
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loading ? (
						<LoadingSkeletonRows rows={10} columns={6} />
					) : (
						accountingData
							.slice(cursor, cursor + rowsPerPage)
							.map((row) => {
								return (
									<TableRow key={row.invoice_id}>
										<TableCell>
											{row.invoice_doc_number}
										</TableCell>
										<TableCell>
											{new Date(
												row.invoice_date ?? ""
											).toLocaleDateString("de-ch")}
										</TableCell>
										<TableCell>
											{row.invoice_value?.toFixed(2)}
										</TableCell>
										<TableCell>
											{row.client_name ??
												row.guarantor_name}
										</TableCell>
										<TableCell className="flex justify-center">
											<div className="bg-neutral-200 w-max rounded-full py-0.5 px-4 flex justify-center items-center">
												{row.status}
											</div>
										</TableCell>
										<TableCell>
											<div className="flex gap-2">
												<Tooltip
													title={
														"Rechnungsdatei öffnen"
													}
												>
													<IconButton
														onClick={() => {
															if (!row.path_name)
																return;
															openFileInNewTab(
																row.path_name
															);
														}}
													>
														<FileIcon />
													</IconButton>
												</Tooltip>
												<Tooltip
													title={
														"Als bezahlt markieren"
													}
												>
													<IconButton
														disabled={
															row.status ===
																AccountingStatus.STORNIERT ||
															row.status ===
																AccountingStatus.BEZAHLT
														}
														onClick={() => {
															if (!row.invoice_id)
																return;
															markInvoiceAsPaid(
																row.invoice_id
															);
														}}
													>
														<ReceiptIcon />
													</IconButton>
												</Tooltip>
												<Tooltip
													title={
														"Rechnung stornieren"
													}
												>
													<IconButton
														disabled={
															row.status?.toLowerCase() ===
															"storniert"
														}
														onClick={() => {
															if (!row.invoice_id)
																return;
															cancelInvoice(
																row.invoice_id
															);
														}}
													>
														<CancelOutlined />
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})
					)}
					<TablePagination
						// Need to have an array of 1 element to prevent the pagination from showing rows per page options
						rowsPerPageOptions={[rowsPerPage]}
						component="div"
						count={accountingData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={(e, n) => {
							if (n <= 0) {
								setPage(0);
								return;
							}
							setPage(n);
						}}
					/>
				</TableBody>
			</Table>
		</ThemeProvider>
	);
};
