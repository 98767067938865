export const nameTitleOptions = [
	{ value: "Herr", label: "Herr" },
	{ value: "Frau", label: "Frau" },
	{ value: "Herr Dr.", label: "Herr Dr." },
	{ value: "Frau Dr.", label: "Frau Dr." },
	{ value: "Herr Prof.", label: "Herr Prof." },
	{ value: "Frau Prof.", label: "Frau Prof." },
	{ value: "Frau Dr. med. dent.", label: "Frau Dr. med. dent." },
	{ value: "Herr Dr. med. dent.", label: "Herr Dr. med. dent." },
];
