import { CircularProgress, Skeleton } from "@mui/material";

export const Loading: React.FC<{
	type?: "circular" | "skeleton";
	height?: string;
	width?: string;
}> = ({ type, height, width }) => {
	if (type === "skeleton") {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: height || "100%",
					width: width || "100%",
				}}
			>
				<Skeleton variant="text" width={90} />
			</div>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: height || "100%",
				width: width || "100%",
			}}
		>
			<CircularProgress />
		</div>
	);
};
