import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import "./custom-cells.css";
import { useToast } from "@/dentlab/src/context/ToastContext";

function isTechnician(field: string | undefined) {
	return field?.includes("techn");
}

function isFirstTechnician(field: string | undefined) {
	return field === "techn_perc";
}

function isDisabled(field: string | undefined) {
	return isFirstTechnician(field); // if it's profile 1, the percentage is calculated instead of set
}

export const PercentageCustomCell = (props: GridCustomCellProps) => {
	const { showToast } = useToast();
	const [percentage, setPercentage] = useState<number>(0);

	useEffect(() => {
		let percentageValue = 0;
		if (isTechnician(props.field) && isFirstTechnician(props.field)) {
			percentageValue =
				100 -
				(props.dataItem.techn_perc_2 ?? 0) -
				(props.dataItem.techn_perc_3 ?? 0);
		} else {
			percentageValue = props.field ? props.dataItem[props.field] : 0;
		}
		setPercentage(percentageValue);
	}, [props.dataItem, props.field]);

	const handlePercentageChange = (value: number) => {
		setPercentage(value);
	};

	const handlePercentageSubmit = (event: unknown) => {
		if (percentage > 100 || percentage < 0) {
			showToast("Prozentwert muss zwischen 0 und 100 liegen", "error");
			setPercentage(props.field ? props.dataItem[props.field] || 0 : 0);
			return;
		}
		if (props.onChange) {
			props.onChange({
				dataItem: props.dataItem,
				field: props.field,
				value: percentage,
				syntheticEvent: event as React.ChangeEvent<
					HTMLTextAreaElement | HTMLInputElement
				>,
				dataIndex: props.dataIndex,
			});
		}
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (event.key === "Enter") {
			handlePercentageSubmit(event);
			(event.target as HTMLInputElement).blur();
		}
	};

	return (
		<td className="custom-td">
			<FormControl
				variant="outlined"
				sx={{
					padding: 0,
					margin: 0,
				}}
			>
				<OutlinedInput
					value={percentage}
					onChange={(event) => {
						handlePercentageChange(Number(event.target.value));
					}}
					onBlur={(event) => {
						handlePercentageSubmit(event);
					}}
					onKeyDown={handleKeyDown}
					id="outlined-adornment-weight"
					endAdornment={
						<InputAdornment position="end">%</InputAdornment>
					}
					aria-describedby="outlined-weight-helper-text"
					inputProps={{
						"aria-label": "weight",
					}}
					disabled={isDisabled(props.field)}
					size="small"
					sx={{
						height: "22px", // match height of TeamProfileSelect
						width: "70px", // width to properly fit up to 3 digits
						margin: 0,
						fontSize: "12px",
						// if the percentage is invalid, the error prop doesn't work, so we add a red border
						"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
							border:
								isDisabled(props.field) &&
								(percentage > 100 || percentage < 0)
									? "1px solid #d32f2f"
									: "",
						},
					}}
					error={percentage > 100 || percentage < 0}
				/>
			</FormControl>
		</td>
	);
};
